import { AUTHOR_PLATFORM } from '../../utils/constants'
import BaseRequest from '../BaseRequest'
export default class AuthRequest extends BaseRequest {
  me () {
    return this.get('auth/v4/me')
  }

  getPersons (params) {
    return this.get('auth/persons', params)
  }

  createPerson (params) {
    const transParams = {
      ...params,
      author_platform: AUTHOR_PLATFORM.WEB
    }
    return this.post('auth/persons', transParams)
  }

  updatePerson (id, params) {
    return this.post(`auth/persons/${id}`, params)
  }

  changeAvatarUser (data) {
    return this.post('auth/upload', data)
  }

  createDisease (data) {
    return this.post('auth/person-diseases/create', data)
  }

  getDisease (id, showMessage = true) {
    return this.get(`auth/person-diseases/${id}`, null, showMessage)
  }

  updateDisease (id, data) {
    return this.put(`auth/person-diseases/${id}/update`, data)
  }

  getDiseases (params, showMessage) {
    return this.get('auth/person-diseases', params, showMessage)
  }

  getDiseasesV2 (params, showMessage) {
    return this.get('go/my-account/person-diseases', params, showMessage)
  }

  multipleUploadDocuments (data) {
    return this.post('auth/documents-multiple', data)
  }

  getDoctors (params) {
    return this.get('auth/doctors', params)
  }

  inviteDoctor (data) {
    return this.post('auth/v3/care-teams/invite-doctor', data)
  }

  deleteDoctor (data, showMessage) {
    return this.post(
      'auth/v3/care-teams/remove-doctor',
      data,
      null,
      showMessage
    )
  }

  getDocuments (params) {
    return this.get('auth/documents', params)
  }

  getNotifications (params = {}) {
    return this.get('auth/notifications', params)
  }

  getTopicDetail (id) {
    return this.get(`auth/topics/${id}/show`)
  }

  getDoctorDetail (id) {
    return this.get(`auth/doctors/${id}`)
  }

  getLinkDownloadAws (params, header) {
    return this.post('core/v1/uploads/s3-get-presigned', params, header)
  }

  pacCreate (params) {
    return this.post('auth/pacs-dicoms', params)
  }

  pacUpdate (id, params) {
    return this.post(`auth/pacs-dicoms/${id}`, params)
  }

  getPacs (params) {
    return this.get('auth/pacs-dicoms', params)
  }

  getUrlPac (params) {
    return this.get('auth/pacs-dicoms/get-url', params)
  }

  getPackageMembers (params) {
    return this.get('auth/v3/member-packages', params)
  }

  showPackageMembers (id, params) {
    return this.get(`auth/v3/member-packages/${id}`, params)
  }

  getPackageCombo (id, params) {
    return this.get(`auth/v3/member-packages/${id}/package-combo`, params)
  }

  showPackageCombo (id, params) {
    return this.get(`auth/v3/package-combo/${id}`, params)
  }

  getPackageOrder (params) {
    return this.get('auth/v4/member-package-orders/latest', params)
  }

  showPackageOrder (id, params) {
    return this.get(`auth/v3/member-package-orders/${id}`)
  }

  postPackageOrder (params) {
    return this.post('auth/v3/member-package-orders', params)
  }

  paidPackageOrder (id) {
    return this.post(`auth/v3/member-package-orders/${id}/paid`)
  }

  cancelPackageOrder (id) {
    return this.post(`auth/v3/member-package-orders/${id}/cancel`)
  }

  getIndications (params) {
    return this.get('auth/indications', params)
  }

  getIndicationById (id, params) {
    return this.get(`auth/indications/${id}`, params)
  }

  getLinkDownloadAwsRc (params, header, showMessage = true) {
    return this.post(
      'core/v1/uploads/s3-get-rc-presigned',
      params,
      header,
      showMessage
    )
  }

  getHealthRecords (params) {
    return this.get('auth/health-records', params)
  }

  getHeathRecordById (id, params) {
    return this.get(`auth/health-records/${id}`, params)
  }

  getConsultationById (id, params) {
    return this.get(`auth/consultations/${id}`, params)
  }

  getInvoices (id, params) {
    return this.get(`invoices/${id}`, params)
  }

  postBankAccounts (id, params) {
    return this.post(`invoices/${id}/bank-accounts`, params)
  }

  postInvoices (id, params) {
    return this.post(`invoices/${id}/status`, params)
  }

  // Medical register
  getDetailWorkSpace (id) {
    return this.get(`auth/clinics/${id}`)
  }

  getListConsulting (params) {
    return this.get('v1/auth/consulting-rooms', params)
  }

  getListDoctorInCr (params) {
    return this.get('auth/list-doctors', params)
  }

  uploadPatientFiles (params) {
    return this.post('auth/patient-visits/files', params)
  }

  getPatientVisitsById (id) {
    return this.get(`auth/v1/patient-visits/${id}`)
  }

  getListPatientVisitById (id) {
    return this.get(`auth/health-records/${id}/patientvisit`)
  }

  deletePatientFile (id) {
    return this.delete(`auth/patient-visits/files/${id}`)
  }

  getPrescriptionByID (id) {
    return this.get(`auth/prescriptions/${id}`)
  }

  getEmrDocument (params) {
    if (!params) return
    return this.post('auth/emr-documents/printing-url', params)
  }

  /**
   * E-Commerce
   */
  // Orders
  getECommerceOrders (params) {
    return this.get('go/customer/products/orders', params)
  }

  getECommerceOrdersById (id, params) {
    return this.get(`go/customer/products/orders/${id}`, params)
  }

  createECommerceOrders (data) {
    return this.post('go/partner/order/create', data)
  }

  createECommerceOrdersV2 (data) {
    return this.post('go/partner/order/createv2', data, null, false)
  }

  uploadFileTenant (params) {
    return this.post('go/core/files/requestUpload', params)
  }

  putOrderFormItem (id, params) {
    return this.put(`go/orders/products/order_form_items/${id}`, params)
  }

  getMyCircles (params) {
    return this.get('go/circle/patient/list', params)
  }

  getCircleDetail (id) {
    return this.get('go/circle/patient/' + id)
  }

  getCircleMembers (params) {
    return this.get('go/circle/patient/listmember', params)
  }

  getTabsTotalNumber (id) {
    return this.get(`go/circle/doctor/statistic/${id}`)
  }

  getMemberByUserId (circleId, userId, header = null, show = true) {
    return this.post(
      `doctor/circles/${circleId}/member/${userId}`,
      null,
      header,
      show
    )
  }

  getCircles (params) {
    return this.get('go/circle/patient/code', params)
  }

  joinCircle (id) {
    return this.post(`go/circle/patient/request/${id}`)
  }

  createPersonV2 (data) {
    return this.post('go/auth/persons', data)
  }

  updatePersonV2 (id, data) {
    return this.put(`go/auth/persons/${id}`, data)
  }

  getDoctorWorkTimeSettings (params) {
    return this.get('go/doctor_work_time_settings', params)
  }

  getDoctorSlotByCrId (data) {
    return this.post('go/getDoctorSlotByCrId', data)
  }

  getDoctorSlotByCrIdV2 (data) {
    return this.post('go/getDoctorSlotByCrIdV2', data)
  }

  bookAppointment (data) {
    return this.post('go/core/doctor/appointment', data)
  }

  getAppointmentDetail (id) {
    return this.get(`go/core/doctor/appointment/${id}`)
  }

  getPublicConsultingRoomsByWsId (ws_id) {
    return this.get(`go/core/cr/public/workspace/${ws_id}`)
  }

  generateUrlOnePay (data) {
    return this.post('go/core/payment/requestpayment', data)
  }

  getValidatePaymentResponse (params, data) {
    return this.post(`go/core/payment/validatepaymentresponse${params}`, data)
  }

  getClinicalServices (params) {
    return this.get('go/clinical_services', params)
  }

  // Get relatives data: only use for Medical Register feature
  getPersonsByWorkspaceId (ws_id) {
    return this.get(`go/auth/persons/workspace?ws_id=${ws_id}`)
  }

  confirmValidateUser (params) {
    return this.post('go/auth/persons/confirm_acc_slip', params)
  }

  getListPaymentReceipt (params) {
    return this.get('go/service_transaction_slip', params)
  }

  getDetailPaymentReceipt (id) {
    return this.get(`go/service_transaction_slip/my_slip/${id}`)
  }

  postRequestPayment (params) {
    return this.post('go/core/payment/requestpayment', params)
  }

  createSlipOrder (params) {
    return this.post('go/service-order/create', params)
  }

  // My Account
  getStatisticTotals (params) {
    return this.get('go/my-account/statistic-totals', params)
  }

  getLinkHodoResourceAws (params, header, showMessage = true) {
    return this.post(
      'core/v1/uploads/s3-get-rc-presigned',
      params,
      header,
      showMessage
    )
  }

  // getIndicationTemplateByCateId (id) {
  //   return this.get(`doctor/indication-template/${id}`)
  // }

  getLisIndicationsResultData (parmas) {
    return this.get('go/core/lis/indications/results', parmas)
  }

  validationPatientVisitIdentify (params) {
    return this.post('go/indications/user/confirm_acc', params)
  }

  getPrescriptionByIDV2 (id) {
    return this.get(`go/pharma/prescription/${id}`)
  }

  printDocument (params) {
    return this.post('doctor/emr-documents/printing-html', params)
  }

  validateVoucher (params) {
    return this.post('go/vouchers/validate', params, null, false)
  }
}
