import Vue from 'vue'
Vue.mixin({
  methods: {
    sendAmplitude (event, eventProperties) {
      const user = Vue.prototype.$user
      if (user) {
        window.amplitude.setUserId(user.id)
        window.amplitude.setUserProperties({
          'User ID': user?.id || '',
          'User Name': user?.name || '',
          Phone: user?.username || '',
          'Person ID': user?.person?.id || '',
          'Person Name': user?.person?.name || ''
        })
      } else {
        window.amplitude.clearUserProperties()
      }
      window.amplitude.logEvent(event, eventProperties)
    }
  }
})
