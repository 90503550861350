<template>
  <div class>
    <div class="container mx-auto">
      <!-- item -->
      <div class="text-center py-44 lg:pb-28">
        <div
          class="text-6xl md:text-8xl lg:text-9xl xl:text-10xl nunitoBold mb-3 text-pri-900 break-words"
        >{{$t('hodoConference')}}</div>
      </div>
      <!-- item -->
      <div class="lg:py-10 my-3">
        <div
          class="bg-pri-900 lg:flex lg:justify-between lg:items-center lg:px-12 px-12 py-5 lg:text-right text-center"
        >
          <div class="lg:pl-24 mb-4 lg:mb-0 lg:text-left">
            <p class="text-5xl nunitoBold text-white mb-0">Trực tuyến</p>
            <p class="text-5xl nunitoBold text-white mb-0">Đa ngữ</p>
          </div>
          <div class="lg:px-24 lg:text-left text-justify flex-1">
            <p class="fs-21 nunitoRegular text-white mb-0">
              HODO phát triển công nghệ để Bác sĩ, Nhân viên Y tế, Chuyên gia Quốc tế tham gia
              <strong>Hội thảo trực tuyến, đa ngữ</strong> linh hoạt không bị giới hạn về địa lý và rào cản ngôn ngữ
            </p>
          </div>
        </div>
      </div>
      <!-- item-1 -->
      <div class="flex flex-col-reverse justify-evenly items-center lg:py-10 my-3 lg:flex-row">
        <div class="text-center mb-3 order-1 lg:order-2 ml-2">
          <img
            class="w-full lg:w-4/5"
            src="../../../public/images/hodo-conference/img_conference_1.png"
          />
        </div>
        <div class="text-center lg:text-left px-4">
          <div
            class="lg:text-left lg:text-5xl text-2xl text-pri-900 nunitoBold lg:leading-tight lg:mb-12 max-w-xl pb-3"
          >Mở rộng quy mô và phạm vi ảnh hưởng</div>
          <div class="text-pri-900 bg-white nunitoRegular text-justify flex mb-4">
            <ul class="lg:px-0 px-3 max-w-lg">
              <li
                class="lg:px-0 lg:text-2xl text-xl nunitoRegular text-pri-900 mb-2"
              >Với sự linh hoạt của mô hình trực tuyến, các chuyên gia, đặc biệt là các chuyên gia quốc tế có thể tham gia một cách dễ dàng mà không chịu sự ràng buộc bởi giới hạn về địa lý hay rào cản ngôn ngữ.</li>
              <li
                class="lg:px-0 lg:text-2xl text-xl nunitoRegular text-pri-900"
              >Không chỉ giúp mở rộng quy mô và phạm vi của sự kiện mà còn tạo ra một mạng lưới y tế toàn cầu.</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- item-2 -->
      <div class="flex flex-col justify-evenly items-center lg:py-10 my-3 lg:flex-row">
        <div class="text-center mb-3">
          <img
            class="w-full lg:w-11/12 lg:pr-12"
            src="../../../public/images/hodo-conference/img_conference_2.png"
          />
        </div>
        <div class="text-center lg:text-left px-4">
          <div
            class="lg:text-left lg:text-5xl text-2xl text-pri-900 nunitoBold lg:leading-tight lg:mb-12 max-w-lg pb-3"
          >Tiết kiệm chi phí</div>
          <div class="text-pri-900 bg-white nunitoRegular text-justify flex mb-4">
            <ul class="lg:px-0 px-3 max-w-lg">
              <li
                class="lg:text-2xl text-xl lg:px-0 text-pri-900 nunitoRegular mb-2"
              >Cơ sở y tế tiết kiệm chi phí trong khâu tổ chức.</li>
              <li
                class="lg:text-2xl text-xl lg:px-0 text-pri-900 nunitoRegular"
              >Người tham dự không cần di chuyển đến địa điểm tổ chức, tiết kiệm các chi phí phát sinh, dễ dàng sắp xếp thời gian tham gia.</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- item-3 -->
      <div class="lg:grid lg:grid-cols-3 lg:gap-3 lg:py-10 my-3">
        <div class="mb-3 lg:pr-5 px-4">
          <p class="font-bold text-pri-900 mb-4 flex justify-start">
            <svg
              width="42"
              height="42"
              viewBox="0 0 42 42"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M33.25 5.25H8.75C6.825 5.25 5.25 6.825 5.25 8.75V33.25C5.25 35.175 6.825 36.75 8.75 36.75H33.25C35.175 36.75 36.75 35.175 36.75 33.25V8.75C36.75 6.825 35.175 5.25 33.25 5.25ZM33.25 33.25H8.75V8.75H33.25V33.25ZM31.4825 15.75L29.015 13.265L17.4825 24.7975L12.9675 20.3L10.4825 22.7675L17.4825 29.75L31.4825 15.75Z"
                fill="#20409B"
              />
            </svg>
            <span class="text-xl lg:text-3xl ml-2">Tổ chức nhất quán, toàn diện</span>
          </p>
          <ul>
            <li
              class="lg:px-0 lg:text-2xl text-xl nunitoRegular text-pri-900 text-justify"
            >Tạo phiên hội thảo trực tuyến dễ dàng.</li>
            <li
              class="lg:px-0 lg:text-2xl text-xl nunitoRegular text-pri-900 text-justify"
            >Thêm chủ toạ và quản lý các thành phần tham gia.</li>
            <li
              class="lg:px-0 lg:text-2xl text-xl nunitoRegular text-pri-900 text-justify"
            >Biên soạn nội dung, dễ dàng chia sẻ tài liệu, video, PDF với người tham gia.</li>
          </ul>
        </div>
        <div class="mb-3 lg:pr-5 px-4">
          <p class="font-bold text-pri-900 mb-4 flex justify-start">
            <svg
              width="42"
              height="42"
              viewBox="0 0 42 42"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M33.25 5.25H8.75C6.825 5.25 5.25 6.825 5.25 8.75V33.25C5.25 35.175 6.825 36.75 8.75 36.75H33.25C35.175 36.75 36.75 35.175 36.75 33.25V8.75C36.75 6.825 35.175 5.25 33.25 5.25ZM33.25 33.25H8.75V8.75H33.25V33.25ZM31.4825 15.75L29.015 13.265L17.4825 24.7975L12.9675 20.3L10.4825 22.7675L17.4825 29.75L31.4825 15.75Z"
                fill="#20409B"
              />
            </svg>
            <span class="text-xl lg:text-3xl ml-2">Đa ngôn ngữ</span>
          </p>
          <ul>
            <li
              class="lg:px-0 lg:text-2xl text-xl nunitoRegular text-pri-900 text-justify"
            >Phiên dịch viên có thể tham gia online tại bất cứ đâu.</li>
            <li
              class="lg:px-0 lg:text-2xl text-xl nunitoRegular text-pri-900 text-justify"
            >Khách mời có thể chủ động lựa chọn bản trình chiếu và kênh ngôn ngữ phù hợp.</li>
          </ul>
        </div>
        <div class="lg:pr-5 px-4">
          <p class="font-bold text-pri-900 mb-4 d-flex justify-start">
            <svg
              width="42"
              height="42"
              viewBox="0 0 42 42"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M33.25 5.25H8.75C6.825 5.25 5.25 6.825 5.25 8.75V33.25C5.25 35.175 6.825 36.75 8.75 36.75H33.25C35.175 36.75 36.75 35.175 36.75 33.25V8.75C36.75 6.825 35.175 5.25 33.25 5.25ZM33.25 33.25H8.75V8.75H33.25V33.25ZM31.4825 15.75L29.015 13.265L17.4825 24.7975L12.9675 20.3L10.4825 22.7675L17.4825 29.75L31.4825 15.75Z"
                fill="#20409B"
              />
            </svg>
            <span class="text-xl lg:text-3xl ml-2">Gắn kết bền lâu</span>
          </p>
          <ul>
            <li
              class="lg:px-0 text-xl lg:text-2xl nunitoRegular text-pri-900 text-justify"
            >Người tham gia dễ dàng đăng ký và xác thực bằng email.</li>
            <li
              class="lg:px-0 text-xl lg:text-2xl nunitoRegular text-pri-900 text-justify"
            >Trải nghiệm trên App: lịch trình hiển thị rõ ràng và gửi lời nhắc tham gia hội thảo trước khi diễn ra.</li>
            <li
              class="lg:px-0 text-xl lg:text-2xl nunitoRegular text-pri-900 text-justify"
            >Gắn kết cộng đồng chuyên môn thông qua Circle.</li>
          </ul>
        </div>
      </div>
      <!-- item-4 -->
      <div class="mb-32">
        <div class="mb-4 hidden lg:block">
          <p
            class="text-center lg:text-5xl text-xl text-pri-900 nunitoBold lg:leading-tight mb-0 lg:mb-2"
          >Hội thảo Đa ngôn ngữ</p>
        </div>
        <div class="max-w-6xl mx-auto text-xl lg:text-2xl text-pri-900">
          <div class="flex flex-wrap -mx-3 pb-3">
            <div class="w-full md:w-4/5 px-3 md:px-12 text-center text-lg-start">
              <h2 class="block text-2xl lg:text-3xl font-bold nunitoBold">Hội thảo Đa ngôn ngữ</h2>
            </div>
            <div class="w-full md:w-1/5 px-3 md:px-12 text-center text-lg-start">
              <a
                href="tel:0345900905"
                class="text-pri-900 font-bold no-underline"
              >{{ appUtils.numberFormat(250000000, 'VNĐ*') }}</a>
            </div>
            <div class="w-full px-3 md:px-12">
              <div class="border-top pt-3">
                <ul class="mb-0">
                  <li>Quản lý thông tin hội thảo; Xây dựng lịch trình hội thảo</li>
                  <li>Tích hợp kênh thanh toán vé trực tuyến</li>
                  <li>Dễ dàng tổ chức trên nhiều nền tảng</li>
                  <li>Có tính năng ghi hình (recording)</li>
                  <li>Có tính năng phát trực tiếp (Livestream)</li>
                  <li>Cho phép 10.000 khách mời tham gia Hội thảo đồng thời</li>
                  <li>Cho phép tới 200 báo cáo viên tham gia Báo cáo đồng thời</li>
                  <li>Tích hợp buồng dịch đa ngôn ngữ trực tuyến</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="flex flex-wrap -mx-3 pb-3">
            <div class="w-full md:w-4/5 px-3 md:px-12 text-center text-lg-start">
              <h2 class="block text-2xl lg:text-3xl font-bold nunitoBold">Hạ tầng máy chủ</h2>
            </div>
            <div class="w-full md:w-1/5 px-3 md:px-12 text-center text-lg-start">
              <a
                href="tel:0345900905"
                class="text-pri-900 font-bold no-underline"
              >{{ $t('Liên hệ') }}</a>
            </div>
            <div class="w-full px-3 md:px-12">
              <div class="border-top pt-3">
                <ul class="mb-0">
                  <li>Chi phí phụ thuộc vào số phiên và số ngày diễn ra hội thảo</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="text-lg italic">
            <div>
              <small>*Đơn giá có thể điều chỉnh tùy theo quy mô và số lượng khách tham dự.</small>
            </div>
            <div>
              <small>*Đơn giá trên đã bao gồm phí VAT.</small>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import appUtils from '../../utils/appUtils'

export default {
  name: 'HodoConference',
  data () {
    return {
      appUtils
    }
  }
}
</script>

<style lang="css" scoped>
@font-face {
  font-family: Montserrat-SemiBold;
  src: url("../../../public/font/Montserrat-SemiBold.ttf");
}
@font-face {
  font-family: NunitoRegular;
  src: url("../../../public/font/Nunito-Regular.ttf");
}
@font-face {
  font-family: NunitoBold;
  src: url("../../../public/font/NunitoSans-ExtraBold.ttf");
}
.nunitoRegular {
  font-family: NunitoRegular;
}
.nunitoBold {
  font-family: NunitoBold;
}

.montserrat-semibold div,
span {
  font-family: Montserrat-SemiBold !important;
}
.fs-21 {
  font-size: 20px;
}
.section-3 {
  background-color: #20409b;
}

.fs-64 {
  font-size: 64px;
}
.fs-26 {
  font-size: 26px;
}

.me-custom {
  margin-right: 30px;
}

.height-150 {
  height: 150px;
}

.bg-bottom {
  height: 20px;
  background-color: #004aad;
}
.fs-52 {
  font-size: 52px;
}
</style>
