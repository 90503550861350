<template>
  <div class="px-160">
    <!-- <img
      src="../../../public/images/Helps/1.png"
      class="img-fluid"
      alt=""
    > -->
    <div class="row">
      <p class="text-topic">HODO là ứng dụng giúp Bệnh nhân</p>
      <p class="text-content">liên lạc với Bác sĩ và an tâm sức khỏe ngay tại nhà. Nhờ các tính
        năng Cập nhật sức khỏe thường xuyên tới Bác sĩ, Bệnh nhân hoặc người thân có thể theo dõi, hiểu rõ sức
        khỏe và thể trạng của Bệnh nhân; Đồng thời nhận được sự Tư vấn, Hướng dẫn và can thiệp kịp thời từ
        Bác sĩ khi có bất kì triệu chứng gì xảy ra.
      </p>
    </div>
    <div class="row">
      <div class="col-md-6 col-lg-4 col-xxl-3">
        <div class="px-1">
          <p class="c-pointer d-flex align-items-start text-content" @click="scrollTo(1)">
            <img src="../../../public/icons/tick-xanh.svg" alt="" class="mt-1 me-1">
            <span>
              Tải HODO
            </span>
          </p>
          <!-- line -->
          <p class="c-pointer d-flex align-items-start text-content" @click="scrollTo(2)">
            <img src="../../../public/icons/tick-xanh.svg" alt="" class="mt-1 me-1">
            <span>
              Đăng ký tài khoản
            </span>
          </p>
          <!-- line -->
          <p class="c-pointer d-flex align-items-start text-content" @click="scrollTo(3)">
            <img src="../../../public/icons/tick-xanh.svg" alt="" class="mt-1 me-1">
            <span>
              Tạo hồ sơ sức khỏe
            </span>
          </p>
          <!-- line -->
          <p class="c-pointer d-flex align-items-start text-content" @click="scrollTo(4)">
            <img src="../../../public/icons/tick-xanh.svg" alt="" class="mt-1 me-1">
            <span>
              Mời Bác sĩ tham gia
            </span>
          </p>
          <!-- line -->
          <p class="c-pointer d-flex align-items-start text-content" @click="scrollTo(5)">
            <img src="../../../public/icons/tick-xanh.svg" alt="" class="mt-1 me-1">
            <span>
              Cập nhật tài liệu xét nghiệm
            </span>
          </p>
          <!-- line -->
          <p class="c-pointer d-flex align-items-start text-content" @click="scrollTo(6)">
            <img src="../../../public/icons/tick-xanh.svg" alt="" class="mt-1 me-1">
            <span>
              Xem lời dặn của Bác sĩ
            </span>
          </p>
          <!-- line -->
          <p class="c-pointer d-flex align-items-start text-content" @click="scrollTo(7)">
            <img src="../../../public/icons/tick-xanh.svg" alt="" class="mt-1 me-1">
            <span>
              Tuân thủ thuốc hàng ngày
            </span>
          </p>
          <!-- line -->
          <p class="c-pointer d-flex align-items-start text-content" @click="scrollTo(8)">
            <img src="../../../public/icons/tick-xanh.svg" alt="" class="mt-1 me-1">
            <span>
              Cập nhật chỉ số sinh hiệu
            </span>
          </p>
          <!-- line -->
          <p class="c-pointer d-flex align-items-start text-content" @click="scrollTo(9)">
            <img src="../../../public/icons/tick-xanh.svg" alt="" class="mt-1 me-1">
            <span>
              Cập nhật dấu hiệu bất thường
            </span>
          </p>
          <!-- line -->
          <p class="c-pointer d-flex align-items-start text-content" @click="scrollTo(10)">
            <img src="../../../public/icons/tick-xanh.svg" alt="" class="mt-1 me-1">
            <span>
              Nhắn tin với Bác sĩ
            </span>
          </p>
          <!-- line -->
          <p class="c-pointer d-flex align-items-start text-content" @click="scrollTo(11)">
            <img src="../../../public/icons/tick-xanh.svg" alt="" class="mt-1 me-1">
            <span>
              Gọi điện thoại với Bác sĩ
            </span>
          </p>
          <!-- line -->
          <p class="c-pointer d-flex align-items-start text-content" @click="scrollTo(12)">
            <img src="../../../public/icons/tick-xanh.svg" alt="" class="mt-1 me-1">
            <span>
              Đặt lịch khám
            </span>
          </p>
          <!-- line -->
          <p class="c-pointer d-flex align-items-start text-content" @click="scrollTo(13)">
            <img src="../../../public/icons/tick-xanh.svg" alt="" class="mt-1 me-1">
            <span>
              Thanh toán lịch khám trực tuyến
            </span>
          </p>
          <!-- line -->
          <p class="c-pointer d-flex align-items-start text-content" @click="scrollTo(14)">
            <img src="../../../public/icons/tick-xanh.svg" alt="" class="mt-1 me-1">
            <span>
              Tham gia lịch khám trực tuyến
            </span>
          </p>
        </div>
      </div>
      <div class="col-md-6 col-lg-8 col-xxl-9">
        <img v-for="i in 17" :key="i" :src="baseUrl + '/images/Helps/' + (i + 1) + '.png'" :id="'img-' + (i + 1)"
          class="img-fluid mb-5" alt="">
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'HelpsPatient',
  data () {
    return {
      baseUrl: window.location.origin
    }
  },
  methods: {
    scrollTo (i) {
      i += 2
      document.getElementById('img-' + i).scrollIntoView()
    }
  }
}
</script>

<style lang="css" scoped>
.text-content {
  color: #061829;
  font-size: 1.25em;
  line-height: 1.6em;
  font-weight: 400;
  text-align: left;
}

.text-topic {
  color: #061829;
  font-size: 1.5em;
  line-height: 1.6em;
  font-weight: 600;
  text-align: left;
}
</style>
