import { render, staticRenderFns } from "./HandbookKnowledge.vue?vue&type=template&id=96474520&scoped=true"
import script from "./HandbookKnowledge.vue?vue&type=script&lang=js"
export * from "./HandbookKnowledge.vue?vue&type=script&lang=js"
import style0 from "./HandbookKnowledge.vue?vue&type=style&index=0&id=96474520&prod&lang=css&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "96474520",
  null
  
)

export default component.exports