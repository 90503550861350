<template>
  <div class="flex h-screen bg-white">
    <div class="flex flex-col w-full">
      <header>
        <MedicalRegisterHeader v-if="Object.keys(defaultWs).length" />
      </header>

      <div class="flex flex-1 flex-col bg-white">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import MedicalRegisterHeader from '../components/MedicalRegister/header.vue'

export default {
  name: 'WhitePublicLayout',
  components: { MedicalRegisterHeader },
  computed: {
    ...mapGetters('registerMedical', ['defaultWs'])
  }
}
</script>

<style scoped>
</style>
