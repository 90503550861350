<template>
  <div>
    <div v-if="disease && !show_select && !show_pacs">
      <p class="fs-28 fw-500 txt-black mb-4">Tải tài liệu lên</p>
      <!-- * -->
      <div class="row mb-3">
        <!-- file-wraper -->
        <div class="col-12 mb-3">
          <div class="file-wraper" @click="openSelectDoc(1)">
            <div class="d-flex p-2">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="me-2"
              >
                <path
                  d="M21.0602 11.8196L20.9002 11.5996C20.6202 11.2596 20.2902 10.9896 19.9102 10.7896C19.4002 10.4996 18.8202 10.3496 18.2202 10.3496H5.7702C5.1702 10.3496 4.6002 10.4996 4.0802 10.7896C3.6902 10.9996 3.3402 11.2896 3.0502 11.6496C2.4802 12.3796 2.2102 13.2796 2.3002 14.1796L2.6702 18.8496C2.8002 20.2596 2.9702 21.9996 6.1402 21.9996H17.8602C21.0302 21.9996 21.1902 20.2596 21.3302 18.8396L21.7002 14.1896C21.7902 13.3496 21.5702 12.5096 21.0602 11.8196ZM14.3902 17.3396H9.6002C9.2102 17.3396 8.9002 17.0196 8.9002 16.6396C8.9002 16.2596 9.2102 15.9396 9.6002 15.9396H14.3902C14.7802 15.9396 15.0902 16.2596 15.0902 16.6396C15.0902 17.0296 14.7802 17.3396 14.3902 17.3396Z"
                  fill="#456BD6"
                />
                <path
                  d="M20.561 8.59643C20.5986 8.97928 20.1833 9.23561 19.8185 9.11348C19.3137 8.94449 18.7824 8.86 18.2299 8.86H5.76988C5.21304 8.86 4.66478 8.95012 4.15322 9.12194C3.79283 9.24298 3.37988 8.99507 3.37988 8.61489V6.66C3.37988 3.09 4.46988 2 8.03988 2H9.21988C10.6499 2 11.0999 2.46 11.6799 3.21L12.8799 4.81C13.1299 5.15 13.1399 5.17 13.5799 5.17H15.9599C19.0856 5.17 20.3069 6.00724 20.561 8.59643Z"
                  fill="#456BD6"
                />
              </svg>
              <div>
                <p class="mb-0 fs-16 fw-500 txt-pri">Sổ khám bệnh</p>
                <p class="mb-0 fs-14 mt-1" :set="count = getNumberOfDoctype(1)">
                  <span
                    :class="count ? '' : 't-red'"
                  >{{count ? 'Đã tải lên ' + count + ' files' : 'Thông tin bệnh được ghi trong số khám bệnh'}}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- file-wraper -->
        <div class="col-12 mb-3">
          <div class="file-wraper" @click="openSelectDoc(2)">
            <div class="d-flex p-2">
              <svg
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="me-2"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M14.1021 3.53576L9.50586 8.13195L16.5769 15.203L21.1731 10.6068C23.1257 8.6542 23.1257 5.48838 21.1731 3.53576C19.2205 1.58313 16.0547 1.58313 14.1021 3.53576ZM15.2448 4.57348C16.5833 3.23494 18.7598 3.23494 20.0984 4.57348C20.3733 4.84838 20.3733 5.29303 20.0984 5.56794L20.0938 5.57247L20.0938 5.57241C19.958 5.70142 19.7833 5.77298 19.5989 5.77298C19.4202 5.77298 19.2411 5.70968 19.0993 5.56794C18.3106 4.77921 17.028 4.77921 16.2393 5.56794L14.8756 6.93158C14.7339 7.07332 14.5503 7.13662 14.3807 7.13662C14.202 7.13662 14.0229 7.07332 13.8812 6.93158C13.6063 6.65667 13.6063 6.21202 13.8812 5.93711L15.2448 4.57348Z"
                  fill="#456BD6"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M4.20275 13.4351C2.25013 15.3877 2.25013 18.5535 4.20275 20.5061C6.15537 22.4588 9.32119 22.4588 11.2738 20.5061L15.87 15.9099L8.79894 8.83887L4.20275 13.4351ZM10.4769 19.3133C9.1384 20.6518 6.96193 20.6518 5.62339 19.3133C5.34849 19.0384 5.34849 18.5937 5.62339 18.3188L5.62792 18.3143L5.62798 18.3143C5.76378 18.1853 5.93849 18.1138 6.12289 18.1138C6.30158 18.1138 6.48065 18.1771 6.6224 18.3188C7.41113 19.1075 8.69375 19.1075 9.48248 18.3188L10.8461 16.9552C10.9879 16.8134 11.1715 16.7501 11.3411 16.7501C11.5198 16.7501 11.6988 16.8134 11.8406 16.9552C12.1155 17.2301 12.1155 17.6747 11.8406 17.9496L10.4769 19.3133Z"
                  fill="#456BD6"
                />
              </svg>
              <div>
                <p class="mb-0 fs-16 fw-500 txt-pri">Đơn thuốc</p>
                <p class="mb-0 fs-14 mt-1" :set="count = getNumberOfDoctype(2)">
                  <span
                    :class="count ? '' : 't-red'"
                  >{{count ? 'Đã tải lên ' + count + ' files' : 'Các đơn thuốc, thuốc sử dụng trong quá trình điều trị'}}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- file-wraper -->
        <!-- file-wraper -->
        <div class="col-12 mb-3">
          <div class="file-wraper" @click="openSelectDoc(3)">
            <div class="d-flex p-2">
              <svg
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="me-2"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M11.1329 2.4002C11.1329 1.73745 11.6702 1.2002 12.3329 1.2002H17.1329C17.7957 1.2002 18.3329 1.73745 18.3329 2.4002V2.40029H20.1327C21.1269 2.40029 21.9327 3.20618 21.9327 4.20029V16.8003C21.9327 17.7944 21.1269 18.6003 20.1327 18.6003H18.3324V6.2502C18.3324 5.17324 17.4593 4.3002 16.3824 4.3002H14.4327V4.30015C14.4327 3.58218 13.8507 3.00015 13.1327 3.00015H7.99121C8.3208 2.63198 8.79971 2.40029 9.33274 2.40029H11.1329V2.4002Z"
                  fill="#456BD6"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M4.53242 5.40039H5.73242V6.06957C5.73242 6.73231 6.26968 7.26957 6.93242 7.26957H12.9324C13.5952 7.26957 14.1324 6.73231 14.1324 6.06957V5.40039H15.3324C16.3265 5.40039 17.1324 6.20628 17.1324 7.20039V19.8004C17.1324 20.7945 16.3265 21.6004 15.3324 21.6004H4.53242C3.53831 21.6004 2.73242 20.7945 2.73242 19.8004V7.20039C2.73242 6.20628 3.53831 5.40039 4.53242 5.40039ZM12.2494 8.16275C12.0291 7.66601 11.3434 7.68399 11.1475 8.19165L9.94981 11.2953L8.77391 9.23846C8.57417 8.88908 8.11104 8.82851 7.83412 9.11555L6.51183 10.4862H4.53203C4.36635 10.4862 4.23203 10.6256 4.23203 10.7977C4.23203 10.9698 4.36635 11.1092 4.53203 11.1092H6.51183C6.67076 11.1092 6.82321 11.0437 6.9357 10.9271L8.25799 9.55654L9.43388 11.6134C9.68776 12.0574 10.3218 12.0064 10.5068 11.5271L11.7044 8.42343L12.735 10.7468C12.833 10.9677 13.0458 11.1092 13.28 11.1092H15.332C15.4977 11.1092 15.632 10.9698 15.632 10.7977C15.632 10.6256 15.4977 10.4862 15.332 10.4862L13.28 10.4862L12.2494 8.16275ZM4.53203 16.5143C4.53203 16.2562 4.7335 16.047 4.98203 16.047H6.48203C6.73056 16.047 6.93203 16.2562 6.93203 16.5143C6.93203 16.7724 6.73056 16.9816 6.48203 16.9816H4.98203C4.7335 16.9816 4.53203 16.7724 4.53203 16.5143ZM14.882 19.7854C15.1306 19.7854 15.332 19.5762 15.332 19.3181C15.332 19.06 15.1306 18.8508 14.882 18.8508L13.382 18.8508C13.1335 18.8508 12.932 19.06 12.932 19.3181C12.932 19.5762 13.1335 19.7854 13.382 19.7854H14.882ZM8.13203 16.5143C8.13203 16.2562 8.3335 16.047 8.58203 16.047L14.882 16.047C15.1306 16.047 15.332 16.2562 15.332 16.5143C15.332 16.7724 15.1306 16.9816 14.882 16.9816L8.58203 16.9816C8.3335 16.9816 8.13203 16.7724 8.13203 16.5143ZM11.282 19.7854C11.5306 19.7854 11.732 19.5762 11.732 19.3181C11.732 19.06 11.5306 18.8508 11.282 18.8508L4.98203 18.8508C4.7335 18.8508 4.53203 19.06 4.53203 19.3181C4.53203 19.5762 4.7335 19.7854 4.98203 19.7854L11.282 19.7854Z"
                  fill="#456BD6"
                />
                <rect x="6.33301" y="4.2002" width="7.2" height="2.4" rx="1.2" fill="#456BD6" />
              </svg>

              <div>
                <p class="mb-0 fs-16 fw-500 txt-pri">Xét nghiệm</p>
                <p class="mb-0 fs-14 mt-1" :set="count = getNumberOfDoctype(3)">
                  <span
                    :class="count ? '' : 't-red'"
                  >{{count ? 'Đã tải lên ' + count + ' files' : 'Các phiếu, kết quả xét nghiệm.'}}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- file-wraper -->
        <!-- file-wraper -->
        <div class="col-12 mb-3">
          <div class="file-wraper" @click="showSelectPacs(true)">
            <div class="d-flex p-2">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="me-2"
              >
                <path
                  d="M19.4345 4.03448C19.5668 4.2424 19.3401 4.48461 19.0998 4.43C18.6298 4.29 18.1098 4.22 17.5798 4.22H14.2796C14.1223 4.22 13.9743 4.14605 13.8798 4.02037L12.7298 2.49C12.589 2.29044 12.7221 2 12.9664 2H15.7198C17.2808 2 18.6559 2.81073 19.4345 4.03448Z"
                  fill="#456BD6"
                />
                <path
                  d="M20.14 6.54C19.71 6.23 19.22 6 18.69 5.87C18.33 5.77 17.96 5.72 17.58 5.72H13.86C13.28 5.72 13.24 5.67 12.93 5.26L11.53 3.4C10.88 2.53 10.37 2 8.74 2H6.42C3.98 2 2 3.98 2 6.42V17.58C2 20.02 3.98 22 6.42 22H17.58C20.02 22 22 20.02 22 17.58V10.14C22 8.65 21.27 7.34 20.14 6.54ZM14.39 16.34H9.6C9.21 16.34 8.91 16.03 8.91 15.64C8.91 15.26 9.21 14.94 9.6 14.94H14.39C14.78 14.94 15.09 15.26 15.09 15.64C15.09 16.03 14.78 16.34 14.39 16.34Z"
                  fill="#456BD6"
                />
              </svg>

              <div :set="countUploaded = getCurrPacFileCount()">
                <p class="mb-0 fs-16 fw-500 txt-pri">Chẩn đoán hình ảnh</p>
                <p class="mb-0 fs-14 mt-1">
                  <span v-if="countUploaded">{{'Đã tải lên ' + countUploaded + ' files'}}</span>
                  <span class="t-red" v-if="!countUploaded">Hỗ trợ định dạng nén .ZIP .RAR</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- file-wraper -->
      </div>
    </div>
    <!--! form -->
    <SelectDocument
      v-if="doc_type && show_select"
      :current_documents="current_documents"
      @hideSelectDoc="hideSelectDoc"
      @openSelectDoc="openSelectDoc"
      :doc_type="doc_type"
      @selectedImage="selectedImage"
      @onDeleteDoc="onDeleteDoc"
    />
    <UploadCompressedFile
      v-if="show_pacs"
      :uploadedPacs="uploadedPacs"
      :disease="disease"
      @showSelectPacs="showSelectPacs"
      @selectedPac="selectedPac"
      :loading="loading"
    />
  </div>
</template>

<script>
import SelectDocument from '../../components/Diseases/SelectDocument.vue'
import UploadCompressedFile from '../../components/Diseases/UploadCompressedFile.vue'
import moment from 'moment'
export default {
  name: 'DiseaseDocument',
  components: { SelectDocument, UploadCompressedFile },
  data () {
    return {
      show_pacs: false,
      sltPac: {
        name: '',
        file: null
      },
      documents: [],
      doc_type: 0,
      persons: null,
      exts: [
        'jpeg',
        'png',
        'jpg',
        'gif',
        'svg',
        'doc',
        'docx',
        'pdf',
        'xls',
        'xlsx'
      ],
      loading: false,
      phoneno: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
      show_select: false,
      disease: null,
      current_documents: [],
      uploadedPacs: []
    }
  },
  watch: {
    $uploading (_new, _old) {
      if (_old && !_new) {
        this.getPacs(this.$route.params.pdid)
      }
    }
  },
  mounted () {
    this.getPersonDisease()
    if (this.$route.query.type === 'uploadPacs') this.showSelectPacs(true)
  },
  methods: {
    async getPersonDisease () {
      const self = this
      await self.$rf
        .getRequest('AuthRequest')
        .getDisease(self.$route.params.pdid, false)
        .then(r => {
          self.disease = r.data
          if (r.data) {
            const disease = r.data
            self.getDocuments(disease.id)
            self.getPacs(disease.id)
          }
        })
        .catch(e => {
          window.$toast.open({
            message: 'Bạn không có quyền xem hồ sơ này hoặc đã có lỗi xảy ra',
            type: 'error'
          })
        })
    },
    async getPacs (id) {
      const self = this
      const params = {
        person_diseases_id: id,
        limit: 1000,
        has_file: 1
      }
      await self.$rf
        .getRequest('AuthRequest')
        .getPacs(params)
        .then(r => {
          if (r?.data?.data) {
            self.uploadedPacs = r?.data?.data
          }
        })
    },
    async getDocuments (id) {
      const self = this
      const params = {
        person_diseases_id: id,
        limit: 1000
      }
      await self.$rf
        .getRequest('AuthRequest')
        .getDocuments(params)
        .then(r => {
          if (r?.data?.data) {
            self.current_documents = r?.data?.data
          }
        })
    },
    onDeleteDoc (doc, i) {
      console.log(doc)
      if (doc.id) {
        console.log(doc)
      } else {
        this.documents.splice(i, 1)
      }
    },
    openSelectDoc (type) {
      this.show_select = true
      this.doc_type = type
      this.switchModeView(true)
    },
    getNumberOfDoctype (type) {
      if (this.current_documents) {
        return this.current_documents.filter(d => d.type === type).length
      } else {
        return 0
      }
    },
    async onUploadPac () {
      const self = this
      const user = this.$user
      self.loading = true
      const id = self.$route.params.pdid
      const params = {
        person_diseases_id: id,
        name: self.sltPac.name
      }
      await self.$rf
        .getRequest('AuthRequest')
        .pacCreate(params)
        .then(r => {
          window.$uploadPacsFile(r.data, self.sltPac.file)
          // self.getPacs(id)
          self.sendAmplitude('Upload PACS file', {
            'Person ID': user.person?.id,
            'Person Name': user.person?.name,
            Status: 'Success',
            Timestamp: moment(new Date()).format('HH:mm DD-MM-YYYY')
          })
        })
        .catch(() => {
          self.sendAmplitude('Upload PACS file', {
            'Person ID': user.person?.id,
            'Person Name': user.person?.name,
            Status: 'Fail',
            Timestamp: moment(new Date()).format('HH:mm DD-MM-YYYY')
          })
        })
        .finally(() => {
          self.loading = false
        })
    },
    async uploadDocuments () {
      const self = this
      try {
        const id = self.$route.params.pdid
        const params = {
          person_diseases_id: id,
          person_id: self.disease.person_id
        }
        const formData = new FormData()
        for (var key in params) {
          formData.append(key, params[key])
        }
        self.documents.forEach((file, i) => {
          formData.append('data[' + i + '][content]', file.content)
          formData.append('data[' + i + '][file]', file.file)
          formData.append('data[' + i + '][type]', file.type)
        })
        await self.$rf
          .getRequest('AuthRequest')
          .multipleUploadDocuments(formData)
          .then(r => {
            self.getDocuments(id)
            self.documents = []
          })
        window.$toast.open({
          message: 'Tải lên thành công',
          type: 'success'
        })
      } catch (error) {
        self.documents = []
        console.log(error)
        window.$toast.open({
          message: 'Có lỗi tong quá trình tải lên',
          type: 'error'
        })
      }
    },
    selectedImage (files, name) {
      const self = this
      let errFile = ''
      if (files) {
        for (let i = 0; i < files.length; i++) {
          const f = files[i]
          if (this.validateFile(f.name.split('.').pop())) {
            self.documents.push({
              type: self.doc_type,
              content: name,
              file: f
            })
          } else {
            errFile += i + 1 + ','
          }
        }
      }
      this.uploadDocuments()
      if (errFile) {
        window.$toast.open({
          message:
            'File số ' +
            errFile.substring(0, errFile.length - 1) +
            ' không đúng định dạng nên đã bị loại bỏ',
          type: 'warning'
        })
      }
    },
    validateFile (ext) {
      return this.exts.findIndex(e => e === ext) > -1
    },

    hideSelectDoc () {
      this.show_select = false
      this.switchModeView(false)
    },
    showSelectPacs (show) {
      this.show_pacs = show
      this.switchModeView(show)
    },
    switchModeView (show) {
      this.$emit('switchModeView', show)
    },
    selectedPac (file, name) {
      this.sltPac = {
        name: name,
        file: file
      }
      this.onUploadPac()
    },
    getCurrPacFileCount () {
      return this.uploadedPacs?.length || 0
    }
  }
}
</script>

<style scoped>
.t-red {
  color: #de3854;
}
.file-wraper {
  width: 100%;
  height: 99px;
  border-radius: 8px;
  background-color: #f7f9fb;
  cursor: pointer;
}
.circle-num {
  width: 24px !important;
  height: 24px !important;
  min-width: 24px !important;
  min-height: 24px !important;
  background-color: #f3f3f4;
  border-radius: 12px;
}
.btn {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0.10000000149011612px;
  text-align: center;
}
</style>
