<template>
  <div class="pt-3 pt-lg-4 pb-4 mx-auto container-home">
    <p class="fs-24 color-pri font-semibold">Xin chào, {{ formatterName }}</p>
    <div class="grid grid-cols-2 gap-2">
      <div class="grid-item flex flex-col items-center">
        <h4 class="font-semibold">
          {{ statisticTotals.person_disease_total }}
        </h4>
        <span class="font-medium">{{ $t("Hồ sơ sức khoẻ") }}</span>
      </div>
      <div class="grid-item flex flex-col items-center">
        <h4 class="font-semibold">
          {{ statisticTotals.person_relative_total }}
        </h4>
        <span class="font-medium">{{ $t("Người thân") }}</span>
      </div>
      <!-- <div class="grid-item flex flex-col items-center">
        <h4 class="font-semibold">03</h4>
        <span class="font-medium">{{ $t('Cập nhật') }}</span>
      </div>
      <div class="grid-item flex flex-col items-center">
        <h4 class="font-semibold">100,000</h4>
        <span class="font-medium">{{ $t('Điểm HODO Credit') }}</span>
      </div> -->
    </div>
    <!-- <div class="my-10">
      <h5>Cập nhật mới</h5>
      <div v-if="listUpdate.length > 0">
        <div
          v-for="item in listUpdate"
          :key="item.id"
          class="grid-item flex justify-between mb-2 update-item"
          style="padding: 12px 16px"
          @click="openDetail(item, true)"
        >
          <div>
            <div class="flex items-center gap-2 mb-1">
              <el-tag type="danger" class="tag">Mới</el-tag>
              <span class="font-semibold">{{ item.title }}</span>
            </div>
            <span>{{ item.clinic }}</span>
          </div>
          <div>
            <span>{{ item.updated_at }}</span>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="grid-item flex justify-between" style="padding: 12px 16px">
          <div>
            <div class="flex items-center gap-2 mb-1">
              <el-tag class="tag">Chào mừng đến với HODO</el-tag>
              <span class="font-semibold">Chào mừng Calla Lily</span>
            </div>
            <span>Một ứng dụng cho tất cả các nhu cầu về sức khỏe</span>
          </div>
          <div>
            <span>14:00 31/01/2024</span>
          </div>
        </div>
      </div>
    </div> -->
    <div class="my-10">
      <h5 class="d-flex align-items-center justify-content-between">
        <span>Hồ sơ sức khoẻ</span>
        <router-link to="/hssk">
          <span class="fs-16">Xem thêm</span>
        </router-link>
      </h5>
      <div v-if="diseases.length === 0">
        <div class="grid-item flex gap-5" style="padding: 20px">
          <div>
            <img src="/images/home/hssk-empty.png" alt />
          </div>
          <div>
            <h6 class="color-pri">Lắng nghe cơ thể</h6>
            <ul>
              <li>
                Cập nhật thường xuyên các chỉ số sức khỏe cùng triệu chứng nhanh
                chóng và đơn giản với Hồ sơ sức khỏe.
              </li>
              <li>
                Theo dõi và quản lý những tín hiệu từ cơ thể để chủ động bảo vệ
                sức khỏe của bản thân và những người thân yêu.
              </li>
            </ul>
            <div
              class="btn-add-hssk"
              style="width: fit-content"
              @click="$router.push({ name: 'DiseasesCreate' })"
            >
              + Tạo Hồ sơ sức khoẻ ngay
            </div>
          </div>
        </div>
      </div>
      <div v-else class="grid grid-cols-4 gap-2">
        <div
          class="grid-item flex flex-col items-center gap-4 justify-between p-3"
        >
          <img class="my-auto" src="/icons/icon-plus-circle.svg" alt />
          <router-link
            :to="{ name: 'DiseasesCreate' }"
            class="btn-add-hssk w-full text-center no-underline"
            >Thêm mới hồ sơ</router-link
          >
        </div>
        <div
          v-for="item in diseases"
          :key="item.id"
          class="grid-item flex flex-col gap-2 p-3 justify-between items-center"
        >
          <img
            :src="
              item.avatar ||
              'https://i.pinimg.com/736x/0d/64/98/0d64989794b1a4c9d89bff571d3d5842.jpg'
            "
            :alt="item.persons && item.persons.name"
            class="rounded-full"
            style="width: 60px; height: 60px; object-fit: cover"
          />
          <span class="block fs-18">{{
            item.persons && item.persons.name
          }}</span>
          <span class="block text-center">{{ item.diseases_name }}</span>
          <router-link
            :to="`/hssk/${item.id}/view`"
            class="block btn-view-hssk mt-auto no-underline"
            >Xem hồ sơ</router-link
          >
        </div>
      </div>
    </div>
    <ModalConfirmSchedule></ModalConfirmSchedule>
  </div>
</template>

<script>
import appUtils from '../../utils/appUtils'
import ModalConfirmSchedule from '../Diagnose/ModalConfirmSchedule.vue'
export default {
  name: 'MyAccount',
  components: { ModalConfirmSchedule },
  data () {
    return {
      appUtils,
      listUpdate: [
        {
          id: 1,
          updated_at: appUtils.formatDateTime(new Date()),
          title: 'Kết quả Xét nghiệm công thức máu 27 Chỉ số',
          clinic: 'Từ Trung Tâm Xét Nghiệm Hi-Detect Labs'
        },
        {
          id: 2,
          updated_at: appUtils.formatDateTime(new Date()),
          title: 'Kết quả Xét nghiệm công thức máu 27 Chỉ số',
          clinic: 'Từ Trung Tâm Xét Nghiệm Hi-Detect Labs'
        },
        {
          id: 3,
          updated_at: appUtils.formatDateTime(new Date()),
          title: 'Kết quả Xét nghiệm công thức máu 27 Chỉ số',
          clinic: 'Từ Trung Tâm Xét Nghiệm Hi-Detect Labs'
        }
      ],
      statisticTotals: {},
      diseases: []
    }
  },
  async created () {
    await this.getStatisticTotals()
    await this.getDiseases()
  },
  mounted () {
    //
  },
  computed: {
    formatterName () {
      const hiddenData = appUtils.hidePhoneNumber(
        this.$user?.phone || this.$user?.username,
        4,
        2,
        '****'
      )
      return this.$user?.name || hiddenData || ''
    }
  },
  methods: {
    async getStatisticTotals () {
      try {
        const res = await this.$rf
          .getRequest('AuthRequest')
          .getStatisticTotals()
        if (!res?.data) return
        this.statisticTotals = res?.data
      } catch (error) {
        console.log(error)
      }
    },
    openDetail (item, show) {
      // this.$router.push({ name: 'DiagnoseDetail', params: { id: item.id } })
      window.$('#ModalConfirmSchedule').modal(show ? 'show' : 'hide')
    },
    async getDiseases () {
      const self = this
      const params = {
        page_num: 1,
        page_size: 3,
        sort_by: 'updated_at',
        order: 'desc'
      }
      await self.$rf
        .getRequest('AuthRequest')
        .getDiseasesV2(params)
        .then((resp) => {
          self.diseases = resp?.data?.data || []
          console.log(resp?.data)
        })
        .catch((e) => {
          console.log(e)
        })
        .finally(() => {})
    }
  }
}
</script>

<style scoped lang="scss">
.container-home {
  max-width: 80%;
}

.color-pri {
  color: #20419b;
}

.grid-item {
  border: 1px solid #a6b3d7;
  border-radius: 10px;
  padding: 20px 0;
}

.tag {
  border-radius: 20px;
  display: flex;
  padding: 0 20px;
  align-items: center;
  justify-content: center;
  line-height: inherit;
  font-size: 16px;
  font-weight: 600;
}

.btn-add-hssk {
  background: #20419b;
  padding: 12px 20px;
  border-radius: 4px;
  line-height: 20px;
  color: #fff;
  display: flex;
  justify-content: center;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }
}

.btn-view-hssk {
  border: 1px solid #20419b;
  padding: 8px 16px;
  border-radius: 4px;
  line-height: 20px;
  color: #20419b;
  width: 100%;
  display: flex;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background: #ecf5ff;
  }
}

.update-item {
  &:hover {
    background: #ecf5ff;
    cursor: pointer;
  }
}
</style>
