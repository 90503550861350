import { render, staticRenderFns } from "../../../public/HODORegulationWeb/index.html?vue&type=template&id=a5fa7dd0&scoped=true&external"
import script from "./RegulationWebsite.vue?vue&type=script&lang=js"
export * from "./RegulationWebsite.vue?vue&type=script&lang=js"
import style0 from "../../../public/HODORegulationWeb/quyche_files/Site.css?vue&type=style&index=0&id=a5fa7dd0&prod&lang=css&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a5fa7dd0",
  null
  
)

export default component.exports