<template>
  <div class="container bg-white mt-1">
    <div class="p-48">
      <div v-if="!loading">
        <div class="border-bottom mb-3 d-flex justify-content-between py-3">
          &nbsp;
          <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            @click="goToList"
            class="cursor-pointer"
          >
            <path
              d="M6 18.4998L17.9998 6.5"
              stroke="#292D32"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M17.9998 18.4998L6 6.5"
              stroke="#292D32"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>

        <div class="row">
          <div class="col-lg-6 col-xl-7">
            <div class="d-flex justify-content-between">
              <p class="fs-24 fw-600 max-line-2">{{ circle && circle.name }}</p>
            </div>
            <div class="d-flex mb-3">
              <div class="icinfo">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M14 17H4V19H14V17ZM20 9H4V11H20V9ZM4 15H20V13H4V15ZM4 5V7H20V5H4Z"
                    fill="#84818A"
                  />
                </svg>
              </div>
              <p class="white-s-pre">{{ circle && circle.description }}</p>
            </div>
            <div class="d-flex mb-3">
              <div class="icinfo">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6 16H18V8H6V16ZM8 10H16V14H8V10ZM4 15H2V18C2 19.1 2.9 20 4 20H7V18H4V15ZM4 6H7V4H4C2.9 4 2 4.9 2 6V9H4V6ZM20 4H17V6H20V9H22V6C22 4.9 21.1 4 20 4ZM20 18H17V20H20C21.1 20 22 19.1 22 18V15H20V18Z"
                    fill="#84818A"
                  />
                </svg>
              </div>
              <div class="row w-100">
                <div class="col-6">
                  <p class="fs-14 fw-600 mb-1 mt-1">{{ $t("circle_feature.lbl_circle_code") }}</p>
                  <p class="fs-16 fw-600 mb-0 txt-pri">{{ circle && circle.code }}</p>
                </div>
                <div class="col-6">
                  <p
                    class="fs-14 fw-500 mb-0 txt-pri text-decoration-underline text-right mb-1 mt-1 cursor-pointer"
                    @click="showQr(true)"
                  >QR code</p>
                  <p class="fs-14 fw-500 mb-0 text-right">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      @click="copyCode(circle && circle.code)"
                      class="cursor-pointer"
                    >
                      <path
                        d="M16.5 1H4.5C3.4 1 2.5 1.9 2.5 3V17H4.5V3H16.5V1ZM19.5 5H8.5C7.4 5 6.5 5.9 6.5 7V21C6.5 22.1 7.4 23 8.5 23H19.5C20.6 23 21.5 22.1 21.5 21V7C21.5 5.9 20.6 5 19.5 5ZM19.5 21H8.5V7H19.5V21Z"
                        fill="#1D2939"
                      />
                    </svg>
                  </p>
                </div>
              </div>
            </div>
            <div class="d-flex mb-3">
              <div class="icinfo">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.5947 18.2565V13.6353M12.5947 12.6891C12.8067 10.038 11.3857 5.02198 4.00492 6.16728C3.89887 8.34122 5.46835 12.6891 12.5947 12.6891ZM12.5947 12.6891C13.7081 11.5226 15.723 8.56014 20.5 10C20.0654 12.0378 18.3162 14.7488 12.5947 13.6353M12.5947 12.6891V13.6353"
                    stroke="#84818A"
                    stroke-width="2"
                  />
                  <rect x="3" y="18" width="17" height="2" fill="#84818A" />
                </svg>
              </div>
              <div class="row">
                <p class="fs-14 fw-600 mb-1 mt-1">Seed</p>

                <div class="d-flex align-items-center mb-40" v-if="circle && circle.seed_info">
                  <div style="min-width: 40px">
                    <span
                      v-if="circle.seed_info.avatar"
                      class="avatar avatar-sm avatar-rounded"
                      :style="
                        'background-image: url(' +
                        getImageURL(circle.seed_info.avatar) +
                        ')'
                      "
                    ></span>
                    <span
                      v-if="!circle.seed_info || !circle.seed_info.avatar"
                      class="avatar avatar-sm avatar-rounded bg-pri text-white"
                    >
                      {{
                      circle.seed_info.name.charAt(0) ||
                      circle.seed_info.email.charAt(0)
                      }}
                    </span>
                  </div>
                  <div class="flex-1">
                    <p class="fs-14 fw-600 txt-black mb-0">{{ circle.seed_info.name }}</p>
                    <p
                      class="fs-12 fw-500 txt-grey-600 mb-0"
                    >{{ circle.seed_info.doctor_infor && circle.seed_info.doctor_infor.chuc_vu }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-xl-3 ml-40">
            <!-- Hide attachment -->
            <div v-if="false" class="open-chat-att" @click="goToAttachment">
              <svg
                width="48"
                height="48"
                viewBox="0 0 48 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="mr-2"
              >
                <path d="M8 16H40V36H8V16Z" fill="#20419B" />
                <path
                  d="M40 12H24L20 8H8C5.8 8 4.02 9.8 4.02 12L4 36C4 38.2 5.8 40 8 40H40C42.2 40 44 38.2 44 36V16C44 13.8 42.2 12 40 12ZM40 36H8V16H40V36Z"
                  fill="#20419B"
                />
              </svg>
              <span class="txt-black fs-16 fw-500">
                {{
                $t("circle_feature.lbl_org_resource")
                }}
              </span>
            </div>

            <div
              v-if="circle && circle.type === CIRCLE_TYPE.LIVE"
              class="open-chat-att"
              @click="goToStream"
            >
              <div class="d-flex align-items-center">
                <img
                  class="mr-2"
                  :src="liveStream"
                  :alt="circle && circle.name"
                  width="32"
                  height="32"
                />
                <div>
                  <span
                    class="inline-block livestream-redot mb-0"
                    v-if="circle && circle.is_live_stream === 2"
                  >Đang trực tiếp</span>
                  <span
                    class="block txt-black fs-16 fw-500"
                  >{{ $t(circle && circle.is_live_stream === 2?"circle_feature.lbl_circle_live_watch_now":"circle_feature.lbl_circle_live") }}</span>
                </div>
                <!-- <span class="txt-black fs-16 fw-500">{{
                  $t("circle_feature.lbl_circle_live")
                }}</span>-->
              </div>
            </div>
          </div>
          <div class="col-12">
            <CircleSchedule />
          </div>

          <div>
            <div class="d-flex mb-3">
              <div class="icinfo">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.67 13.13C18.04 14.06 19 15.32 19 17V20H23V17C23 14.82 19.43 13.53 16.67 13.13Z"
                    fill="#84818A"
                  />
                  <path
                    d="M15 12C17.21 12 19 10.21 19 8C19 5.79 17.21 4 15 4C14.53 4 14.09 4.1 13.67 4.24C14.5 5.27 15 6.58 15 8C15 9.42 14.5 10.73 13.67 11.76C14.09 11.9 14.53 12 15 12Z"
                    fill="#84818A"
                  />
                  <path
                    d="M9 12C11.21 12 13 10.21 13 8C13 5.79 11.21 4 9 4C6.79 4 5 5.79 5 8C5 10.21 6.79 12 9 12ZM9 6C10.1 6 11 6.9 11 8C11 9.1 10.1 10 9 10C7.9 10 7 9.1 7 8C7 6.9 7.9 6 9 6Z"
                    fill="#84818A"
                  />
                  <path
                    d="M9 13C6.33 13 1 14.34 1 17V20H17V17C17 14.34 11.67 13 9 13ZM15 18H3V17.01C3.2 16.29 6.3 15 9 15C11.7 15 14.8 16.29 15 17V18Z"
                    fill="#84818A"
                  />
                </svg>
              </div>
              <div class="row">
                <p class="fs-14 fw-600 mb-0 mt-1">{{ $t("circle_feature.lbl_circle_member") }}</p>
              </div>
            </div>
          </div>
          <div class="col-12">
            <nav class="navbar navbar-expand-lg navbar-light bg-white">
              <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav mr-auto">
                  <li class="nav-item" :class="{ active: activeTab === 0 }">
                    <a class="nav-link fs-14" href="javascript:;" @click="changeTab(0)">
                      {{ $t("circle_feature.lbl_specialist") }} ({{
                      tabsTotalNumber.specialist_count
                      }})
                    </a>
                  </li>
                  <li class="nav-item" :class="{ active: activeTab === 1 }">
                    <a class="nav-link fs-14" href="javascript:;" @click="changeTab(1)">
                      {{ $t("circle_feature.lbl_patient") }} ({{
                      tabsTotalNumber.patient_count
                      }})
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
            <div class="table-responsive">
              <table
                v-if="members"
                class="table table-vcenter table-mobile-md card-table no--border table-patient-list"
              >
                <thead>
                  <tr>
                    <th>
                      <span class="txt-grey-600 fs-14 fw-600">
                        {{
                        $t("circle_feature.lbl_information")
                        }}
                      </span>
                    </th>
                    <th>
                      <span class="txt-grey-600 fs-14 fw-600">
                        {{
                        $t("circle_feature.lbl_position")
                        }}
                      </span>
                    </th>
                    <th>
                      <span class="txt-grey-600 fs-14 fw-600">
                        {{
                        $t("circle_feature.lbl_workplace")
                        }}
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody style="border-top: none;">
                  <!-- <tr>
                    <td colspan="4"></td>
                  </tr>-->
                  <tr
                    v-for="member in members"
                    class="cursor-pointer custom"
                    :key="member.id"
                    :set="(user = member.user_info)"
                  >
                    <td v-if="user" :data-label="$t('circle_feature.lbl_information')">
                      <div class="d-flex align-items-center px-3">
                        <span
                          v-if="user.avatar"
                          class="avatar avatar-rounded mr-2"
                          :style="
                            'background-image: url(' +
                            getImageURL(user.avatar) +
                            ')'
                          "
                        ></span>
                        <span v-if="!user.avatar" class="avatar avatar-rounded mr-2">
                          {{
                          user.name
                          ? user.name.charAt(0)
                          : user.email
                          ? user.email.charAt(0)
                          : "H"
                          }}
                        </span>
                        <p class="mb-0 fs-14 fw-600 flex-1">{{ user.name || user.email }}</p>
                      </div>
                    </td>
                    <td v-if="user" :data-label="$t('circle_feature.lbl_position')">
                      <p class="mb-0 fs-14 fw-500">
                        {{
                        user.chuc_vu ||
                        (user.doctor_info && user.doctor_info.chuc_vu)
                        }}
                      </p>
                    </td>
                    <td v-if="user" :data-label="$t('circle_feature.lbl_workplace')">
                      <p
                        class="mb-0 fs-14 fw-500"
                      >{{ user && user.doctor_info && user.doctor_info.work }}</p>
                    </td>
                  </tr>
                </tbody>
                <tbody style="border-top: none;">
                  <tr v-if="paginationData.total_page > 1">
                    <td colspan="4" class="text-center">
                      <a
                        href="javascript:;"
                        class="txt-pri fs-16"
                        v-if="
                          paginationData.page_num < paginationData.total_page
                        "
                        @click="getCircleMembers(true)"
                      >{{ $t("circle_feature.btn_load_more") }}</a>
                      <div
                        class="text-center"
                        v-if="
                          (!members || !paginationData.total) && !member_loading
                        "
                      >
                        <!-- <p class="fs-16 fw-500">{{ $t("circle_feature.lbl_no_member") }}</p> -->
                      </div>
                      <div class="skeleton-line" v-if="member_loading"></div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="loading-wr" v-if="loading">
        <div class="skeleton-line" v-for="i in 15" :key="i + 'loading'"></div>
      </div>
      <div
        class="modal fade"
        id="modalQrcode"
        tabindex="-1"
        role="dialog"
        aria-labelledby="modalQrcodeLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-qr modal-dialog-centered" role="document">
          <div class="modal-content">
            <qrcode
              v-if="circle && circle.code"
              :value="circle.code"
              :options="{
                width: 280,
                margin: 1,
                color: {
                  dark: '#20419B',
                  light: '#ffffff',
                },
              }"
              style="margin: 0 auto; padding: 6px"
              class
              :text="circle.code"
            ></qrcode>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import liveStream from '../../assets/images/livestream.png'
import appUtils from '../../utils/appUtils'
import { CIRCLE_TYPE } from '../../utils/constants'
import CircleSchedule from './CircleSchedule.vue'

export default {
  name: 'LiveCircleDetail',
  components: { CircleSchedule },
  data () {
    return {
      circle: null,
      loading: false,
      circle_member: null,
      activeTab: 0,
      specialist_members: [],
      patient_members: [],
      invited_members: [],
      requested_members: [],
      member_loading: false,
      user_id: null,
      liveStream,
      CIRCLE_TYPE,
      params: {
        status: 2,
        role: 2,
        keyword: '',
        page_num: 1,
        page_size: 10,
        circle_id: 0
      },
      member_type: 'specialist_members',
      tabsTotalNumber: {
        specialist_count: 0,
        patient_count: 0,
        invited_count: 0,
        pending_count: 0
      },
      paginationData: {
        total: 0,
        totalPage: 0
      }
    }
  },
  computed: {
    members () {
      const activeTab = this.activeTab
      if (activeTab === 0) {
        return this.specialist_members
      }
      if (activeTab === 1) {
        return this.patient_members
      }
      if (activeTab === 2) {
        return this.invited_members
      }
      if (activeTab === 3) {
        return this.requested_members
      }
      return []
    }
  },
  created () {
    this.user_id = appUtils.getLocalUser().id
    this.prepare()
  },
  methods: {
    async prepare () {
      try {
        this.loading = true
        await this.getCircleDetail()
        this.getTabsTotalNumber()
        this.getCircleMembers()
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
    async getCircleMembers (isNext = false) {
      const self = this
      if (!this.$route.params.id) {
        return
      }
      if (!isNext) {
        self[this.member_type] = []
      } else {
        this.params.page_num++
      }
      self.member_loading = true
      await this.$rf
        .getRequest('AuthRequest')
        .getCircleMembers(this.params)
        .then(r => {
          if (!isNext) {
            self[this.member_type] = r.data.data
            self.paginationData = r.data.page
          } else {
            self.paginationData = r.data.page
            const old = self[this.member_type]
            const newObj = r.data.data
            self[this.member_type] = old.concat(newObj)
          }
        })
        .catch(e => {
          console.log(e)
          self.$toast.open({
            message: this.$t('circle_feature.lbl_permission_msg'),
            type: 'warning'
          })
        })
        .finally(() => {
          self.member_loading = false
        })
    },
    async getCircleDetail () {
      const self = this
      if (!this.$route.params.id) {
        return
      }
      const r = await this.$rf
        .getRequest('AuthRequest')
        .getCircleDetail(this.$route.params.id)
        .then(r => {
          // if (r.data?.status === 0) {
          //   this.$toast.open({
          //     message: this.$t('Bạn chưa được tham gia vào Circle này'),
          //     type: 'warning'
          //   })
          //   this.$router
          //     .replace({
          //       name: 'CircleList'
          //     })
          //     .catch(() => {})
          //   return
          // }
          self.circle = r.data
          self.params.circle_id = self.circle.id
          return r
        })
        .catch(e => {
          console.log(e)
          self.$toast.open({
            message: this.$t('circle_feature.lbl_permission_msg'),
            type: 'warning'
          })
          self.$router.push({ name: 'CircleList' })
          return false
        })
      return r?.data
    },
    async getTabsTotalNumber () {
      if (!this.circle?.id) return
      await this.$rf
        .getRequest('AuthRequest')
        .getTabsTotalNumber(this.circle.id)
        .then(r => {
          this.tabsTotalNumber = { ...r.data }
        })
        .catch(() => {
          this.tabsTotalNumber = { ...this.tabsTotalNumber }
        })
    },
    changeTab (t) {
      if (t === 0 || t === 1 || t === 2 || t === 3) {
        this.activeTab = t
        this.params.page_num = 1
        switch (t) {
          case 0:
            this.params.status = 2
            this.params.role = 2
            this.member_type = 'specialist_members'
            break
          case 1:
            this.params.status = 2
            this.params.role = 3
            this.member_type = 'patient_members'
            break
          case 2:
            this.params.status = 1
            this.params.role = ''
            this.member_type = 'invited_members'
            break
          case 3:
            this.params.status = 0
            this.params.role = ''
            this.member_type = 'requested_members'
            break
          default:
            break
        }
        this.getCircleMembers()
        this.getTabsTotalNumber()
      }
    },
    goToList () {
      this.$router.push({ name: 'CircleList' })
    },
    copyCode (code) {
      const el_code = document.createElement('input')
      document.body.appendChild(el_code)
      el_code.value = code
      el_code.select()
      el_code.setSelectionRange(0, 99999)
      document.execCommand('copy')
      this.$toast.open({
        message: this.$t('circle_feature.lbl_copied_clipboard'),
        type: 'success'
      })
      el_code.remove()
    },
    showQr (show) {
      window.$('#modalQrcode').modal(show ? 'show' : 'hide')
    },
    getImageURL (a) {
      return appUtils.getDocumentURL(a)
    },
    goToAttachment () {
      this.$router.push({
        name: 'CircleAttachments',
        params: { id: this.$route.params.id }
      })
    },
    async goToStream () {
      if (!this.circle.live_room_id) {
        alert(this.$t('circle_feature.lbl_live_circle_not_exist'))
        return
      }
      const { id, live_room_id } = this.circle
      const token = appUtils.getLocalToken()
      let dm = ''
      if (process.env.NODE_ENV === 'development') {
        dm = 'https://dev.live.hodo.app'
      } else {
        dm = 'https://live.hodo.app'
      }
      const routeRaw = `${dm}/redirect.html?token=${token}&room_id=${live_room_id}&circle_id=${id}&type=streaming&role=viewer`
      window.open(routeRaw, '_blank')
    }
  }
}
</script>

<style scoped>
.custom:hover {
  background-color: #e4e7ec;
}
.px {
  padding: 9px 43px;
}
.mb-40 {
  margin-bottom: 40px;
}
.ml-40 {
  margin-left: 40px;
}
.container {
  width: 1024px;
}
.p-48 {
  padding: 0px 48px 0px 48px;
}
.white-s-pre {
  white-space: pre-wrap;
}
.icinfo {
  margin-right: 16px;
}
.bd-blur-gray {
  border-color: #98a2b3;
}
.nav-item.active a {
  color: #20419b !important;
  font-weight: 500;
}
.modal-dialog.modal-qr {
  position: relative;
  display: table; /* This is important */
  overflow-y: auto;
  overflow-x: auto;
  width: auto;
  min-width: 300px;
}
.btn-FEF3F2 {
  background-color: #fef3f2;
  color: #b42318;
  border-color: #fef3f2;
}
.btn-add-member {
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 10px;
  border: 1px dashed #20419b;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Primary */

  color: #20419b;
  padding: 8px;
  cursor: pointer;
}
.open-chat-att {
  padding: 16px;
  display: flex;
  align-items: center;
  background-color: #fafafa;
  width: 100%;
  border-radius: 10px;
  cursor: pointer;
  margin-bottom: 16px;
  position: relative;
}
.has-unread-chat {
  background-color: #f2f6ff;
  border: 1px solid #20419b;
}
.red-dot-new {
  position: absolute;
  top: 5px;
  right: 10px;
}
.disabled {
  opacity: 0.6;
  cursor: not-allowed;
  pointer-events: none;
}
.loading-btn-icon {
  display: none;
}
.loading-btn .loading-btn-icon {
  display: block !important;
}
.loading-btn {
  pointer-events: none;
  color: white;
  border-color: #98a2b3 !important;
}
.loading-btn span {
  display: none;
}
.avatar {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #f0f2f6 no-repeat center/cover;
}
</style>
<style scoped lang="scss">
.txt-pri {
  color: #20419b !important;
}
.bg-pri {
  background-color: #20419b !important;
}

.livestream-redot {
  background-color: #d92d20;
  color: white;
  padding: 1px 8px;
  border-radius: 10px;
  font-size: 10px;
  font-weight: 500;
}
</style>
