import { render, staticRenderFns } from "./SelectCareTeamDoctor.vue?vue&type=template&id=35f12191&scoped=true"
import script from "./SelectCareTeamDoctor.vue?vue&type=script&lang=js"
export * from "./SelectCareTeamDoctor.vue?vue&type=script&lang=js"
import style0 from "./SelectCareTeamDoctor.vue?vue&type=style&index=0&id=35f12191&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35f12191",
  null
  
)

export default component.exports