<template>
  <div
    class="modal fade"
    id="modal-confirm"
    tabindex="-1"
    aria-labelledby="modalConfirm"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content radius-15 p-4 w-150">
        <div class="flex justify-end items-center">
          <div class="text-right">
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              @click="showModalConfirm(false)"
              class="cursor-pointer"
            >
              <path
                d="M6 18.4998L17.9998 6.5"
                stroke="#292D32"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M17.9998 18.4998L6 6.5"
                stroke="#292D32"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
        <div class="fs-20 fw-600">Chú ý</div>
        <div class="fw-500">Bạn đang có đơn hàng chưa được xác nhận thanh toán, nếu tiếp tục mua sẽ hủy đơn cũ.</div>
        <div class="flex justify-center space-x-5 mt-5">
          <button
            @click="reviewOrder"
            class="btn btn-light w-40 p-2"
          >Xem lại Đơn hàng</button>
          <button
            @click="continueOrder"
            class="btn btn-pri w-32 p-2"
          >Tiếp tục</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalConfirm',
  data () {
    return {
    }
  },
  methods: {
    reviewOrder () {
      this.$emit('submit', 'reviewOrder')
      this.showModalConfirm(false)
    },
    continueOrder () {
      this.$emit('submit', 'continueOrder')
      this.showModalConfirm(false)
    },
    showModalConfirm (show) {
      window.$('#modal-confirm').modal(show ? 'show' : 'hide')
    }
  }
}
</script>

<style lang="css" scoped>
</style>
