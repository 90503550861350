<template>
  <div
    class="modal fade"
    id="modalConfirmPac"
    tabindex="-1"
    aria-labelledby="modalConfirmPac"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content radius-15 p-4">
        <div class="mb-3">
          <div class="text-right flex justify-between items-center">
            <div class="fs-20 fw-600">Hãy trở thành Thành viên</div>
            <svg
              @click="goToBack"
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="cursor-pointer"
            >
              <path
                d="M6 18.4998L17.9998 6.5"
                stroke="#292D32"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M17.9998 18.4998L6 6.5"
                stroke="#292D32"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
        <div class="fw-500">Bạn cần trở thành Thành viên của HODO để xem và chia sẻ tài liệu Chẩn đoán hình ảnh. Bạn vẫn muốn tiếp tục?</div>
        <div class="flex justify-end mt-5 space-x-2">
          <button
            class="btn btn-light w-32 p-2"
            @click="closePac"
          >Hủy</button>
          <button
            @click="continuePac"
            class="btn btn-pri w-32 p-2"
          >Đồng ý</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalConfirmPac',
  data () {
    return {
    }
  },
  methods: {
    closePac () {
      this.$emit('submit', 'closePac')
      this.showModalConfirmPac(false)
    },
    continuePac () {
      this.$emit('submit', 'continuePac')
      this.showModalConfirmPac(false)
    },
    showModalConfirmPac (show) {
      window.$('#modalConfirmPac').modal(show ? 'show' : 'hide')
    },
    goToBack () {
      this.showModalConfirmPac(false)
    }
  }
}
</script>

  <style lang="css" scoped>
</style>
