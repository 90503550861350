<template>
  <div class="w-2/3 py-5 mx-auto">
    <div class="shadow-2xl p-4 rounded-2xl" v-loading="isLoading">
      <div class="mb-4 text-center">
        <h2 class="text-2xl">Đăng ký tài khoản dùng thử HODO Pro cho Cơ sở Y tế</h2>
        <p class="italic">Anh, chị vui lòng để lại thông tin để nhận được tài khoản dùng thử ngay!</p>
      </div>
      <el-form :model="form" :rules="rules" ref="registerForm">
        <div class="mb-3">
          <h3 class="text-xl">Thông tin Cơ sở Y tế</h3>
          <div class="mb-2">
            <label class="block required">Tên Phòng khám, Bệnh viện, Cơ sở Y tế</label>
            <el-form-item prop="clinic_name">
              <el-input v-model="form.clinic_name" :disabled="isSubmitting || isResendMail" />
            </el-form-item>
          </div>
          <div class="mb-2">
            <label class="block required">Loại hình hoạt động</label>
            <el-form-item prop="business_type">
              <el-select class="w-full" v-model="form.business_type" default-first-option :disabled="isSubmitting || isResendMail">
                <el-option
                  v-for="item in business_types"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </div>
        </div>
        <div class="mb-3">
          <h3 class="text-xl">Thông tin cá nhân</h3>
          <div class="mb-2">
            <label class="block required">Họ và tên người đăng ký</label>
            <el-form-item prop="contact_name">
              <el-input v-model="form.contact_name" :disabled="isSubmitting || isResendMail" />
            </el-form-item>
          </div>
          <div class="mb-2">
            <label class="block required">Số điện thoại</label>
            <el-form-item prop="contact_phone">
              <el-input v-model="form.contact_phone" :disabled="isSubmitting || isResendMail" />
            </el-form-item>
          </div>
          <div class="mb-2">
            <label class="block required">Email</label>
            <el-form-item prop="contact_email">
              <el-input type="email" v-model="form.contact_email" :disabled="isSubmitting || isResendMail" />
            </el-form-item>
          </div>
          <div class="mb-2">
            <label class="block required">Địa chỉ</label>
            <el-form-item prop="address">
              <el-input type="textarea" v-model="form.address" :disabled="isSubmitting || isResendMail" />
            </el-form-item>
          </div>
        </div>
        <div class="mb-2">
          <el-button type="primary" :loading="isSubmitting || isResendMail" class="w-full" @click="submitForm">
            <span class="uppercase">Hoàn thành đăng ký</span>
          </el-button>
        </div>
        <div v-if="isResendMail">
          <el-button plain :loading="isSubmitting" class="w-full" @click="submitResendMail">
            <span class="uppercase">Gửi lại email thông tin</span>
          </el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import i18n from '@/lang'

export default {
  name: 'RegisterFormHodoPro',
  data () {
    return {
      isLoading: true,
      isSubmitting: false,
      isResendMail: false,
      business_types: [
        {
          value: 1,
          label: 'Bệnh viện'
        },
        {
          value: 2,
          label: 'Phòng khám  Đa khoa'
        },
        {
          value: 3,
          label: 'Phòng khám Chuyên khoa'
        },
        {
          value: 4,
          label: 'Phòng mạch tư'
        },
        {
          value: 5,
          label: 'Nha khoa'
        },
        {
          value: 6,
          label: 'Viện thẩm mỹ'
        },
        {
          value: 7,
          label: 'Nhà thuốc'
        },
        {
          value: 8,
          label: 'Trung tâm Xét nghiệm'
        },
        {
          value: 9,
          label: 'Trung tâm Chẩn đoán hình ảnh'
        },
        {
          value: 10,
          label: 'Khoa trực thuộc bệnh viện'
        },
        {
          value: 11,
          label: 'Spa'
        },
        {
          value: 12,
          label: 'Khác'
        }
      ],
      form: {
        clinic_name: '',
        business_type: 1, // default: Bệnh viện
        contact_name: '',
        contact_phone: '',
        contact_email: '',
        address: ''
      },
      rules: {
        clinic_name: [
          { required: true, message: 'Vui lòng nhập tên Cơ sở Y tế', trigger: ['blur', 'change'] }
        ],
        business_type: [
          { required: true, message: 'Vui lòng chọn loại hình hoạt động', trigger: ['blur', 'change'] }
        ],
        contact_name: [
          { required: true, message: 'Vui lòng nhập họ và tên người đăng ký', trigger: ['blur', 'change'] }
        ],
        contact_phone: [
          { required: true, message: 'Vui lòng nhập số điện thoại', trigger: ['blur', 'change'] },
          { min: 10, max: 11, message: i18n.t('message_errors.phone_invalidate'), trigger: ['blur', 'change'] },
          {
            pattern: /(84|0)+([3|5|7|8|9])+([0-9]{8,})\b/,
            message: i18n.t('message_errors.phone_invalidate'),
            trigger: ['blur', 'change']
          }
        ],
        contact_email: [
          { required: true, message: 'Vui lòng nhập email', trigger: ['blur', 'change'] },
          { type: 'email', message: 'Email không hợp lệ', trigger: ['blur', 'change'] }
        ],
        address: [
          { required: true, message: 'Vui lòng nhập địa chỉ', trigger: ['blur', 'change'] }
        ]
      }
    }
  },
  mounted () {
    setTimeout(() => {
      this.isLoading = false
    }, 750)
  },
  methods: {
    async submitForm () {
      try {
        this.isSubmitting = true
        this.$refs.registerForm.validate(async (valid) => {
          if (valid) {
            const res = await this.$rf.getRequest('GuestRequest').trialRegister(this.form)

            if (!res) return

            if (res?.data?.code === 200110) {
              this.$refs.registerForm.validateField('contact_phone', () => {
                this.$refs.registerForm.fields.find(field => field.prop === 'contact_phone').validateMessage = 'Số điện thoại này đã được đăng ký'
                this.$refs.registerForm.fields.find(field => field.prop === 'contact_phone').validateState = 'error'
              })
              return
            }

            if (res?.data?.code === 200210) {
              this.$refs.registerForm.validateField('contact_email', () => {
                this.$refs.registerForm.fields.find(field => field.prop === 'contact_email').validateMessage = 'Email này đã được đăng ký'
                this.$refs.registerForm.fields.find(field => field.prop === 'contact_email').validateState = 'error'
              })
              return
            }

            if (res?.data?.code === 4030001 || res?.data?.code === 200001) {
              this.$message({
                message: 'Gửi mail thông tin thất bại',
                type: 'error'
              })
              this.isResendMail = true
              return
            }

            if (res?.code === 200) {
              this.$alert({
                message: 'Đăng ký thành công, vui lòng kiểm tra hòm thư của bạn',
                type: 'success'
              })
              this.$alert('Cảm ơn bạn đã đăng ký trải nghiệm ứng dụng HODO. Chúng tôi đã gửi thông tin tài khoản dùng thử tới hòm thư của bạn.', 'Đăng ký thành công', {
                confirmButtonText: 'OK'
              })

              this.$refs.registerForm.resetFields()
            }
          } else {
            return false
          }
        })
      } catch (error) {
        console.log(error)
      } finally {
        this.isSubmitting = false
      }
    },
    async submitResendMail () {
      try {
        if (!this.form.contact_email) {
          this.$message({
            message: 'Không tìm thấy email để gửi',
            type: 'error'
          })
          return
        }

        this.isSubmitting = true
        await this.$rf.getRequest('GuestRequest').trialRegisterResendMail({
          contact_email: this.form.contact_email
        }).then((res) => {
          if (res?.data?.code === 200001) {
            this.$message({
              message: 'Không tìm thấy email để gửi',
              type: 'error'
            })
            return
          }

          if (res?.data?.code === 4030001 || res?.data?.code === 200001) {
            this.$message({
              message: 'Gửi mail thông tin thất bại',
              type: 'error'
            })
            this.isResendMail = true
            return
          }

          if (res?.code === 200) {
            this.$message({
              message: 'Đã gửi lại email thành công',
              type: 'success'
            })
            this.isResendMail = false
            this.$refs.registerForm.resetFields()
          }
        })
      } catch (error) {
        console.log(error)
      } finally {
        this.isSubmitting = false
      }
    }
  }
}
</script>
