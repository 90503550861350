<template>
  <div class="px-160">
    <div class="row">
      <div class="col-md-6 col-lg-4 col-xxl-3">
        <div class="px-1">
          <p class="c-pointer d-flex align-items-start text-content">
            <span class="text-bold">I.GIỚI THIỆU VỀ HODO MEET
            </span>
          </p>
          <p class="c-pointer d-flex align-items-start text-content" @click="scrollTo(1)">
            <span>
              1. HODO là gì ?
            </span>
             <!-- <img src="../../../public/icons/arraw.svg" alt="" class="mt-1 me-1" />-->
          </p>
          <!-- line -->
          <p class="c-pointer d-flex align-items-start text-content" @click="scrollTo(2)">
            <span>
              2. HODO Meet là gì ?
            </span>
          </p>
          <!-- line -->
          <p class="c-pointer d-flex align-items-start text-content" @click="scrollTo(3)">
            <span>
              3. Các cách sử dụng HODO Meet ?
            </span>
          </p>
          <!-- line -->
          <p class="c-pointer d-flex align-items-start text-content" @click="scrollTo(4)">
            <span>
              4. Tài khoản HODO của tôi là gì ?
            </span>
          </p>
          <!-- line -->
          <p class="c-pointer d-flex align-items-start text-content" @click="scrollTo(5)">
            <span>
              5. Làm thế nào để Tải ứng dụng HODO Pro ?
            </span>
          </p>
          <!-- line -->
          <p class="c-pointer d-flex align-items-start text-content">
            <span class="text-bold">II.BẮT ĐẦU SỬ DỤNG HODO MEET ĐỂ HỌP
            </span>
          </p>
          <!-- line -->
          <p class="c-pointer d-flex align-items-start text-content" @click="scrollTo(6)">
            <span>
              1. Làm sao tham gia cuộc họp trên HODO Meet từ thư mời họp qua email ?
            </span>
          </p>
          <!-- line -->
          <p class="c-pointer d-flex align-items-start text-content" @click="scrollTo(7)">
            <span>
              2. Làm sao đăng nhập Tài khoản HODO Meet?
            </span>
          </p>
          <!-- line -->
          <p class="c-pointer d-flex align-items-start text-content" @click="scrollTo(8)">
            <span>
              3. Làm sao tạo 1 cuộc họp mới và thêm thành viên tham gia?
            </span>
          </p>
          <!-- line -->
          <p class="c-pointer d-flex align-items-start text-content" @click="scrollTo(9)">
            <span>
              4. Làm sao tham gia các cuộc họp Định kỳ trên HODO Meet?
            </span>
          </p>
          <!-- line -->
          <p class="c-pointer d-flex align-items-start text-content" @click="scrollTo(10)">
            <span>
              5. Làm sao để tham gia 1 cuộc họp đột xuất mà người chủ trì chưa kịp thêm tôi vào phần thành viên?
            </span>
          </p>
          <!-- line -->
          <p class="c-pointer d-flex align-items-start text-content">
            <span class="text-bold">III.BẮT ĐẦU SỬ DỤNG HỘI CHẨN TRÊN HODO
            </span>
          </p>
          <!-- line -->
          <p class="c-pointer d-flex align-items-start text-content" @click="scrollTo(11)">
            <span>
              1. Điều kiện cần để tham gia Hội chẩn thông qua HODO?
            </span>
          </p>
          <!-- line -->
          <p class="c-pointer d-flex align-items-start text-content" @click="scrollTo(12)">
            <span>
              2. Làm sao để đưa ca bệnh vào phiên hội chẩn?
            </span>
          </p>
          <!-- line -->
          <p class="c-pointer d-flex align-items-start text-content" @click="scrollTo(13)">
            <span>
              3. Làm sao đưa tài liệu/xét nghiệm, phim chẩn đoán hình ảnh PACS đính kèm lên cùng ca bệnh?
            </span>
          </p>
          <!-- line -->
          <p class="c-pointer d-flex align-items-start text-content" @click="scrollTo(14)">
            <span>
              4. Làm sao để tạo và chỉnh sửa “Trích biên bản hội chẩn” và “Tóm tắt thông qua mổ”?
            </span>
          </p>
          <!-- line -->
          <p class="c-pointer d-flex align-items-start text-content" @click="scrollTo(15)">
            <span>
              5. Ai là người có thể tạo và chỉnh sửa “Trích biên bản hội chẩn” và “Tóm tắt thông qua mổ”?
            </span>
          </p>
          <!-- line -->
          <p class="c-pointer d-flex align-items-start text-content">
            <span class="text-bold">IV.KHẮC PHỤC SỰ CỐ
            </span>
          </p>
          <!-- line -->
          <p class="c-pointer d-flex align-items-start text-content" @click="scrollTo(16)">
            <span>
              1. Tôi không nhận được email lời mời họp/ Hội chẩn hoặc email OTP ?
            </span>
          </p>
          <!-- line -->
          <p class="c-pointer d-flex align-items-start text-content" @click="scrollTo(17)">
            <span>
              2. Làm gì khi vào link và gặp thông báo “Bạn không thể tham gia cuộc họp này” ?
            </span>
          </p>
          <!-- line -->
          <p class="c-pointer d-flex align-items-start text-content" @click="scrollTo(18)">
            <span>
              3. Làm gì khi không nhận được OTP khi truy cập vào đường link đính kèm ở email lời mời ?
            </span>
          </p>
          <!-- line -->
          <p class="c-pointer d-flex align-items-start text-content" @click="scrollTo(19)">
            <span>
              4. Làm gì trong trường hợp gặp lỗi sau khi đăng nhập vào ứng dụng ?
            </span>
          </p>
          <!-- line -->
          <p class="c-pointer d-flex align-items-start text-content" @click="scrollTo(20)">
            <span>
              5. Lỗi camera hoặc micro không khả dụng ?
            </span>
          </p>
          <!-- line -->
          <p class="c-pointer d-flex align-items-start text-content" @click="scrollTo(21)">
            <span>
              6. Lỗi không tắt được micro hoặc camera trong cuộc họp?
            </span>
          </p>
          <!-- line -->
          <p class="c-pointer d-flex align-items-start text-content" @click="scrollTo(22)">
            <span>
              7. Làm sao để liên hệ Hỗ trợ kỹ thuật?
            </span>
          </p>
          <!-- line -->
          <p class="c-pointer d-flex align-items-start text-content">
            <span class="text-bold">V.QUYỀN RIÊNG TƯ TRONG HODO
            </span>
          </p>
          <!-- line -->
          <p class="c-pointer d-flex align-items-start text-content" @click="scrollTo(23)">
            <span>
              Cơ chế bảo mật và an toàn thông tin của HODO?
            </span>
          </p>
        </div>
      </div>
      <div class="col-md-6 col-lg-8 col-xxl-9">
        <!-- line -->
        <p class="text-content">
          <span class="text-title">I. GIỚI THIỆU VỀ HODO MEET</span>
        </p>
        <!-- line -->
        <p id="1" class="text-content">
          <span class="text-title">1. HODO là gì ?</span>
        </p>
        <p class="text-content">
          <span class="text-bold">HODO</span> là nền tảng được xây dựng giúp kết nối, cộng tác giữa người chăm sóc,
          <br />
          bác sĩ điều trị và bác sĩ gia đình để chăm sóc bệnh nhân, đặc biệt là bệnh nhân ung thư trong quá trình điều
          trị.<br />
          Các tính năng của HODO:<br />
          + Quản lý công việc dễ dàng và hiệu quả<br />
          + Họp và Hội chẩn trực tuyến<br />
          + Cộng tác thông qua <span class="text-bold">Workspace</span> và <span class="text-bold">Circle</span><br />
          + Theo dõi sức khỏe của bệnh nhân từ xa<br />
          + Nắm bắt dữ liệu bệnh nhân theo thời gian thực<br />
          + Theo sát sự tuân thủ của bệnh nhân<br />
        </p>
        <!-- line -->
        <p id="2" class="text-content">
          <span class="text-title">2. HODO Meet là gì ?</span>
        </p>
        <p class="text-content">
          <span class="text-bold">HODO Meet</span> là công cụ giúp Bác sĩ, nhân viên y tế họp và hội chẩn trực tuyến với
          <span class="text-bold">chuẩn bảo mật HIPAA</span> dành
          cho Y tế.<br />
        </p>
        <!-- line -->
        <p id="3" class="text-content">
          <span class="text-title">3. Các cách sử dụng HODO Meet ?</span>
        </p>
        <p class="text-content">
          Có ba cách truy cập để sử dụng <span class="text-bold">HODO Meet</span>: <br />
          <span class="text-italic">Cách 1</span>: Từ Email có chứa link mời họp/ hội chẩn<br />
          <span class="text-italic">Cách 2</span>: Đăng nhập bằng <span class="text-bold-blue">tài khoản HODO</span>
          trên trình duyệt internet máy tính hoặc điện thoại (Chrome, Cốc Cốc,
          Safari):<br />
          Trực tiếp trên Website: <a href="https://meet.hodo.com.vn">https://meet.hodo.com.vn</a><br />
          Thông qua Workspace: <a href="https://hodo.app">https://hodo.app</a><br />
          <span class="text-italic">Cách 3</span>: Sử dụng Ứng dụng HODO pro trên điện thoại iOS hoặc Android<br />

        </p>
        <!-- line -->
        <p id="4" class="text-content">
          <span class="text-title">4. Tài khoản HODO của tôi là gì ?</span>
        </p>
        <p class="text-content">
          Tài khoản HODO là tài khoản gắn liền với email mà bạn nhận được lời mời Họp/Hội chẩn hoặc tham gia Workspace.
          Bạn có thể dùng mã xác thực OTP để đăng nhập vào tài khoản.<br />
          <span class="text-italic">Để đặt hoặc thay đổi mật khẩu</span> <br />
          - Đăng nhập tại: <a href="https://hodo.app" class="text-bold">https://hodo.app</a><br />
          - Đăng nhập bằng OTP: tại màn hình đăng nhập, chọn <span class="text-bold">“Đăng nhập bằng OTP”</span>
          sau đó nhập email được mời họp và ấn <span class="text-bold">“Gửi OTP”</span>.<br />
          <img :src="baseUrl + '/images/Helps/Meet/41.png'" class="img-fluid mb-5" alt="" /><br />
          - Đổi mật khẩu lần đầu : Sau khi đăng nhập lần đầu bằng mã xác thực OTP, chọn mục <span
            class="text-bold">“Thiết lập”</span>,
          vào phần <span class="text-bold">“Tài khoản & bảo mật”</span> để tiến hành thay đổi mật khẩu.
          Click <span class="text-bold">“Cập nhật”</span> khi hoàn thành cài đặt mật khẩu.
          <img :src="baseUrl + '/images/Helps/Meet/42.png'" class="img-fluid mb-5" alt="" />
        </p>
        <!-- line -->
        <p id="5" class="text-content">
          <span class="text-title">5. Làm thế nào để Tải ứng dụng HODO Pro ?</span>
        </p>
        <div class="row">
          <div class="col-md-3 col-lg-3 col-xxl-3">
            <img :src="baseUrl + '/images/Helps/Meet/51.png'" class="img-fluid mb-5" alt="" />
          </div>
          <div class="col-md-9 col-lg-9 col-xxl-9">
            <p id="51" class="text-content">
              Bạn có thể tải ứng dụng HODO PRO xuống để thuận tiện Họp/Hội chẩn trên điện thoại.<br />
              Tải HODO PRO tại: <a href="http//hodo.app/bsi" class="text-bold">http//hodo.app/bsi</a><br />
              hoặc quét mã QR code.
            </p>
          </div>
        </div>
        <p id="52" class="text-content">
          Điều kiện tải và sử dụng:<br />
          - Đối với thiết bị hệ điều hành IOS ( Iphone, Ipad ), bạn cần có hệ điều hành tương thích: iOS 11 trở
          lên.<br />
          - Đối với thiết bị hệ điều hành <span class="text-bold">Android</span> ( Samsung, Xiaomi, Vsmart,...). Bạn cần
          có hệ điều hành tương thích:
          Android 5.0 trở lên.<br />
        </p>
        <!-- line -->
        <p class="text-content">
          <span class="text-title">II. BẮT ĐẦU SỬ DỤNG HODO MEET ĐỂ HỌP</span>
        </p>
        <!-- line -->
        <p id="6" class="text-content">
          <span class="text-title">1. Làm sao tham gia cuộc họp trên HODO Meet từ thư mời họp qua email ?</span>
        </p>
        <p class="text-content">
          <span class="text-bold">Bước 1</span>Click vào link mời Họp/Hội chẩn.
          <span class="text-bold">Bước 2</span>Nhập mã OTP đã được gửi vào email để xác nhận.<br />
          <img :src="baseUrl + '/images/Helps/Meet/61.png'" class="img-fluid mb-5" alt="" />
        </p>
        <p class="text-content">
          Bạn có thể <span class="text-bold">test loa</span> hoặc tùy chọn <span class="text-bold">Bật/Tắt Camera và
            Micro</span> của mình.<br />
          Bước 3: Chọn <span class="text-bold">“Tham gia ngay”</span> để tham gia vào phòng họp<br />
          Chi tiết tại: <a href="https://www.youtube.com/watch?v=4Zd2q1bIqJQ"
            class="text-bold">https://www.youtube.com/watch?v=4Zd2q1bIqJQ</a><br />
        </p>
        <!-- line -->
        <p id="7" class="text-content">
          <span class="text-title">2. Làm sao đăng nhập Tài khoản HODO Meet?</span>
        </p>
        <p class="text-content">
          Bạn có thể Đăng nhập tài khoản trên website:<br />
          Truy cập website <a href="https://meet.hodo.com.vn" class="text-bold">https://meet.hodo.com.vn</a> sau đó chọn
          “Đăng nhập”<br />
          Bạn có thể lựa chọn 1 trong 2 cách sau:<br />
          <img :src="baseUrl + '/images/Helps/Meet/71.png'" class="img-fluid mb-5" alt="" />
          <br />
          + <span class="text-bold">Đăng nhập bằng Mật khẩu</span>: Nhập email và mật khẩu Tài khoản HODO để đăng nhập
          vào hệ thống.<br />
          + <span class="text-bold">Đăng nhập bằng OTP</span>: Nhập email và lựa chọn “Đăng nhập bằng OTP”,<br />
          Chọn <span class="text-bold"></span>“Gửi mã OTP”, sau đó kiểm tra email để xem và nhập mã xác nhận. <br />
          <span class="text-italic-red">Lưu ý: tài khoản email mà bạn nhập phải là tài khoản được mời tham gia họp hoặc
            đã đăng ký với HODO trước đó.<br />
          </span><br />
          Ngoài ra, bạn cũng có thể đăng nhập <span class="text-bold-blue">Tài khoản HODO</span> trên ứng dụng HODO Pro
          để thuận tiện tham gia các Cuộc họp/Hội chẩn trên điện thoại.
        </p>
        <div class="row">
          <div class="col-md-6 col-lg-6 col-xxl-6">
            <img :src="baseUrl + '/images/Helps/Meet/81.png'" class="img-fluid mb-5" alt="" width="400px" />
          </div>
          <div class="col-md-6 col-lg-6 col-xxl-6">
            <img :src="baseUrl + '/images/Helps/Meet/82.png'" class="img-fluid mb-5" alt="" width="400px" />
          </div>
        </div>
        <!-- line -->
        <p id="8" class="text-content">
          <span class="text-title">3. Làm sao tạo 1 cuộc họp mới và thêm thành viên tham gia?</span>
        </p>
        <p class="text-content">
          Để tạo cuộc họp mới, đăng nhập <span class="text-bold-blue">Tài khoản HODO</span> tại website <a
            href="https://hodo.app" class="text-bold">https://hodo.app</a><br />
          Chọn mục <span class="text-bold">“Họp/Hội chẩn”</span>,<br />
          <span class="text-bold">Bước 1</span>: Chọn <span class="text-bold">“Tạo mới”</span>, sau đó chọn <span
            class="text-bold">“Cuộc họp”</span>
          <img :src="baseUrl + '/images/Helps/Meet/91.png'" class="img-fluid mb-5" alt="" /><br />
          <span class="text-bold">Bước 2</span>: Nhập các thông tin chi tiết của Cuộc họp và chọn <span
            class="text-bold">“Lưu”</span><br />
          <img :src="baseUrl + '/images/Helps/Meet/92.png'" class="img-fluid mb-5" alt="" /><br />
          <span class="text-bold">Bước 3</span>: Bạn có thể thêm các thành viên vào cuộc họp bằng cách thêm Email tại
          phần <span class="text-bold">“Thêm thành viên”</span>
          <img :src="baseUrl + '/images/Helps/Meet/93.png'" class="img-fluid mb-5" alt="" /><br />
        </p>
        <!-- line -->
        <p id="9" class="text-content">
          <span class="text-title">4. Làm sao tham gia các cuộc họp Định kỳ trên HODO Meet?</span>
        </p>
        <p class="text-content">
          Bạn có thể xem Danh sách các cuộc họp sẽ diễn ra và tham gia sau khi đăng nhập<br />
          vào website <a href="https://meet.hodo.com.vn" class="text-bold">https://meet.hodo.com.vn</a> hoặc tải và đăng
          nhập <span class="text-bold-blue">Tài khoản HODO</span><br />
          trên ứng dụng HODO Pro.<br />
          <img :src="baseUrl + '/images/Helps/Meet/101.png'" class="img-fluid mb-5" alt="" /><br />
        </p>
        <!-- line -->
        <p id="10" class="text-content">
          <span class="text-title">5. Làm sao để tham gia 1 cuộc họp đột xuất mà người chủ trì chưa kịp thêm tôi vào
            phần thành viên?</span>
        </p>
        <p class="text-content">
          Bạn có thể tham gia 1 cuộc họp nội bộ trong Workspace kể cả khi chưa được thêm vào <br />
          “Thành viên cuộc họp”, thông qua link cuộc họp và phê duyệt của người chủ trì.<br /><br />

          Khi nhận được link cuộc họp, bạn truy cập link và “Yêu cầu tham gia“ sau đó <br />
          chờ đợi phê duyệt tham gia của người chủ trì.
          <br />
        </p>
        <!-- line -->
        <p class="text-content">
          <span class="text-title">III. BẮT ĐẦU SỬ DỤNG HỘI CHẨN TRÊN HODO</span>
        </p>
        <!-- line -->
        <p id="11" class="text-content">
          <span class="text-title">1. Điều kiện cần để tham gia Hội chẩn thông qua HODO?</span>
        </p>
        <p class="text-content">
          Để đảm bảo quy chuẩn thực hiện Hội chẩn, Bạn cần thực hiện xác minh định danh<br />
          Bác sĩ/Nhân viên y tế thông qua việc <span class="text-bold-blue">xác minh nhân thân</span> bởi hệ thống
          HODO<br />
          hoặc thuộc 1 Workspace - đại diện 1 Cơ sở khám chữa bệnh.<br />
        </p>
        <!-- line -->
        <p id="12" class="text-content">
          <span class="text-title">2. Làm sao để đưa ca bệnh vào phiên hội chẩn?</span>
        </p>
        <p class="text-content">
          Bước 1: Đăng nhập tài khoản Bác sĩ thông qua website <a href="https://hodo.app"
            class="text-bold">https://hodo.app</a><br />
          Bước 2: Chọn <span class="text-bold">“Họp/Hội chẩn”</span>, chọn phiên hội chẩn phù hợp<br />
          <span class="text-italic">Lưu ý: chọn đúng Workspace thực hiện phiên hội chẩn</span><br />
          <img :src="baseUrl + '/images/Helps/Meet/121.png'" class="img-fluid mb-5" alt="" /><br />
          Bước 3: Tại phần <span class="text-bold">“Danh sách ca bệnh”</span><br />
          <img :src="baseUrl + '/images/Helps/Meet/122.png'" class="img-fluid mb-5" alt="" /><br />
          Chọn <span class="text-bold">“Thêm ca bênh”</span>.
          Bác sĩ nhập đầy đủ thông tin liên quan tới ca bệnh, chọn <span class="text-bold">“Thêm”</span>.
        </p>
        <!-- line -->
        <p id="13" class="text-content">
          <span class="text-title">3. Làm sao đưa tài liệu/xét nghiệm, phim chẩn đoán hình ảnh PACS đính kèm lên cùng ca
            bệnh?</span>
        </p>
        <p class="text-content">
          Sau khi đưa ca bệnh lên phiên hội chẩn, Bác sĩ có thể đính kèm các <span class="text-bold">Tài liệu/ Xét
            nghiệm</span>,<br />
          hoặc <span class="text-bold">phim chẩn đoán hình ảnh PACS</span> giúp thành viên tham gia khác có thể<br />
          dễ dàng theo dõi chi tiết về ca bệnh.<br />
          <span class="text-bold">Bước 1</span>: Chọn ca bệnh tại mục <span class="text-bold">“Danh sách ca
            bệnh”</span>trong phiên hội chẩn.<br />
          <span class="text-bold">Bước 2</span>: Tải lên <span class="text-bold">Tài liệu/ Xét nghiệm</span>hoặc <span
            class="text-bold">phim chẩn đoán hình ảnh PACS</span> tại các mục tương ứng.<br />
          <img :src="baseUrl + '/images/Helps/Meet/131.png'" class="img-fluid mb-5" alt="" /><br />
        </p>
        <!-- line -->
        <p id="14" class="text-content">
          <span class="text-title">4. Làm sao để tạo và chỉnh sửa “Trích biên bản hội chẩn” và “Tóm tắt thông qua mổ”?
          </span>
        </p>
        <p class="text-content">
          Bạn có thể có thể soạn <span class="text-bold">“Trích biên bản hội chẩn”</span> và <span
            class="text-bold">“Tóm tắt thông qua mổ”</span><br />
          trước khi phiên hội chẩn diễn ra, và thực hiện chỉnh sửa trong hoặc sau quá trình<br />
          diễn ra hội chẩn.<br />
          <img :src="baseUrl + '/images/Helps/Meet/141.png'" class="img-fluid mb-5" alt="" /><br />
          Các tài liệu này đã được định dạng theo chuẩn theo mẫu tài liệu được quy định của Bộ y tế.<br />
          Do vậy, bạn có thể xuất file trực tiếp từ hệ thống và lưu hành tài liệu này<br />
          mà không cần soạn lại thông qua công cụ văn phòng nào khác.<br />
        </p>
        <!-- line -->
        <p id="15" class="text-content">
          <span class="text-title">5. Ai là người có thể tạo và chỉnh sửa “Trích biên bản hội chẩn” và “Tóm tắt thông
            qua mổ”?</span>
        </p>
        <p class="text-content">
          Chỉ có Bác sĩ phụ trách trực tiếp ca bênh, Chủ tọa và thư ký của phiên hội chẩn<br />
          có thể tạo và chỉnh sửa <span class="text-bold">“Trích biên bản hội chẩn”</span> và <span
            class="text-bold">“Tóm tắt thông qua mổ”</span><br />
        </p>
        <!-- line -->
        <p class="text-content">
          <span class="text-title">IV. KHẮC PHỤC SỰ CỐ</span>
        </p>
        <!-- line -->
        <p id="16" class="text-content">
          <span class="text-title">1. Tôi không nhận được email lời mời họp/ Hội chẩn hoặc email OTP ?</span>

        </p>
        <p class="text-content">
          Vui lòng kiểm tra các <span class="text-bold">mục thư Spam</span> trong gmail để chắc chắn<br />
          bạn không bỏ lỡ thư mời Họp/Hội chẩn<br />
          <img :src="baseUrl + '/images/Helps/Meet/161.png'" class="img-fluid mb-5" alt="" /><br />
          Liên hệ quản trị viên để xác nhận đã được gửi đúng email.<br />
          Liên hệ Hotline: <span class="text-bold-blue">+84 345 900 905</span> để được <span class="text-bold-blue">Hỗ
            trợ kỹ thuật</span>.<br />

        </p>
        <!-- line -->
        <p id="17" class="text-content">
          <span class="text-title">2. Làm gì khi vào link và gặp thông báo “Bạn không thể tham gia cuộc họp này”
            ?</span>
        </p>
        <p class="text-content">
          Trước tiên, cần đảm bảo bạn thuộc Workspace sở hữu cuộc họp/ hội chẩn<br />
          hoặc là thành viên trong cuộc họp/ hội chẩn.<br />
          Liên lạc với quản trị viên trong trường hợp bạn chưa thuộc workspace đó.<br />
        </p>
        <!-- line -->
        <p id="18" class="text-content">
          <span class="text-title">3. Làm gì khi không nhận được OTP khi truy cập vào đường link đính kèm ở email lời
            mời ?</span>
        </p>
        <p class="text-content">
          Đảm bảo bạn truy cập link họp/hội chẩn bằng trình duyệt ( Chrome, Cốc Cốc, Safari )<br />
          hoặc ứng dụng.<br />
          Nếu tình trạng vẫn chưa được khắc phục, vui lòng <span class="text-bold-blue">Liên hệ hỗ trợ kỹ
            thuật</span>.<br />

        </p>
        <!-- line -->
        <p id="19" class="text-content">
          <span class="text-title">4. Làm gì trong trường hợp gặp lỗi sau khi đăng nhập vào ứng dụng ?</span>
        </p>
        <p class="text-content">
          Đối với các lỗi như Trắng thông tin khi đăng nhập vào ứng dụng, lỗi Unauthenticated,<br />
          hoặc lỗi không vào được ứng dụng, Bạn có thể thực hiện <span class="text-bold">“Đăng xuất”</span> khỏi ứng
          dụng,<br />
          sau đó xóa đi và tải lại ứng dụng.<br />
          <span class="text-italic-red">Lưu ý: hãy thường xuyên cập nhật ứng dụng, để đảm bảo độ ổn
            định</span>.<br /><br />
          Trong trường hợp, gặp các lỗi khác hoặc không thể khắc phục, Bạn có thể liên hệ với HODO để nhận được <span
            class="text-bold-blue">Hỗ trợ kỹ thuật</span>.<br />

        </p>
        <!-- line -->
        <p id="20" class="text-content">
          <span class="text-title">5. Lỗi camera hoặc micro không khả dụng ?</span>
        </p>
        <p class="text-content">
          Đảm bảo Hodo có quyền truy cập vào micro và camera để phục vụ cho việc Họp/Hội chẩn,<br />
          bạn có thể thay đổi trong cài đặt của máy hoặc trình duyệt.<br />
          <img :src="baseUrl + '/images/Helps/Meet/201.png'" class="img-fluid mb-5" alt="" /><br />
          Trong trường hợp không khắc phục được, vui lòng liên hệ hotline: <span class="text-bold-blue">+84 345 900
            905</span>
        </p>
        <!-- line -->
        <p id="21" class="text-content">
          <span class="text-title">6. Lỗi không tắt được micro hoặc camera trong cuộc họp?</span>
        </p>
        <p class="text-content">
          Hãy thử <span class="text-bold">“tải lại”</span> cuộc họp<br />
          Trong trường hợp không khắc phục được, vui lòng liên hệ hotline: <span class="text-bold-blue">+84 345 900
            905</span>
        </p>
        <!-- line -->
        <p id="22" class="text-content">
          <span class="text-title">7. Làm sao để liên hệ Hỗ trợ kỹ thuật?</span>
        </p>
        <p class="text-content">
          Liên hệ hotline của HODO:<br />
          CSKH - Ms. Điệp : <span class="text-bold-blue">+84 345 900 905</span><br />
          CSKH - Ms. Hương: <span class="text-bold-blue">+84 376 777 340</span><br />
        </p>
        <!-- line -->
        <p class="text-content">
          <span class="text-title">V. QUYỀN RIÊNG TƯ TRONG HODO</span>
        </p>
        <!-- line -->
        <p id="23" class="text-content">
          <span class="text-title">1. Cơ chế bảo mật và an toàn thông tin của HODO?</span>
        </p>
        <p class="text-content">
          HODO sử dụng hàng loạt biện pháp an toàn nhằm đảm bảo an toàn cho các cuộc họp video của bạn.<br />
          Trong đó có các biện pháp kiểm soát chống xâm nhập dành cho cả cuộc họp video trên web<br />
          lẫn cuộc họp gọi qua điện thoại.<br />
          Dưới đây là một số biện pháp mà chúng tôi đang áp dụng:<br />
          - Magic link<br />
          - Xác minh 2 bước với OTP<br />
          - Bảo mật <span class="text-bold-blue">HIPAA</span> và <span class="text-bold-blue">Cystack</span><br />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelpsMeet',
  data () {
    return {
      baseUrl: window.location.origin
    }
  },
  methods: {
    scrollTo (i) {
      document.getElementById(i).scrollIntoView()
    }
  }
}
</script>

<style lang="css" scoped>
.text-content {
  color: #061829;
  font-size: 1.25em;
  line-height: 1.6em;
  font-weight: 400;
  text-align: left;
}

.text-topic {
  color: #061829;
  font-size: 1.5em;
  line-height: 1.6em;
  font-weight: 600;
  text-align: left;
}

p .text-italic {
  color: #061829;
  font-size: 1.0em;
  line-height: 1.6em;
  font-weight: 500;
  text-align: left;
  font-style: italic;
}

p .text-italic-red {
  color: #e15109e6;
  font-size: 1.0em;
  line-height: 1.6em;
  font-weight: 500;
  text-align: left;
  font-style: italic;
}

p .text-title {
  color: #061829;
  font-size: 1.1em;
  line-height: 1.6em;
  font-weight: 500;
  text-align: left;
  padding-bottom: 5px;
}

p .text-bold {
  color: #061829;
  font-size: 1.0em;
  line-height: 1.6em;
  font-weight: 500;
  text-align: left;
}

p .text-bold-blue {
  color: #0010fd;
  font-size: 1.1em;
  line-height: 1.6em;
  font-weight: 500;
  text-align: left;
}
</style>
