<template>
    <div class="circle-docs container mt-1 bg-white">
      <div class="d-flex justify-content-between py-3">
        <span class="fs-20 fw-700">{{ $t('home_page.lbl_org_resource') }}</span>
        <svg
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          @click="goToDetail"
          class="cursor-pointer"
        >
          <path
            d="M6 18.4998L17.9998 6.5"
            stroke="#292D32"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M17.9998 18.4998L6 6.5"
            stroke="#292D32"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <div class="d-flex mb-3" v-if="circle">
        <a
          href="javascript:;"
          class="fs-16 fw-600 txt-grey-900 mr-2"
          @click="goToFolder(null)"
        >{{ circle && circle.name }} ></a>
        <div v-if="bread && bread.length">
          <a
            href="javascript:;"
            class="fs-16 fw-600 mr-2"
            v-for="(b, i) in bread"
            :key="b.id + 'b'"
            :class="i === bread.length - 1 ? 'txt-grey-600' : 'txt-grey-900'"
            @click="goToFolder(b.id)"
          >{{ b.name }} ></a>
        </div>
      </div>

      <!-- Search & filter -->
      <div class="flex justify-between mb-4 items-center">
        <div class="flex items-center mt-3 w-72">
          <div class="input-group input-search-box input-search mb-1">
            <input
              type="search"
              v-model="search"
              class="form-control bg-white"
              :placeholder="$t('circle_feature.lbl_enter_doctor_msg')"
              @keyup.enter="getCircleAttachments"
            />
            <span
              class="input-group-text bg-white border-0 radius-10 font-size-20 cursor-pointer"
              @click="getCircleAttachments"
            >
              <i class="fa fa-search"></i>
            </span>
          </div>
        </div>
        <div class="flex items-center gap-2">
          <div class="fs-14 font-bold text-gray-900">
            <span class="mb-2">{{ $t("Chọn loại") }}</span>
            <el-select v-model="filter.documentType" class="w-full">
              <div v-for="item in DOCUMENT_OPTIONS" :key="item.type + 'dc'">
              <el-option
                v-if="!(item.type === DOCUMENT_OPTIONS[0].type && $route.params.folder === 'meet')"
                :label="item.label"
                :value="item.type"
              />

              </div>
            </el-select>
          </div>
          <div>
            <label class="fs-14 font-bold text-gray-900 mb-0">
              <span class="mb-2">{{ $t("Từ ngày") }}</span>
              <span class="hodo-el block el-input el-input--suffix">
                <input
                  name="fromDate"
                  v-model="filter.fromDate"
                  type="date"
                  class="el-input__inner pr-3 bg-white border border-gray-200"
                  :placeholder="$t('Chọn ngày')"
                />
              </span>
            </label>
          </div>
          <div>
            <label class="fs-14 font-bold text-gray-900 mb-0">
              <span class="mb-2">{{ $t("Đến ngày") }}</span>
              <span class="hodo-el block el-input el-input--suffix">
                <input
                  name="toDate"
                  v-model="filter.toDate"
                  type="date"
                  class="el-input__inner pr-3 bg-white border border-gray-200"
                  :placeholder="$t('Chọn ngày')"
                />
              </span>
            </label>
          </div>
        </div>
      </div>
      <!-- Table -->
      <div class="row">
        <div class="row pr-0 py-0 pl-3 fs-16 fw-600">
          <div class="flex items-center py-2 border-bottom">
            <div class="col-4">Tên</div>
            <div class="col-3">Người tạo</div>
            <div class="col-2">Ngày tạo</div>
            <div class="col-1">Loại</div>
            <div class="col-2"></div>
          </div>
        </div>
        <div class="px-3">
          <div
            class="row flex items-center p-2 item-doc"
            v-for="(item,index) in attachments"
            :key="index + 'item'"
          >
            <div
              @click="openDocAttach(item)"
              class="col-4 flex items-center gap-1 p-0"
              :set="(icon = getFileAttIcon(item))"
            >
              <div
                class="item-doc-thumb-img flex justify-center items-center"
                v-if="icon && icon.is_image"
              >
                <img
                  width="30"
                  height="30"
                  src="../../../public/assets/images/icon/image_default.svg"
                  alt
                />
              </div>
              <div
                class="item-doc-thumb-img d-flex justify-center items-center"
                v-if="icon && !icon.is_image"
              >
                <img width="24" height="24" :src="icon.path" alt />
              </div>
              <p class="flex-1 block px-2 txt-black fs-14 fw-500 break-all mb-0">{{ item.isFile ? item.file.name:item.folder.name }}</p>
            </div>
            <p
              @click="openDocAttach(item)"
              class="col-3 p-0 fs-14 txt-black fw-500 max-line-1 mb-0"
            >{{ getCreateBy(item) }}</p>
            <p @click="openDocAttach(item)" class="col-2 p-0 fs-14 txt-black fw-500 max-line-1 mb-0">
              {{ item.last_update ? DDMMYYYYSlash(item.last_update) : item.created_at
              ? DDMMYYYYSlash(item.created_at) : '' }}
            </p>
            <p
              @click="openDocAttach(item)"
              class="col-1 p-0 capitalize txt-black fs-14 fw-500 max-line-1 mb-0"
            >{{ getDocType(item) }}</p>
            <div @click="openDocAttach(item)" class="col-1 p-0 text-center flex gap-2">
              <p
                class="fs-14 max-line-1 mb-0"
                v-if="item.isFile && item.file.type == 3 && item.file.pac"
                :set="pacStatus = getPacStatus(item.file.pac.status)"
              >
                <span class="text-center" :class="pacStatus ? pacStatus.class : ''">{{ pacStatus.name || '' }}</span>
              </p>

              <div
              class="p-0 radius-10 c-pointer"
              v-if="item.isFile && item.file.type == 3 && item.file.pac && item.file.pac.status === 2"
              >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21.25 9.14993C18.94 5.51993 15.56 3.42993 12 3.42993C10.22 3.42993 8.49 3.94993 6.91 4.91993C5.33 5.89993 3.91 7.32993 2.75 9.14993C1.75 10.7199 1.75 13.2699 2.75 14.8399C5.06 18.4799 8.44 20.5599 12 20.5599C13.78 20.5599 15.51 20.0399 17.09 19.0699C18.67 18.0899 20.09 16.6599 21.25 14.8399C22.25 13.2799 22.25 10.7199 21.25 9.14993ZM12 16.0399C9.76 16.0399 7.96 14.2299 7.96 11.9999C7.96 9.76993 9.76 7.95993 12 7.95993C14.24 7.95993 16.04 9.76993 16.04 11.9999C16.04 14.2299 14.24 16.0399 12 16.0399Z"
                  fill="#476DD6"
                />
                <path
                  d="M12.0004 9.13989C10.4304 9.13989 9.15039 10.4199 9.15039 11.9999C9.15039 13.5699 10.4304 14.8499 12.0004 14.8499C13.5704 14.8499 14.8604 13.5699 14.8604 11.9999C14.8604 10.4299 13.5704 9.13989 12.0004 9.13989Z"
                  fill="#476DD6"
                />
              </svg>
            </div>
            </div>

            <div
              class="col-1 p-0 text-right"
              v-if="checkRole(item)"
            >
              <div class="dropdown">
                <img
                  src="../../../public/assets/images/icon/icon-more.svg"
                  class="dropdown-toggle"
                  data-toggle="dropdown"
                />
                <div class="dropdown-menu item-drop z-10 relative" aria-labelledby="dropdownMenuButton">
                  <a
                    class="dropdown-item fs-16"
                    href="javascript:;"
                    @click="modalEdit(true, item)"
                    v-if="(item.isFile && Number(item.file.type) === 2)"
                  >
                    <img class="mr-2" src="../../../public/assets/images/icon/icon-edit-2.svg" />
                    {{ $t('circle_feature.lbl_change_name') }}
                  </a>

                  <a
                    class="dropdown-item fs-16"
                    href="javascript:;"
                    @click="modalEditFolder(true, item)"
                    v-if="item.isFile === 0"
                  >
                    <img class="mr-2" src="../../../public/assets/images/icon/icon-edit-2.svg" />
                    {{ $t('circle_feature.lbl_change_name') }}
                  </a>

                  <a
                    class="dropdown-item fs-16"
                    href="javascript:;"
                    v-if="item.isFile"
                    @click="deleteCircleAttachment(item.file.id)"
                  >
                    <img class="mr-3" src="../../../public/assets/images/icon/icon-trash-2.svg" />
                    {{
                    $t('multidisciplinary_board.btn_remove') }}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <AppPagination :paginationProps="pagination" @onChangePagination="(data) => handleChangePagination(data)" />
        <!-- </div> -->
      </div>
      <!-- Table -->

    </div>

    </div>
</template>
<script>
import appUtils from '../../utils/appUtils'
import moment from 'moment'
import { AppPagination } from '@/components/Common'

const file_icons = [
  { type: ['doc', 'docx', 'docm'], path: 'assets/images/icon/icon-doc.svg' },
  {
    type: ['pptx', 'ppsx', 'ppt', 'pptm'],
    path: 'assets/images/icon/icon-pptx.svg'
  },
  {
    type: ['xlsx', 'xlsm', 'xlsb', 'xltx'],
    path: 'assets/images/icon/icon-xls.svg'
  },
  { type: ['pdf'], path: 'assets/images/icon/icon-pdf.svg' },
  { type: ['zip', 'rar', '7z'], path: 'assets/images/icon/icon-file.svg' },
  { type: ['link'], path: 'assets/images/icon/u_link.svg' },
  { type: ['folder'], path: 'assets/images/icon/_folder.svg' },
  { type: ['pac'], path: 'assets/images/icon/pac-icon.png' },
  { type: ['undefine'], path: 'assets/images/icon/undefine.svg' },
  {
    type: ['mp3', 'flac', 'aac', 'alac', 'wma', 'ogg', 'wav', 'aiff'],
    path: 'assets/images/icon/audio.svg'
  },
  {
    type: [
      'webm',
      'mkv',
      'flv',
      'flv',
      'vob',
      'ogv',
      'gifv',
      'mng',
      'avi',
      'mov',
      'wmv',
      'yuv',
      'rm',
      'rmvb',
      'mp4',
      '3gp',
      'm4v',
      'mpg'
    ],
    path: 'assets/images/icon/icon-video-f.svg'
  }
]
const pac_statuses = [
  { id: 1, name: 'Đang xử lý', class: 'text-warning' },
  { id: 2, name: 'Hoàn thành', class: 'text-success' },
  { id: 3, name: 'Không xử lý được tài liệu', class: 'text-danger' }
]

const DOCUMENT_OPTIONS = [
  {
    type: 1,
    label: 'Tất cả'
  },
  {
    type: 2,
    label: 'Thư mục'
  },
  {
    type: 3,
    label: 'Tệp'
  },
  {
    type: 4,
    label: 'PACS file'
  },
  {
    type: 5,
    label: 'Link'
  }
]

const FILE_TYPE = {
  file: 1,
  link: 2,
  pacs: 3,
  carePlan: 4
}

export default {
  name: 'CircleDocumentsV2',
  components: { AppPagination },
  data () {
    return {
      exts: [
        'jpeg',
        'png',
        'jpg',
        'gif',
        'svg',
        'doc',
        'docx',
        'pdf',
        'xls',
        'xlsx',
        'xlsm',
        'ppt',
        'pptx',
        'zip',
        'rar',
        '7z',
        'link'
      ],
      img_exts: ['jpeg', 'png', 'jpg', 'gif', 'svg', 'link'],
      file_icons,
      err_file: '',
      selected_docs: [],
      search: '',
      circle: null,
      attachments: [],
      uploading: false,
      user_id: null,
      days: 10000,
      item_select: null,
      folder: null,
      bread: [],
      pac_statuses,
      isFolder: false,
      formFolder: {
        name: '',
        parent_id: null
      },
      form: {
        name: '',
        link: ''
      },
      filter: {
        documentType: DOCUMENT_OPTIONS[0].type,
        fromDate: '',
        toDate: ''
      },
      pagination: {
        currentPage: 1,
        pageSize: 15,
        totalItems: 0
      },
      DOCUMENT_OPTIONS
    }
  },
  mounted () {
    this.user_id = appUtils.getLocalUser()?.id
    this.prepare()
    if (this.$route.params.folder) {
      this.isFolder = true
    } else {
      this.isFolder = false
    }
  },
  watch: {
    '$route.params.id' () {
      this.prepare()
    },
    '$route.params.folder' () {
      if (Number(this.$route.params.folder) >= 0) {
        this.isFolder = true
        this.filter.documentType = DOCUMENT_OPTIONS[0].type
        this.showCircleFolder()
        this.getCircleAttachments()
      } else {
        this.filter.documentType = DOCUMENT_OPTIONS[0].type
        this.isFolder = false
        this.showCircleFolder()
        this.getCircleAttachments()
      }
    },
    $refresh () {
      this.showCircleFolder()
      this.getCircleAttachments()
    },
    filter: {
      handler: function (filterData) {
        this.getCircleAttachments()
      },

      deep: true
    }
  },
  methods: {
    goToDetail () {
      this.$router
        .push({ name: 'CircleDetail', params: { id: this.$route.params.id } })
        .catch(() => {})
    },
    goToFolder (folderId) {
      const params = {
        id: this.$route.params.id
      }
      if (folderId) {
        params.folder = folderId
        this.$router
          .push({ name: 'CircleDocumentsFolder', params: params })
          .catch(() => {})
      } else {
        this.$router
          .push({ name: 'CircleDocuments', params: params })
          .catch(() => {})
      }
    },
    async prepare () {
      try {
        this.loading = true
        const m = await this.getMember()
        const c = await this.showCircle()
        if (
          (m && m.data && m.data.status === 2) ||
            c?.data?.created_by === this.user_id
        ) {
          this.showCircleFolder()
          this.getCircleAttachments()
        } else {
          this.$toast.open({
            message: this.$t('circle_feature.lbl_permission_msg'),
            type: 'warning'
          })
          this.$router.push({ name: 'CircleList' })
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
    async showCircle () {
      if (!this.$route.params.id) return
      const self = this
      const r = await this.$rf
        .getRequest('DoctorRequest')
        .showCircle(this.$route.params.id)
        .then(r => {
          self.circle = r.data
          return r
        })
        .catch(e => {
          console.log(e)
          self.$toast.open({
            message: this.$t('circle_feature.lbl_permission_msg'),
            type: 'warning'
          })
          self.$router.push({ name: 'CircleList' })
          return false
        })
      return r
    },
    async getMember () {
      const self = this
      const r = await this.$rf
        .getRequest('AuthRequest')
        .getMemberByUserId(
          this.$route.params.id,
          appUtils.getLocalUser().id,
          null,
          false
        )
        .then(r => {
          console.log(r.data)
          self.circle_member = r.data
          return r
        })
        .catch(() => {
          return false
        })
      return r
    },
    async showCircleFolder () {
      this.bread = []
      if (!this.$route.params.folder) {
        this.folder = null
        this.bread = []
        return
      }
      if (!this.$route.params.id) return
      const self = this
      const r = await this.$rf
        .getRequest('DoctorRequest')
        .showCircleFolder(this.$route.params.id, this.$route.params.folder)
        .then(r => {
          self.folder = r.data
          self.getBread(r.data, true)
          return r
        })
        .catch(e => {
          console.log(e)
          self.$toast.open({
            message: this.$t('circle_feature.lbl_folder_notexist'),
            type: 'warning'
          })
          self.goToDetail()
          return false
        })
      return r
    },
    async getCircleAttachments (data) {
      const self = this

      const params = {
        page: data?.currentPage || this.pagination.currentPage,
        limit: data?.pageSize || this.pagination.pageSize,
        folderId: this.$route.params.folder || 0,
        keyword: this.search

      }
      if (this.filter.documentType) {
        params.type = this.filter.documentType
      }
      if (this.filter.fromDate && this.filter.toDate) {
        params.startDate = moment(this.filter.fromDate).format('YYYY/MM/DD')
        params.endDate = moment(this.filter.endDate).format('YYYY/MM/DD')
      }

      await this.$rf
        .getRequest('DoctorRequest')
        .getCircleAttachmentsV2(this.$route.params.id, params)
        .then(r => {
          const responseData = r.data
          self.attachments = responseData.data
          self.pagination = {
            currentPage: responseData.meta?.current_page || 1,
            pageSize: Number(responseData?.meta.per_page) || 15,
            totalItems: responseData.meta?.total || 0
          }
        })
        .catch(() => {
          self.goToDetail()
        })
    },
    onSelectDoc (e) {
      const self = this
      const files = e.target.files
      self.err_file = ''
      const errFile = ''
      if (files) {
        for (let i = 0; i < files.length; i++) {
          const f = files[i]
          self.selected_docs.push(f)
        }
      }
      self.$refs.inputFile.value = null
      if (errFile) {
        alert(
          this.$t('circle_feature.lbl_file_no') +
              errFile.substring(0, errFile.length - 1) +
              this.$t('circle_feature.lbl_reject_format_msg')
        )
      }
    },
    isImage (ext) {
      return this.img_exts.findIndex(e => e === ext.toLowerCase()) > -1
    },
    getBase64URL (file) {
      return URL.createObjectURL(file)
    },
    getImageUrl (path) {
      return appUtils.getDocumentURL(path)
    },
    getFileIcon (doc) {
      if (this.isImage(doc.name.split('.').pop())) {
        return this.getBase64URL(doc)
      } else {
        const extIc = this.file_icons.find(
          fi => fi.type.findIndex(t => t === doc.name.split('.').pop()) > -1
        )
        if (extIc) {
          return extIc.path
        } else {
          return this.file_icons[5].path
        }
      }
    },
    getFileAttIcon (doc) {
      if (doc?.isFile === 1) {
        if (Number(doc.file.type) === 3) {
          return { is_image: false, path: this.file_icons[7].path }
        } else if (Number(doc.file.type) === 2) {
          return { is_image: false, path: this.file_icons[5].path }
        } else {
          if (this.isImage(doc.file.name.split('.').pop())) {
            return { is_image: true, path: doc.url }
          } else {
            const extIc = this.file_icons.find(
              fi => fi.type.findIndex(t => t === doc.file.name.split('.').pop()) > -1
            )
            if (extIc) {
              return { is_image: false, path: extIc.path }
            } else {
              return { is_image: false, path: this.file_icons[8].path }
            }
          }
        }
      } else {
        return { is_image: false, path: this.file_icons[6].path }
      }
    },
    deleteSltDoct (i) {
      this.selected_docs.splice(i, 1)
    },

    openDocAttach (doc) {
      if (doc.isFile === 0) {
        this.goToFolder(doc.folder.id)
      } else {
        if (Number(doc.file.type) === FILE_TYPE.pacs) {
          if (doc.file?.pac?.status === 2) {
            this.openPac(doc.file.pac.accession)
          }
        } else if (Number(doc.file.type) === FILE_TYPE.link) {
          window.open(doc.file.url)
        } else {
          if (doc.file.url) {
            window.open(doc.file.url)
          } else if (doc.file.file_name) {
            this.openFile(doc.file.file_name)
          }
        }
      }
    },
    stopPrevent () {
      window.event.stopPropagation()
    },
    getBread (folder, refresh = false) {
      if (folder) {
        if (refresh) this.bread = []
        this.bread.unshift(folder)
        if (folder.parent) {
          this.getBread(folder.parent)
        }
      }
    },

    async openPac (accession) {
      const self = this
      const params = {
        accession: accession
      }
      await self.$rf
        .getRequest('DoctorRequest')
        .getUrlPac(params)
        .then(r => {
          if (r.data) {
            window.open(r.data)
          }
        })
    },
    async openFile (name) {
      const self = this
      const params = {
        fileName: name
      }
      const header = {
        'x-api-key': '4be5160e89e681cede9a80ae6db6af7c'
      }
      await self.$rf
        .getRequest('AuthRequest')
        .getLinkDownloadAwsRc(params, header, false)
        .then(r => {
          if (r?.data?.data) window.open(r.data.data)
        })
        .catch(e => {
          self.$toast.open({
            message: this.$t('circle_feature.lbl_err_down_msg'),
            type: 'warning'
            // all other options
          })
          console.error(e)
        })
    },
    dateLabel (date) {
      if (date === window.moment().format('YYYY-MM-DD')) {
        return this.$t('circle_feature.lbl_today')
      } else {
        return window.moment(date, 'YYYY-MM-DD').format('DD-MM-YYYY')
      }
    },
    DDMMYYYYSlash (d) {
      if (!d) return
      return window.moment(d).utc().format('DD/MM/YYYY HH:mm:ss')
    },
    loadMore () {
      this.getCircleAttachments()
    },
    getPacStatus (stt) {
      return this.pac_statuses.find(s => s.id === stt) || this.statuses[0]
    },
    handleFilter (value) {
      console.log({ value })
    },
    handleOpenDocsMeet () {
      this.$router.push({ name: 'CircleDocumentsMeetFolder' })
    },
    handleShowMeetFolder () {},
    handleChangePagination (data) {
      this.pagination = { ...this.pagination, ...data }

      this.getCircleAttachments(data)
    },
    getCreateBy (item) {
      return item.isFile ? item.file?.user?.name || '' : item.folder?.user?.name || ''
    },
    getDocType (item) {
      if (item.isFile === 0) return 'Thư mục'

      if (Number(item.file.type) === FILE_TYPE.link) return 'Link'
      else if (Number(item.file.type) === FILE_TYPE.pacs) return 'PACS File'
      else return 'Tệp'
    },
    checkRole (item) {
      if (this.circle?.created_by === this.user_id) return true

      if (item.isFile) {
        return item.file?.user?.id === this.user_id
      } else {
        return item.folder?.user?.id === this.user_id
      }
    }
  }
}
</script>
  <style scoped>
  .circle-docs {
    height: calc(100vh - 90px);
    overflow: auto;
  }

  .item-drop {
    border-radius: 16px;
  }

  .item-doc {
    display: flex;
    border-bottom: 1px solid #ebeaed;
    border-radius: 4px;
    cursor: pointer;
    position: relative;
  }

  .item-doc-info {
    height: 76px;
    overflow: hidden;
  }

  .item-doc-thumb {
    padding: 50px 0px 50px 0px;
  }

  .item-doc-thumb-img {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    height: 24px;
    width: 24px;
    background-size: contain;
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: center;
  }

  .i-item {
    border: 1px solid black;
  }

  .upload-btn2 {
    padding: 18px 0px 18px 0px;
  }

  .upload-btn {
    width: 100%;
    border: 1px dashed #20419b;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70px;
    border-radius: 9px;
    cursor: pointer;
  }

  .u-button {
    border-style: dashed;
    width: 100%;
    border: 2px dashed #edf2f7;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
    border-radius: 9px;
    cursor: pointer;
  }

  .input-search {
    border: 1px solid #edf2f7;
    border-radius: 10px;
  }

  .selected-file-icon {
    width: 24px;
    height: 24px;
    background-size: contain;
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: center;
  }

  .item-doc-info-text {
    width: calc(100% - 35px);
    padding-left: 15px;
  }

  .w-265px {
    width: 100%;
    max-width: 265px;
  }

  .txt-greyscale-600 {
    color: #718096;
  }

  .border-custom {
    border: 1px solid #dfdfdf;
    border-radius: 16px;
  }

  .border-bottom {
    border-bottom: 1px solid #ebeaed;
  }
  </style>
