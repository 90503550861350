<template>
  <aside :class="`${isExpanded && 'is-expanded'}`">
    <div class="logo">
      <img src="../../../public/logo.svg" />
    </div>
    <div class="menu mt-3">
      <router-link class="button" to="/my-account">
        <!-- <img class="img-nav" src="../../../public/icons/UserProfile/NavBar/home.svg" alt /> -->
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="40"
          height="40"
          viewBox="0 0 32 32"
          fill="none"
          stroke="#475467"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-user ml-6"
        >
          <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
          <circle cx="12" cy="7" r="4" />
        </svg>
        <span class="text whitespace-nowrap" v-if="isExpanded">Tài khoản của tôi</span>
      </router-link>
      <router-link class="button" to="/hssk">
        <img class="img-nav" src="../../../public/icons/UserProfile/NavBar/clipboard.svg" alt />
        <span class="text whitespace-nowrap" v-if="isExpanded">Hồ sơ sức khỏe</span>
      </router-link>
      <!-- <router-link class="button" to="/diagnose">
        <img class="img-nav" src="../../../public/icons/UserProfile/NavBar/result.svg" alt />
        <span class="text whitespace-wrap" v-if="isExpanded">KQ xét nghiệm, chẩn đoán hình ảnh</span>
      </router-link>-->
      <router-link class="button" to="/health-records">
        <img class="img-nav" src="../../../public/icons/UserProfile/NavBar/stickynote.svg" alt />
        <span class="text whitespace-nowrap" v-if="isExpanded">Sổ khám bệnh</span>
      </router-link>
      <router-link class="button" to="/circles">
        <svg
          class="img-nav el-icon-shopping-cart-full fs-32 align-middle"
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.87868 14.6213C11.0503 15.7929 12.9497 15.7929 14.1213 14.6213C15.2929 13.4497 15.2929 11.5502 14.1213 10.3786C12.9497 9.20707 11.0503 9.20707 9.87868 10.3786C8.70711 11.5502 8.70711 13.4497 9.87868 14.6213Z"
            fill="currentColor"
          />
          <circle cx="3.99974" cy="12.4997" r="2.99974" fill="#5D5D5D" />
          <path
            d="M15 4.5C15 6.15685 13.6569 7.5 12 7.5C10.3431 7.5 9 6.15685 9 4.5C9 2.84315 10.3431 1.5 12 1.5C13.6569 1.5 15 2.84315 15 4.5Z"
            fill="currentColor"
          />
          <path
            d="M15 20.5C15 22.1569 13.6569 23.5 12 23.5C10.3431 23.5 9 22.1569 9 20.5C9 18.8431 10.3431 17.5 12 17.5C13.6569 17.5 15 18.8431 15 20.5Z"
            fill="currentColor"
          />
          <circle cx="19.9997" cy="12.5002" r="2.99974" fill="currentColor" />
        </svg>
        <span class="text whitespace-nowrap" v-if="isExpanded">Circle</span>
      </router-link>
      <router-link class="button" to="/ecommerce">
        <img class="img-nav" src="../../../public/icons/UserProfile/NavBar/shopping.svg" alt />
        <span class="text whitespace-nowrap" v-if="isExpanded">Mua sắm</span>
      </router-link>
      <router-link class="button" to="/orders">
        <i class="img-nav el-icon-shopping-cart-full fs-32 align-middle" />
        <span class="text whitespace-nowrap" v-if="isExpanded">Đơn hàng</span>
      </router-link>
      <router-link class="button" to="/payment-receipts">
        <i class="img-nav el-icon-bank-card fs-32 align-middle" />
        <span class="text whitespace-nowrap" v-if="isExpanded">Thanh toán</span>
      </router-link>

      <router-link class="button" to="/thong-tin-ca-nhan">
        <img class="img-nav" src="../../../public/icons/UserProfile/NavBar/users.svg" alt />
        <span class="text whitespace-nowrap" v-if="isExpanded">Thông tin cá nhân</span>
      </router-link>
      <a class="button cursor-pointer" @click="logout">
        <img class="img-nav" src="../../../public/icons/UserProfile/NavBar/log-out.svg" alt />
        <span class="text whitespace-nowrap text-danger" v-if="isExpanded">Đăng xuất</span>
      </a>
    </div>
    <div class="menu-tooggle-wrap mt-auto bg-gray-200 text-gray-700 cursor-pointer">
      <div
        class="menu-toggle flex"
        :class="isExpanded ? 'justify-between' : 'justify-center'"
        @click="ToggleMenu"
      >
        <i
          :class="isExpanded ? 'el-icon-d-arrow-left' : 'el-icon-d-arrow-right'"
          class="text-2xl align-middle"
        />
        <span
          v-if="isExpanded"
          class="align-middle whitespace-nowrap -ml-3 transition-all duration-300"
        >
          Thu gọn danh
          mục
        </span>
        <span v-if="isExpanded">&nbsp;</span>
      </div>
    </div>
  </aside>
</template>

<script>
import { ref } from '@vue/composition-api'
import appUtils from '../../utils/appUtils'

export default {
  name: 'SideBar',
  setup () {
    const isExpanded = ref(true)
    const ToggleMenu = () => {
      isExpanded.value = !isExpanded.value
    }
    return {
      ToggleMenu,
      isExpanded
    }
  },
  methods: {
    logout () {
      const r = confirm('Bạn có chắc chắn muốn đăng xuất?')
      if (!r) return
      appUtils.removeLocalUser()
      appUtils.removeLocalToken()
      this.$user = null
      setTimeout(() => {
        this.$router.push({ path: '/login' }).catch(() => {})
      }, 100)
    }
  }
}
</script>

<style lang="scss" scoped>
aside {
  display: flex;
  flex-direction: column;
  width: calc(2rem + 50px);
  overflow: hidden;
  padding: 1rem 1rem 0 1rem;
  background-color: var(--background-sidebar);
  color: var(--primary);
  transition: 0.2s ease-out;

  .logo {
    img {
      width: 10rem;
    }
  }

  .menu-tooggle-wrap {
    position: relative;
    top: 0;
    transition: 0.2s ease-out;
    margin: 0 -1rem;
    padding: 0.75rem 1rem;

    .menu-toggle {
      transition: 0.2s ease-out;

      .material-icons {
        font-size: 2rem;
        color: var(--primary);
        transition: 0.2s ease-out;
      }

      &:hover {
        .material-icons {
          cursor: pointer;
          color: var(--primary);
          transform: translateX(0.5rem);
        }
      }
    }
  }

  .button .text {
    opacity: 0;
    transition: 0.3s ease-out;
  }

  .menu {
    margin: 0 -1rem;

    .button {
      height: 3rem;
      display: flex;
      align-items: center;
      text-decoration: none;
      margin-bottom: 1rem;
      transition: 0.2s ease-out;

      .img-nav {
        margin-left: calc(2rem - 9px);
        margin-right: 1rem;
        width: 2rem;
        color: var(--text-color);
      }

      .material-icons {
        margin-right: calc(1rem);
        font-size: 2rem;
        color: var(--text-color);
        transition: 0.2s ease-out;
      }

      .text {
        color: var(--text-color);
        transition: 0.2s ease-out;
      }

      &:hover,
      &.router-link-exact-active {
        background-color: var(--dark-alt);

        .material-icons,
        .text {
          color: var(--text-color);
        }
      }

      &.router-link-exact-active {
        border-right: 5px solid var(--dark-alt);
      }
    }
  }

  &.is-expanded {
    width: var(--sidebar-width);

    .menu-tooggle-wrap {
      .menu-toggle {
      }
    }

    .button .text {
      opacity: 1;
      transition: 0.9s ease-in;
    }
  }

  @media (max-width: 768) {
    position: fixed;
    z-index: 99;
  }
}
</style>
