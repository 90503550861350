import Vue from 'vue'
// import store from '../store'

// axios
import axios from 'axios'
import appUtils from '../utils/appUtils'

// Fake db
// const axiosIns = axios.create({
// You can add your headers here
// ================================
// baseURL: 'https://some-domain.com/api/',
// timeout: 1000,
// headers: {'X-Custom-Header': 'foobar'}
// })

// Medical register
const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: process.env.VUE_APP_BASE_API + 'api/',
  headers: {
    'Content-Type': 'application/json'
  }
})
axiosIns.interceptors.request.use(config => {
  if (appUtils.getLocalToken()) {
    config.headers = {
      Authorization: `Bearer ${appUtils.getLocalToken()}`
    }
  }
  return config
})
Vue.prototype.$http2 = axiosIns

export default axiosIns
