export const TQM_TYPE = 1
export const TQM_PASS = 2
export const TQM_NOT_PASS = 3
export const STATUS_SCHEDULE = {
  CODE: {
    PENDING: 0,
    DONE: 1,
    CANCEL: 2
  },
  NAME: {
    PENDING: 'PENDING',
    DONE: 'DONE',
    CANCEL: 'CANCEL'
  }
}

export const EXAMINATION_STATUS = {
  CODE: {
    WAITING: 1,
    BEING: 2,
    WAITING_RESULT: 3,
    WAITING_READ_RESULT: 4,
    DONE: 5,
    CANCELED: 6,
    NEXT_CALL: 7,
    MISSING: 8
  },
  NAME: {
    WAITING: 'WAITING',
    BEING: 'BEING',
    WAITING_RESULT: 'WAITING_RESULT',
    WAITING_READ_RESULT: 'WAITING_READ_RESULT',
    DONE: 'DONE',
    CANCELED: 'CANCELED',
    NEXT_CALL: 'NEXT_CALL',
    MISSING: 'MISSING'
  },
  ID: {
    1: 'WAITING',
    2: 'BEING',
    3: 'WAITING_RESULT',
    4: 'WAITING_READ_RESULT',
    5: 'DONE',
    6: 'CANCELED',
    7: 'NEXT_CALL',
    8: 'MISSING'
  }
}

export const APPOINTMENT_STATUS = {
  CODE: {
    CLINIC_PENDING: 1,
    CLINIC_CONFIRMED: 2,
    CLINIC_REJECTED: 3,
    PATIENT_PENDING: 4,
    PATIENT_ACCEPTED: 5,
    PATIENT_CANCELLED: 6
  },
  NAME: {
    CLINIC_PENDING: 'CLINIC_PENDING',
    CLINIC_CONFIRMED: 'CLINIC_CONFIRMED',
    CLINIC_REJECTED: 'CLINIC_REJECTED',
    PATIENT_PENDING: 'PATIENT_PENDING',
    PATIENT_ACCEPTED: 'PATIENT_ACCEPTED',
    PATIENT_CANCELLED: 'PATIENT_CANCELLED'
  },
  ID: {
    1: 'CLINIC_PENDING',
    2: 'CLINIC_CONFIRMED',
    3: 'CLINIC_REJECTED',
    4: 'PATIENT_PENDING',
    5: 'PATIENT_ACCEPTED',
    6: 'PATIENT_CANCELLED'
  }
}

export const APPOINTMENT_METHOD_STATUS = {
  CODE: {
    OFFLINE: 1,
    ONLINE: 2
  },
  NAME: {
    OFFLINE: 'OFFLINE',
    ONLINE: 'ONLINE'
  },
  ID: {
    1: 'OFFLINE',
    2: 'ONLINE'
  }
}

export const INVOICE_STATUS = {
  CODE: {
    PATIENT_PENDING: 1,
    PATIENT_PAID: 2,
    DOCTOR_ACCEPT: 3,
    PAID_IN_FRONT_DESK: 4
  },
  NAME: {
    PATIENT_PENDING: 1,
    PATIENT_PAID: 2,
    DOCTOR_ACCEPT: 3,
    PAID_IN_FRONT_DESK: 4
  },
  ID: {
    1: 'PATIENT_PENDING',
    2: 'PATIENT_PAID',
    3: 'DOCTOR_ACCEPT',
    4: 'PAID_IN_FRONT_DESK'
  }
}

export const PATIENT_RECEPTION_TYPE = {
  CODE: {
    APPOINTMENT: 1,
    WAITING_EXAMINATION: 2
  },
  NAME: {
    APPOINTMENT: 'APPOINTMENT',
    WAITING_EXAMINATION: 'WAITING_EXAMINATION'
  },
  ID: {
    1: 'APPOINTMENT',
    2: 'WAITING_EXAMINATION'
  }
}

export const INDICATION_TYPES = {
  CODE: {
    TEST_FORM: 1,
    ULTRASOUND: 2,
    OTHERS: 3,
    MOLECULAR_BIOLOGY: 4
  }
}
export const DOCUMENT_SIGN_TYPES = {
  CODE: {
    CONSULTATION: 1,
    INDICATION: 2,
    PRESCRIPTION: 3,
    INDICATION_ORDER: 4,
    DISEASE_OPERATION: 5
  }
}

export const INDICATION_STATUS = {
  CODE: {
    PENDING: 0,
    HAS_RESULT: 1,
    CS: 2,
    NCS: 3
  }
}

export const CIRCLE_TYPE = {
  NORMAL: 1,
  LIVE: 2
}

export const AUTHOR_PLATFORM = {
  WEB: 1,
  MOBILE: 2
}
export const USE_ROOM_PRICE_CR = {
  isNotUse: 1,
  isUse: 2
}

export const PAYMENT_TRANSACTION_STATUS = [
  {
    value: 0,
    label: 'Tất cả trạng thái thanh toán'
  },
  {
    value: 1,
    label: 'Chưa thanh toán'
  },
  {
    value: 2,
    label: 'Đã thanh toán'
  }
]

export const TRANSACTION_ERROR = [
  { value: '1', label: 'lbl_the_bank_refused_to_authorize_the_transaction_' },
  {
    value: '2',
    label: 'lbl_the_card_issuing_bank_refuses_to_authorize_the_transaction_'
  },
  {
    value: '3',
    label: 'lbl_no_response_has_been_received_from_the_card_issuer_'
  },
  {
    value: '4',
    label:
      'lbl_the_card_s_expiration_month_year_is_incorrect_or_the_card_has_expired_'
  },
  { value: '5', label: 'lbl_card_balance_limit_is_not_enough_for_payment_' },
  {
    value: '6',
    label: 'lbl_no_response_has_been_received_from_the_card_issuer_'
  },
  {
    value: '7',
    label: 'lbl_error_in_the_bank_s_transaction_processing_process_'
  },
  {
    value: '8',
    label: 'lbl_the_card_issuing_bank_does_not_support_online_payments_'
  },
  { value: '9', label: 'lbl_invalid_cardholder_account_name_' },
  { value: '10', label: 'lbl_expired_card_card_locked_' },
  {
    value: '11',
    label:
      'lbl_card_account_has_not_registered_for_online_payment_support_service_'
  },
  {
    value: '12',
    label: 'lbl_month_year_of_issuance_or_expiration_of_the_card_is_invalid_'
  },
  {
    value: '13',
    label:
      'lbl_transactions_exceeding_the_online_payment_limit_according_to_the_bank_s_regulations_'
  },
  { value: '14', label: 'lbl_invalid_card_number_' },
  { value: '21', label: 'lbl_account_balance_is_not_enough_for_payment_' },
  { value: '22', label: 'lbl_invalid_account_information_' },
  { value: '23', label: 'lbl_card_account_is_locked_or_not_activated_' },
  { value: '24', label: 'lbl_invalid_card_account_information_' },
  { value: '25', label: 'lbl_otp_authentication_code_is_invalid_' },
  { value: '26', label: 'lbl_the_otp_authentication_code_has_expired_' },
  { value: '98', label: 'lbl_transaction_validation_is_cancelled' },
  { value: '99', label: 'lbl_user_cancels_transaction_' },
  {
    value: 'B',
    label:
      'lbl_error_during_transaction_authentication_by_the_card_issuing_bank_'
  },
  {
    value: 'D',
    label:
      'lbl_error_during_transaction_authentication_by_the_card_issuing_bank_'
  },
  { value: 'F', label: 'lbl_csc_code_validation_failed_' },
  { value: 'U', label: 'lbl_transaction_validation_failed_' },
  { value: 'Z', label: 'lbl_transaction_declined_' },
  {
    value: '253',
    label: 'lbl_the_deadline_for_entering_payment_information_has_expired_'
  },
  { value: '', label: 'lbl_an_unknown_error_' }
]
