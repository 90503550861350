/* eslint-disable import/no-cycle */
import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import ecommerce from './ecommerce'
import registerMedical from './registerMedical'
import app from './app'
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    ecommerce,
    registerMedical,
    app
  },
  strict: true
})
