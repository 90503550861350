<template>
  <div class="handbook-detail px-4">
    <div v-if="!loading && handbook">
      <div class="pt-3">
          <a
            href="javascript:;"
            class="fs-21 fw-500 txt-gray-blur text-decoration-none d-flex align-items-center mb-1"
            @click="goBack()"
          ><img
              src="../../../public/icons/lessthan-blur-gray.svg"
              width="24"
              alt=""
            > Quay lại</a>
        <div class="row pb-3">
          <div class="col-12">

            <p class="title-handbook mb-0 txt-black">{{handbook.title}}</p>

          <p class="fs-14 fw-400 txt-gray-blur">
            <span>{{formatDMYHM(handbook.publish_at)}}</span>
            <span>{{getCate()}}</span>
          </p>
            <p
              class="txt-content-handbook txt-gray-dark fw-500"
              style="white-space: pre-wrap;"
            >
              {{handbook.desc_short}}
            </p>
          </div>
          <div class="col-md-12 mb-3">
            <div class="ratio ratio-16x9">
              <img
                v-if="handbook.image"
                :src="getDocumentURL(handbook.image)"
                class="img-detail"
                alt="..."
              >
            </div>
          </div>
        </div>
      </div>
      <div class="pt-3">
        <div v-html="handbook.desc_full"></div>
        <p class="fs-16 fw-500 txt-black text-end mt-3">{{handbook.source}}</p>
      </div>
    </div>
    <div
      v-if="loading"
      class="loading"
    >
      <div
        class="spinner-border ms-2"
        role="status"
      >
        <span class="visually-hidden">Đang tải...</span>
      </div>
    </div>

  </div>
</template>

<script>
import appUtils from '../../utils/appUtils'
export default {
  name: 'HanbookDetail',
  components: {},
  data () {
    return {
      handbook: null,
      loading: false
    }
  },
  mounted () {
    const self = this
    if (self.$route.params.id) {
      self.getServiceDetail(self.$route.params.id)
    } else {
      this.goToList()
    }
  },
  methods: {
    goBack () {
      this.$router.go(-1)
    },
    async getServiceDetail (id) {
      const self = this
      self.loading = true
      await self.$rf.getRequest('GuestRequest').getNewDetail(id).then((resp) => {
        self.handbook = resp?.data
      }).catch((e) => {
        console.log(e)
        self.goToList()
      }).finally(() => {
        self.loading = false
      })
    },
    getDocumentURL (path) {
      return appUtils.getDocumentURL(path)
    },
    modalRegis (show) {
      window.$('#modal-regis').modal(show ? 'show' : 'hide')
    },
    formatDMYHM (datTime) {
      return this.moment(datTime).format('DD/MM/YYYY HH:mm')
    },
    getCate () {
      if (this.handbook && this.handbook.categories) {
        let string = ' | '
        this.handbook.categories.forEach(c => {
          string += (c.name + ', ')
        })
        return string
      }
    }
  }
}
</script>

<style lang="css" scoped>
.handbook-detail {
  padding-bottom: 15px;
  max-width: 1152px;
  margin: 0 auto;
}
.loading {
  display: flex;
  width: 100%;
  height: 500px;
  justify-content: center;
  align-items: center;
}
.detail-overview {
  min-height: 230px;
  position: relative;
}
.show-select:hover {
  background-color: #517dff;
}
.img-detail {
  max-width: 100%;
  max-height: 100%;
}
.show-select {
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #476dd6;
  color: white;
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0.10000000149011612px;
  text-align: center;
  border-radius: 12px;
  height: 52px;
  border: solid 1px white !important;
}
.txt-content-handbook {
  white-space: pre-wrap;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0.5px;
  text-align: left;
}
.title-handbook {
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -0.5px;
  text-align: left;
}
@media only screen and (min-width: 1440px) {
  .detail-title {
    font-size: 45px;
    font-style: normal;
    font-weight: 500;
    line-height: 52px;
    letter-spacing: 0em;
    text-align: left;
  }
  .title-handbook {
    font-size: 36px;
  }
}
@media only screen and (max-width: 1439px) and (min-width: 1024px) {
  .detail-title {
    font-size: 45px;
    font-style: normal;
    font-weight: 500;
    line-height: 52px;
    letter-spacing: 0em;
    text-align: left;
  }
  .title-handbook {
    font-size: 36px;
  }
}
@media only screen and (max-width: 1023px) {
  .detail-clinic {
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 51px;
    letter-spacing: 0em;
    text-align: left;
  }
  .show-select {
    width: 100%;
  }
}
</style>
