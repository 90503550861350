<template>
  <div>
    <div
      class="disease-create"
      v-if="user && !show_select && !show_pacs"
    >
      <p
        v-if="textPACS"
        class="text-pri-900 fs-20 fw-500"
      >Để lưu trữ tài liệu Chẩn đoán hình ảnh và các tài liệu khác, bạn cần Tạo Hồ Sơ Sức Khỏe</p>
      <div class="fs-28 fw-500 txt-black mb-3 flex justify-between items-center">
        <div>Tạo Hồ Sơ Sức Khỏe</div>
        <div class="text-right">
          <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            @click="goToBack"
            class="cursor-pointer"
          >
            <path
              d="M6 18.4998L17.9998 6.5"
              stroke="#292D32"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M17.9998 18.4998L6 6.5"
              stroke="#292D32"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>
      <div class="d-flex align-items-center mb-3 pt-2">
        <div class="circle-num text-center fs-18 fw-500 txt-black me-2">1</div>
        <p class="mb-0 fs-18 fw-500 txt-black"> Thông tin sức khỏe</p>
      </div>
      <!-- * -->
      <div
        class="mb-3"
        v-if="persons"
      >
        <label class="fs-18 fw-500 txt-black">Tạo Hồ Sơ Sức Khỏe cho <span class="text-danger">*</span></label>
        <select
          v-model="form.person_id"
          class="form-select"
          :class="form.person_id ? 'bd-pri' : err.person_id && !form.person_id ? 'border-danger' : ''"
        >
          <option
            v-for="person in persons.data"
            :key="person.id"
            :value="person.id"
            class="py-2"
          >{{person.name}}</option>
        </select>
      </div>
      <!-- * -->
      <div class="mb-3">
        <label class="fs-18 fw-500 txt-black">Chẩn đoán <span class="text-danger">*</span></label>
        <textarea
          type="text"
          class="form-control txt-pri"
          :class="form.diseases_name ? 'bd-pri' : err.diseases_name && !form.diseases_name ? 'border-danger' : ''"
          v-model="form.diseases_name"
          placeholder="Nhập tên bệnh của bạn"
          cols="30"
          rows="2"
        ></textarea>
      </div>
      <!-- * -->
      <div class="mb-3">
        <label class="fs-18 fw-500 txt-black">Giai đoạn</label>
        <textarea
          type="text"
          class="form-control txt-pri"
          :class="form.diseases_stage ? 'bd-pri' : ''"
          v-model="form.diseases_stage"
          placeholder="Bệnh đang ở giai đoạn nào"
          cols="30"
          rows="2"
        ></textarea>
      </div>
      <!-- * -->
      <div class="mb-3">
        <label class="fs-18 fw-500 txt-black">Tình trạng hiện tại</label>
        <textarea
          type="text"
          class="form-control txt-pri"
          :class="form.diseases_current_cure ? 'bd-pri' : ''"
          v-model="form.diseases_current_cure"
          placeholder="Tình trạng bệnh hiện tại ra sao"
          cols="30"
          rows="2"
        ></textarea>
      </div>
      <!-- * -->
      <div class="mb-3">
        <label class="fs-18 fw-500 txt-black">Điều trị</label>
        <textarea
          type="text"
          class="form-control txt-pri"
          :class="form.diseases_previous_cure ? 'bd-pri' : ''"
          v-model="form.diseases_previous_cure"
          placeholder="Quá trình điều trị"
          cols="30"
          rows="2"
        ></textarea>
      </div>
      <!-- * -->
      <div class="mb-4">
        <label class="fs-18 fw-500 txt-black">Bệnh khác</label>
        <input
          type="text"
          class="form-control txt-pri"
          :class="form.background_diseases ? 'bd-pri' : ''"
          v-model="form.background_diseases"
          placeholder="Ví Dụ: Tiêu đường, Viêm khớp, ..."
        >
      </div>
      <!-- *****2***** -->
      <div class="d-flex align-items-center mb-3 pt-2">
        <div class="circle-num text-center fs-18 fw-500 txt-black me-2">2</div>
        <p class="mb-0 fs-18 fw-500 txt-black"> Người liên hệ</p>
      </div>
      <!-- * -->
      <div class="mb-3">
        <label class="fs-18 fw-500 txt-black">Họ và tên</label>
        <input
          type="text"
          class="form-control txt-pri"
          :class="form.contact_name ? 'bd-pri' : err.contact_name && !form.contact_name ? 'border-danger' : ''"
          v-model="form.contact_name"
          placeholder="Nguyễn Văn A"
        >
      </div>
      <!-- * -->
      <div class="mb-3">
        <label class="fs-18 fw-500 txt-black">Số điện thoại</label>
        <input
          type="text"
          class="form-control txt-pri"
          :class="form.contact_phone ? 'bd-pri': err.contact_phone && !form.contact_phone ? 'border-danger' : ''"
          v-model="form.contact_phone"
          placeholder="Nhận số điện thoại liên lạc"
          @input="acceptNumber()"
        >
      </div>
      <!-- * -->
      <div class="mb-4">
        <label class="fs-18 fw-500 txt-black">Mối quan hệ ( Với bạn )</label>
        <input
          type="text"
          class="form-control txt-pri"
          :class="form.contact_relationship ? 'bd-pri': ''"
          v-model="form.contact_relationship"
          placeholder="Ví Dụ: Bố, Mẹ, Vợ, Chồng, ..."
        >
      </div>
      <div class="pt-4">
        <button
          class="btn btn-pri w-24 radius-15 m-auto"
          @click="onSubmit()"
          :disabled="loading"
        >Lưu
          <span
            v-if="loading"
            class="spinner-border ms-2"
            role="status"
          >
            <span class="visually-hidden">Loading...</span>
          </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  name: 'DiseasesCreate',
  data () {
    return {
      show_pacs: false,
      sltPac: null,
      form: {
        person_id: '',
        diseases_name: '',
        diseases_stage: '',
        diseases_current_cure: '',
        diseases_previous_cure: '',
        background_diseases: '',
        contact_name: '',
        contact_relationship: '',
        contact_phone: ''
      },
      err: {
        person_id: '',
        contact_name: '',
        diseases_name: '',
        contact_phone: ''
      },
      documents: [],
      doc_type: 0,
      user: null,
      persons: null,
      exts: ['jpeg', 'png', 'jpg', 'gif', 'svg', 'doc', 'docx', 'pdf', 'xls', 'xlsx'],
      loading: false,
      phoneno: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
      show_select: false,
      pacs: [],
      textPACS: false
    }
  },
  mounted () {
    const self = this
    self.user = this.$user
    self.getPersons()
    if (this.$route.query.type === 'uploadPacs') this.textPACS = true
    self.sendAmplitude('Click to create care plan', {
      'Person ID': this.user.person?.id,
      'Person Name': this.user.person?.name,
      Timestamp: moment(new Date()).format('HH:mm DD-MM-YYYY')
    })
  },
  methods: {
    async getPersons () {
      const self = this
      const params = {
        litmit: 100
      }
      await self.$rf.getRequest('AuthRequest').getPersons(params).then((rs) => {
        self.persons = rs.data
        if (rs?.data?.data && rs?.data?.data.length) {
          self.form.person_id = rs.data.data[0]?.id
        } else {
          self.$router.push({ path: '/tai-khoan-moi' })
        }
      })
    },
    validate () {
      const self = this
      const form = self.form
      if (!form.person_id) {
        self.err.person_id = true
        return false
      }
      if (!form.diseases_name) {
        self.err.diseases_name = true
        return false
      }
      return true
    },
    async onSubmit () {
      const self = this
      let disease = null
      if (this.validate() && !self.loading) {
        try {
          self.loading = true
          disease = await self.onCreateDiseases()
        } catch (error) {
          console.log(error)
        } finally {
          if (this.$route.query.type === 'uploadPacs') self.$router.push({ path: `/hssk/${disease.id}/view`, query: { type: 'uploadPacs' } })
          else self.$router.push({ path: `/hssk/${disease.id}/view` })
          self.loading = false
        }
      } else {
        alert('Vui lòng nhập đủ các thông tin có dấu *')
      }
    },
    async onCreateDiseases () {
      const self = this
      try {
        const resp = await self.$rf.getRequest('AuthRequest').createDisease(self.form)
        if (resp.data) {
          self.sendAmplitude('Click to save care plan', {
            'Person ID': this.user.person?.id,
            'Person Name': this.user.person?.name,
            Timestamp: moment(new Date()).format('HH:mm DD-MM-YYYY'),
            Status: 'Success'
          })
          return resp.data
        }
      } catch (error) {
        self.sendAmplitude('Click to save care plan', {
          'Person ID': this.user.person?.id,
          'Person Name': this.user.person?.name,
          Timestamp: moment(new Date()).format('HH:mm DD-MM-YYYY'),
          Status: 'Fail'
        })
        return false
      }
    },
    goToBack () {
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped>
.disease-create {
  width: 100%;
  max-width: 1040px;
  padding: 16px;
  margin: 0 auto;
}
.btn {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0.10000000149011612px;
  text-align: center;
}
.form-control,
.form-select {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-radius: 0;
  color: #476dd6;
}
.circle-num {
  width: 24px !important;
  height: 24px !important;
  min-width: 24px !important;
  min-height: 24px !important;
  background-color: #f3f3f4;
  border-radius: 12px;
}
</style>
