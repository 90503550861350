<template>
  <div class="services pb-5">
    <div class="content">
      <div class="services-container">
        <div class="row pt-5 mb-3">
          <div class="col-lg-6">
            <p
              class="content-title"
              id="content-title"
            >
            </p>
          </div>
          <div class="col-lg-6">
            <div class="input-group search-input d-flex align-items-center">
              <input
                type="text"
                class="form-control ps-3 h-100 input-search"
                aria-label="Tìm kiếm dịch vụ"
                placeholder="Tìm kiếm dịch vụ"
                v-model="search"
                @keypress.enter="onSearch()"
              >
              <span
                class="input-group-text c-pointer btn-close"
                @click="clearSearch()"
                v-if="search"
              ></span>
              <span class="input-group-text c-pointer"><img
                  src="../../../public/icons/search-gray.svg"
                  width="24"
                  alt=""
                  @click="onSearch()"
                ></span>
            </div>
          </div>
        </div>
        <div
          class="d-flex align-items-center flex-wrap nav-categories mb-3"
          v-if="categories && categories.data"
        >
          <a
            class="nav-link text-nowrap d-flex align-items-center"
            :class="!cid ? 'active txt-black' : 'txt-gray-blur'"
            aria-current="page"
            href="javascript:;"
            @click="goToCategory()"
          >
            <img
              alt=""
              src="../../../public/icons/compass.svg"
              width="24"
              class="me-2"
            >
            Tất cả
          </a>
          <a
            class="nav-link text-nowrap d-flex align-items-center"
            v-for="cate in categories.data"
            :key="cate.id"
            :class="Number(cid) === cate.id ? 'active txt-black' : 'txt-gray-blur'"
            aria-current="page"
            href="javascript:;"
            @click="goToCategory(cate)"
          >
            <img
              v-if="cate.icon"
              :src="getDocumentURL(cate.icon)"
              alt=""
              width="24"
              class="me-2"
            >
            {{cate.name}}
          </a>
        </div>
        <div
          class="row"
          v-if="!loading"
        >
          <div
            class="col-lg-4 col-md-6"
            v-for="service in services.data"
            :key="service.id"
          >
            <div class="serv-item">
              <div class="card radius-15 bg-white c-pointer item-show">
                <div class="out-side-item-img ratio ratio-4x3">
                  <img
                    v-if="service.banner_web"
                    :src="getDocumentURL(service.banner_web)"
                    class="item-img"
                    alt="..."
                  >
                  <img
                    v-if="!service.banner_web"
                    src="../../../public/images/handbook/sample-img.svg"
                    class="item-img"
                    alt="..."
                  >

                </div>
                <div class="card-body text-start">
                  <div class="outer-item-clinic-name">
                    <p class="serv-item-clinic mb-0 max-line-2">{{service.clinic_name}}</p>
                  </div>
                  <div class="outer-item-name">
                    <h5 class="serv-item-title max-line-3">{{service.name}}</h5>
                  </div>
                  <p class="serv-item-clinic mb-0">Chỉ từ</p>
                  <div class="outer-item-clinic-name">
                    <p class="txt-pri fs-24 fw-500 text-uppercase max-line-2">
                      <span v-if="service.price">{{parseInt(service.price).format(0, 3, '.')}} {{service.currency || 'VNĐ'}}</span>
                      <span v-if="!service.price">Liên hệ HODO</span>
                    </p>
                  </div>
                </div>
              </div>
              <div class="card item-hide">
                <div class="w-100 p-3">
                  <p class="mb-0 fs-24 fw-500 text-white text-center">{{service.name}}</p>
                  <button
                    class="show-select"
                    @click="onClickRegis(service)"
                  >Đặt dịch vụ</button>
                  <button
                    class="show-info"
                    @click="onDetail(service)"
                  >Tìm hiểu thêm</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="!loading && services && services.data && !services.data.length"
          class="w-75  m-auto"
        >
          <div class="w-75 m-auto">
            <img
              src="../../../public/images/Page/empty.png"
              class="img-fluid mb-3"
              alt=""
            >

          </div>
          <p class="text-center fs-24 fw-500 txt-pri">Chưa có dịch vụ nào</p>
        </div>
        <div
          v-if="loading"
          class="loading"
        >
          <div
            class="spinner-border ms-2"
            role="status"
          >
            <span class="visually-hidden">Đang tải...</span>
          </div>
        </div>

        <div class="d-flex justify-content-center mb-5">
          <div>
            <a
              class="fs-14 fw-500 txt-pri text-decoration-none m-3"
              href="javascript:;"
              @click="prePage()"
              v-if="page > 1"
            ><img
                width="24"
                src="../../../public/icons/arrow-right-pri.svg"
                alt=""
                class="me-2 flip-180"
              > Trang trước</a>
            <a
              class="fs-14 fw-500 txt-pri text-decoration-none m-3"
              href="javascript:;"
              @click="nextPage()"
              v-if="page < total_page"
            >Trang sau &nbsp;<img
                width="24"
                src="../../../public/icons/arrow-right-pri.svg"
                alt=""
                class="ms-2"
              ></a>
          </div>
        </div>
      </div>
    </div>
    <ModaUnauthBookServices
      :slt_serv="slt_serv"
      @submit="()=> {slt_serv = null}"
    />
  </div>
</template>

<script>
import appUtils from '../../utils/appUtils'
import ModaUnauthBookServices from '../../components/Modals/ModaUnauthBookServices'
export default {
  name: 'ListServices',
  components: { ModaUnauthBookServices },
  data () {
    return {
      services: [],
      page: 1,
      sort: 'created_at',
      sort_by: 'desc',
      search: '',
      total_page: 0,
      slt_serv: null,
      loading: false,
      cid: null,
      categories: null
    }
  },
  mounted () {
    const self = this
    self.cid = self.$route.query.cid || null
    self.page = self.$route.query.page || 1
    self.sort = self.$route.query.sort || 'created_at'
    self.sort_by = self.$route.query.sort_by || 'desc'
    self.search = self.$route.query.search || ''
    self.getServices()
    self.scrollToTopList()
    self.getServiceCategories()
  },
  watch: {
    '$route.query.cid': {
      handler: function (cid) {
        const self = this
        self.cid = cid || null
      },
      deep: true,
      immediate: true
    },
    '$route.query.search': {
      handler: function (search) {
        this.search = search
      },
      deep: true,
      immediate: true
    },
    '$route.query.page': {
      handler: function (page) {
        this.page = page
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    nextPage () {
      const self = this
      if (self.page >= self.total_page) return
      self.page++
      setTimeout(() => {
        const query = { ...self.$route.query }
        if (self.page) {
          query.page = self.page
        }
        console.log(query)
        self.$router.push({ query: query }).catch(() => { })
        self.getServices()
        self.scrollToTopList()
      }, 50)
    },
    prePage () {
      const self = this
      if (self.page <= 1) return
      self.page--
      setTimeout(() => {
        const query = { ...self.$route.query }
        if (self.page) {
          query.page = self.page
        }
        self.$router.push({ query: query }).catch(() => { })
        self.getServices()
        self.scrollToTopList()
      }, 50)
    },
    scrollToTopList () {
      var title = this.$el.querySelector('#content-title')
      title.scrollIntoView()
    },
    async getServiceCategories () {
      const self = this
      self.loading = true
      const params = {
        status: 1
      }
      await self.$rf.getRequest('GuestRequest').getServiceCategories(params).then((rs) => {
        self.categories = rs.data
      }).finally(() => {
        self.loading = false
      })
    },
    async getServices () {
      const self = this
      self.loading = true
      const params = {
        page: self.page,
        sort: self.sort,
        sort_by: self.sort_by,
        limit: 6
      }
      if (self.search) {
        params.search = self.search
      }
      if (self.cid) {
        const ids = []
        ids.push(self.cid)
        params.category_ids = ids
      }
      await self.$rf.getRequest('GuestRequest').getServices(params).then((rs) => {
        self.services = rs.data
        self.total_page = rs.data.last_page
      }).finally(() => {
        self.loading = false
      })
    },
    getDocumentURL (path) {
      return appUtils.getDocumentURL(path)
    },
    onClickRegis (serv) {
      if (!serv) return
      this.slt_serv = serv
      this.modalRegis(true)
    },
    onDetail (serv) {
      if (!serv) return
      this.$router.push({ path: `/dich-vu/${serv.id}/chi-tiet` })
    },
    modalRegis (show) {
      window.$('#modal-regis').modal(show ? 'show' : 'hide')
    },
    goToCategory (cate) {
      const self = this
      const query = { ...self.$route.query }
      if (cate) {
        query.cid = cate.id
      } else {
        delete query.cid
      }
      query.page = 1
      self.$router.push({ query: query }).catch(() => { })
      setTimeout(() => {
        self.getServices()
        self.scrollToTopList()
      }, 50)
    },
    onSearch () {
      const self = this
      const query = { ...self.$route.query }
      if (self.search) {
        query.search = self.search
      }
      query.page = 1
      self.$router.push({ query: query }).catch(() => { })
      self.getServices()
    },
    clearSearch () {
      this.search = ''
      const self = this
      const query = { ...self.$route.query }
      delete query.search
      query.page = 1
      self.$router.push({ query: query }).catch(() => { })
      self.getServices()
    }
  }
}
</script>

<style lang="css" scoped>
@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
.services-container {
  max-width: 1152px !important;
  margin: 0 auto;
  padding: 16px;
}
.search-input {
  height: 64px;
  background: #fbfbfb;
  border: 2px solid #d5d2f9;
  box-sizing: border-box;
  border-radius: 67px;
}
.search-input input {
  border-top-left-radius: 33.5px;
  border-bottom-left-radius: 33.5px;
  background: #fbfbfb !important;
  border: none;
}
.search-input span:last-child {
  border-top-right-radius: 33.5px;
  border-bottom-right-radius: 33.5px;
  background: #fbfbfb !important;
  margin-right: 10px;
  border: none;
}
.loading {
  display: flex;
  width: 100%;
  height: 400px;
  justify-content: center;
  align-items: center;
}
.services {
  background-color: #f7f7f9;
}
.h-left {
  animation: 1s ease-out 0s 1 slideInFromLeft;
}
.h-right {
  animation: 1s ease-out 0s 1 slideInFromRight;
}
.show-select:hover {
  background-color: #f4f6fc;
}
.show-select {
  width: 100% !important;
  background-color: white;
  color: #476dd6;
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0.10000000149011612px;
  text-align: center;
  border-radius: 12px;
  height: 52px;
  border: solid 1px white !important;
  margin-bottom: 32px;
  margin-top: 58px;
}
.show-info {
  width: 100% !important;
  background: unset;
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0.10000000149011612px;
  text-align: center;
  border-radius: 12px;
  height: 52px;
  border: solid 1px white !important;
  color: white;
}
.show-info:hover {
  background-color: #6185e9;
}

.serv-item > div {
  backface-visibility: hidden;
  transition: transform 300ms;
  transition-timing-function: linear;
  width: 100%;
  height: 100%;
  margin: 0;
}

.item-show {
  transform: rotateY(0deg);
}

.item-hide {
  transform: rotateY(180deg);
  position: absolute;
  top: 0;
}

.serv-item:hover .item-show {
  transform: rotateY(-180deg);
}

.serv-item:hover .item-hide {
  transform: rotateY(0deg);
}
.serv-item {
  margin-bottom: 32px;
  position: relative;
}
.item-hide {
  width: 100%;
  height: 100%;
  position: absolute;
  background-image: url("../../../public/images/backgrounds/bg-item.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  top: 0;
  cursor: pointer;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.serv-item-clinic {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
  text-align: left;
}
.serv-item-title {
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
}
.out-side-item-img {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.item-img {
  border-radius: 15px;
  max-width: 100%;
  max-height: 100%;
}
.outer-item-name {
  height: 96px !important;
  margin-bottom: 15px;
}
.outer-item-clinic-name {
  height: 40px !important;
  margin-bottom: 5px;
}
.input-search:focus {
  box-shadow: none;
}
.nav-categories .nav-link {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0.10000000149011612px;
  text-align: left;
}
.nav-categories .nav-link {
  margin-right: 16px;
}
.nav-link {
  color: #5d5d5d !important;
}
.nav-link {
  padding-left: 15px !important;
  padding-right: 15px !important;
}
.nav-link.active {
  background-color: #e7eaf5;
  /* color: #121212 !important; */
  border-radius: 10px;
}
@media only screen and (min-width: 1440px) {
  .content-title {
    font-size: 48px;
    font-style: normal;
    font-weight: 500;
    line-height: 76px;
    letter-spacing: -0.5px;
    text-align: left;
  }
}
@media only screen and (max-width: 1439px) and (min-width: 1024px) {
  .content-title {
    font-size: 48px;
    font-style: normal;
    font-weight: 500;
    line-height: 76px;
    letter-spacing: -0.5px;
    text-align: left;
  }
}
@media only screen and (max-width: 1023px) {
  .content-title {
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 51px;
    letter-spacing: -0.5px;
    text-align: left;
  }
}
</style>
