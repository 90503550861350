<!-- eslint-disable no-tabs -->
<template>
	<div>
		<div>
<!-- 			{{this.short_name}} -->
			<!-- <img src="../../public/assets/images/logo_bank/logo_abbank.svg"> -->
			<div>
        <!-- <img :src="`${getImageURL(this.short_name)}`"> -->
        <img class="algin-center" style="width: 100px;" src="../../../public/images/logo_bank/logo_bidv.svg">
      </div>
		</div>
	</div>
</template>
<script>
export default {
  name: 'DisplayBankLogo',
  props: ['short_name'],
  mounted () {
  },
  data () {
    return {
      url: '',
      list_logo_bank: [
        {
          id: 0,
          vn_name: '',
          shortname: '',
          link_img: 'assets/images/logo_bank/logo_bank_emty.svg',
          bank_code: 0
        },
        {
          id: 1,
          vn_name: 'Ngân hàng An Bình',
          shortname: 'abbank',
          link_img: 'assets/images/logo_bank/logo_abbank.svg',
          bank_code: 323
        },
        {
          id: 2,
          vn_name: 'Ngân hàng Á Châu',
          shortname: 'acb',
          link_img: 'assets/images/logo_bank/logo_acb.svg',
          bank_code: 307
        },
        {
          id: 3,
          vn_name: 'Ngân hàng nông nghiệp và phát triển nông thôn việt nam',
          shortname: 'agribank, vbard',
          link_img: 'assets/images/logo_bank/logo_agribank-vbard.svg',
          bank_code: 204
        },
        {
          id: 4,
          vn_name: 'Ngân hàng nông nghiệp và phát triển nông thôn việt nam',
          shortname: 'vbard',
          link_img: 'assets/images/logo_bank/logo_agribank-vbard.svg',
          bank_code: 204
        },
        {
          id: 5,
          vn_name: 'Ngân hàng anz việt nam',
          shortname: 'anz',
          link_img: 'assets/images/logo_bank/logo_anz.svg',
          bank_code: 602
        },
        {
          id: 6,
          vn_name: 'bangkok bank',
          shortname: 'bangkok bank',
          link_img: 'assets/images/logo_bank/logo_bangkokbank.svg',
          bank_code: 612
        },
        {
          id: 7,
          vn_name: 'Ngân hàng TMCP Bảo Việt',
          shortname: 'baoviet bank',
          link_img: 'assets/images/logo_bank/logo_baoviet-bank.svg',
          bank_code: 359
        },
        {
          id: 8,
          vn_name: 'Bank of china',
          shortname: 'bc',
          link_img: 'assets/images/logo_bank/logo_bc.svg',
          bank_code: 620
        },
        {
          id: 9,
          vn_name: 'NH ĐT&PT Campuchia CN HCM"',
          shortname: 'bidc hcm',
          link_img: 'assets/images/logo_bank/logo_bidc.svg',
          bank_code: 648
        },
        {
          id: 10,
          vn_name: 'NH ĐT&PT Campuchia CN Hà Nội',
          shortname: 'bidc hn',
          link_img: 'assets/images/logo_bank/logo_bidc.svg',
          bank_code: 638
        },
        {
          id: 11,
          vn_name: 'Ngân hàng Đầu tư và Phát triển Việt Nam',
          shortname: 'bidv',
          link_img: 'assets/images/logo_bank/logo_bidv.svg',
          bank_code: 202
        },
        {
          id: 12,
          vn_name: 'BNP Paribas Bank Ha Noi',
          shortname: 'bnp pariqbas hn',
          link_img: 'assets/images/logo_bank/',
          bank_code: 657
        },
        {
          id: 13,
          vn_name: 'BNP Paribas Bank HCM',
          shortname: 'bnp pariqbas hcm',
          link_img: 'assets/images/logo_bank/logo_bnp-pariqbas-hcm.svg',
          bank_code: 657

        },
        {
          id: 14,
          vn_name: 'Bank of Communications',
          shortname: 'boc',
          link_img: 'assets/images/logo_bank/logo_boc.svg',
          bank_code: 615
        },
        {
          id: 15,
          vn_name: 'Ngân hàng BPCEIOM CN  TP Hồ Chí Minh',
          shortname: 'bpceicom',
          link_img: 'assets/images/logo_bank/logo_bpceicom.svg',
          bank_code: 601
        },
        {
          id: 16,
          vn_name: 'BANK OF TOKYO - MITSUBISHI UFJ - TP HCM',
          shortname: 'btmu hcm',
          link_img: 'assets/images/logo_bank/logo_btmu.svg',
          bank_code: 622
        },
        {
          id: 98,
          vn_name: 'BANK OF TOKYO - MITSUBISHI UFJ - HN',
          shortname: 'btmu hn',
          link_img: 'assets/images/logo_bank/logo_btmu.svg',
          bank_code: 653
        },
        {
          id: 17,
          vn_name: 'Credit Agricole Corporate and Investment Bank',
          shortname: 'cacib',
          link_img: 'assets/images/logo_bank/logo_cacib.svg',
          bank_code: 621
        },
        {
          id: 18,
          vn_name: 'Commonwealth Bank of Australia',
          shortname: 'cba',
          link_img: 'assets/images/logo_bank/logo_cba.svg',
          bank_code: 643
        },
        {
          id: 19,
          vn_name: 'China Construction Bank Corporation',
          shortname: 'ccbc',
          link_img: 'assets/images/logo_bank/logo_ccbc.svg',
          bank_code: 611
        },
        {
          id: 20,
          vn_name: 'The Chase Manhattan Bank"',
          shortname: 'chase',
          link_img: 'assets/images/logo_bank/logo_chase.svg',
          bank_code: 627
        },
        {
          id: 21,
          vn_name: 'Ngân hàng TNHH MTV CIMB Việt Nam',
          shortname: 'cimb',
          link_img: 'assets/images/logo_bank/',
          bank_code: 661
        },
        {
          id: 22,
          vn_name: 'Citi Bank TP HCM',
          shortname: 'citibankhcm',
          link_img: 'assets/images/logo_bank/logo_citibank.svg',
          bank_code: 654
        },
        {
          id: 23,
          vn_name: 'Citi Bank Ha Noi',
          shortname: 'citibankhn',
          link_img: 'assets/images/logo_bank/logo_citibank.svg',
          bank_code: 605
        },
        {
          id: 24,
          vn_name: 'Ngân hàng Hợp tác Việt Nam"',
          shortname: 'coopbank',
          link_img: 'assets/images/logo_bank/logo_coopbank.svg',
          bank_code: 901
        },
        {
          id: 25,
          vn_name: 'Ngân hàng CTBC CN TP Hồ Chí Minh',
          shortname: 'ctbc',
          link_img: 'assets/images/logo_bank/logo_ctbc.svg',
          bank_code: 629
        },
        {
          id: 25,
          vn_name: 'DEUTSCHE BANK',
          shortname: 'db',
          link_img: 'assets/images/logo_bank/logo_db.svg',
          bank_code: 619
        },
        {
          id: 26,
          vn_name: 'DBS Bank Ltd"',
          shortname: 'dbs',
          link_img: 'assets/images/logo_bank/logo_dbs.svg',
          bank_code: 650
        },
        {
          id: 27,
          vn_name: 'Ngân hàng Đông Á',
          shortname: 'dong a bank, dab',
          link_img: 'assets/images/logo_bank/logo_dab.svg',
          bank_code: 304
        },
        {
          id: 28,
          vn_name: 'Ngân hàng Xuất nhập khẩu Việt Nam',
          shortname: 'eximbank, eib',
          link_img: 'assets/images/logo_bank/logo_eib.svg',
          bank_code: 305
        },
        {
          id: 29,
          vn_name: 'First Commercial Bank',
          shortname: 'fcnb',
          link_img: 'assets/images/logo_bank/logo_fcnb.svg',
          bank_code: 630
        },
        {
          id: 30,
          vn_name: 'First Commercial Bank Ha Noi"',
          shortname: 'fcnb hn',
          link_img: 'assets/images/logo_bank/',
          bank_code: 608
        },
        {
          id: 31,
          vn_name: 'Ngân hàng Dầu khí Toàn cầu',
          shortname: 'gp bank',
          link_img: 'assets/images/logo_bank/logo_gp-bank.svg',
          bank_code: 320
        },
        {
          id: 32,
          vn_name: 'Ngân hàng Phát triển TP HCM',
          shortname: 'hdbank',
          link_img: 'assets/images/logo_bank/logo_hdbank.svg',
          bank_code: 321
        },
        {
          id: 33,
          vn_name: 'Ngân hàng Hong Leong Viet Nam',
          shortname: 'hlo',
          link_img: 'assets/images/logo_bank/logo_hlo.svg',
          bank_code: 603
        },
        {
          id: 34,
          vn_name: 'Hua Nan Commercial Bank',
          shortname: 'hncb',
          link_img: 'assets/images/logo_bank/logo_hncb.svg',
          bank_code: 640
        },
        {
          id: 35,
          vn_name: 'NH TNHH Một Thành Viên HSBC Việt Nam',
          shortname: 'hsbc',
          link_img: 'assets/images/logo_bank/logo_hsbc.svg',
          bank_code: 617
        },
        {
          id: 36,
          vn_name: 'Ngân hàng The Hongkong và Thượng Hải',
          shortname: 'hsbc hn',
          link_img: 'assets/images/logo_bank/',
          bank_code: 645
        },
        {
          id: 37,
          vn_name: 'Industrial Bank of Korea',
          shortname: 'ibk',
          link_img: 'assets/images/logo_bank/logo_ibk.svg',
          bank_code: 641
        },
        {
          id: 38,
          vn_name: 'ICB of China CN Ha Noi',
          shortname: 'icb',
          link_img: 'assets/images/logo_bank/logo_icb.svg',
          bank_code: 649
        },
        {
          id: 39,
          vn_name: 'Indovina Bank',
          shortname: 'ivb',
          link_img: 'assets/images/logo_bank/logo_ivb.svg',
          bank_code: 502
        },
        {
          id: 40,
          vn_name: 'Kho Bạc Nhà Nước',
          shortname: 'kbnn',
          link_img: '',
          bank_code: 701
        },
        {
          id: 41,
          vn_name: 'Korea Exchange Bank"',
          shortname: 'keb',
          link_img: 'assets/images/logo_bank/logo_keb.svg',
          bank_code: 626
        },
        {
          id: 42,
          vn_name: 'Ngân hàng Kiên Long',
          shortname: 'kienlongbank',
          link_img: '',
          bank_code: 353
        },
        {
          id: 43,
          vn_name: 'Ngân hàng Kookmin',
          shortname: 'kmb',
          link_img: 'assets/images/logo_bank/logo_kmb.svg',
          bank_code: 631
        },
        {
          id: 44,
          vn_name: 'Ngan hàng TMCP Bưu điện Liên Việt',
          shortname: 'lienvietbank, lpb',
          link_img: 'assets/images/logo_bank/logo_lienvietbank-lpb.svg',
          bank_code: 357
        },
        {
          id: 45,
          vn_name: 'Ngân hàng Hàng Hải Việt Nam"',
          shortname: 'maritime bank, msb',
          link_img: 'assets/images/logo_bank/logo_msb.svg',
          bank_code: 302
        },
        {
          id: 46,
          vn_name: 'Malayan Banking Berhad',
          shortname: 'maybank',
          link_img: 'assets/images/logo_bank/logo_maybank.svg',
          bank_code: 609
        },
        {
          id: 47,
          vn_name: 'Ngân hàng Quân Đội',
          shortname: 'mb',
          link_img: 'assets/images/logo_bank/logo_mb.svg',
          bank_code: 311
        },
        {
          id: 48,
          vn_name: 'Malayan Banking Berhad',
          shortname: 'mbb',
          link_img: '',
          bank_code: 635
        },
        {
          id: 49,
          vn_name: 'Mizuho Corporate Bank - TP HCM',
          shortname: 'mcb_hcm',
          link_img: 'assets/images/logo_bank/logo_mcb.svg',
          bank_code: 639
        },
        {
          id: 50,
          vn_name: 'Mega ICBC Bank',
          shortname: 'micb',
          link_img: 'assets/images/logo_bank/logo_micb.svg',
          bank_code: 623
        },
        {
          id: 51,
          vn_name: 'Mizuho Corporate Bank',
          shortname: 'mizuho bank',
          link_img: 'assets/images/logo_bank/logo_mcb.svg',
          bank_code: 613
        },
        {
          id: 52,
          vn_name: 'Ngân hàng Nam Á"',
          shortname: 'nam a bank, nab',
          link_img: 'assets/images/logo_bank/logo_nab.svg',
          bank_code: 306
        },
        {
          id: 53,
          vn_name: 'Ngân hàng Bắc Á',
          shortname: 'nasbank, nasb',
          link_img: 'assets/images/logo_bank/logo_nasbank-nasb.svg',
          bank_code: 313
        },
        {
          id: 54,
          vn_name: 'Ngân hàng Quoc Dan',
          shortname: 'ncb',
          link_img: 'assets/images/logo_bank/logo_kienlongbank.svg',
          bank_code: 352
        },
        {
          id: 55,
          vn_name: 'Oversea - Chinese Bank',
          shortname: 'ocbc',
          link_img: 'assets/images/logo_bank/logo_ocbc.svg',
          bank_code: 625
        },
        {
          id: 56,
          vn_name: 'Ngân hàng Đại Dương',
          shortname: 'ocean bank',
          link_img: 'assets/images/logo_bank/logo_ocean-bank.svg',
          bank_code: 319
        },
        {
          id: 57,
          vn_name: 'Ngân hàng Phương Đông',
          shortname: 'oricombank, ocb, phuongdong bank',
          link_img: 'assets/images/logo_bank/logo_ocb.svg',
          bank_code: 333
        },
        {
          id: 58,
          vn_name: 'Ngân hàng Xăng dầu Petrolimex',
          shortname: 'pg bank',
          link_img: 'assets/images/logo_bank/logo_pg-bank.svg',
          bank_code: 341
        },
        {
          id: 59,
          vn_name: 'NH TMCP Đại Chúng Viet Nam',
          shortname: 'pvcombank',
          link_img: 'assets/images/logo_bank/logo_pvcombank.svg',
          bank_code: 360
        },
        {
          id: 60,
          vn_name: 'Quỹ tín dụng cơ sở"',
          shortname: 'qtdcs',
          link_img: '',
          bank_code: 902
        },
        {
          id: 61,
          vn_name: 'Ngân hàng Sài Gòn Thương Tín',
          shortname: 'sacombank',
          link_img: 'assets/images/logo_bank/logo_sacombank.svg',
          bank_code: 303
        },
        {
          id: 62,
          vn_name: 'Ngân hàng Sài Gòn Công Thương',
          shortname: 'saigonbank',
          link_img: 'assets/images/logo_bank/logo_saigonbank.svg',
          bank_code: 308
        },
        {
          id: 63,
          vn_name: 'Ngân Hàng Nhà Nước',
          shortname: 'sbv',
          link_img: '',
          bank_code: 101
        },
        {
          id: 64,
          vn_name: 'Ngân hàng TMCP Sài Gòn',
          shortname: 'scb',
          link_img: 'assets/images/logo_bank/logo_scb.svg',
          bank_code: 334
        },
        {
          id: 65,
          vn_name: 'Ngân hàng Standard Chartered Bank Việt Nam',
          shortname: 'scbank',
          link_img: 'assets/images/logo_bank/logo_scbank.svg',
          bank_code: 604
        },
        {
          id: 66,
          vn_name: 'Ngân hàng Standard Chartered Bank Việt Nam',
          shortname: 'scbank hn',
          link_img: 'assets/images/logo_bank/logo_scbank.svg',
          bank_code: 646
        },
        {
          id: 67,
          vn_name: 'The Shanghai Commercial & Savings Bank CN Đồng Nai',
          shortname: 'scsb',
          link_img: '',
          bank_code: 606
        },
        {
          id: 68,
          vn_name: 'Ngân hàng TMCP Đông Nam Á',
          shortname: 'seabank',
          link_img: 'assets/images/logo_bank/logo_seabank.svg',
          bank_code: 317
        },
        {
          id: 69,
          vn_name: 'Ngân hàng Á Châu',
          shortname: 'acb',
          link_img: '',
          bank_code: 307
        },
        {
          id: 70,
          vn_name: 'Ngân hàng Sài Gòn - Hà Nội',
          shortname: 'shb',
          link_img: 'assets/images/logo_bank/logo_shb.svg',
          bank_code: 348
        },
        {
          id: 71,
          vn_name: 'Ngân hàng TNHH MTV Shinhan Việt Nam',
          shortname: 'shinhan bank',
          link_img: 'assets/images/logo_bank/logo_shihan-bank.svg',
          bank_code: 616
        },
        {
          id: 72,
          vn_name: 'Ngân hàng The Siam Commercial Public',
          shortname: 'siam',
          link_img: '',
          bank_code: 600
        },
        {
          id: 73,
          vn_name: 'Sumitomo Mitsui Banking Corporation HCM',
          shortname: 'smbc',
          link_img: 'assets/images/logo_bank/logo_smbc.svg',
          bank_code: 636
        },
        {
          id: 74,
          vn_name: 'Sumitomo Mitsui Banking Corporation HN',
          shortname: 'smbc hn',
          link_img: 'assets/images/logo_bank/logo_smbc.svg',
          bank_code: 936
        },
        {
          id: 75,
          vn_name: 'Ngân hàng SinoPac',
          shortname: 'spb',
          link_img: 'assets/images/logo_bank/logo_spb.svg',
          bank_code: 632
        },
        {
          id: 76,
          vn_name: 'Taipei Fubon Commercial Bank Ha Noi',
          shortname: 'tfcbhn',
          link_img: 'assets/images/logo_bank/logo_tfcb.svg',
          bank_code: 642
        },
        {
          id: 77,
          vn_name: 'Taipei Fubon Commercial Bank TP Ho Chi Minh',
          shortname: 'tfcbtphcm',
          link_img: 'assets/images/logo_bank/logo_tfcb.svg',
          bank_code: 651
        },
        {
          id: 78,
          vn_name: 'United Oversea Bank"',
          shortname: 'uob',
          link_img: 'assets/images/logo_bank/logo_uob.svg',
          bank_code: 618
        },
        {
          id: 79,
          vn_name: 'Ngân hàng Chính sách xã hội Việt Nam',
          shortname: 'vbsp',
          link_img: 'assets/images/logo_bank/logo_vbsp.svg',
          bank_code: 207
        },
        {
          id: 80,
          vn_name: 'Ngân hàng Phát triển Việt Nam',
          shortname: 'vdb',
          link_img: 'assets/images/logo_bank/logo_vdb.svg',
          bank_code: 208
        },
        {
          id: 81,
          vn_name: 'Ngân hàng Quốc tế',
          shortname: 'vibank, vib',
          link_img: 'assets/images/logo_bank/logo_vib.svg',
          bank_code: 314
        },
        {
          id: 82,
          vn_name: 'Ngân hàng VID Public',
          shortname: 'vid public',
          link_img: 'assets/images/logo_bank/logo_vid-public.svg',
          bank_code: 501
        },
        {
          id: 83,
          vn_name: 'Ngân hàng Việt Hoa',
          shortname: 'viet hoa bank',
          link_img: '',
          bank_code: 324
        },
        {
          id: 84,
          vn_name: 'Ngân hàng Việt Á',
          shortname: 'vieta bank, vab',
          link_img: 'assets/images/logo_bank/logo_vab.svg',
          bank_code: 355
        },
        {
          id: 85,
          vn_name: 'Ngân hàng Việt Nam Thương Tín',
          shortname: 'vietcapital bank"',
          link_img: 'assets/images/logo_bank/logo_vietbank.svg',
          bank_code: 356
        },
        {
          id: 86,
          vn_name: 'NHTMCP Bản Việt',
          shortname: 'vietcapitl bank',
          link_img: 'assets/images/logo_bank/logo_vietcapital-bank.svg',
          bank_code: 327
        },
        {
          id: 87,
          vn_name: 'Ngân hàng TMCP Ngoại Thương',
          shortname: 'vietcombank, vcb',
          link_img: 'assets/images/logo_bank/logo_vietcombank-vcb.svg',
          bank_code: 203
        },
        {
          id: 88,
          vn_name: 'Ngân hàng công thương Việt Nam',
          shortname: 'vietinbank',
          link_img: 'assets/images/logo_bank/logo_vietinbank.svg',
          bank_code: 201
        },
        {
          id: 89,
          vn_name: 'NH TMCP Xây dựng Việt Nam',
          shortname: 'vncb',
          link_img: 'assets/images/logo_bank/logo_vncb.svg',
          bank_code: 339
        },
        {
          id: 90,
          vn_name: 'Ngân hàng Thương mại cổ phần Việt Nam Thịnh Vượng',
          shortname: 'vpbank',
          link_img: 'assets/images/logo_bank/logo_vpbank.svg',
          bank_code: 309
        },
        {
          id: 91,
          vn_name: 'Ngân hàng Liên doanh Việt Nga',
          shortname: 'vrb',
          link_img: 'assets/images/logo_bank/logo_vrb.svg',
          bank_code: 505
        },
        {
          id: 92,
          vn_name: 'Ngân hàng Liên doanh Việt Nga',
          shortname: 'vung tau',
          link_img: '',
          bank_code: 315
        },
        {
          id: 93,
          vn_name: 'NH Woori HCM',
          shortname: 'whhcm',
          link_img: 'assets/images/logo_bank/logo_whh.svg',
          bank_code: 637
        },
        {
          id: 94,
          vn_name: 'NH Woori Hanoi',
          shortname: 'whhn',
          link_img: 'assets/images/logo_bank/logo_whh.svg',
          bank_code: 624
        },
        {
          id: 95,
          vn_name: 'Công ty cổ phần chuyển mạch tài chính quốc gia Việt Nam',
          shortname: 'banknetvn',
          link_img: 'assets/images/logo_bank/logo_banknetvn.svg',
          bank_code: 401
        },
        {
          id: 96,
          vn_name: 'Ngân hàng Cathay',
          shortname: 'ctu',
          link_img: 'assets/images/logo_bank/logo_ctu.svg',
          bank_code: 401
        },
        {
          id: 97,
          vn_name: 'Ngân hàng Kỹ thương Việt Nam',
          shortname: 'ctu',
          link_img: 'assets/images/logo_bank/logo_techcombank-tcb.svg',
          bank_code: 310
        }
      ]
    }
  },
  methods: {
    getImageURL (shortname) {
      var logo = this.list_logo_bank.filter(item => item.shortname.indexOf(shortname) !== -1)[0]
      var link = logo ? logo.link_img : ''
      return link
    }
  }
}
</script>
<style scoped>
.algin-center {
  display: flex;
  justify-content: center;
}
</style>
