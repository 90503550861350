import { render, staticRenderFns } from "./PackageMember.vue?vue&type=template&id=708d5970&scoped=true"
import script from "./PackageMember.vue?vue&type=script&lang=js"
export * from "./PackageMember.vue?vue&type=script&lang=js"
import style0 from "./PackageMember.vue?vue&type=style&index=0&id=708d5970&prod&lang=css&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "708d5970",
  null
  
)

export default component.exports