<template>
  <div class="pt-3 pt-lg-5 pb-5 mx-auto container-home">
    <a href="#">Tra cứu Kết quả xét nghiệm, chẩn đoán hình ảnh</a>
    <el-input
      placeholder="Nhập tên hoặc mã xét nghiệm, chẩn đoán hình ảnh "
      v-model="keyword"
      prefix-icon="el-icon-search"
      class="my-4"
    ></el-input>
    <div>
      <div
        v-for="item in listDiagnose"
        :key="item.id"
        class="grid-item flex justify-between mb-2 update-item"
        style="padding: 12px 16px"
        @click="openDetail(item)"
      >
        <div>
          <div class="flex items-center gap-2 mb-1">
            <el-tag type="danger" class="tag">Mới</el-tag>
            <span class="font-semibold">{{ item.title}}</span>
          </div>
          <span>{{item.clinic}}</span>
        </div>
        <div>
          <span>{{item.updated_at}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import appUtils from '../../utils/appUtils'
export default {
  name: 'Diagnose',
  data () {
    return {
      appUtils,
      keyword: '',
      listDiagnose: [
        {
          id: 1,
          updated_at: appUtils.formatDateTime(new Date()),
          title: 'Kết quả Xét nghiệm công thức máu 27 Chỉ số',
          clinic: 'Từ Trung Tâm Xét Nghiệm Hi-Detect Labs'
        },
        {
          id: 2,
          updated_at: appUtils.formatDateTime(new Date()),
          title: 'Kết quả Xét nghiệm công thức máu 27 Chỉ số',
          clinic: 'Từ Trung Tâm Xét Nghiệm Hi-Detect Labs'
        },
        {
          id: 3,
          updated_at: appUtils.formatDateTime(new Date()),
          title: 'Kết quả Xét nghiệm công thức máu 27 Chỉ số',
          clinic: 'Từ Trung Tâm Xét Nghiệm Hi-Detect Labs'
        }
      ]
    }
  },
  mounted () {
    //
  },
  methods: {
    openDetail (item) {
      this.$router.push({ name: 'DiagnoseDetail', params: { id: item.id } })
    }
  }
}
</script>

<style scoped lang="scss">
.container-home {
  max-width: 80%;
}
.grid-item {
  border: 1px solid #a6b3d7;
  border-radius: 10px;
  padding: 20px 0;
}
.update-item {
  &:hover {
    background: #ecf5ff;
    cursor: pointer;
  }
}
</style>
