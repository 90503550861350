import { render, staticRenderFns } from "../../../public/HODOTermCondition/dieukhoan.html?vue&type=template&id=3b7f3819&scoped=true&external"
import script from "./Term.vue?vue&type=script&lang=js"
export * from "./Term.vue?vue&type=script&lang=js"
import style0 from "../../../public/HODOTermCondition/dieukhoan_files/Site.css?vue&type=style&index=0&id=3b7f3819&prod&lang=css&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b7f3819",
  null
  
)

export default component.exports