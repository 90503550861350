<template>
  <div class="pb-5 container mx-auto">
    <div class="row">
      <div class="col-lg-7 flex justify-center text-pri-900">
        <div class="text-center mx-3">
          <div v-if="!isGold(pac.code)">
            <img
              class="
            h-16
            mb-4"
              :src="getUrlImg(pac.package_icon)"
              alt=""
            />
            <p class="font-bold text-center text-2xl f-24 fw-600">{{ pac.code }}</p>
            <!-- <div
              class="mb-3"
              v-for="cb in combo.data"
              :key="cb.id"
            >
              <p
                v-if="cb.package_months === 1"
                class="mb-0 lg:text-3xl text-2xl fw-600"
              ><span class="text-xl"></span> {{ numberFormat(cb.combo_price) }} /tháng</p>
            </div> -->
            <p class="fs-18 fw-400 mb-1 text-justify">
              <svg
                width="16"
                height="12"
                viewBox="0 0 16 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.79573 9.1308L1.19893 5.51294L0 6.71889L4.79573 11.5427L15.0723 1.20595L13.8734 0L4.79573 9.1308Z"
                  fill="#20409B"
                />
              </svg>
              Nhắn tin không giới hạn với Bác sĩ
            </p>
            <p class="fs-18 fw-400 mb-1 text-justify">
              <svg
                width="16"
                height="12"
                viewBox="0 0 16 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.79573 9.1308L1.19893 5.51294L0 6.71889L4.79573 11.5427L15.0723 1.20595L13.8734 0L4.79573 9.1308Z"
                  fill="#20409B"
                />
              </svg>
              Gọi điện trực tiếp với Bác sĩ
            </p>
            <p class="fs-18 fw-400 mb-1 text-justify">
              <svg
                width="16"
                height="12"
                viewBox="0 0 16 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.79573 9.1308L1.19893 5.51294L0 6.71889L4.79573 11.5427L15.0723 1.20595L13.8734 0L4.79573 9.1308Z"
                  fill="#20409B"
                />
              </svg>
              Chuyển đổi số các thông tin sức khỏe từ hình ảnh
            </p>
            <p class="fs-18 fw-400 mb-1 text-justify">
              <svg
                class="mr-2"
                width="16"
                height="12"
                viewBox="0 0 16 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.79573 9.1308L1.19893 5.51294L0 6.71889L4.79573 11.5427L15.0723 1.20595L13.8734 0L4.79573 9.1308Z"
                  fill="#20409B"
                />
              </svg>
              Lữu trữ phim Chẩn đoán hình ảnh (CT, MRI, PET/CT...)
            </p>
            <p class="fs-18 fw-400 mb-1 text-justify font-semibold">
              <svg
                class="mr-2"
                width="16"
                height="12"
                viewBox="0 0 16 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.79573 9.1308L1.19893 5.51294L0 6.71889L4.79573 11.5427L15.0723 1.20595L13.8734 0L4.79573 9.1308Z"
                  fill="#20409B"
                />
              </svg>
              <span class="text-red-500">15GB</span> lưu trữ dữ liệu trên HODO Cloud
            </p>
            <p class="fs-18 fw-400 mb-1 text-justify text-pri-900 font-semibold">
              <svg
                class="mr-2"
                width="16"
                height="12"
                viewBox="0 0 16 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.79573 9.1308L1.19893 5.51294L0 6.71889L4.79573 11.5427L15.0723 1.20595L13.8734 0L4.79573 9.1308Z"
                  fill="#20409B"
                />
              </svg>
              Xem và chia sẻ tài liệu CĐHA, HSSK cùng các thông tin, tài liệu sức khỏe
            </p>
            <p class="fs-18 fw-400 mb-1 text-justify text-gray-500 text-decoration-line-through">
              <svg
                class="mr-2"
                width="13"
                height="13"
                viewBox="0 0 13 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.33279 6.71747L12.6328 2.42747C12.8211 2.23917 12.9269 1.98377 12.9269 1.71747C12.9269 1.45117 12.8211 1.19577 12.6328 1.00747C12.4445 0.819167 12.1891 0.713379 11.9228 0.713379C11.6565 0.713379 11.4011 0.819167 11.2128 1.00747L6.92279 5.30747L2.63279 1.00747C2.44449 0.819167 2.18909 0.713379 1.92279 0.713379C1.65649 0.713379 1.40109 0.819167 1.21279 1.00747C1.02449 1.19577 0.918699 1.45117 0.918699 1.71747C0.918699 1.98377 1.02449 2.23917 1.21279 2.42747L5.51279 6.71747L1.21279 11.0075C1.11906 11.1004 1.04467 11.211 0.993899 11.3329C0.943131 11.4548 0.916992 11.5855 0.916992 11.7175C0.916992 11.8495 0.943131 11.9802 0.993899 12.102C1.04467 12.2239 1.11906 12.3345 1.21279 12.4275C1.30575 12.5212 1.41635 12.5956 1.53821 12.6464C1.66007 12.6971 1.79078 12.7233 1.92279 12.7233C2.0548 12.7233 2.18551 12.6971 2.30737 12.6464C2.42923 12.5956 2.53983 12.5212 2.63279 12.4275L6.92279 8.12747L11.2128 12.4275C11.3058 12.5212 11.4164 12.5956 11.5382 12.6464C11.6601 12.6971 11.7908 12.7233 11.9228 12.7233C12.0548 12.7233 12.1855 12.6971 12.3074 12.6464C12.4292 12.5956 12.5398 12.5212 12.6328 12.4275C12.7265 12.3345 12.8009 12.2239 12.8517 12.102C12.9025 11.9802 12.9286 11.8495 12.9286 11.7175C12.9286 11.5855 12.9025 11.4548 12.8517 11.3329C12.8009 11.211 12.7265 11.1004 12.6328 11.0075L8.33279 6.71747Z"
                  fill="#CA0F0F"
                />
              </svg>
              Giảm giá 20% tất cả dịch vụ tư vấn trực tuyến và hàng hóa thiết yếu
            </p>
            <p class="fs-18 fw-400 mb-1 text-justify text-gray-500 text-decoration-line-through">
              <svg
                class="mr-2"
                width="13"
                height="13"
                viewBox="0 0 13 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.33279 6.71747L12.6328 2.42747C12.8211 2.23917 12.9269 1.98377 12.9269 1.71747C12.9269 1.45117 12.8211 1.19577 12.6328 1.00747C12.4445 0.819167 12.1891 0.713379 11.9228 0.713379C11.6565 0.713379 11.4011 0.819167 11.2128 1.00747L6.92279 5.30747L2.63279 1.00747C2.44449 0.819167 2.18909 0.713379 1.92279 0.713379C1.65649 0.713379 1.40109 0.819167 1.21279 1.00747C1.02449 1.19577 0.918699 1.45117 0.918699 1.71747C0.918699 1.98377 1.02449 2.23917 1.21279 2.42747L5.51279 6.71747L1.21279 11.0075C1.11906 11.1004 1.04467 11.211 0.993899 11.3329C0.943131 11.4548 0.916992 11.5855 0.916992 11.7175C0.916992 11.8495 0.943131 11.9802 0.993899 12.102C1.04467 12.2239 1.11906 12.3345 1.21279 12.4275C1.30575 12.5212 1.41635 12.5956 1.53821 12.6464C1.66007 12.6971 1.79078 12.7233 1.92279 12.7233C2.0548 12.7233 2.18551 12.6971 2.30737 12.6464C2.42923 12.5956 2.53983 12.5212 2.63279 12.4275L6.92279 8.12747L11.2128 12.4275C11.3058 12.5212 11.4164 12.5956 11.5382 12.6464C11.6601 12.6971 11.7908 12.7233 11.9228 12.7233C12.0548 12.7233 12.1855 12.6971 12.3074 12.6464C12.4292 12.5956 12.5398 12.5212 12.6328 12.4275C12.7265 12.3345 12.8009 12.2239 12.8517 12.102C12.9025 11.9802 12.9286 11.8495 12.9286 11.7175C12.9286 11.5855 12.9025 11.4548 12.8517 11.3329C12.8009 11.211 12.7265 11.1004 12.6328 11.0075L8.33279 6.71747Z"
                  fill="#CA0F0F"
                />
              </svg>
              Được chuyên gia hướng dẫn chuyên biệt về dinh dưỡng
            </p>
          </div>
          <div v-else>
            <img
              class="h-16 mb-4"
              :src="getUrlImg(pac.package_icon)"
              alt=""
            />
            <p class="font-bold text-center text-2xl f-24 fw-600">{{ pac.code }}</p>
            <!-- <div
              class="mb-3"
              v-for="cb in combo.data"
              :key="cb.id"
            >
              <p
                v-if="cb.package_months === 1"
                class="mb-0 lg:text-3xl text-2xl fw-600"
              ><span class="text-xl"></span> {{ numberFormat(cb.combo_price) }} /tháng</p>
            </div> -->
            <p class="fs-18 fw-400 mb-1 text-justify">
              <svg
                width="16"
                height="12"
                viewBox="0 0 16 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.79573 9.1308L1.19893 5.51294L0 6.71889L4.79573 11.5427L15.0723 1.20595L13.8734 0L4.79573 9.1308Z"
                  fill="#20409B"
                />
              </svg>
              Nhắn tin không giới hạn với Bác sĩ
            </p>
            <p class="fs-18 fw-400 mb-1 text-justify">
              <svg
                width="16"
                height="12"
                viewBox="0 0 16 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.79573 9.1308L1.19893 5.51294L0 6.71889L4.79573 11.5427L15.0723 1.20595L13.8734 0L4.79573 9.1308Z"
                  fill="#20409B"
                />
              </svg>
              Gọi điện trực tiếp với Bác sĩ
            </p>
            <p class="fs-18 fw-400 mb-1 text-justify">
              <svg
                width="16"
                height="12"
                viewBox="0 0 16 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.79573 9.1308L1.19893 5.51294L0 6.71889L4.79573 11.5427L15.0723 1.20595L13.8734 0L4.79573 9.1308Z"
                  fill="#20409B"
                />
              </svg>
              Chuyển đổi số các thông tin sức khỏe từ hình ảnh
            </p>
            <p class="fs-18 fw-400 mb-1 text-justify">
              <svg
                class="mr-2"
                width="16"
                height="12"
                viewBox="0 0 16 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.79573 9.1308L1.19893 5.51294L0 6.71889L4.79573 11.5427L15.0723 1.20595L13.8734 0L4.79573 9.1308Z"
                  fill="#20409B"
                />
              </svg>
              Lữu trữ phim Chẩn đoán hình ảnh (CT, MRI, PET/CT...)
            </p>
            <p class="fs-18 fw-400 mb-1 text-justify font-semibold">
              <svg
                class="mr-2"
                width="16"
                height="12"
                viewBox="0 0 16 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.79573 9.1308L1.19893 5.51294L0 6.71889L4.79573 11.5427L15.0723 1.20595L13.8734 0L4.79573 9.1308Z"
                  fill="#20409B"
                />
              </svg>
              <span class="text-red-500">100GB</span> lưu trữ dữ liệu trên HODO Cloud
            </p>
            <p class="fs-18 fw-400 mb-1 text-justify">
              <svg
                width="16"
                height="12"
                viewBox="0 0 16 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.79573 9.1308L1.19893 5.51294L0 6.71889L4.79573 11.5427L15.0723 1.20595L13.8734 0L4.79573 9.1308Z"
                  fill="#20409B"
                />
              </svg>
              Xem và chia sẻ tài liệu CĐHA, HSSK cùng các thông tin, tài liệu sức khỏe
            </p>
            <p class="fs-18 fw-400 mb-1 text-justify text-pri-900 font-semibold">
              <svg
                class="mr-2"
                width="16"
                height="12"
                viewBox="0 0 16 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.79573 9.1308L1.19893 5.51294L0 6.71889L4.79573 11.5427L15.0723 1.20595L13.8734 0L4.79573 9.1308Z"
                  fill="#20409B"
                />
              </svg>
              Giảm giá <span class="text-red-500">20% tất cả</span> dịch vụ tư vấn trực tuyến và hàng hóa thiết yếu
            </p>
            <p class="fs-18 fw-400 mb-1 text-justify text-pri-900 font-semibold">
              <svg
                class="mr-2"
                width="16"
                height="12"
                viewBox="0 0 16 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.79573 9.1308L1.19893 5.51294L0 6.71889L4.79573 11.5427L15.0723 1.20595L13.8734 0L4.79573 9.1308Z"
                  fill="#20409B"
                />
              </svg>
              Được chuyên gia hướng dẫn chuyên biệt về dinh dưỡng
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-5">
        <div class="flex flex-col-reverse">
          <div
            class="mt-3"
            v-for="cb in combo.data"
            :key="cb.id"
          >
            <div class="">
              <label
                class="h-20 btn w-full hover:bg-gray-300 bg-gray-100 cursor-pointer flex items-center p-3"
                :for="cb.id"
              >
                <div class="flex w-full">
                  <input
                    class="mr-2"
                    type="radio"
                    name="options"
                    :id="cb.id"
                    :value="cb.combo_months"
                    @change="setId(cb)"
                  />
                  <div class="text-2xl font-semibold">{{ cb.combo_months + ' Tháng' }}</div>
                </div>
                <div class="flex justify-end w-full">
                  <div class="text-end">
                    <div class="text-2xl font-semibold">{{ numberFormat(cb.combo_price) + ' đ'}}</div>
                    <div class="line-through text-gray-500">{{ numberFormat(cb.package_price) + ' đ'}}</div>
                  </div>
                </div>
              </label>
            </div>
          </div>
        </div>
        <div class="flex justify-end mt-5">
          <button
            :disabled="!cbItem || loading"
            @click="submit"
            class="btn btn-pri w-full py-3"
          >Lựa chọn</button>
        </div>
      </div>
    </div>
    <ModalConfirm @submit="handlerOrder" />
  </div>
</template>
<script>
import appUtils from '../../utils/appUtils'
import ModalConfirm from './ModalConfirm.vue'
export default {
  name: 'PackageSelect',
  components: { ModalConfirm },
  data () {
    return {
      list: [],
      loading: false,
      pac: {},
      cbItem: null,
      oderId: null,
      form: {
        code: ''
      },
      combo: [],
      pendingOrder: null
    }
  },
  mounted () {
    this.getPackageOrder()
    this.getPackageCombo()
    this.showPackageMembers()
  },
  methods: {
    isGold (code) {
      return code && (code.toUpperCase() === 'HODO GOLD' || code.toUpperCase() === 'HODO GOLD PLUS')
    },
    numberFormat (number) {
      return number.toLocaleString(undefined, { minimumFractionDigits: 0 })
    },
    setId (item) {
      this.cbItem = item
    },
    getUrlImg (path) {
      return appUtils.getDocumentURL(path)
    },
    submit () {
      if (this.pendingOrder) this.modalConfirm(true)
      else this.postPackageOrder()
    },
    modalConfirm (show) {
      window.$('#modal-confirm').modal(show ? 'show' : 'hide')
    },
    handlerOrder (event) {
      if (event === 'reviewOrder') {
        this.$router.push({ path: `/thanh-toan/${this.pendingOrder.id}` })
      } else {
        this.cancelPackageOrder(this.pendingOrder.id)
      }
      console.log('event', event)
    },
    async getPackageOrder () {
      try {
        const res = await this.$rf.getRequest('AuthRequest').getPackageOrder()
        this.pendingOrder = res.data
      } catch (error) {
        console.log(error)
      }
    },
    async cancelPackageOrder (id) {
      try {
        const res = await this.$rf.getRequest('AuthRequest').cancelPackageOrder(id)
        console.log('cancelPackageOrder', res.data)
        if (res.data) {
          this.postPackageOrder()
        }
      } catch (error) {
        console.log(error)
      }
    },
    async getPackageCombo () {
      const res = await this.$rf.getRequest('AuthRequest').getPackageCombo(this.$route.params.id)
      this.combo = res.data
    },
    async postPackageOrder () {
      this.loading = true
      try {
        const params = {
          package_id: this.cbItem.member_package.id,
          combo_id: this.cbItem.id,
          months: this.cbItem.combo_months,
          voucher_code: this.form.code,
          description: this.cbItem.description
        }
        const res = await this.$rf.getRequest('AuthRequest').postPackageOrder(params)
        this.oderId = res.data.id
        this.$toast.open({
          message: 'Thành công',
          type: 'success'
        })
        this.$router.push({ path: `/thanh-toan/${this.oderId}` })
      } finally {
        this.loading = false
      }
    },
    async showPackageMembers () {
      const res = await this.$rf.getRequest('AuthRequest').showPackageMembers(this.$route.params.id)
      this.pac = res.data
    }
  }
}
</script>

    <style lang="css" scoped>
</style>
