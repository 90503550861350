<template>
    <div>
        <slot />
    </div>
</template>

<script>
export default {
  name: 'AppLayout'
}
</script>

<style scoped>

</style>
