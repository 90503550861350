<template>
  <div class="full">
    <div class="LandingPageLogin pt-md-5">
      <img
        src="../../../public/images/common/landing-page-login.svg"
        class="mt-5 img-fluid"
        alt=""
      >
      <div class="content radius-15 bg-white pt-3">
        <p class="fs-16 fw-500 text-center mb-2">Hồ Sơ Sức Khỏe đã được gửi đến bác sĩ</p>
        <p class="fs-14 text-center txt-gray-blur">Hãy bắt đầu ngay</p>
        <div
          class="progresss"
          v-if="!loading"
        >
          <!-- bước 1 -->
          <div class="mb-1">
            <div class="d-flex align-items-center">
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="me-3"
                v-if="person"
              >
                <path
                  d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z"
                  fill="#45B26B"
                />
                <path
                  d="M10 16L14 20L22 12"
                  stroke="#FCFCFD"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <div
                v-if="!person"
                class="step-num bg-pri d-flex justify-content-center align-items-center me-3"
              >
                <span class="fs-14 fw-500 text-white">
                  1
                </span>
              </div>
              <p class="fs-16 mb-0"> Tạo tài khoản HODO</p>
            </div>

          </div>

          <!-- Mũi tên 1 -->
          <div
            class="mb-1 d-flex justify-content-center"
            style="width: 32px;"
          >
            <svg
              width="12"
              height="30"
              viewBox="0 0 12 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7 1C7 0.447715 6.55228 -2.41412e-08 6 0C5.44772 2.41412e-08 5 0.447715 5 1L7 1ZM6 30L11.7735 20L0.226498 20L6 30ZM5 1L5 21L7 21L7 1L5 1Z"
                :fill="!person ? '#949494' : '#476DD6'"
              />
            </svg>
          </div>
          <!-- bước 2 -->
          <div class="mb-1">
            <div class="d-flex align-items-center">
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="me-3"
                v-if="disease"
              >
                <path
                  d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z"
                  fill="#45B26B"
                />
                <path
                  d="M10 16L14 20L22 12"
                  stroke="#FCFCFD"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <div
                v-if="!disease"
                class="step-num d-flex justify-content-center align-items-center me-3"
                :class="person ? 'bg-pri' : 'bg-white border border-2'"
              >
                <span
                  class="fs-14 fw-500 "
                  :class="person ? 'text-white' : 'txt-gray-blur'"
                >
                  2
                </span>
              </div>
              <p
                class="fs-16 mb-0"
                :class="!disease &&  person? 'fw-500 txt-pri' : ''"
              > Tạo Hồ Sơ Sức Khỏe</p>
            </div>

          </div>
          <!-- Mũi tên 2 -->
          <div
            class="mb-1 d-flex justify-content-center"
            style="width: 32px;"
          >
            <svg
              width="12"
              height="30"
              viewBox="0 0 12 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7 1C7 0.447715 6.55228 -2.41412e-08 6 0C5.44772 2.41412e-08 5 0.447715 5 1L7 1ZM6 30L11.7735 20L0.226498 20L6 30ZM5 1L5 21L7 21L7 1L5 1Z"
                :fill="!disease ? '#949494' : '#476DD6'"
              />
            </svg>
          </div>
          <!-- bước 3 -->
          <div class="mb-1">
            <div class="d-flex align-items-center">
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="me-3"
                v-if="disease && disease.care_team"
              >
                <path
                  d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z"
                  fill="#45B26B"
                />
                <path
                  d="M10 16L14 20L22 12"
                  stroke="#FCFCFD"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <div
                v-if="!disease || !disease.care_team"
                class="step-num d-flex justify-content-center align-items-center me-3"
                :class="disease ? 'bg-pri' : 'bg-white border border-2'"
              >
                <span
                  class="fs-14 fw-500 "
                  :class="disease ? 'text-white' : 'txt-gray-blur'"
                >
                  3
                </span>
              </div>
              <p
                class="fs-16 mb-0"
                :class="disease && !disease.care_team? 'fw-500 txt-pri' : !disease ?  'txt-gray-blur' : ''"
              > Mời Bác sĩ tham gia </p>
            </div>

          </div>
          <!-- tiếp tục -->
          <div class="pt-5 pb-3 d-flex justify-content-center">
            <button
              type="button"
              class="btn btn-pri radius-15 btn-send"
              @click="goToStep()"
              v-if="(!person || !disease || (disease && !disease.care_team)) && !loading"
            >
              <span class="fs-18 fw-700 me-2">Bắt đầu</span>
              <svg
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.5"
                  width="24"
                  height="24"
                  rx="12"
                  fill="#F6F5FA"
                />
                <path
                  d="M5.5 11.25C5.08579 11.25 4.75 11.5858 4.75 12C4.75 12.4142 5.08579 12.75 5.5 12.75V11.25ZM18.0303 12.5303C18.3232 12.2374 18.3232 11.7626 18.0303 11.4697L13.2574 6.6967C12.9645 6.40381 12.4896 6.40381 12.1967 6.6967C11.9038 6.98959 11.9038 7.46447 12.1967 7.75736L16.4393 12L12.1967 16.2426C11.9038 16.5355 11.9038 17.0104 12.1967 17.3033C12.4896 17.5962 12.9645 17.5962 13.2574 17.3033L18.0303 12.5303ZM5.5 12.75H17.5V11.25H5.5V12.75Z"
                  fill="#476DD6"
                />
              </svg>
            </button>

            <button
              type="button"
              class="btn btn-pri radius-15 btn-send"
              @click="goToReview()"
              v-if="(person && disease && disease.care_team) && !loading"
            >Xem lại Hồ Sơ Sức Khỏe</button>

          </div>
        </div>
        <div
          class="d-flex justify-content-center align-items-center progresss"
          v-if="loading"
          style="height: 100px"
        >
          <div
            class="spinner-border txt-pri"
            role="status"
          >
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import appUtils from '../../utils/appUtils'
export default {
  name: 'LandingPageLogin',
  data () {
    return {
      person: null,
      disease: null,
      loading: false
    }
  },
  mounted () {
    const self = this
    self.getUserInfo()
    self.getDiseases()
  },
  methods: {
    async getUserInfo () {
      const self = this
      await self.$rf
        .getRequest('AuthRequest')
        .me()
        .then((resp) => {
          self.person = resp.data?.person
          if (resp.data?.doctor || resp.data?.clinic) {
            appUtils.removeLocalUser()
            appUtils.removeLocalToken()
            window.location.reload()
          }
          if (!resp.data?.person) {
            self.$router.push({ path: '/tai-khoan-moi' })
          }
          appUtils.setLocalUser(resp.data)
          console.log(resp)
        })
        .catch((e) => {
          console.log(e)
        })
    },
    async getDiseases () {
      const self = this
      self.loading = true
      const params = {
        limit: 2,
        sort: 'updated_at',
        sort_by: 'desc'
      }
      await self.$rf
        .getRequest('AuthRequest')
        .getDiseases(params)
        .then((resp) => {
          self.disease = resp?.data?.data[0] || null
          if (resp?.data?.data?.length > 1) {
            self.$router.push({ path: '/hssk' })
          }
          console.log(resp)
        })
        .catch((e) => {
          console.log(e)
        }).finally(() => {
          self.loading = false
        })
    },
    goToStep () {
      const self = this
      if (!self.person) {
        self.$router.push({ path: '/tai-khoan-moi' })
      } else if (!self.disease) {
        self.$router.push({ path: '/hssk' })
      } else if (!self.disease.care_team) {
        self.$router.push({ path: `hssk/${self.disease.id}/moi-bac-si` })
      }
    },
    goToReview () {
      this.$router.push({ path: `hssk/${this.disease.id}/cap-nhat` })
    }
  }

}
</script>

<style scoped>
.full {
  background-color: #f7f7f9;
  padding-bottom: 100px;
}
.LandingPageLogin {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}
.progresss {
  width: 100%;
  max-width: 330px;
  margin: 0 auto;
  padding-left: 50px;
  min-height: 170px;
}
.step-num {
  width: 32px;
  height: 32px;
  min-width: 32px;
  min-height: 32px;
  border-radius: 16px;
}
.btn-send {
  height: 50px;
  width: 250px;
}
</style>
