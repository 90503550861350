<template>
  <div class="scroll-to-top c-pointer" @click="top()">
    <img src="../../../public/icons/scroll-top.svg" alt="">
  </div>
</template>

<script>
export default {
  name: 'ScrollToTop',
  methods: {
    top () {
      window.scrollTo(0, 0)
    }
  }
}
</script>

<style lang="css" scoped>
.scroll-to-top {
  position: fixed;
  bottom: 32px;
  right: 32px;
}
</style>
