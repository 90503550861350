const defaultRoutes = [
  {
    path: '*',
    meta: { layout: 'white-public' },
    name: '404',
    component: () => import('../pages/404.vue')
  },
  {
    path: '/',
    name: 'Home',
    meta: { layout: 'white-public', public: true },
    component: () => import('../pages/MedicalRegister/index.vue')
  }
]

export const examinationRegisterRoutes = [
  {
    path: '/examination-register',
    name: 'ExaminationRegister',
    meta: { layout: 'white-public', public: true },
    component: () => import('../pages/MedicalRegister/index.vue')
  },
  {
    path: '/examination-register/:ws_id',
    name: 'ExaminationRegisterForm',
    meta: { layout: 'white-public', public: true },
    component: () => import('../pages/MedicalRegister/FormOTP/index.vue')
  },
  {
    path: '/form-information',
    name: 'FormInformation',
    meta: { layout: 'white-public', public: true },
    component: () => import('../pages/MedicalRegister/FormRegister/index.vue')
  },
  {
    path: '/review-form-information',
    name: 'ReviewFormInformation',
    meta: { layout: 'white-public', public: true },
    component: () => import('../pages/MedicalRegister/ReviewForm/index.vue')
  },
  {
    path: '/schedule-medical-service',
    name: 'ScheduleMedicalService',
    meta: { layout: 'white-public', public: true },
    component: () =>
      import('../pages/MedicalRegister/ScheduleMedicalService/index.vue')
  },
  {
    path: '/detail-schedule-service/:id',
    name: 'DetailSchedule',
    meta: { layout: 'white-public', public: true },
    component: () =>
      import('../pages/MedicalRegister/DetailSchedule/index.vue')
  },
  {
    path: '/detail-invoice-appointment/:id',
    name: 'InvoiceOnePayRegisterMedical',
    meta: { layout: 'white-public', public: true },
    component: () =>
      import('../pages/MedicalRegister/OnePay/InvoicePublicLink.vue')
  },
  {
    path: '/result-invoice-appointment/:id',
    name: 'InvoiceOnePayRegisterMedical',
    meta: { layout: 'white-public', public: true },
    component: () =>
      import('../pages/MedicalRegister/OnePay/InvoicePublicResult.vue')
  },
  {
    path: '/detail-patients-visit/:id',
    name: 'DetailPatientsVisit',
    meta: { layout: 'white-public', public: true },
    component: () => import('../pages/MedicalRegister/PatientVisit/index.vue')
  }
]

export const bvkExaminationRegisterRoutes = [
  ...defaultRoutes,
  ...examinationRegisterRoutes
]
