import CareTeam from '../pages/Diseases/CareTeam.vue'
import CircleAttachments from '../pages/Circle/CircleAttachments.vue'
import CircleDetail from '../pages/Circle/CircleDetail.vue'
import CircleJoin from '../pages/Circle/CircleJoin.vue'
import CircleList from '../pages/Circle/CircleList.vue'
import CreatePerson from '../pages/User/CreatePerson.vue'
import Diagnose from '../pages/Diagnose/Diagnose.vue'
import DiagnoseDetail from '../pages/Diagnose/DiagnoseDetail.vue'
import DiseaseDocument from '../pages/Diseases/DiseaseDocument.vue'
import DiseasesCreate from '../pages/Diseases/DiseasesCreate.vue'
import DiseasesUpdate from '../pages/Diseases/DiseasesUpdate.vue'
import DiseasesView from '../pages/Diseases/DiseasesView.vue'
import DoctorAccept from '../pages/Diseases/DoctorAccept.vue'
import DoctorDetail from '../pages/Doctor/DoctorDetail.vue'
import HanbookDetail from '../pages/Handbook/HanbookDetail'
import HandbookKnowledge from '../pages/Handbook/HandbookKnowledge'
import HealthRecords from '../pages/HealthRecords'
import HelpsMeet from '../pages/Helps/HelpsMeet.vue'
import HelpsPatient from '../pages/Helps/HelpsPatient.vue'
import HodoAppDetail from '../pages/HodoApp/HodoAppDetail'
import HodoConference from '../pages/HodoConference/index'
import HodoProDetail from '../pages/HodoPro/HodoProDetail'
import Home from '../pages/Home/Home.vue'
import InvoicePublicLink from '../pages/Publics/InvoicePublicLink.vue'
import InvoicePublicResult from '../pages/Publics/InvoicePublicResult.vue'
import LandingPageDoctor from '../pages/Doctor/LandingPageDoctor.vue'
import LandingPageLogin from '../pages/User/LandingPageLogin.vue'
import LandingPagePac from '../pages/PacsTutorial/LandingPagePac.vue'
import ListDiseases from '../pages/Diseases/ListDiseases.vue'
import ListServices from '../pages/Services/ListServices'
import Login from '../pages/User/Login.vue'
import MyAccount from '../pages/Home/MyAccount.vue'
import PackageCombo from '../pages/Packages/PackageCombo.vue'
import PackageMember from '../pages/Packages/PackageMember.vue'
import PackageOrder from '../pages/Invoices/PackageOrder.vue'
import PackageSelect from '../pages/Packages/PackageSelect.vue'
import Policy from '../pages/TermPolicy/Policy.vue'
import Regulation from '../pages/TermPolicy/Regulation.vue'
import RegulationWebsite from '../pages/TermPolicy/RegulationWebsite.vue'
import SelectCareTeamDoctor from '../pages/Diseases/SelectCareTeamDoctor.vue'
import ServiceDetail from '../pages/Services/ServiceDetail'
import Term from '../pages/TermPolicy/Term.vue'
import TopicDetail from '../pages/Diseases/TopicDetail.vue'
import UpdatePerson from '../pages/User/UpdatePerson.vue'
import cpn404 from '../pages/404.vue'
import { examinationRegisterRoutes } from './examination-register-routes'

export const hodoRoutes = [
  {
    path: '*',
    meta: { layout: 'guest' },
    name: '404',
    component: cpn404
  },
  {
    path: '/',
    meta: { layout: 'guest' },
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    meta: { layout: 'guest' },
    name: 'Login',
    component: Login
  },
  {
    path: '/cam-nang-kien-thuc',
    meta: { layout: 'guest' },
    name: 'HandbookKnowledge',
    component: HandbookKnowledge
  },
  {
    path: '/app-hodo',
    meta: { layout: 'guest' },
    name: 'HodoAppDetail',
    component: HodoAppDetail
  },
  {
    path: '/hodo-pro',
    meta: { layout: 'guest' },
    name: 'HodoProDetail',
    component: HodoProDetail
  },
  {
    path: '/hodo-conference',
    meta: { layout: 'guest' },
    name: 'HodoConference',
    component: HodoConference
  },
  {
    path: '/dich-vu',
    meta: { layout: 'guest' },
    name: 'ListServices',
    component: ListServices
  },
  {
    path: '/dich-vu/:id/chi-tiet',
    meta: { layout: 'guest' },
    name: 'ServiceDetail',
    component: ServiceDetail
  },
  {
    path: '/dieu-khoan',
    meta: { layout: 'guest' },
    name: 'Term',
    component: Term
  },
  {
    path: '/chinh-sach',
    meta: { layout: 'guest' },
    name: 'Policy',
    component: Policy
  },
  {
    path: '/quy-che-website',
    meta: { layout: 'guest' },
    name: 'RegulationWebsite',
    component: RegulationWebsite
  },
  {
    path: '/quy-che-ung-dung',
    meta: { layout: 'guest' },
    name: 'Regulation',
    component: Regulation
  },
  {
    path: '/thanh-vien',
    meta: { layout: 'guest' },
    name: 'PackageMember',
    component: PackageMember
  },
  {
    path: '/help',
    meta: { layout: 'guest' },
    name: 'HelpsPatient',
    component: HelpsPatient
  },
  {
    path: '/help/meet',
    meta: { layout: 'guest' },
    name: 'HelpsMeet',
    component: HelpsMeet
  },
  {
    path: '/cam-nang-kien-thuc/:id/chi-tiet',
    meta: { layout: 'guest' },
    name: 'HanbookDetail',
    component: HanbookDetail
  },
  {
    path: '/danh-cho-bac-si',
    meta: { layout: 'guest' },
    name: 'LandingPageDoctor',
    component: LandingPageDoctor
  },
  {
    path: '/indication-consent-form',
    meta: { layout: 'guest' },
    name: 'IndicationConsentForm',
    component: () => import('../pages/IndicationConsentForm/index.vue')
  },
  {
    path: '/tai-khoan-moi',
    meta: { layout: 'auth' },
    name: 'CreatePerson',
    component: CreatePerson
  },
  {
    path: '/hssk/tao-ho-so-suc-khoe',
    meta: { layout: 'auth' },
    name: 'DiseasesCreate',
    component: DiseasesCreate
  },
  {
    path: '/hssk',
    meta: { layout: 'auth' },
    name: 'ListDiseases',
    component: ListDiseases
  },
  {
    path: '/hssk/:pdid/tai-lieu',
    meta: { layout: 'auth' },
    name: 'DiseaseDocument',
    component: DiseaseDocument
  },
  {
    path: '/hssk/:pdid/moi-bac-si',
    meta: { layout: 'auth' },
    name: 'CareTeam',
    component: CareTeam
  },
  {
    path: '/hssk/:pdid/moi-bac-si/:type/chon-bac-si',
    meta: { layout: 'auth' },
    name: 'SelectCareTeamDoctor',
    component: SelectCareTeamDoctor
  },
  {
    path: '/health-records',
    meta: { layout: 'auth' },
    name: 'HealthRecords',
    component: HealthRecords
  },
  {
    path: '/health-records/:id',
    meta: { layout: 'auth' },
    name: 'HealthRecordList',
    component: () => import('../pages/HealthRecords/List.vue')
  },
  {
    path: '/circles',
    meta: { layout: 'auth' },
    name: 'CircleList',
    component: CircleList
  },
  {
    path: '/circles/join',
    meta: { layout: 'blank' },
    name: 'CircleJoin',
    component: CircleJoin
  },
  {
    path: '/circle/:id',
    meta: { layout: 'auth' },
    name: 'CircleDetail',
    component: CircleDetail
  },
  {
    path: '/circle/:id/attachments',
    meta: { layout: 'auth' },
    name: 'CircleAttachments',
    component: CircleAttachments
  },
  {
    path: '/circle/:id/documents/folder/:folder',
    meta: {
      layout: 'auth',
      requiredInfo: true,
      requiredWs: false,
      public: false
    },
    name: 'CircleDocumentsFolder',
    component: CircleAttachments
  },
  {
    path: '/hr/pv/:id',
    meta: { layout: 'auth' },
    name: 'patient-visit',
    component: () => import('../pages/HealthRecords/PatientVisit/index.vue')
  },
  {
    path: '/phieu-kham/:id/chi-tiet',
    meta: { layout: 'auth' },
    name: 'ConsultationDetail',
    component: () => import('../pages/HealthRecords/ConsultationDetail.vue')
  },
  {
    path: '/health-records/indication/:id',
    meta: { layout: 'auth' },
    name: 'DetailIndication',
    component: () => import('../pages/HealthRecords/DetailIndication.vue')
  },
  {
    path: '/bat-dau',
    meta: { layout: 'auth' },
    name: 'LandingPageLogin',
    component: LandingPageLogin
  },
  {
    path: '/hssk/:pdid/cap-nhat',
    meta: { layout: 'auth' },
    name: 'DiseasesUpdate',
    component: DiseasesUpdate
  },
  {
    path: '/hssk/:pdid/view',
    meta: { layout: 'auth' },
    name: 'DiseasesView',
    component: DiseasesView
  },
  {
    path: '/hssk/:pdid/topic/:id',
    meta: { layout: 'auth' },
    name: 'TopicDetail',
    component: TopicDetail
  },
  {
    path: '/hssk/:pdid/bac-si-dong-y/:id',
    meta: { layout: 'auth' },
    name: 'DoctorAccept',
    component: DoctorAccept
  },
  {
    path: '/my-account',
    meta: { layout: 'auth' },
    name: 'MyAccount',
    component: MyAccount
  },
  {
    path: '/diagnose',
    meta: { layout: 'auth' },
    name: 'Diagnose',
    component: Diagnose
  },
  {
    path: '/diagnose/:id',
    meta: { layout: 'auth' },
    name: 'DiagnoseDetail',
    component: DiagnoseDetail
  },
  {
    path: '/thong-tin-ca-nhan',
    meta: { layout: 'auth' },
    name: 'UpdatePerson',
    component: UpdatePerson
  },
  {
    path: '/orders',
    meta: { layout: 'auth' },
    name: 'Orders',
    component: () => import('../pages/User/Orders.vue')
  },
  {
    path: '/bac-si/:code',
    meta: { layout: 'guest' },
    name: 'DoctorDetail',
    component: DoctorDetail
  },
  {
    path: '/thanh-toan/:id(\\d+)',
    meta: { layout: 'auth' },
    name: 'PackageOrder',
    component: PackageOrder
  },
  {
    path: '/thanh-toan/:id(\\d+)/one-pay',
    meta: { layout: 'auth' },
    name: 'PaymentWithOnePay',
    component: () => import('../pages/Invoices/PaymentWithOnePay.vue')
  },
  {
    path: '/chon-goi',
    meta: { layout: 'auth' },
    name: 'PackageCombo',
    component: PackageCombo
  },
  {
    path: '/chon-combo/:id',
    meta: { layout: 'auth' },
    name: 'PackageSelect',
    component: PackageSelect
  },
  {
    path: '/luutrupacs',
    meta: { layout: 'guest' },
    name: 'LandingPagePac',
    component: LandingPagePac
  },
  {
    path: '/ket-qua/:id',
    meta: { layout: 'guest' },
    name: 'IndicationResult',
    component: () => import('../pages/Publics/IndicationResult.vue')
  },
  {
    path: '/tra-ket-qua/:id',
    meta: { layout: 'guest' },
    name: 'IndicationResultLogin',
    component: () => import('../pages/Publics/ResultLogin.vue')
  },
  {
    path: '/phieu-kham/:id/ket-qua',
    meta: { layout: 'guest' },
    name: 'ConsultationResultLogin',
    component: () => import('../pages/Publics/ResultLogin.vue')
  },
  {
    path: '/luot-kham/:id/ket-qua',
    meta: { layout: 'guest' },
    name: 'PatientVisitResultLogin',
    component: () => import('../pages/Publics/ResultLogin.vue')
  },
  {
    path: '/ecommerce',
    meta: { layout: 'guest' },
    name: 'Ecommerce',
    component: () => import('../pages/Ecom/index.vue')
    // component: () => {}
  },
  {
    path: '/ecommerce/:slug',
    meta: { layout: 'guest' },
    name: 'DetailProduct',
    component: () => import('../pages/Ecom/detail/index.vue')
    // component: () => {}
  },
  {
    path: '/shopping-cart',
    meta: { layout: 'guest' },
    name: 'ShoppingCart',
    component: () => import('../pages/Ecom/ShoppingCart.vue')
    // component: () => {}
  },
  {
    // path: '/payment/:id(\\d+)/order',
    path: '/payment/order',
    meta: { layout: 'guest' },
    name: 'OrderConfirm',
    component: () => import('../pages/Ecom/OrderConfirm.vue')
    // component: () => {}
  },
  {
    path: '/payment/:id(\\d+)/onepay',
    meta: { layout: 'guest' },
    name: 'PaymentOrderEcom',
    component: () => import('../pages/Ecom/PaymentOrderEcom.vue')
    // component: () => {}
  },
  {
    path: '/payment/:id(\\d+)/invoice',
    meta: { layout: 'guest' },
    name: 'ProductOrderInvoice',
    component: () => import('../pages/Invoices/ProductOrderInvoice.vue')
    // component: () => {}
  },
  // Public invoice
  {
    path: '/invoice/public/:id',
    name: 'InvoicePublicLink',
    meta: { layout: 'public', public: true },
    component: InvoicePublicLink
  },
  {
    path: '/invoice/public-result/:id',
    meta: { layout: 'public', public: true },
    name: 'InvoicePublicResult',
    component: InvoicePublicResult
  },
  {
    path: '/pricing',
    name: 'Pricing',
    meta: {
      layout: 'guest'
    },
    component: () => import('../pages/Pricing')
  },
  {
    path: '/meet-pricing',
    name: 'MeetPricing',
    meta: {
      layout: 'guest'
    },
    component: () => import('../pages/MeetPricing/index.vue')
  },
  {
    path: '/payment-receipts',
    name: 'PaymentReceipts',
    meta: { layout: 'auth' },
    component: () => import('../pages/PaymentReceipts/index.vue')
  },
  {
    path: '/payment-response',
    name: 'PaymentResponse',
    meta: { layout: 'auth' },
    component: () => import('../pages/Ecom/PaymentResponse.vue')
  },
  {
    path: '/receipt-detail/:id',
    name: 'ReceiptDetail',
    meta: { layout: 'blank' },
    component: () => import('../pages/PaymentReceipts/ReceiptDetail.vue')
  },
  {
    path: '/slip/:person_id/:slip_id/:token',
    name: 'CheckUserViewReceipt',
    meta: { layout: 'blank' },
    component: () => import('../pages/PaymentReceipts/CheckUserViewReceipt.vue')
  },
  {
    path: '/video',
    name: 'VideoIntro',
    meta: { layout: 'blank' },
    component: () => import('../pages/Publics/VideoIntro.vue')
  },
  ...examinationRegisterRoutes
]
