// eslint-disable-next-line import/no-cycle
import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    orders: {},
    ordersDetail: null,
    selectedVoucher: '',
    voucher_code: {},
    listVoucher: [],
    voucherInfo: {}
  },
  getters: {
    getOrders (state) {
      return state.orders
    },
    getOrdersDetail (state) {
      return state.ordersDetail
    },
    voucherSelected (state) {
      return state.selectedVoucher
    }
  },
  mutations: {
    setOrders (state, payload) {
      state.orders = { ...payload }
    },
    setOrdersDetail (state, payload) {
      state.ordersDetail = payload
    },
    SET_VOUCHER_SELECTED (state, payload) {
      state.selectedVoucher = payload
    },
    SET_VOUCHER_CODE (state, payload) {
      state.voucher_code = payload
    },
    SET_LIST_VOUCHER (state, payload) {
      state.listVoucher = payload
    },
    SET_VOUCHER_INFO (state, payload) {
      state.voucherInfo = payload
    }
  },
  actions: {
    fetchProducts (ctx, params) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$rf
          .getRequest('GuestRequest')
          .getECommerceProducts(params)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    getVoucherCode (ctx, params) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$rf
          .getRequest('HIDRequest')
          .getVoucherCode(params)
          .then((response) => {
            const res = response.data
            ctx.commit('SET_VOUCHER_CODE', res?.Voucher)
            resolve({
              success: true,
              data: res
            })
          })
          .catch((error) => reject(error.message))
      })
    },
    getListVoucher (ctx, params) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$rf
          .getRequest('HIDRequest')
          .getListVoucher(params)
          .then((response) => {
            const res = response.data
            ctx.commit('SET_LIST_VOUCHER', res?.Evelops)
            resolve({
              success: true,
              data: res
            })
          })
          .catch((error) => reject(error.message))
      })
    },
    fetchProductDetail (ctx, { slug, params }) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$rf
          .getRequest('GuestRequest')
          .getECommerceProductDetail(slug, params)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    fetchCategories (ctx, params) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$rf
          .getRequest('GuestRequest')
          .getProductCategories(params)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    createECommerceOrders (ctx, params) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$rf
          .getRequest('AuthRequest')
          .createECommerceOrdersV2(params)
          .then((response) => {
            const res = response.data
            if (response?.status === 200) {
              ctx.commit('setOrdersDetail', res)
            }
            resolve({
              success: true,
              data: res
            })
          })
          .catch((error) => reject(error.message))
      })
    },
    getECommerceOrders (ctx, params) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$rf
          .getRequest('AuthRequest')
          .getECommerceOrders(params)
          .then((response) => {
            const res = response.data
            if (response?.status === 200) {
              ctx.commit('setOrders', res)
            }
            resolve({
              success: true,
              data: res
            })
          })
          .catch((error) => reject(error.message))
      })
    },
    getECommerceOrdersById (ctx, { id, params }) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$rf
          .getRequest('AuthRequest')
          .getECommerceOrdersById(id, params)
          .then((response) => {
            const res = response.data
            if (response?.status === 200) {
              ctx.commit('setOrdersDetail', res)
            }
            resolve({
              success: true,
              data: res
            })
          })
          .catch((error) => reject(error.message))
      })
    }
  }
}
