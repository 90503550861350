<template>
  <div class="handbook pb-5">
    <div class="handbook-banner text-center">
      <p class="banner-title txt-black">Cẩm nang kiến thức</p>
      <p class="banner-sub txt-black">Cẩm nang tổng hợp những kiến thức hữu ích, hướng tới gắn kết, sẻ chia và nâng cao chất lượng sống cho bệnh nhân Ung thư. Những chiến binh K dũng cảm ơi, hành trình sắp tới bạn đã có chúng tôi đồng hành</p>
      <div class="row">
        <div class="col-lg-6 col-md-12 px-3 offset-lg-3">
          <div class="input-group search-input d-flex align-items-center">
            <input
              type="text"
              class="form-control ps-3 h-100 input-search"
              aria-label="Tìm kiếm"
              placeholder="Tìm kiếm"
              v-model="search"
              @keypress.enter="onSearch()"
            >
            <span
              class="input-group-text c-pointer btn-close"
              @click="clearSearch()"
              v-if="search"
            ></span>
            <span class="input-group-text c-pointer"><img
                src="../../../public/icons/search-gray.svg"
                width="24"
                alt=""
                @click="onSearch()"
              ></span>
          </div>
        </div>
      </div>
    </div>
    <div class="hanbook-body px-160">
      <div
        class="category-list mb-5  d-md-flex d-none"
        v-if="!loading"
      >
        <a
          href="javascript:;"
          class="badge rounded-pill category-item"
          :class="!cid ? 'bg-pri text-white' : 'bg-light txt-black border'"
          @click="selectCate()"
        >Tất cả</a>
        <a
          href="javascript:;"
          class="category-item badge rounded-pill"
          v-for="cate in categories.data"
          :key="cate.id"
          @click="selectCate(cate)"
          :class="cid == cate.id ? 'bg-pri text-white' : 'bg-light txt-black border'"
        >{{cate.name}}</a>
      </div>
      <div class="dropdown d-block d-md-none mb-3">
        <button
          class="btn btn-pri text-start dropdown d-flex justify-content-between w-100"
          type="button"
          id="dropdownMenuButton1"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          {{selected_category ? selected_category.name : 'Tất cả'}}
          <img
            src="../../../public/icons/dropdown-white.svg"
            alt=""
          >
        </button>
        <ul
          class="dropdown-menu w-100"
          aria-labelledby="dropdownMenuButton1"
        >
          <li><a
              class="dropdown-item"
              href="javascript:;"
              :class="!cid ? 'bg-pri text-white' : 'bg-light txt-black'"
              @click="selectCate()"
            >Tất cả</a></li>
          <li><a
              class="dropdown-item"
              v-for="cate in categories.data"
              :key="cate.id"
              @click="selectCate(cate)"
              :class="cid == cate.id ? 'bg-pri text-white' : 'bg-light txt-black'"
              href="javascript:;"
            >{{cate.name}}</a></li>
        </ul>
      </div>
      <div
        v-if="!loading && !first_item && !tripple_item.length && !news.length"
        class="w-100  m-auto"
      >
        <div class="w-25 m-auto">
          <img
            src="../../../public/images/Page/empty.png"
            class="img-fluid mb-3"
            alt=""
          >

        </div>
        <p class="text-center fs-24 fw-500 txt-pri">Chưa có bài viết nào</p>
      </div>
      <!-- pc -->
      <div class="hight-light d-pc row mb-3 mb-lg-5">
        <div
          class="col-md-6 relative c-pointer"
          v-if="first_item"
          @click="goToDetail(first_item.id)"
        >
          <div class="card radius-15 h-100">
            <div class="out-side-item-img ratio ratio-16x9">
              <img
                v-if="first_item.image"
                :src="getDocumentURL(first_item.image)"
                class="item-img"
                alt="..."
              >
              <img
                v-if="!first_item.image"
                src="../../../public/images/handbook/sample-img.svg"
                class="item-img"
                alt="..."
              >

            </div>
            <div class="card-body text-start first-item-body">
              <div class="outer-first-item-title mb-2">
                <h5 class="first-item-title max-line-2 mb-0">{{first_item.title}}</h5>
              </div>
              <div class="outer-item-desc">
                <p class="item-desc max-line-3">{{first_item.desc_short}}</p>
              </div>
              <div class="bottom-left">
                <span class="item-cate-name">
                  {{selected_category ? selected_category.name : first_item.categories && first_item.categories[0] ? first_item.categories[0].name : ''}}

                </span>
                <span class="item-cate-date">
                  {{formatDMY(first_item.created_at)}}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-md-6"
          v-if="tripple_item"
        >
          <div
            class="card radius-15 c-pointer"
            v-for="(New, i) in tripple_item"
            :key="New.id"
            :class="i === 0 || i === 1 ? 'mb-3' : ''"
            @click="goToDetail(New.id)"
          >
            <div class="row">
              <div class="col-lg-6">
                <div class="out-side-item-img ratio ratio-16x9">
                  <img
                    v-if="New.image"
                    :src="getDocumentURL(New.image)"
                    class="item-img"
                    alt="..."
                  >
                  <img
                    v-if="!New.image"
                    src="../../../public/images/handbook/sample-img.svg"
                    class="item-img"
                    alt="..."
                  >
                </div>
              </div>
              <div class="col-lg-6 relative">
                <div class="card-body text-start">
                  <div class="outer-item-title mb-2">
                    <h5 class="item-title max-line-2 mb-0">{{New.title}}</h5>
                  </div>
                  <div class="outer-item-desc">
                    <p class="item-desc max-line-1 mb-0">{{New.desc_short}}</p>
                  </div>
                  <div class="bottom-left">
                    <span class="item-cate-name">
                      {{selected_category ? selected_category.name : New.categories && New.categories[0] ? New.categories[0].name : ''}}
                    </span>
                    <span class="item-cate-date">
                      {{formatDMY(New.created_at)}}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- tablet  -->
      <div class="hight-light d-mob row">
        <div
          class="col-md-6 c-pointer mb-3"
          v-if="first_item"
          @click="goToDetail(first_item.id)"
        >
          <div class="card radius-15">
            <div class="out-side-item-img ratio ratio-16x9">
              <img
                v-if="first_item.image"
                :src="getDocumentURL(first_item.image)"
                class="item-img"
                alt="..."
              >
              <img
                v-if="!first_item.image"
                src="../../../public/images/handbook/sample-img.svg"
                class="item-img"
                alt="..."
              >

            </div>
            <div class="card-body text-start">
              <div class="outer-first-item-title mb-2">
                <h5 class="first-item-title max-line-2 mb-0">{{first_item.title}}</h5>
              </div>
              <div class="outer-item-desc">
                <p class="item-desc max-line-3 mb-0">{{first_item.desc_short}}</p>
              </div>
              <div class="">
                <span class="item-cate-name">
                  {{selected_category ? selected_category.name : first_item.categories && first_item.categories[0] ? first_item.categories[0].name : ''}}

                </span>
                <span class="item-cate-date">
                  {{formatDMY(first_item.created_at)}}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div
          class="col-md-6 c-pointer mb-3"
          v-for="(New, i) in tripple_item"
          :key="New.id"
          :class="i === 0 || i === 1 ? 'mb-3' : ''"
          @click="goToDetail(New.id)"
        >
          <div class="card radius-15">
            <div class="out-side-item-img ratio ratio-16x9">
              <img
                v-if="New.image"
                :src="getDocumentURL(New.image)"
                class="item-img"
                alt="..."
              >
              <img
                v-if="!New.image"
                src="../../../public/images/handbook/sample-img.svg"
                class="item-img"
                alt="..."
              >

            </div>
            <div class="card-body text-start">
              <div class="outer-first-item-title mb-2">
                <h5 class="first-item-title max-line-2 mb-0">{{New.title}}</h5>
              </div>
              <div class="outer-item-desc">
                <p class="item-desc max-line-3">{{New.desc_short}}</p>
              </div>
              <div class="">
                <span class="item-cate-name">
                  {{selected_category ? selected_category.name : New.categories && New.categories[0] ? New.categories[0].name : ''}}

                </span>
                <span class="item-cate-date">
                  {{formatDMY(New.created_at)}}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- item -->
      <div class="row">
        <div
          class="col-md-6 col-xl-4 c-pointer mb-3"
          v-for="(New, i) in news"
          :key="New.id"
          :class="i === 0 || i === 1 ? 'mb-3' : ''"
          @click="goToDetail(New.id)"
        >
          <div class="card radius-15">
            <div class="out-side-item-img ratio ratio-16x9">
              <img
                v-if="New.image"
                :src="getDocumentURL(New.image)"
                class="item-img"
                alt="..."
              >
              <img
                v-if="!New.image"
                src="../../../public/images/handbook/sample-img.svg"
                class="item-img"
                alt="..."
              >

            </div>
            <div class="card-body text-start">
              <div class="outer-item-title mb-2">
                <h5 class="item-title max-line-2 mb-0">{{New.title}}</h5>
              </div>
              <div class="outer-item-desc">
                <p class="item-desc max-line-3">{{New.desc_short}}</p>
              </div>
              <div class="">
                <span class="item-cate-name">
                  {{selected_category ? selected_category.name : New.categories && New.categories[0] ? New.categories[0].name : ''}}
                </span>
                <span class="item-cate-date">
                  {{formatDMY(New.created_at)}}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-center my-5">
        <div>
          <!-- <a
            class="fs-14 fw-500 txt-pri text-decoration-none m-3"
            href="javascript:;"
            @click="prePage()"
            v-if="limit > 1"
          ><img
              width="24"
              src="../../../public/icons/arrow-right-pri.svg"
              alt=""
              class="me-2 flip-180"
            > Trang trước</a> -->
          <a
            class="fs-14 fw-500 txt-pri text-decoration-none m-3"
            href="javascript:;"
            @click="nextPage()"
            v-if="limit < total"
          >Xem thêm &nbsp;<img
              width="18"
              src="../../../public/icons/arrow-right-pri.svg"
              alt=""
              class="ms-2"
              style="transform: rotate(90deg);"
            ></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import appUtils from '../../utils/appUtils'
export default {
  name: 'HandbookKnowledge',
  data () {
    return {
      news: {},
      limit: 16,
      total: 0,
      search: '',
      loading: false,
      appUtils,
      first_item: null,
      tripple_item: {},
      categories: [],
      selected_category: null,
      cid: null
    }
  },
  mounted () {
    const self = this
    self.limit = self.$route.query.limit || 16
    self.search = self.$route.query.search || ''
    self.getNews()
    self.getNewCategories()
  },
  watch: {
    '$route.query.search': {
      handler: function (search) {
        this.search = search
      },
      deep: true,
      immediate: true
    },
    '$route.query.limit': {
      handler: function (limit) {
        this.limit = limit
      },
      deep: true,
      immediate: true
    },
    '$route.query.cid': {
      handler: function (cid) {
        console.log(cid)
        this.cid = cid
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    async getNewCategories () {
      const self = this
      self.loading = true
      const params = {
        status: 1
      }
      await self.$rf.getRequest('GuestRequest').getNewCategories(params).then((rs) => {
        self.categories = rs.data
      }).finally(() => {
        self.loading = false
      })
    },
    selectCate (cate) {
      const query = { ...this.$route.query }
      if (cate) {
        this.selected_category = cate
        query.cid = cate?.id
      } else {
        delete query.cid
        this.selected_category = null
      }
      query.limit = 16
      this.$router.push({ query: query }).catch(() => { })
      setTimeout(() => {
        this.getNews()
      }, 50)
    },
    async getNews () {
      const self = this
      self.loading = true
      const params = {
        page: 1,
        limit: self.limit,
        status: 1
      }
      if (self.cid) {
        params.category_ids = [self.cid]
      }
      if (self.search) {
        params.search = self.search
      }
      await self.$rf.getRequest('GuestRequest').getNews(params).then((resp) => {
        const data = resp?.data
        self.total = data?.total
        self.first_item = data?.data[0]
        self.tripple_item = data?.data.filter((n, i) => i < 4 && i > 0)
        self.news = data?.data.filter((n, i) => i >= 4)
      }).finally(() => {
        self.loading = false
      })
    },
    nextPage () {
      this.limit = parseInt(this.limit) + 8
      const query = { ...this.$route.query }
      if (this.limit) {
        query.limit = this.limit
      } else {
        delete query.limit
      }
      this.$router.push({ query: query }).catch(() => { })
      setTimeout(() => {
        this.getNews()
      }, 50)
    },
    onSearch () {
      const self = this
      const query = { ...self.$route.query }
      if (self.search) {
        query.search = self.search
      }
      query.limit = 16
      self.$router.push({ query: query }).catch(() => { })
      self.getNews()
    },
    clearSearch () {
      this.search = ''
      const self = this
      const query = { ...self.$route.query }
      delete query.search
      query.limit = 16
      self.$router.push({ query: query }).catch(() => { })
      self.getNews()
    },
    goToDetail (id) {
      this.$router.push({ path: `/cam-nang-kien-thuc/${id}/chi-tiet` })
    },
    getDocumentURL (path) {
      return appUtils.getDocumentURL(path)
    },
    formatDMY (dt) {
      return this.moment(dt).format('DD/MM/YYYY')
    }
  }
}
</script>

<style lang="css" scoped>
.handbook-banner {
  background-image: url("../../../public/images/handbook/banner-handbook.svg");
  padding-top: 16px;
  padding-bottom: 16px;
  background-size: cover; /* <------ */
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #f7f7f9;
}

.input-search:focus {
  box-shadow: none;
}
.search-input {
  height: 64px;
  background: #fbfbfb;
  border: 2px solid #d5d2f9;
  box-sizing: border-box;
  border-radius: 67px;
  margin-top: 33px;
  margin-bottom: 46px;
}
.search-input input {
  border-top-left-radius: 33.5px;
  border-bottom-left-radius: 33.5px;
  background: #fbfbfb !important;
  border: none;
}
.search-input span:last-child {
  border-top-right-radius: 33.5px;
  border-bottom-right-radius: 33.5px;
  background: #fbfbfb !important;
  margin-right: 10px;
  border: none;
}
.outer-first-item-title {
  height: 80px;
  overflow: hidden;
}
.relative {
  position: relative;
}
.bottom-left {
  position: absolute;
  bottom: 10px;
  left: 20px;
}
.category-item {
  font-size: 16px;
  font-weight: 500;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 15px !important;
  padding-left: 15px !important;
  text-decoration: none;
  margin-right: 16px;
  margin-bottom: 16px;
}
.item-cate-name,
.item-cate-date {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4000000059604645px;
  text-align: left;
}
.item-cate-name {
  padding-right: 8px;
  border-right: 1px solid #757575;
}
.out-side-item-img {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.item-img {
  border-radius: 15px;
  max-width: 100%;
  max-height: 100%;
}
.handbook {
  background-color: #f7f7f9;
}
.img-hb-banner {
  width: 100vw;
}
.category-list {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.first-item-title,
.item-cate-name {
  color: #292929 !important;
}
.item-cate-date {
  color: #757575 !important;
  padding-left: 8px;
}
.outer-item-title {
  height: 48px;
  overflow: hidden;
}
.outer-item-desc {
  height: 76px;
  overflow: hidden;
}
.item-title {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
.item-desc {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.5px;
  text-align: left;
}
.banner-title {
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -0.5181204080581665px;
  text-align: center;
}
.banner-sub {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  width: 90%;
  margin: 0 auto;
}
.first-item-body{
  height: 215px;
}
@media only screen and (min-width: 1440px) {
  .d-mob {
    display: none;
  }
  .first-item-title {
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: left;
  }
  .banner-title {
    font-size: 80px;
    font-style: normal;
    font-weight: 500;
    line-height: 99px !important;
    letter-spacing: -0.5181204080581665px;
    text-align: center;
    margin-bottom: 18px;
  }
  .banner-sub {
    width: 60%;
    margin: 0 auto;
  }
  .handbook-banner {
    padding-top: 64px;
    padding-bottom: 64px;
  }
  .search-input {
    margin-bottom: 240px;
  }
}
@media only screen and (max-width: 1439px) and (min-width: 1024px) {
  .d-mob {
    display: none;
  }
  .first-item-title {
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: left;
  }
  .banner-title {
    font-size: 80px;
    font-style: normal;
    font-weight: 500;
    line-height: 99px !important;
    letter-spacing: -0.5181204080581665px;
    text-align: center;
    margin-bottom: 18px;
  }
  .banner-sub {
    width: 60%;
    margin: 0 auto;
  }
  .handbook-banner {
    padding-top: 64px;
    padding-bottom: 64px;
  }
}
@media only screen and (max-width: 1023px) {
  .d-pc {
    display: none;
  }
  .first-item-title {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
  }
  .outer-first-item-title {
    height: 48px;
    overflow: hidden;
  }
}
</style>
