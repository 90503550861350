import BaseRequest from '../BaseRequest'

export default class GuestRequest extends BaseRequest {
  getOTP (params) {
    return this.post('users/otp', params, null, true)
  }

  otpVerify (params) {
    return this.post('users/otp-verify', params, null, false)
  }

  getServices (params) {
    return this.get('guest-show-services', params)
  }

  guestBooking (params) {
    return this.post('guest-booking-services', params, null, false)
  }

  getServiceDetail (id) {
    return this.get(`services/${id}/show`)
  }

  getServiceCategories (params) {
    return this.get('guest/service-categories', params)
  }

  getNews (params) {
    return this.get('news', params)
  }

  getNewDetail (id) {
    return this.get(`news/${id}/show`)
  }

  getNewCategories (params) {
    return this.get('guest/new-categories', params)
  }

  showDoctor (code) {
    return this.get(`guest/doctor/${code}/show`)
  }

  getLinkDownloadAwsRc (params, header, showMessage = true) {
    return this.post(
      'core/v1/uploads/s3-get-rc-presigned',
      params,
      header,
      showMessage
    )
  }

  sendOtpIndicationResult (id, header, showMessage = true) {
    return this.post(`indication/${id}/otp`, null, header, showMessage)
  }

  getIndicationResult (id, params, header, showMessage = true) {
    return this.get(`indication/${id}`, params, header, showMessage)
  }

  sendOtpIndicationLogin (id, header, showMessage = true) {
    return this.post(`indication/${id}/login-otp`, null, header, showMessage)
  }

  sendOtpConsultationResult (id, header, showMessage = true) {
    return this.post(`consultation/${id}/login-otp`, null, header, showMessage)
  }

  sendOtpPatientResult (id, header, showMessage = true) {
    return this.post(`patientvisit/${id}/login-otp`, null, header, showMessage)
  }

  /**
   * E-Commerce
   */
  // Categories
  getProductCategories (params) {
    return this.get('go/partner/products/categories', params)
  }

  // Products
  getECommerceProducts (params) {
    return this.get('go/customer/products', params)
  }

  getECommerceProductDetail (slug, params) {
    return this.get(`go/customer/products/${slug}`, params)
  }

  putCancelOrder (id) {
    return this.put(`go/customer/products/orders/${id}`)
  }

  applyVoucher (params) {
    return this.post('go/customer/voucher/check', params)
  }

  markVoucherAsUsed (params) {
    return this.post('go/customer/voucher/apply', params)
  }

  getOrgAndWs (params) {
    return this.get('go/organizations/public', params, false)
  }

  checkAvailableDoctorByMonthCr (params) {
    return this.post('go/checkdoctoravailbymonthv2', params)
  }

  getTimeSlotByCrID (params) {
    return this.post('go/getDoctorSlotByCrId', params)
  }

  getWorkspacePublicByOrgId (id) {
    return this.get(`go/core/workspace/public/${id}`)
  }

  getLiveCircleMembers (params) {
    return this.get('go/circle/doctor/listmember', params)
  }

  fetchLivestreamSchedule (params) {
    return this.get('go/circle/schedule/list', params)
  }

  getCircleInfoByCode (code) {
    return this.get('go/circle/joins', code)
  }

  getHodoSevicesBenefit () {
    return this.get('go/hodo/services/public/benefit')
  }

  getOrgAndWsCode (params) {
    return this.get('go/workspace/code', params)
  }

  sendOTPPatientVisit (params) {
    return this.post('go/patient_visit/user/send_otp', params)
  }

  trialRegister (data) {
    return this.post('go/trial_member/register', data)
  }

  trialRegisterResendMail (data) {
    return this.post('go/trial_member/sendmail', data)
  }
}
