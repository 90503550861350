<template>
  <div>
    <div class="item-w h-196px">
      <p class="fs-18 mb-2 max-line-2 txt-grey-900 fw-500">
        <span v-if="circle.type === CIRCLE_TYPE.LIVE" class="mr-2 align-bottom">
          <img :src="liveStream" :alt="circle.name" width="22" height="22" />
        </span>
        <span class="align-bottom leading-tight">{{ circle.name }}</span>
      </p>
      <p class="txt-grey-900 fs-14 max-line-1">{{ circle.members_count || 0 }} Thành viên</p>
      <p class="txt-grey-900 fs-14 fw-400 mb-2 max-line-3 h-40px">{{ circle.description }}</p>
    </div>
  </div>
</template>

<script>
import liveStream from '../../assets/images/livestream.png'
import { CIRCLE_TYPE } from '../../utils/constants'

export default {
  name: 'CirclePendingItem',
  props: ['circle', 'loading', 'type'],
  data () {
    return {
      count: 0,
      CIRCLE_TYPE,
      liveStream
    }
  },
  methods: {
    rejectCircle (id, e) {
      e.stopPropagation()
      this.$emit('rejectCircle', id, this.type)
    },
    acceptCircle (circle, e) {
      e.stopPropagation()
      this.$emit('acceptCircle', circle)
    }
  }
}
</script>

<style scoped>
.item-w {
  padding: 1.3rem;
  background-color: #ffffff;
  border: 1px solid #edeae9;
  border-radius: 16px;
  cursor: pointer;
}

.mb-24 {
  margin-bottom: 24px;
}
</style>
<style scoped lang="scss">
.txt-pri {
  color: #20419b !important;
}
.bg-pri {
  background-color: #20419b !important;
}
.h-196px {
  height: 196px;
}
</style>
