<template>
  <div class="bg-grey-200">
    <div class="container">
      <div class="row">
        <div class="col-12 py-4 flex justify-between items-center">
          <p class="fs-28 fw-500">Thông tin Đơn hàng</p>
          <div class="flex justify-end items-center">
            <div class="text-right">
              <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                @click="goToBack"
                class="cursor-pointer"
              >
                <path
                  d="M6 18.4998L17.9998 6.5"
                  stroke="#292D32"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M17.9998 18.4998L6 6.5"
                  stroke="#292D32"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="mb-4">
            <p class="fs-16 fw-500 txt-grey-900 mb-1">Nhà cung cấp dịch vụ</p>
            <p class="fs-16 txt-grey-600 mb-0">
              CÔNG TY CỔ PHẦN CÔNG NGHỆ 905 VIỆT NAM
            </p>
            <p class="fs-16 txt-grey-600 mb-0">
              Tầng 8, tòa nhà ACCI, Số 210 Lê Trọng Tấn, Phường Khương Mai, Quận
              Thanh Xuân, Thành phố Hà Nội, Việt Nam
            </p>
            <p class="fs-16 txt-grey-600 mb-0">Hotline: (+84)0345 - 900905</p>
            <p class="fs-16 txt-grey-600 mb-0">Email: Hello@hodo.com.vn</p>
          </div>
          <div class="mb-4">
            <p class="fs-16 fw-500 txt-grey-900 mb-1">Mã số thuế:</p>
            <p class="fs-16 txt-grey-600 mb-0">0109377692</p>
          </div>
          <div class="mb-4">
            <p class="fs-16 fw-500 txt-grey-900 mb-1">Thông tin khách hàng</p>
            <p class="fs-16 txt-grey-600 mb-0">Họ và tên: {{ cus.name }}</p>
            <!-- <p class="fs-16 txt-grey-600 mb-0" v-if="cus.code">
              Tài khoản: {{ cus.code }}
            </p> -->
          </div>
          <div class="mb-2">
            <p class="fs-16 fw-500 txt-grey-900 mb-2">Thông tin dịch vụ</p>
            <div class="d-flex justify-content-between">
              <p class="fs-16 fw-500 txt-grey-900 mb-0">Dịch vụ</p>
              <p class="fs-16 fw-500 txt-grey-900 text-end mb-0">Thành tiền</p>
            </div>
          </div>
          <div class="d-flex justify-content-between">
            <p class="fs-16 fw-500 txt-grey-900 mb-0">
              {{ order && order.member_package && order.member_package.name }}
              {{ order && order.months }} tháng
            </p>
            <p class="fs-16 txt-grey-900 mb-0">
              {{
                order && order.total_price && numberFormat(order.total_price)
              }}
              VNĐ
            </p>
          </div>
          <div class="w-100 overflow-x-hidden">
            <svg
              width="1500"
              height="3"
              viewBox="0 0 1500 3"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                y="0.432129"
                width="1500"
                height="2"
                fill="#19181A"
              />
            </svg>

            <div class="d-flex justify-content-between">
              <p class="fs-16 fw-500 txt-grey-900">Tổng tiền thanh toán</p>
              <p class="fs-16 txt-grey-900">
                {{
                  order && order.total_price && numberFormat(order.total_price)
                }}
                VNĐ
              </p>
            </div>
            <div class="d-flex justify-content-between">
              <p class="fs-16 fw-500 txt-grey-900">Mã đơn</p>
              <p class="fs-16 txt-grey-900">{{ order && order.code }}</p>
            </div>
            <div class="d-flex justify-content-between">
              <p class="fs-16 fw-500 txt-grey-900">Tình trạng</p>
              <p
                class="fs-16 txt-grey-900"
                v-if="order && order.status"
                :style="order.status === 1 ? 'color: #DE3854;' : order.status === 2 ? 'color: #FBCF5E;' : 'color: #6CC7A4;'"
              >{{order.status === 1 ? 'Chưa thanh toán' : order.status === 2 ? 'Chờ xác nhận thanh toán' : 'Chấp nhận'}}</p>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <p class="fs-16 fw-500 txt-grey-900">
            Để hoàn thành thanh toán, Bạn vui lòng chuyển khoản đến tài khoản
            sau
          </p>
          <div class="bg-white p-2 mb-3">
            <div class="d-flex mb-3 border-bottom">
              <img
                src="../../assets/vcb.png"
                width="50"
                height="50"
                alt=""
              />
              <div>
                <p class="fs-16 fw-700 txt-grey-900 mb-0">
                  Ngân hàng Vietcombank
                </p>
                <p class="fs-14 fw-500 txt-grey-900 mb-0">
                  Chi nhánh Chương Dương, Hà Nội
                </p>
              </div>
            </div>
            <div class="mb-3 border-bottom">
              <p class="fs-14 txt-grey-600 mb-0">Số tài khoản:</p>
              <div class="d-flex justify-content-between">
                <p
                  class="fs-16 fw-500 txt-grey-900"
                  id="stk"
                >1017444706</p>
                <button
                  @click="copyText('stk')"
                  class="px-2 border-0 fs-13 bg-pri text-white radius-15 h-21px"
                >
                  Sao chép
                </button>
              </div>
            </div>
            <div class="mb-3 border-bottom">
              <p class="fs-14 txt-grey-600 mb-0">Chủ tài khoản:</p>
              <div class="d-flex justify-content-between">
                <p
                  class="fs-16 fw-500 txt-grey-900"
                  id="stk"
                >
                  CÔNG TY CỔ PHẦN CÔNG NGHỆ 905 VIỆT NAM
                </p>
              </div>
            </div>
            <div class="mb-3 border-bottom">
              <p class="fs-14 txt-grey-600 mb-0">Nội dung chuyển khoản:</p>
              <div class="d-flex justify-content-between">
                <p
                  class="fs-16 fw-500 txt-grey-900"
                  id="nd"
                >
                  {{ order && order.code }}
                </p>
                <button
                  @click="copyText('nd')"
                  class="px-2 border-0 fs-13 bg-pri text-white radius-15 h-21px"
                >
                  Sao chép
                </button>
              </div>
            </div>
            <div>
              <p class="fs-14 txt-grey-600 mb-0">Số tiền cần thanh toán</p>
              <div class="d-flex justify-content-between">
                <p
                  class="fs-16 fw-500 txt-grey-900"
                  id="stk"
                >
                  {{
                    order &&
                    order.total_price &&
                    numberFormat(order.total_price)
                  }}
                  VNĐ
                </p>
              </div>
            </div>
          </div>
          <div class="p-3 bg-pri-50 mb-3">
            <div class="d-flex">
              <div class="me-2">
                <svg
                  width="25"
                  height="24"
                  viewBox="0 0 25 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.5001 2C6.99006 2 2.50006 6.49 2.50006 12C2.50006 17.51 6.99006 22 12.5001 22C18.0101 22 22.5001 17.51 22.5001 12C22.5001 6.49 18.0101 2 12.5001 2ZM11.7501 8C11.7501 7.59 12.0901 7.25 12.5001 7.25C12.9101 7.25 13.2501 7.59 13.2501 8V13C13.2501 13.41 12.9101 13.75 12.5001 13.75C12.0901 13.75 11.7501 13.41 11.7501 13V8ZM13.4201 16.38C13.3701 16.51 13.3001 16.61 13.2101 16.71C13.1101 16.8 13.0001 16.87 12.8801 16.92C12.7601 16.97 12.6301 17 12.5001 17C12.3701 17 12.2401 16.97 12.1201 16.92C12.0001 16.87 11.8901 16.8 11.7901 16.71C11.7001 16.61 11.6301 16.51 11.5801 16.38C11.5301 16.26 11.5001 16.13 11.5001 16C11.5001 15.87 11.5301 15.74 11.5801 15.62C11.6301 15.5 11.7001 15.39 11.7901 15.29C11.8901 15.2 12.0001 15.13 12.1201 15.08C12.3601 14.98 12.6401 14.98 12.8801 15.08C13.0001 15.13 13.1101 15.2 13.2101 15.29C13.3001 15.39 13.3701 15.5 13.4201 15.62C13.4701 15.74 13.5001 15.87 13.5001 16C13.5001 16.13 13.4701 16.26 13.4201 16.38Z"
                    fill="#456BD6"
                  />
                </svg>
              </div>
              <p class="txt-pri fs-16">
                Vui lòng nhập đúng nội dung chuyển khoản, để giao dịch được hoàn
                tất trong thời gian sớm nhất
              </p>
            </div>
            <div class="d-flex">
              <div class="me-2">
                <svg
                  width="25"
                  height="24"
                  viewBox="0 0 25 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.5001 2C6.99006 2 2.50006 6.49 2.50006 12C2.50006 17.51 6.99006 22 12.5001 22C18.0101 22 22.5001 17.51 22.5001 12C22.5001 6.49 18.0101 2 12.5001 2ZM11.7501 8C11.7501 7.59 12.0901 7.25 12.5001 7.25C12.9101 7.25 13.2501 7.59 13.2501 8V13C13.2501 13.41 12.9101 13.75 12.5001 13.75C12.0901 13.75 11.7501 13.41 11.7501 13V8ZM13.4201 16.38C13.3701 16.51 13.3001 16.61 13.2101 16.71C13.1101 16.8 13.0001 16.87 12.8801 16.92C12.7601 16.97 12.6301 17 12.5001 17C12.3701 17 12.2401 16.97 12.1201 16.92C12.0001 16.87 11.8901 16.8 11.7901 16.71C11.7001 16.61 11.6301 16.51 11.5801 16.38C11.5301 16.26 11.5001 16.13 11.5001 16C11.5001 15.87 11.5301 15.74 11.5801 15.62C11.6301 15.5 11.7001 15.39 11.7901 15.29C11.8901 15.2 12.0001 15.13 12.1201 15.08C12.3601 14.98 12.6401 14.98 12.8801 15.08C13.0001 15.13 13.1101 15.2 13.2101 15.29C13.3001 15.39 13.3701 15.5 13.4201 15.62C13.4701 15.74 13.5001 15.87 13.5001 16C13.5001 16.13 13.4701 16.26 13.4201 16.38Z"
                    fill="#456BD6"
                  />
                </svg>
              </div>
              <p class="txt-pri fs-16">
                Bạn cũng có thể dùng App ngân hàng và quét mã QR dưới đây
              </p>
            </div>
            <div class="w-100">
              <div class="d-flex justify-content-center mb-3">
                <img
                  v-if="order && order.total_price && order && order.code"
                  class="w-50"
                  :src="`https://img.vietqr.io/image/VCB-1017444706-qr_only.jpg?amount=${
                    order.total_price
                  }&addInfo=${
                    order && order.code
                  }&accountName=CÔNG TY CỔ PHẦN CÔNG NGHỆ 905 VIỆT NAM`"
                  alt=""
                />
              </div>
            </div>
          </div>
          <button
            class="btn bg-pri text-white p-3 w-100 mb-4"
            :disabled="order && order.status !== 1"
            @click="paid"
          >
            {{getBtnString}}
          </button>
          <button
            class="btn bg-pri text-white p-3 w-100 mb-4"
            @click="paymentWithOnePay"
          >
            Thanh toán ATM/Visa/Master/QR code
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  name: 'PackageOrder',
  data () {
    return {
      order: null,
      user: null
    }
  },
  computed: {
    cus () {
      const r = {
        name: '',
        code: ''
      }
      return this.$user?.person || r
    },
    getBtnString () {
      if (!this.order) return
      const order = this.order
      return order.status === 0 ? 'Đơn này đã bị hủy' : order.status === 1 ? 'Xác nhận đã chuyển Khoản' : order.status === 2 ? 'Bạn đã thanh toán hóa đơn này' : order.status === 3 ? 'Đã xác nhận thanh toán' : ''
    }
  },
  mounted () {
    this.getDetail()

    this.user = this.$user
  },
  methods: {
    paymentWithOnePay () {
      this.$router.push({ name: 'PaymentWithOnePay', params: { id: this.$route.params.id } })
    },
    async getDetail () {
      await this.$rf.getRequest('AuthRequest').showPackageOrder(this.$route.params.id).then((r) => {
        this.order = r.data
      })
    },
    async paid () {
      if (!confirm('Bạn có chắc chắn không?')) return
      await this.$rf.getRequest('AuthRequest').paidPackageOrder(this.$route.params.id).then((r) => {
        this.sendAmplitude('Confirmed paid', {
          'Person ID': this.user.person?.id,
          'Person Name': this.user.person?.name,
          Timestamp: moment(new Date()).format('HH:mm DD-MM-YYYY')
        })
        this.$router.push({ name: 'ListDiseases' })
      })
    },
    numberFormat (number) {
      return number.toLocaleString(undefined, { minimumFractionDigits: 0 })
    },
    copyText (idElement) {
      var code = document.getElementById(idElement)
      const range = document.createRange()
      var selection = window.getSelection()
      selection.removeAllRanges()
      range.selectNodeContents(code)
      selection.addRange(range)
      try {
        if (this.isSupport('copy')) {
          document.execCommand('copy')
          selection.removeAllRanges()
          this.$toast.open({
            message: 'Sao chép thành công',
            type: 'success'
          })
        } else {
          this.$toast.open({
            message: 'Sao chép không được hỗ trợ trên trình duyệt của bạn',
            type: 'error'
          })
        }
      } catch (e) {
        console.log(e)
      }
    },
    isSupport (cmd) {
      return document.queryCommandSupported(cmd)
    },
    goToBack () {
      this.sendAmplitude('Exit order page', {
        'Person ID': this.user.person?.id,
        'Person Name': this.user.person?.name,
        Timestamp: moment(new Date()).format('HH:mm DD-MM-YYYY')
      })
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped>
.h-21px {
  height: 21px !important;
}
.w-70 {
  width: 70%;
  margin: 0 auto;
}
</style>
