<template>
  <div class="mb-5">
    <div class="create-person">
      <p class="fs-28 fw-500 txt-black">HODO xin chào !</p>
      <p class="fs-18 fw-400 txt-gray-blur mb-4">Để bắt đầu sử dụng HODO, <br>bạn cần hoàn thiện một số thông tin sau </p>
      <div class="d-flex align-items-center mb-3">
        <img
          v-if="avt_file"
          class="avatar me-3"
          :src="getBase64URL(avt_file)"
        />
        <img
          v-if="!avt_file"
          class="avatar me-3"
          src="../../../public/images/common/df-avatar.svg"
          alt=""
        >
        <label
          for="avatar-in"
          class="btn btn-pri radius-15 px-5"
        >Tải ảnh lên</label>
      </div>
      <!-- * -->
      <div class="mb-3">
        <label
          class="fs-18 fw-500 txt-black"
          for="name"
        >Họ và tên <span class="text-danger">*</span></label>
        <input
          type="text"
          class="form-control txt-pri"
          :class="form.name ? 'bd-pri' : err.name && !form.name ? 'border-danger' : ''"
          v-model="form.name"
        >
      </div>
      <!-- * -->
      <div class="mb-3">
        <label
          class="fs-18 fw-500 txt-black"
          for="bday"
        >Ngày sinh <span class="text-danger">*</span></label>
        <div class="d-flex justify-content-between align-items-center">

          <select
            class="input-date form-select txt-gray-blur"
            v-model="bday.d"
            :class="err.d && !bday.d ? 'border-danger' : ''"
          >
            <option value="">Chọn ngày</option>
            <option
              v-for="n in getDate()"
              :value="n"
              :key="n"
            >{{ n }}</option>
          </select>
          <select
            class="input-date form-select txt-gray-blur"
            v-model="bday.m"
            @change="checkDate()"
            :class="err.m && !bday.m ? 'border-danger' : ''"
          >
            <option value="">Chọn tháng</option>
            <option
              v-for="n in 12"
              :value="n"
              :key="n"
            >Tháng {{ n }}</option>
          </select>
          <select
            class="input-date form-select txt-gray-blur"
            v-model="bday.y"
            @change="checkDate()"
            :class="err.y && !bday.y ? 'border-danger' : ''"
          >
            <option value="">Năm</option>
            <option
              v-for="(n, i) in 120"
              :value="currentYear - i"
              :key="i"
            >{{ currentYear - i }}</option>
          </select>
        </div>
      </div>
      <!-- * -->
      <div class="mb-3 row">
        <div class="col-6">
          <label
            class="fs-18 fw-500 txt-black"
            for="bday"
          >Giới tính <span class="text-danger">*</span></label>

        </div>
        <div class="col-6 d-flex justify-content-around align-items-center">
          <div
            class="d-flex align-items-center c-pointer"
            @click="form.gender = 1"
          >
            <svg
              width="20"
              height="21"
              viewBox="0 0 20 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="me-2"
            >
              <circle
                cx="6.66732"
                cy="13.8333"
                r="5"
                :stroke="form.gender !== 1 ? '#D2DDE8' : '#476DD6'"
                stroke-width="1.66667"
              />
              <path
                d="M18.3333 3C18.3333 2.53976 17.9602 2.16667 17.5 2.16667H10C9.53976 2.16667 9.16667 2.53976 9.16667 3C9.16667 3.46024 9.53976 3.83333 10 3.83333H16.6667V10.5C16.6667 10.9602 17.0398 11.3333 17.5 11.3333C17.9602 11.3333 18.3333 10.9602 18.3333 10.5V3ZM16.9107 2.41074L9.41074 9.91074L10.5893 11.0893L18.0893 3.58926L16.9107 2.41074Z"
                :fill="form.gender !== 1 ? '#D2DDE8' : '#476DD6'"
              />
            </svg>
            <span
              class="fs-16 fw-500"
              :class="form.gender !== 1? 'txt-gray-blur' :'txt-pri'"
            >Nam</span>

          </div>
          <div
            class="d-flex align-items-center c-pointer"
            @click="form.gender = 2"
          >
            <svg
              width="20"
              height="21"
              viewBox="0 0 20 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="me-2"
            >
              <circle
                cx="6.66732"
                cy="13.8333"
                r="5"
                :stroke="form.gender !== 2 ? '#D2DDE8' : '#476DD6'"
                stroke-width="1.66667"
              />
              <path
                d="M18.3333 3C18.3333 2.53976 17.9602 2.16667 17.5 2.16667H10C9.53976 2.16667 9.16667 2.53976 9.16667 3C9.16667 3.46024 9.53976 3.83333 10 3.83333H16.6667V10.5C16.6667 10.9602 17.0398 11.3333 17.5 11.3333C17.9602 11.3333 18.3333 10.9602 18.3333 10.5V3ZM16.9107 2.41074L9.41074 9.91074L10.5893 11.0893L18.0893 3.58926L16.9107 2.41074Z"
                :fill="form.gender !== 2 ? '#D2DDE8' : '#476DD6'"
              />
            </svg>
            <span
              class="fs-16 fw-500"
              :class="form.gender !== 2? 'txt-gray-blur' :'txt-pri'"
            >Nữ</span>
          </div>
        </div>
      </div>
      <!-- * -->
      <div class="mb-3">
        <label
          class="fs-18 fw-500 txt-black"
          for="name"
        >Số CMND/CCCD</label>
        <input
          type="text"
          class="form-control txt-pri"
          :class="form.cmnd ? 'bd-pri' : ''"
          v-model="form.cmnd"
        >
      </div>
      <!-- * -->
      <div class="mb-3">
        <label
          class="fs-18 fw-500 txt-black"
          for="name"
        >Địa chỉ <span class="text-danger">*</span></label>
        <input
          type="text"
          class="form-control txt-pri"
          :class="form.address ? 'bd-pri' : err.address && !form.address ? 'border-danger' : ''"
          v-model="form.address"
        >
      </div>
      <!-- * -->
      <div class="mb-5">
        <label
          class="fs-18 fw-500 txt-black"
          for="name"
        >Nghề nghiệp</label>
        <input
          type="text"
          class="form-control txt-pri"
          :class="form.career ? 'bd-pri' : ''"
          v-model="form.career"
        >
      </div>
      <!-- * -->
      <div>

        <button
          for="avatar-in"
          class="btn btn-pri radius-15 w-75 text-center m-auto"
          @click="createPerson()"
          :disabled="loading"
        >Tạo tài khoản
          <span
            v-if="loading"
            class="spinner-border ms-2"
            role="status"
          >
            <span class="visually-hidden">Loading...</span>
          </span>
        </button>
      </div>
    </div>
    <input
      type="file"
      class="d-none"
      id="avatar-in"
      accept=".jpg, .jpeg, .png, .gif, .svg"
      @change="onSelectedAvatar($event)"
    >
  </div>
</template>

<script>
import appUtils from '../../utils/appUtils'
import moment from 'moment'
export default {
  name: 'CreatePerson',
  data () {
    return {
      form: {
        name: '',
        bday: '',
        gender: 1,
        cmnd: '',
        address: '',
        career: ''
      },
      err: {
        name: '',
        bday: '',
        gender: 1,
        cmnd: '',
        address: '',
        d: '',
        m: '',
        y: ''
      },
      bday: {
        d: '',
        m: '',
        y: ''
      },
      avt_file: '',
      appUtils,
      user: null,
      loading: false
    }
  },
  computed: {
    currentYear () {
      return new Date().getFullYear()
    }
  },
  mounted () {
    const self = this
    self.user = this.$user
    // self.getPersons()
  },
  methods: {
    async getPersons () {
      const self = this
      self.loading = true
      const params = {
        litmit: 100
      }
      await self.$rf.getRequest('AuthRequest').getPersons(params).then((rs) => {
        self.persons = rs.data
      }).finally(() => {
        self.loading = false
      })
    },
    getDate () {
      const self = this
      const m = self.bday.m
      const y = self.bday.y
      if (m) {
        if (m === 1 || m === 3 || m === 5 || m === 7 || m === 8 || m === 10 || m === 12) {
          return 31
        }
        if (m === 4 || m === 6 || m === 9 || m === 11) {
          return 30
        }
        if (y && m === 2) {
          if (y % 4 === 0) {
            return 29
          } else {
            return 28
          }
        }
      } else {
        return 31
      }
    },
    checkDate () {
      const self = this
      if (self.bday.d && self.getDate() < self.bday.d) {
        self.bday.d = ''
      }
    },
    getBase64URL (file) {
      return URL.createObjectURL(file)
    },
    onSelectedAvatar (event) {
      this.avt_file = event.target.files[0]
    },
    validate () {
      const self = this
      if (!self.form.name) {
        self.err.name = true
        return false
      }
      if (!self.bday.d) {
        self.err.d = true
        return false
      }
      if (!self.bday.m) {
        self.err.m = true
        return false
      }
      if (!self.bday.y) {
        self.err.y = true
        return false
      }
      if (!self.form.gender) {
        self.form.gender = 1
      }
      if (!self.form.address) {
        self.err.address = true
        return false
      }
      return true
    },
    async uploadAvatar () {
      const self = this
      if (!self.avt_file) return
      var formData = new FormData()
      formData.append('avatar', self.avt_file)
      await self.$rf.getRequest('AuthRequest').changeAvatarUser(formData).then((resp) => {
        console.log(resp)
      })
    },
    async getInfo () {
      const self = this
      await self.$rf.getRequest('AuthRequest').me().then((rs) => {
        appUtils.setLocalUser(rs.data)
      }).finally(() => {
        self.loading = false
      })
    },
    async createPerson () {
      const self = this
      if (self.validate() && self.user) {
        self.loading = true
        const form = self.form
        const params = {
          name: form.name,
          phone: self.user.username,
          birthday: self.formatDMY(self.bday.m + '/' + self.bday.d + '/' + self.bday.y),
          gender: form.gender,
          cmnd: form.cmnd,
          address: form.address,
          career: form.career
        }
        await self.$rf.getRequest('AuthRequest').createPerson(params).then((resp) => {
          self.getInfo()
          if (self.avt_file) {
            self.uploadAvatar()
          }
          if (resp.data) {
            self.sendAmplitude('Click to save profile ', {
              'Person ID': resp.data.user_id,
              Status: 'Success',
              Timestamp: moment(new Date()).format('HH:mm DD-MM-YYYY'),
              'Person Name': resp.data.name
            })
          }
        }).catch((e) => {
          this.sendAmplitude('Click to save profile ', {
            Status: 'Fail',
            Timestamp: moment(new Date()).format('HH:mm DD-MM-YYYY'),
            Error: e
          })
        }).finally(() => {
          self.loading = false
          setTimeout(() => {
            self.$router.push({ path: '/hssk' })
          }, 100)
        })
      } else {
        alert('Vui lòng nhập đủ các thông tin có dấu *')
      }
    },
    formatDMY (d) {
      return this.moment(d).format('DD/MM/YYYY')
    }
  }
}
</script>

<style scoped>
.create-person {
  width: 100%;
  max-width: 480px;
  padding: 16px;
  margin: 0 auto;
}
.avatar {
  width: 99px;
  height: 99px;
  border-radius: 50px;
}
.btn {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0.10000000149011612px;
  text-align: center;
}
.form-control,
.form-select {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-radius: 0;
}
.input-date {
  width: 135px;
  height: 37px;
}
.t-red {
  color: #de3854;
}
</style>
