import { render, staticRenderFns } from "../../../public/HODORegulation/index.html?vue&type=template&id=3a565ac0&scoped=true&external"
import script from "./Regulation.vue?vue&type=script&lang=js"
export * from "./Regulation.vue?vue&type=script&lang=js"
import style0 from "../../../public/HODORegulation/quyche_files/Site.css?vue&type=style&index=0&id=3a565ac0&prod&lang=css&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a565ac0",
  null
  
)

export default component.exports