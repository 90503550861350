<template>
  <div class="container mx-auto">
    <div class="mt-4 text-center">
      <span class="font-bold text-3xl lg:text-5xl text-blue-900 mx-6 lg:mx-0">Đăng nhập HODO, lưu trữ hồ sơ trọn đời!</span>
    </div>
    <div class="lg:mt-16 m-6 border-8">
      <iframe
        class="w-full h-48 sm:h-96 lg:h-170 border-c"
        src="https://www.youtube.com/embed/jt9QOMPKr-g?autoplay=1"
        title="HoDo"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>

    </div>
    <div class="fw-400 text-1xl lg:text-3xl text-blue-900 lg:mt-16 mx-6 lg:mx-0 lg:space-y-8">
      <p><img
          class="img-fluid c-pointersss mr-2"
          src="../../../public/images/package/medal-star.png"
          alt=""
        ><span class="font-semibold">Tất cả</span> các định dạng CT, MRI, PET/CT</p>
      <p><img
          class="img-fluid c-pointersss mr-2"
          src="../../../public/images/package/medal-star.png"
          alt=""
        >Thuận tiện chia sẻ với<span class="font-semibold"> nhiều Bác sĩ</span></p>
      <p><img
          class="img-fluid c-pointersss mr-2"
          src="../../../public/images/package/medal-star.png"
          alt=""
        >Lưu trữ<span class="font-semibold"> trọn đời</span></p>
    </div>
    <div class="lg:flex lg:justify-center lg:items-center my-6 lg:mt-14 text-pri-900 px-4 pb-5">
      <div class="">
        <div class="text-center">
          <div class="lg:px-0 text-2xl lg:text-5xl mb-4 lg:mb-5 lg:mt-20 font-bold lg:text-left">BẮT ĐẦU NGAY</div>
        </div>
        <div class="flex items-center mb-4">
          <div class="w-16 lg:w-24 mr-5 lg:mr-5 lg:text-2xl font-bold">BƯỚC 1</div>
          <div class="lg:flex lg:justify-center lg:items-center text-center w-4/6">
            <div
              @click="redirectLogin"
              class="bg-pri-900 rounded-full cursor-pointer text-white p-2 font-bold w-full"
            >
              Truy cập HODO
            </div>
          </div>
        </div>
        <div class="flex mb-4">
          <div class="mr-5">
            <div class="w-16 lg:w-24 font-bold lg:text-2xl">BƯỚC 2</div>
          </div>
          <div class="nunitoRegular text-justify w-72 lg:w-full lg:text-2xl text-xl">
            Tải lên file chẩn đoán hình ảnh (PACS)
          </div>
        </div>
        <div class="flex mb-4">
          <div class="mr-5">
            <div class="w-16 lg:w-24 font-bold lg:text-2xl">BƯỚC 3</div>
          </div>
          <div class="nunitoRegular text-justify w-72 lg:w-full lg:text-2xl text-xl">
            Chia sẻ cho bác sĩ
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  name: 'LandingPagePac',
  mounted () {
    // this.sendAmplitude('Click to Video', {
    //   Timestamp: moment(new Date()).format('HH:mm DD-MM-YYYY')
    // })
  },
  methods: {
    redirectLogin () {
      this.sendAmplitude('Click to "Access HODO"', {
        Timestamp: moment(new Date()).format('HH:mm DD-MM-YYYY')
      })
      this.$router.push({ path: '/login' }).catch(() => { })
    }
  }
}
</script>
<style scoped>
.h-600 {
  height: 600px;
}
.border-c {
  border-radius: 10px;
  border: 1px solid lightgray;
}
</style>
