<template>
  <div class="pb-5">

      <div class="px-160 px-300 mb-3 mt-5">
         <div class="row">
        <div class="col-md-6"><p><a class="text-decoration-none" href="/"><i class="arrow left"></i>&nbsp;<span class="fw-500 fs-14 text-decoration-none i-text">Quay lại trang chủ</span></a></p>
          <p class="fw-500 fs-70 text-header">Giới thiệu chương trình thành viên HODO</p></div>
        <div class="col-md-6 row">
          <iframe class="video-header mt-5" src="https://www.youtube.com/embed/ciPXdT__onc?controls=0" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </div>
      </div>

      <div class="card-home mb-4 pt-4">
        <div class="row px-160 px-300">
          <div class="col-md-4 mb-5">
            <div class="card w-100 h-75 mb-3">
              <div class="card-top1"></div>
              <div class="card1 card-body">
                <div class="d-flex justify-content-center">
                  <h5 class="card-title t-box fw-500 fs-24 m-3"><img class="img-fluid c-pointersss" src="../../../public/images/package/sliver.png" alt="">&nbsp;HODO Silver</h5>
                </div>
                <p class="card-text fw-500 fs-18 text-decoration-line-through text-secondary text-center mt-2 opa">op</p>
                <p class="card-text fw-500 fs-48 text-center">FREE</p>
                <p class="card-text fw-400 fs-18 text-center f-btn mb-4">opacity</p>
                <div class="card-btn">
                  <a href="#/login" class="btn btn-primary card-btn-item fw-500 fs-16">Sử dụng ngay!</a>
                </div>
              </div>
            </div>
            <div class="">
              <p class="fw-500 fs-16">An tâm sống khỏe mỗi ngày:</p>
              <p class="fw-400 fs-14 d-flex align-items-center"><img class="img-fluid c-pointersss" src="../../../public/images/package/medal-star.png" alt="">&nbsp;&nbsp;<span>Lưu trữ thông tin Hồ Sơ Sức Khỏe</span></p>
              <p class="fw-400 fs-14 d-flex align-items-center"><img class="img-fluid c-pointersss" src="../../../public/images/package/medal-star.png" alt="">&nbsp;&nbsp;<span>Nhắn tin với Bác sĩ thông qua App</span></p>
              <p class="fw-400 fs-14 d-flex align-items-center"><img class="img-fluid c-pointersss" src="../../../public/images/package/medal-star.png" alt="">&nbsp;&nbsp;<span>10 phút gọi điện với Bác sĩ thông qua App</span></p>
            </div>
          </div>
          <div class="col-md-4 mb-5">
            <div class="card w-100 h-75 mb-3">
              <div class="card-top2 fw-400 fs-18 d-flex justify-content-center align-items-center text-white">Nhiều người lựa chọn</div>
              <div class="card2 card-body">
                 <div class="d-flex justify-content-center">
                  <h5 class="card-title t-box fw-500 fs-24 m-3"><img class="img-fluid c-pointersss" src="../../../public/images/package/sliver-plus.png" alt="">&nbsp;HODO Silver Plus</h5>
                </div>
                <p class="card-text fw-500 fs-18 text-decoration-line-through text-secondary text-center mt-2">92.000 đ</p>
                <p class="card-text fw-500 fs-48 text-center">46.000 đ</p>
                <p class="card-text fw-400 fs-18 text-center mb-4">Kết nối không giới hạn</p>
                <div class="card-btn">
                  <a href="#/login" class="btn btn-primary card-btn-item fw-500 fs-16">Nâng cấp ngay</a>
                </div>
              </div>
            </div>
              <div class="">
              <p class="fw-500 fs-16">Tất cả tính năng của HODO Silver và:</p>
              <p class="fw-400 fs-14 d-flex align-items-center"><img class="img-fluid c-pointersss" src="../../../public/images/package/medal-star.png" alt="">&nbsp;&nbsp;<span>Lưu trữ và đọc tài liệu Chẩn đoán hình ảnh</span></p>
              <p class="fw-400 fs-14 d-flex align-items-center"><img class="img-fluid c-pointersss" src="../../../public/images/package/medal-star.png" alt="">&nbsp;&nbsp;<span>không giới hạn số lượng và thời gian cuộc gọi</span></p>
            </div>
          </div>
          <div class="col-md-4 mb-5">
            <div class="card w-100 h-75 mb-3">
              <div class="card-top3 fw-400 fs-18 d-flex justify-content-center align-items-center text-white">Chăm sóc sức khỏe toàn diện</div>
              <div class="card3 card-body">
                <div class="d-flex justify-content-center">
                  <h5 class="card-title t-box fw-500 fs-24 m-3"><img class="img-fluid c-pointersss" src="../../../public/images/package/gold.png" alt="">&nbsp;HODO Gold</h5>
                </div>
                <p class="card-text fw-500 fs-18 text-decoration-line-through text-secondary text-center mt-2">345.00 đ</p>
                <p class="card-text fw-500 fs-48 text-center">184.000 đ</p>
                <p class="card-text fw-400 fs-18 text-center mb-4">An tâm sống khỏe</p>
                <div class="card-btn">
                  <a href="#/login" class="btn btn-primary card-btn-item fw-500 fs-16">Nâng cấp ngay</a>
                </div>
              </div>
            </div>
              <div class="">
              <p class="fw-500 fs-16">Tất cả tính năng của HODO Silver và:</p>
              <p class="fw-400 fs-14 d-flex align-items-center"><img class="img-fluid c-pointersss" src="../../../public/images/package/medal-star.png" alt="">&nbsp;&nbsp;<span>Kết nối Bác sĩ dinh dưỡng và nhận tư vấn dinh dưỡng</span></p>
              <p class="fw-400 fs-14 d-flex align-items-center"><img class="img-fluid c-pointersss" src="../../../public/images/package/medal-star.png" alt="">&nbsp;&nbsp;<span>Giảm giá 20% dịch vụ tư vấn trực tuyến và các sản phẩm thiết yếu trên HODO</span></p>
            </div>
          </div>
        </div>
      </div>

       <div class="px-160 px-300 mb-5">
          <div class="uudai-box uudai-header">
            <div class="uudai-left mb-3 uudai-text">
              <p class="fw-600 fs-60">Ưu đãi lên <span class="color-476DD6">đến 50%</span></p>
              <p class="fw-500 fs-24 color-5C6277">khi kích hoạt gói thành viên HODO</p>
              <p class="fw-400 fs-18 color-5C6277">Thời gian áp dụng tới 31/10/2022</p>
              <a class="btn btn-primary fw-500 fs-16 tn-btn" href="#/login">Trải nghiệm ngay!</a>
            </div>
            <div class="uudai-right d-flex justify-content-center">
              <img width="550px" class="img-fluid c-pointersss mt-5" src="../../../public/images/package/Group 625898.png" alt="">
            </div>
          </div>
        </div>

        <div class="f-uudai mb-3">
            <div class="text-center">
              <p class="fw-500 fs-24">Ưu đãi đặc biệt:</p>
              <p class="fw-500 fs-24">Tặng ngay USB 32Gb khi mua gói HODO Gold 6 tháng</p>
              <p class="fw-500 fs-32">Tặng USB 32Gb khi mua gói Gold 6 tháng và 12 tháng</p>
              <div class="card-btn">
                     <a class="btn btn-primary fw-500 fs-16 tn2-btn" href="#/login">Trải nghiệm ngay!</a>
              </div>

            </div>
        </div>
  </div>
</template>
<script>
export default {
  name: 'PackageMember'
}
</script>

<style lang="css" scoped>
.opa {
  opacity: 0;
}
.f-btn {
  opacity: 0;
}
.card1 {
  border: 1.5px solid #E3DEDE;
  border-end-end-radius: 8px;
  border-end-start-radius: 8px;
}
.card2 {
  background: #ffffff;
  border: 1.5px solid #476DD6;
  border-end-end-radius: 8px;
  border-end-start-radius: 8px;
}
.card3 {
  background: #ffffff;
  border: 1.5px solid #4DA366;
  border-end-end-radius: 8px;
  border-end-start-radius: 8px;
}
.card-top1 {
  background-color: #E3DEDE;
  height: 34px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border: 1.5px solid #E3DEDE;
}
.card-top2 {
  background-color: #476DD6;
  height: 34px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border: 1.5px solid #476DD6;
}
.card-top3 {
  background-color: #4DA366;
  height: 34px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border: 1.5px solid #4DA366;
}
.fs-70 {
  font-size: 70px;
}
.fs-60 {
  font-size: 60px;
}

@media only screen and (min-width: 1440px) {
  .uudai-header {
    padding-left: 100px;
    display: flex;
    align-items: center;
  }
  .uudai-left {
    width: 60%;
    justify-content: start;
  }
  .uudai-right {
    width: 60%;
  }
  .text-header {
    text-align: left;
    width: 440px;
  }
  .text-header {
    text-align: left;
    width: 440px;
  }
  .uudai-text {
    text-align: left;
  }
  .f-uudai {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (max-width: 1439px) and (min-width: 1024px) {
  .uudai-header {
    padding-left: 64px;
    display: flex;
    align-items: center;
  }
  .uudai-left {
    width: 50%;
    justify-content: start;
  }
  .uudai-right {
    width: 50%;
    float: left;
  }
  .text-header {
    text-align: left;
  }
   .uudai-text {
    text-align: left;
  }
   .f-uudai {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .column {
    width: 30%;
  }
   .px-180 {
    padding-left: 180px;
    padding-right: 180px;
  }
}
@media only screen and (max-width: 1023px) and (min-width: 768px) {
  .uudai-header {
    padding-left: 64px;
    display: flex;
    align-items: center;
  }
  .uudai-left {
    width: 50%;
    justify-content: start;
  }
  .uudai-right {
    width: 50%;
    float: left;
  }
  .text-header {
    text-align: left;
  }
   .uudai-text {
    text-align: left;
  }
   .column {
    width: 190px;
  }
}
@media only screen and (max-width: 767px) {
  .uudai-header {
    padding-left: 10px;
    padding-right: 10px;
  }
  .uudai-left {
    width: 100%;
    justify-content: start;
  }
  .uudai-right {
    width: 100%;;
  }
  .text-header {
    text-align: center;
  }
   .uudai-text {
    text-align: center;
  }
   .column {
    width: 100%;
  }
}
@media only screen and (min-width: 1920px) {

  .px-300 {
    padding-left: 300px;
    padding-right: 300px;
  }
}
.video-header {
  width: 100%;
  height: 372px;
  display: flex;
  margin-left: 10px;
}
.card-home {
  background-color: #f7f7f9;
}
.arrow {
  border: solid #8F90A6;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  border-radius: 1px;
  margin-left: 25px;
}
.left {
  transform: rotate(135deg);
}
.i-text {
  color: #8F90A6;
}
.fs-45 {
  font-size: 45px;
}
.card {
  height: 400px;
  width: 277px;
  animation: 1s ease-out 0s 1 slideInFromLeft;
}
.card-white {
  border: 1.5px solid #E3DEDE;
  border-top: none;
  border-end-end-radius: 8px;
  border-end-start-radius: 8px;
}
.card-blue {
  border: 1.5px solid #476DD6;
  border-top: none;
  border-end-end-radius: 8px;
  border-end-start-radius: 8px;
}
.card-green {
  border: 1.5px solid #4DA366;
  border-top: none;
  border-end-end-radius: 8px;
  border-end-start-radius: 8px;
}
.t-box {
  width: 245px;
  height: 64px;
  background-color: #EAEFF4;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}
.card-btn {
  display: flex;
  justify-content: center;
}
.card-btn-item {
  width: 315.43px;
  height: 50px;
  border-radius: 12px;
  line-height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #476DD6;
}
.btn-p {
  margin-top: 103px;
}
.color-476DD6 {
  color: #476DD6;
}
.uudai-box {
  min-height: 325px;
  background-color: #DFE6FB;
  border-radius: 30px;
}
.color-5C6277 {
  color: #5C6277;
}
.tn-btn {
  width: 228px;
  height: 50px;
  line-height: 35px;
  background-color: #476DD6;
}
.f-uudai {
  background-image: url("../../../public/images/package/uudai.png");
  height: 300px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

}
.t-uudai {
  margin-top: 50px;
}
.tn2-btn {
  width: 801px;
  height: 50px;
  border-radius: 12px;
  background-color: #ffffff;
  color: #476DD6;
  display: flex;
  justify-content: center;
  align-items: center;
}
.fs-32 {
  font-size: 32px;
}

</style>
