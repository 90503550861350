<template>
  <div id="Footer" class="bg-white px-160" :class="layout == 'auth '? 'pt-5' : 'pt-2'">
    <div class="row border-bottom mx-auto" v-if="layout != 'auth'">
      <div class="flex flex-col lg:flex-row mb-6">
        <div class="col-lg-4 mb-3 mb-lg-0">
          <img src="../../../public/logo.svg" width="192" alt />
        </div>
        <div class="col-lg-4 lg:mx-12">
          <div class="d-flex justify-content-start align-items-center mb-4 mb-lg-0">
            <img src="../../../public/icons/phone.svg" alt width="30" class="me-2" />
            <div>
              <p class="fs-16 fw-400 text-black mb-0 font-bold">Hotline</p>
              <p class="fs-16 fw-400 text-gray-500 mb-0">(+84)0345 - 900905</p>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="d-flex justify-content-start align-items-center mb-4 mb-lg-0">
            <img src="../../../public/icons/mail.svg" alt width="30" class="me-2" />
            <div>
              <p class="fs-16 fw-400 text-black mb-0 font-bold">Email</p>
              <p class="fs-16 fw-400 text-gray-500 mb-0">Hello@hodo.com.vn</p>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6 mb-4">
        <div class="row">
          <div class="col-lg-12">
            <div class="row">
              <div class="col-lg-8 text-start mb-4 mb-lg-0">
                <div class="font-bold uppercase mb-3">CÔNG TY CỔ PHẦN CÔNG NGHỆ 905 VIỆT NAM</div>
                <div class="fs-14 text-gray-500 mb-2">
                  <label class="text-black font-bold mr-1">Địa chỉ trụ sở:</label>
                  <span>
                    Tầng 8, Tòa ACCI, 210 Lê Trọng Tấn,
                    <br />P. Phương Mai, Q. Thanh Xuân, TP. Hà Nội
                  </span>
                </div>
                <div class="fs-14 text-gray-500 mb-2">
                  <label class="text-black font-bold mr-1">Số điện thoại:</label>
                  <span>0345 900 905</span>
                </div>
                <div class="fs-14 text-gray-500 mb-2">
                  <label class="text-black font-bold mr-1">Email:</label>
                  <a href="mailto:hello@hodo.com.vn?subject=Hello%20HODO">hello@hodo.com.vn</a>
                </div>
                <div class="fs-14 text-gray-500 mb-2">
                  <label class="text-black font-bold mr-1">Người đại diện của Cty:</label>
                  <span>Trương Trọng Thể</span>
                </div>
                <div class="fs-14 text-gray-500 mb-2">
                  <label class="text-black font-bold mr-1">Mã số doanh nghiệp:</label>
                  <span>0109377692 do Sở Kế hoạch & Đầu tư TP Hà Nội cấp lần đầu ngày 14/10/2020</span>
                </div>
                <div class="flex -mx-3">
                  <div class="px-3">
                    <a href="http://online.gov.vn/Home/AppDetails/2211" target="_blank">
                      <img
                        alt
                        height="60"
                        width="160"
                        src="../../../public/images/common/logoCCDV.png"
                      />
                    </a>
                  </div>
                  <div class="px-3">
                    <a href="http://online.gov.vn/Home/WebDetails/107992" target="_blank">
                      <img
                        alt
                        height="60"
                        width="160"
                        src="../../../public/images/common/logoCCDV.png"
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 text-start flex flex-row">
                <div class="col-5">
                  <p class="fs-16 fw-500 mb-3 txt-black">Menu</p>
                  <p
                    class="fs-14 fw-400 mb-3 text-gray-500 hover:text-black transition-all duration-200 c-pointer"
                    @click="()=>{this.$router.push({path: '/'}).catch(()=>{})}"
                  >{{$t('home')}}</p>
                  <p
                    class="fs-14 fw-400 mb-3 text-gray-500 hover:text-black transition-all duration-200 c-pointer"
                    @click="()=>{this.$router.push({path: '/app-hodo'}).catch(()=>{})}"
                  >{{$t('hodo')}}</p>
                  <p
                    class="fs-14 fw-400 mb-3 text-gray-500 hover:text-black transition-all duration-200 c-pointer"
                    @click="()=>{this.$router.push({path: '/hodo-pro'}).catch(()=>{})}"
                  >{{$t('hodopro')}}</p>
                  <p
                    class="fs-14 fw-400 mb-3 text-gray-500 hover:text-black transition-all duration-200 c-pointer"
                    @click="()=>{this.$router.push({path: '/pricing'}).catch(()=>{})}"
                  >{{$t('pricing')}}</p>
                  <p
                    class="fs-14 fw-400 mb-3 text-gray-500 hover:text-black transition-all duration-200 c-pointer"
                    @click="()=>{this.$router.push({path: '/cam-nang-kien-thuc'}).catch(()=>{})}"
                  >{{$t('handbook')}}</p>
                </div>
                <div class="d-lg-none col-7 text-start">
                  <p class="fs-16 fw-500 mb-3 txt-black">{{$t('other')}}</p>
                  <p
                    class="fs-14 fw-400 mb-3 text-gray-500 hover:text-black transition-all duration-200 c-pointer"
                    @click="()=> {this.$router.push({path: '/dieu-khoan'}).catch(()=> {})}"
                  >{{$t('term')}}</p>
                  <p
                    class="fs-14 fw-400 mb-3 text-gray-500 hover:text-black transition-all duration-200 c-pointer"
                    @click="()=> {this.$router.push({path: '/chinh-sach'}).catch(()=> {})}"
                  >{{$t('privacy')}}</p>
                  <p
                    class="fs-14 fw-400 mb-3 text-gray-500 hover:text-black transition-all duration-200 c-pointer"
                    @click="()=> {this.$router.push({path: '/quy-che-website'}).catch(()=> {})}"
                  >{{$t('regulation_website')}}</p>
                  <p
                    class="fs-14 fw-400 mb-3 text-gray-500 hover:text-black transition-all duration-200 c-pointer"
                    @click="()=> {this.$router.push({path: '/quy-che-ung-dung'}).catch(()=> {})}"
                  >{{$t('regulation')}}</p>
                  <p
                    class="fs-14 fw-400 mb-3 text-gray-500 hover:text-black transition-all duration-200 c-pointer"
                    @click="()=> {this.$router.push({path: '/help'}).catch(()=> {})}"
                  >{{$t('help')}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 mb-4">
        <div class="row">
          <div class="d-none d-lg-block col-6 text-start">
            <p class="fs-16 fw-500 mb-3 txt-black">{{$t('other')}}</p>
            <p
              class="fs-14 fw-400 mb-3 text-gray-500 hover:text-black transition-all duration-200 c-pointer"
              @click="()=> {this.$router.push({path: '/dieu-khoan'}).catch(()=> {})}"
            >{{$t('term')}}</p>
            <p
              class="fs-14 fw-400 mb-3 text-gray-500 hover:text-black transition-all duration-200 c-pointer"
              @click="()=> {this.$router.push({path: '/chinh-sach'}).catch(()=> {})}"
            >{{$t('privacy')}}</p>
            <p
              class="fs-14 fw-400 mb-3 text-gray-500 hover:text-black transition-all duration-200 c-pointer"
              @click="()=> {this.$router.push({path: '/quy-che-website'}).catch(()=> {})}"
            >{{$t('regulation_website')}}</p>
            <p
              class="fs-14 fw-400 mb-3 text-gray-500 hover:text-black transition-all duration-200 c-pointer"
              @click="()=> {this.$router.push({path: '/quy-che-ung-dung'}).catch(()=> {})}"
            >{{$t('regulation')}}</p>
            <p
              class="fs-14 fw-400 mb-3 text-gray-500 hover:text-black transition-all duration-200 c-pointer"
              @click="()=> {this.$router.push({path: '/help'}).catch(()=> {})}"
            >{{$t('help')}}</p>
          </div>
          <div class="col-6 text-start">
            <p class="fs-16 fw-500 mb-3 txt-black">{{$t('download')}} HODO🔥</p>
            <img
              src="../../../public/images/home/appstoredowload.png"
              class="dowload-img c-pointer mb-1 mt-3"
              alt
              @click="goToPStore('i')"
            />
            <br />
            <img
              src="../../../public/images/home/ggdowload.png"
              class="dowload-img c-pointer"
              alt
              @click="goToPStore('a')"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-between">
      <p class="fs-12 txt-black py-3 mb-0">HODO © Copyright | Designed & Developed by HODO</p>
      <a href="https://cystack.net/en/projects/hodo" target="_blank">
        <img src="../../../public/images/common/Secured_by_CyStack.svg" alt width="128" height="48" />
      </a>
    </div>
  </div>
</template>

<script>
import appUtils from '../../utils/appUtils'
export default {
  name: 'Footer',
  data () {
    return {
      lang: 'vi',
      appUtils
    }
  },
  computed: {
    layout () {
      return this.$route.meta.layout
    }
  },
  mounted () {},
  methods: {
    goToPStore (os) {
      if (os === 'a') {
        window.open(process.env.VUE_APP_GOOGLE_PLAY)
      }
      if (os === 'i') {
        window.open(process.env.VUE_APP_APPLE_STORE)
      }
    }
  }
}
</script>

<style lang="css" scoped>
.dowload-img {
  max-width: 135px;
  margin-bottom: 10px;
  display: block;
  width: auto;
  height: auto;
}

@media only screen and (max-width: 760px) {
  #Footer {
    display: none;
  }
}
</style>
