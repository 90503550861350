<template src="../../../public/HODORegulation/index.html"></template>

<script>
export default {

  name: 'Regulation',

  data () {
    return {

    }
  }
}
</script>

<style lang="css" scoped src="../../../public/HODORegulation/quyche_files/Site.css"></style>
