<template>
  <div>
    <b-breadcrumb>
      <b-breadcrumb-item
        class="no-underline"
        :to="item.to"
        :active="
          breadcrumb.length > 1
            ? index === breadcrumb.length - 1
            : index === breadcrumb.length
        "
        v-for="(item, index) in breadcrumb"
        :key="index"
        @click="backToEcommerce"
      >
        <b-icon
          v-if="index === 0"
          class="align-middle mr-1"
          icon="chevron-left"
          scale="1.25"
          shift-v="1.25"
          aria-hidden="true"
        ></b-icon>
        <span class="text-truncate">{{ item.text }}</span>
      </b-breadcrumb-item>
    </b-breadcrumb>
  </div>
</template>

<style lang="scss" scoped>
::v-deep {
  .breadcrumb {
    &-item {
      &.no-underline {
        a {
          text-decoration: none;
          color: #476dd6;
          &:hover {
            color: #20419b;
          }
        }
      }
    }
  }
}
</style>

<script>
export default {
  name: 'BreadcrumbCpn',
  props: {
    breadcrumb: Array
  },
  methods: {
    backToEcommerce () {
      if (this.breadcrumb.length === 1) {
        this.$router.push({ name: 'Ecommerce' })
      }
    }
  }
}
</script>

<style>
</style>
