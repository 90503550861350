<template>
  <div v-if="$user" class="flex h-full">
    <SideBar class="side-bar h-screen sticky top-0" />
    <div class="content">
      <NavbarTop />
      <slot />
    </div>
  </div>
</template>

<script>
import NavbarTop from '../components/Layout/NavbarTop.vue'
import SideBar from '../components/Layout/SideBar.vue'
import appUtils from '../utils/appUtils'
// import Alert from '../components/UserProfile/Alert.vue'
// import {
// watch
// ref
// } from '@vue/composition-api'
import router from '../router'

export default {
  name: 'AuthLayout',
  components: {
    SideBar,
    NavbarTop
    // Alert
  },
  setup () {
    const convertArrayNavigation = value => {
      const arr = []
      let firstLink = null
      value
        .split('/')
        .slice(1)
        .forEach((e, index) => {
          if (index === 0) {
            firstLink = '/' + e
          } else {
            firstLink = firstLink + '/' + e
          }
          const objectLink = {
            name: e,
            link: firstLink
          }
          arr.push(objectLink)
        })
      return arr
    }
    const navigationTitle = router.currentRoute.path
    return {
      navigationTitle,
      convertArrayNavigation
    }
  },
  data () {
    return {
      showAlert: true,
      appUtils
    }
  },
  watch: {
    $route: {
      handler (t) {
        this.navigationTitle = t.path
      }
    }
  },
  mounted () {
    if (!this.$user) {
      return this.$router.push({ path: '/login' })
    }
  }
}
</script>

<style scoped lang="scss">
.main {
  display: flex;
  .content {
    width: 100%;
  }
}
@media only screen and (max-width: 992px) {
  .side-bar {
    display: none;
  }
}
.navigation-link {
  // margin-left: calc(11rem - 7px);
  margin-left: 1rem;
  margin-bottom: 1rem;
}
</style>
