<template>
  <div
    class="modal fade"
    id="modal-regis"
    tabindex="-1"
    aria-labelledby="modalRegisLabel"
    aria-hidden="true"
  >
    <div
      class="modal-dialog modal-dialog-centered"
      v-show="!!slt_serv"
    >
      <div class="modal-content radius-15">
        <div class="text-center p-3">
          <h5
            class="fs-28 fw-500"
            id="modalRegisLabel"
          >{{slt_serv ? slt_serv.name : ''}}</h5>
        </div>
        <div
          class="modal-body text-start px-4"
          v-if="slt_serv"
        >
          <p class="serv-item-clinic mb-0">Đơn giá</p>
          <p class="txt-pri fs-24 fw-500 text-uppercase">{{parseInt(slt_serv.price).format(0, 3, '.')}} {{slt_serv.currency || 'VNĐ'}}</p>
          <div>
            <label for="sv-name" class="fs-18 fw-500 mb-1 txt-black">Họ và tên <span class="text-danger">*</span></label>
            <input
              type="text"
              class="form-control fs-18 bg-light border-0 py-2 mb-3"
              v-model="form_regis.name"
              placeholder="Nhập họ và tên của bạn"
              aria-label="Nhập họ và tên của bạn"
              id="sv-name"
            >
            <label for="sv-phone" class="fs-18 fw-500 mb-1 txt-black">Nhập số điện thoại <span class="text-danger">*</span></label>
            <div
              class="input-group radius-5"
              :class="form_regis.phone && !validatePhone(form_regis.phone) ? 'border-danger border mb-1' : 'mb-3'"
            >
              <span
                class="input-group-text bg-light fw-500 border-0 py-2"
                id="basic-addon1"
              >+84</span>
              <input
                type="text"
                class="form-control fs-18 bg-light border-top-0 border-bottom-0 border-end-0 py-2"
                placeholder="Nhập số điện thoại"
                aria-label="Nhập số điện thoại"
                aria-describedby="basic-addon1"
                id="sv-phone"
                v-model="form_regis.phone"
              >
            </div>
            <p
              class="mb-3 fs-12 text-danger"
              v-if="form_regis.phone && !validatePhone(form_regis.phone)"
            >Vui lòng nhập số điện thoại đúng định dạng. VD: 0912345678</p>

            <label class="fs-18 fw-500 mb-1 txt-black">Lời nhắn</label>
            <textarea
              v-model="form_regis.description"
              cols="30"
              rows="3"
              class="form-control fs-18 mb-3 border-0 bg-light"
              placeholder="Lời nhắn của bạn"
            ></textarea>
            <p class="mb-3 text-center fs-18 txt-gray-blur">HODO sẽ liên hệ lại với bạn</p>

          </div>
        </div>
        <div class="p-3">
          <div class="row">
            <div class="col-6">

              <button
                type="button"
                class="btn btn-sec txt-pri w-100 radius-10 py-2"
                data-bs-dismiss="modal"
              >Hủy</button>
            </div>
            <div class="col-6">
              <button
                type="button"
                class="btn btn-pri w-100 radius-10 py-2"
                :disabled="!validatePhone(form_regis.phone) || !validateName(form_regis.name)"
                @click="onSubmitRegis()"
              >Gửi</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModaUnauthBookServices',
  props: ['slt_serv'],
  data () {
    return {
      form_regis: {
        phone: '',
        name: '',
        description: ''
      }
    }
  },
  methods: {
    onSubmitRegis () {
      const self = this
      const form = self.form_regis
      if (!self.validatePhone(form.phone) || !self.validateName(form.name) || !self.slt_serv) return
      const params = {
        service_id: self.slt_serv.id,
        price: self.slt_serv.price,
        discount: 0,
        name: form.name,
        phone: form.phone,
        description: form.description,
        currency: self.slt_serv.currency
      }
      self.submitRegis(params)
      setTimeout(() => {
        self.form_regis = {
          phone: '',
          name: '',
          description: ''
        }
        self.$emit('submit')
      }, 50)
    },
    async submitRegis (params) {
      const self = this
      await self.$rf.getRequest('GuestRequest').guestBooking(params).then((resp) => {
        if (resp) {
          window.$toast.open({
            message: 'Gửi yêu cầu thành công! HODO sẽ liên hệ lại với bạn.',
            type: 'success'
          })
          self.modalRegis(false)
        }
      })
    },
    modalRegis (show) {
      window.$('#modal-regis').modal(show ? 'show' : 'hide')
    },
    validatePhone (phone) {
      return phone.match('^[0-9]+$')
    },
    validateName (name) {
      return !!name && typeof name === 'string'
    }
  }
}
</script>

<style lang="css" scoped>
</style>
