<template>
  <div class="serv-detail">
    <div v-if="!loading && serv">
      <div class="bg-white pt-5 px-160">
        <a
          href="javascript:;"
          class="fs-21 fw-500 txt-gray-blur text-decoration-none d-flex align-items-center mb-1"
          @click="goToList()"
        ><img
            src="../../../public/icons/lessthan-blur-gray.svg"
            width="24"
            alt=""
          > Quay lại trang dịch vụ</a>
        <div class="row pb-3">
          <div class="col-md-6 mb-3">
            <div class="ratio ratio-4x3">

              <img
                v-if="serv.banner_web"
                :src="getDocumentURL(serv.banner_web)"
                class="img-detail"
                alt="..."
              >
              <img
                v-if="!serv.banner_web"
                src="../../../public/images/handbook/sample-img.svg"
                class="img-detail"
                alt="..."
              >
            </div>
          </div>
          <div class="col-md-6 detail-overview">
            <div class="text-start mb-5">

              <p class="fs-18 txt-gray-blur mb-2">{{serv.clinic ? serv.clinic.name : ''}}</p>
              <h5 class="detail-title mb-4">{{serv.name || ''}}</h5>
              <p class="txt-pri fs-36 fw-700 text-uppercase mb-5">
                <span v-if="serv.price">{{parseInt(serv.price).format(0, 3, '.')}} {{serv.currency || 'VNĐ'}}</span>
                <span v-if="!serv.price">Liên hệ HODO</span>
              </p>
            </div>
            <button
              class="show-select px-4 mb-3"
              @click="modalRegis(true)"
            >Đặt dịch vụ</button>
          </div>
        </div>
      </div>
      <div
        class="px-160 pt-3"
        v-if="serv"
      >
        <p class="title-serv">Thông tin dịch vụ</p>
        <p
          class="txt-content-serv"
          style="white-space: pre-wrap;"
        >
          {{serv.desc_full}}
        </p>
      </div>
    </div>
    <div
      v-if="loading"
      class="loading"
    >
      <div
        class="spinner-border ms-2"
        role="status"
      >
        <span class="visually-hidden">Đang tải...</span>
      </div>
    </div>
    <ModaUnauthBookServices :slt_serv="serv" @submit="() => {slt_serv = null}"/>

  </div>
</template>

<script>
import appUtils from '../../utils/appUtils'
import ModaUnauthBookServices from '../../components/Modals/ModaUnauthBookServices'
export default {
  name: 'ServiceDetail',
  components: { ModaUnauthBookServices },
  data () {
    return {
      serv: null,
      loading: false
    }
  },
  mounted () {
    const self = this
    if (self.$route.params.id) {
      self.getServiceDetail(self.$route.params.id)
    } else {
      this.goToList()
    }
  },
  methods: {
    goToList () {
      this.$router.push({ path: '/dich-vu' })
    },
    async getServiceDetail (id) {
      const self = this
      self.loading = true
      await self.$rf.getRequest('GuestRequest').getServiceDetail(id).then((resp) => {
        self.serv = resp?.data
      }).catch((e) => {
        console.log(e)
        self.goToList()
      }).finally(() => {
        self.loading = false
      })
    },
    getDocumentURL (path) {
      return appUtils.getDocumentURL(path)
    },
    modalRegis (show) {
      window.$('#modal-regis').modal(show ? 'show' : 'hide')
    }
  }
}
</script>

<style lang="css" scoped>
.serv-detail {
  background-color: #f7f7f9;
  padding-bottom: 50px;
}
.loading {
  display: flex;
  width: 100%;
  height: 500px;
  justify-content: center;
  align-items: center;
}
.detail-overview {
  min-height: 230px;
  position: relative;
}
.show-select:hover {
  background-color: #517dff;
}
.img-detail {
  max-width: 100%;
  max-height: 100%;
}
.show-select {
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #476dd6;
  color: white;
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0.10000000149011612px;
  text-align: center;
  border-radius: 12px;
  height: 52px;
  border: solid 1px white !important;
}
.txt-content-serv {
  white-space: pre-wrap;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.5px;
  text-align: left;
}
.title-serv {
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 76px;
  letter-spacing: -0.5px;
  text-align: left;
}
@media only screen and (min-width: 1440px) {
  .detail-title {
    font-size: 45px;
    font-style: normal;
    font-weight: 500;
    line-height: 52px;
    letter-spacing: 0em;
    text-align: left;
  }
  .title-serv {
    font-size: 36px;
  }
}
@media only screen and (max-width: 1439px) and (min-width: 1024px) {
  .detail-title {
    font-size: 45px;
    font-style: normal;
    font-weight: 500;
    line-height: 52px;
    letter-spacing: 0em;
    text-align: left;
  }
  .title-serv {
    font-size: 36px;
  }
}
@media only screen and (max-width: 1023px) {
  .detail-clinic {
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 51px;
    letter-spacing: 0em;
    text-align: left;
  }
  .show-select {
    width: 100%;
  }
}
</style>
