<template>
  <div v-if="defaultWs.name">
    <div class="bg-gray-200" v-if="Object.keys(userData).length > 0">
      <div class="container">
        <div class="flex justify-end">
          <div class="py-1 text-sm">
            <span>Xin chào, {{ appUtils.hidePhoneNumber(userData.username, 4, 2, '****') }}</span>
            <span class="mx-1">-</span>
            <a
              href="javascript:void();"
              class="text-pri-900 hover:text-blue-600 transition-all duration-200"
              @click.stop="appUtils.onLogout()"
            >Thoát</a>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <b-row align-content="between">
        <b-col md="2">
          <div class="py-2 text-center text-md-start">
            <router-link
              :to="{
            name: 'ExaminationRegister'
          }"
              class="inline-block"
            >
              <img
                class="w-auto"
                :src="formatterLogo"
                :alt="defaultWs.name"
                :style="{maxHeight: '88px'}"
                @error="(e) => appUtils.onHandleErrorLoadImage(e)"
              />
            </router-link>
          </div>
        </b-col>
        <b-col md="10" align-self="center" class="d-none d-md-block">
          <b-row
            class="justify-content-end"
            v-if="defaultWs.ws_public && defaultWs.ws_public.sub_org.length > 0"
          >
            <!-- <b-col md="4" xl="3">
            <div class="fs-14 txt-pri-900 font-bold text-lg uppercase">{{ defaultWs.name }}</div>
            <div class="text-sm">
              <i class="el-icon-location txt-pri-900" />
              <span class="ml-1">{{ defaultWs.address }}</span>
            </div>
            </b-col>-->
            <b-col md="4" xl="3" v-for="(item, index) in defaultWs.ws_public.sub_org" :key="index">
              <div class="fs-14 txt-pri-900 font-bold text-lg uppercase">{{ item.name }}</div>
              <div class="text-sm">
                <i class="el-icon-location txt-pri-900" />
                <span class="ml-1">{{ item.address }}</span>
              </div>
            </b-col>
          </b-row>
          <div v-else-if="defaultWs.name" class="flex">
            <div class="ml-auto text-end">
              <div class="h3 uppercase txt-pri-900 mb-0 d-none d-md-block">
                <span class="font-bold">{{ defaultWs.name }}</span>
              </div>
              <div
                class="text-xl uppercase"
              >{{ defaultWs.workspace_info && defaultWs.workspace_info.name }}</div>
              <div class="uppercase">
                <i class="el-icon-location txt-pri-900" />
                <span>{{ defaultWs.workspace_info && defaultWs.workspace_info.address }}</span>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import appUtils from '../../utils/appUtils'

export default {
  name: 'MedicalRegisterHeader',
  data () {
    return {
      appUtils,
      userData: this.$user || {}
    }
  },
  computed: {
    ...mapGetters('registerMedical', ['defaultWs']),
    formatterLogo () {
      const img = appUtils.getDocumentURL(this.defaultWs?.logo)
      return img
    }
  },
  watch: {
    $user: {
      handler (data) {
        this.userData = { ...data }
      }
    }
  }
}
</script>

<style>
</style>
