<template>
  <div class="wrp">
    <p class="fs-24 fw-600 txt-black ml-3">Circle</p>

    <div>
      <div class="p-3" v-if="(pendings.data && pendings.page.total)">
        <div
          class="d-flex align-items-center cursor-pointer mb-2"
          @click="is_pending = !is_pending"
        >
          <div v-if="pendings.data && pendings.page.total">
            <p class="fs-16 fw-600 mb-0">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                v-show="is_pending"
              >
                <path d="M7 9.5L12 14.5L17 9.5H7Z" fill="#1D2939" />
              </svg>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                v-show="!is_pending"
              >
                <path d="M9.5 17L14.5 12L9.5 7L9.5 17Z" fill="#1D2939" />
              </svg>
              {{ $t("circle_feature.lbl_invitation") }} ({{
              (pendings.data && pendings.page.total) || 0
              }})
            </p>
          </div>
        </div>
        <div class="row" v-show="is_pending && !loading_pending">
          <div
            class="col-md-4 col-xl-3 mb-2"
            v-for="circle in pendings.data"
            :key="circle.id + 'pending'"
          >
            <CircleListPendingItem :circle="circle" :loading="loading" :type="'pending'" />
          </div>
          <div class="col-12 text-center" v-if="pendings.data && pendings.page.data">
            <a
              href="javascript:;"
              class="txt-pri robo-16-400"
              v-if="pendings.page.page_num < pendings.page.total_page"
              @click="getMyCircles(1, true)"
            >{{ $t("circle_feature.btn_load_more") }}</a>
          </div>
        </div>
        <div class v-if="loading_pending">
          <div class="skeleton-line" v-for="i in 15" :key="i + 'loading'"></div>
        </div>
      </div>
      <!-- chờ duyệt -->
      <div class="p-3" v-if="(waitings.data && waitings.page.total)">
        <div
          class="d-flex align-items-center cursor-pointer mb-2"
          @click="is_waiting = !is_waiting"
        >
          <div v-if="waitings.data && waitings.page.total">
            <p class="fs-16 fw-600 mb-0">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                v-show="is_waiting"
              >
                <path d="M7 9.5L12 14.5L17 9.5H7Z" fill="#1D2939" />
              </svg>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                v-show="!is_waiting"
              >
                <path d="M9.5 17L14.5 12L9.5 7L9.5 17Z" fill="#1D2939" />
              </svg>
              {{ $t("circle_feature.lbl_approval_wait") }} ({{
              (waitings.data && waitings.page.total) || 0
              }})
            </p>
          </div>
        </div>
        <div class="row" v-show="is_waiting && !loading_waiting">
          <div
            class="col-md-4 col-xl-3 mb-2"
            v-for="circle in waitings.data"
            :key="circle.id + 'waiting'"
            @click="goToCircle(circle, 0)"
          >
            <CircleListPendingItem :circle="circle" :loading="loading" :type="'waiting'" />
          </div>
          <div class="col-12 text-center" v-if="waitings && waitings.data">
            <a
              href="javascript:;"
              class="txt-pri fs-16"
              v-if="waitings.page.page_num < waitings.page.total_page"
              @click="getMyCircles(0, true)"
            >{{ $t("circle_feature.btn_load_more") }}</a>
          </div>
        </div>
        <div class v-if="loading_waiting">
          <div class="skeleton-line" v-for="i in 15" :key="i + 'loading'"></div>
        </div>
      </div>
      <!-- của tôi -->
      <div class="p-3">
        <div class="d-flex align-items-center cursor-pointer mb-2" @click="is_owner = !is_owner">
          <div v-if="accepteds.data && accepteds.page.total">
            <p class="fs-16 fw-600 mb-0">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                v-show="is_owner"
              >
                <path d="M7 9.5L12 14.5L17 9.5H7Z" fill="#1D2939" />
              </svg>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                v-show="!is_owner"
              >
                <path d="M9.5 17L14.5 12L9.5 7L9.5 17Z" fill="#1D2939" />
              </svg>
              {{ $t("circle_feature.lbl_my_circle") }} ({{
              (accepteds.data && accepteds.page.total) || 0
              }})
            </p>
          </div>
        </div>
        <div class="row" v-show="is_owner && !loading_accepted">
          <div class="col-md-4 col-xl-3 mb-32">
            <div
              class="item-w h-196px d-flex justify-content-center align-items-center cursor-pointer"
              @click="showAdd(true)"
            >
              <div class="d-flex flex-col items-center">
                <svg
                  width="80"
                  height="80"
                  viewBox="0 0 80 80"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.1"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M40 80C62.0914 80 80 62.0914 80 40C80 17.9086 62.0914 0 40 0C17.9086 0 0 17.9086 0 40C0 62.0914 17.9086 80 40 80Z"
                    fill="#20419B"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M41.6 38.3996H52.8C53.6836 38.3996 54.4 39.116 54.4 39.9996C54.4 40.8833 53.6836 41.5996 52.8 41.5996H41.6V52.7996C41.6 53.6833 40.8836 54.3996 40 54.3996C39.1163 54.3996 38.4 53.6833 38.4 52.7996V41.5996H27.2C26.3163 41.5996 25.6 40.8833 25.6 39.9996C25.6 39.116 26.3163 38.3996 27.2 38.3996H38.4V27.1996C38.4 26.316 39.1163 25.5996 40 25.5996C40.8836 25.5996 41.6 26.316 41.6 27.1996V38.3996Z"
                    fill="#20419B"
                  />
                </svg>

                <p
                  class="mb-0 text-center mt-2 txt-black fw-600 fs-18"
                >{{ $t("circle_feature.lbl_join_circle") }}</p>
              </div>
            </div>
          </div>
          <div
            class="col-md-4 col-xl-3 mb-32"
            v-for="circle in accepteds.data"
            :key="circle.id + 'accepted'"
            @click="goToCircle(circle, 2)"
          >
            <CircleListItem :circle="circle" />
          </div>
          <div class="col-12 text-center" v-if="accepteds.data && accepteds.page.total">
            <a
              href="javascript:;"
              class="txt-pri fs-16"
              v-if="accepteds.page.page_num < accepteds.page.total_page"
              @click="getMyCircles(2, true)"
            >{{ $t("circle_feature.btn_load_more") }}</a>
          </div>
        </div>

        <div class v-if="loading_accepted">
          <div class="skeleton-line" v-for="i in 15" :key="i + 'loading'"></div>
        </div>
      </div>
    </div>
    <div class v-if="loading">
      <div class="skeleton-line" v-for="i in 15" :key="i + 'loading'"></div>
    </div>
    <AddCircle @refresh="getMyCircles(0)"></AddCircle>
  </div>
</template>

<script>
// import appUtils from '../../utils/appUtils'
import CircleListPendingItem from '../../components/Circles/CircleListPendingItem.vue'
import CircleListItem from '../../components/Circles/CircleListItem.vue'
import AddCircle from '../../components/Circles/AddCircle.vue'

export default {
  name: 'CircleList',
  components: { CircleListPendingItem, CircleListItem, AddCircle },
  data () {
    return {
      is_pending: true,
      is_waiting: true,
      is_owner: true,
      pendings: {
        data: [],
        page: {
          page_num: 0,
          page_size: 10,
          total: 0,
          total_page: 10
        }
      },
      waitings: {
        data: [],
        page: {
          page_num: 0,
          page_size: 10,
          total: 0,
          total_page: 10
        }
      },
      accepteds: {
        data: [],
        page: {
          page_num: 0,
          page_size: 10,
          total: 0,
          total_page: 10
        }
      },
      loading: false,
      loading_accepted: false,
      loading_pending: false,
      loading_waiting: false,
      current_page_accepted: 1
    }
  },
  mounted () {
    this.prepare()
  },
  watch: {},
  methods: {
    showAdd (show) {
      window.$('#modalAddCircle').modal(show ? 'show' : 'hide')
    },
    async prepare () {
      try {
        this.loading = true
        await this.getMyCircles(1)
        await this.getMyCircles(0)
        await this.getMyCircles(2)
      } catch (error) {
        console.log(error)
      }
    },
    async getMyCircles (status, isNext = false) {
      const self = this
      const params = {
        status: status,
        page_num: isNext
          ? status === 1
            ? self.pendings.page.page_num + 1
            : status === 0
              ? self.waitings?.page.page_num + 1
              : status === 2
                ? self.accepteds?.page.page_num + 1
                : 1
          : 1,
        page_size: 10
      }
      self.loadingHandler(status, true)
      await this.$rf
        .getRequest('AuthRequest')
        .getMyCircles(params)
        .then(r => {
          if (status === 1) {
            self.pendings.page.page_num = r.data.page.page_num
            if (!isNext) {
              self.pendings = r.data
            } else {
              const old = self.pendings.data
              const newObj = r.data.data
              self.pendings.data = old.concat(newObj)
            }
          } else if (status === 2) {
            self.accepteds.page.page_num = r.data.page.page_num
            if (!isNext) {
              self.accepteds = r.data
            } else {
              const old = self.accepteds.data
              const newObj = r.data.data
              self.accepteds.data = old.concat(newObj)
            }

            this.current_page_accepted += 1
          } else {
            self.waitings.page.page_num = r.data.page.page_num
            if (!isNext) {
              self.waitings = r.data
            } else {
              const old = self.waitings.data
              const newObj = r.data.data
              self.waitings.data = old.concat(newObj)
            }
          }
        })
        .finally(() => {
          self.loadingHandler(status, false)
        })
    },
    goToCircle (circle, type) {
      if (!circle?.id) return
      if (type === 0) {
        this.$toast.open({
          message: this.$t('Circle này đang đợi phê duyệt tham gia'),
          type: 'warning'
        })
        return
      }
      this.$router.push({ name: 'CircleDetail', params: { id: circle?.id } })
    },
    loadingHandler (type, status) {
      if (status) {
        this.loading_accepted = type === 2
        this.loading_pending = type === 1
        this.loading_waiting = type === 0
      } else {
        this.loading_accepted =
          type === 2 && this.loading_accepted ? false : this.loading_accepted
        this.loading_pending =
          type === 1 && this.loading_pending ? false : this.loading_pending
        this.loading_waiting =
          type === 0 && this.loading_waiting ? false : this.loading_waiting
      }
    }
  }
}
</script>

<style scoped>
.mb-16 {
  margin-bottom: 16px;
}
.mb-24 {
  margin-bottom: 24px;
}
.mb-32 {
  margin-bottom: 32px;
}
.mb-40 {
  margin-bottom: 40px;
}
.b-empty {
  width: 50%;
  max-width: 217px;
}
.empty-w {
  width: 100%;
  max-width: 455px;
}
.wrp {
  padding: 16px 64px 16px 64px;
  color: black;
  background-color: #f7f6f6;
  height: calc(100% - 60px);
  /* overflow-x: hidden;
  overflow-y: auto; */
}
.item-w {
  padding: 1.3rem;
  background-color: #ffffff;
  border: 1px solid #edeae9;
  border-radius: 16px;
  cursor: pointer;
}
.h-40px {
  height: 40px;
}
.h-196px {
  height: 196px;
}
.p-18 {
  padding: 12px 16px 12px 16px;
}
</style>
<style scoped lang="scss">
.txt-pri {
  color: #20419b !important;
}
.bg-pri {
  background-color: #20419b !important;
}
</style>
