import Vue from 'vue'
// import appUtils from './appUtils'

const downloadS3 = {
  async downloadFilePac (Key) {
    if (!Key) return
    const filename = Key.split('/').pop()
    const params = {
      fileName: Key
    }
    const header = {
      'x-api-key': '4be5160e89e681cede9a80ae6db6af7c'
    }
    Vue.prototype.$singleLoading = {
      show: true,
      value: 0,
      name: filename,
      title: 'Đang tải xuống'
    }
    await Vue.prototype.$rf.getRequest('AuthRequest').getLinkDownloadAws(params, header, false).then((r) => {
      if (r?.data?.data) this.download(r.data.data, filename)
    }).catch((e) => {
      Vue.prototype.$toast.open({
        message: 'File không tồn tại hoặc gặp lỗi trong quá trình tải xuống',
        type: 'warning'
      })
      console.error(e)
    })
  },
  async downloadFileRC (Key) {
    if (!Key) return
    const filename = Key.split('/').pop()
    const params = {
      fileName: Key
    }
    const header = {
      'x-api-key': '4be5160e89e681cede9a80ae6db6af7c'
    }
    Vue.prototype.$singleLoading = {
      show: true,
      value: 0,
      name: filename,
      title: 'Đang tải xuống'
    }
    await Vue.prototype.$rf.getRequest('AuthRequest').getLinkDownloadAwsRc(params, header, false).then((r) => {
      if (r?.data?.data) this.download(r.data.data, filename)
    }).catch((e) => {
      Vue.prototype.$toast.open({
        message: 'File không tồn tại hoặc gặp lỗi trong quá trình tải xuống',
        type: 'warning'
      })
      console.error(e)
    })
  },
  async download (url, filename) {
    const response = await fetch(url)

    const reader = response.body.getReader()

    // get total length
    const contentLength = +response.headers.get('Content-Length')

    // read the data
    let receivedLength = 0 // received that many bytes at the moment
    const chunks = [] // array of received binary chunks (comprises the body)
    while (true) {
      const { done, value } = await reader.read()

      if (done) {
        setTimeout(() => {
          Vue.prototype.$singleLoading = {
            show: false,
            value: 0,
            name: '',
            title: ''
          }
        }, 500)
        break
      }

      chunks.push(value)
      receivedLength += value.length

      // console.log(`Received ${receivedLength} of ${contentLength}`)
      var uploaded = Math.round(receivedLength / contentLength * 100)
      // console.log(`Received: ${uploaded}%`)
      Vue.prototype.$singleLoading.value = uploaded
    }
    const blob = new Blob(chunks)
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    link.download = filename
    link.click()
  }
}
export default downloadS3
