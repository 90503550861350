import HIDBaseRequest from '../HIDBaseRequest'

export default class HIDRequest extends HIDBaseRequest {
  checkCode (params) {
    return this.post('go/comm/get-voucher-code', params)
  }

  getListVoucher (params) {
    return this.post('go/comm/my-voucher-envelop', params)
  }

  getVoucherCode (params) {
    return this.post('go/comm/get-voucher-code', params)
  }
}
