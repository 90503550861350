<template>
  <div
    class="modal fade"
    id="ModalConfirmSchedule"
    tabindex="-1"
    aria-labelledby="ModalConfirmSchedule"
    aria-hidden="true"
  >
    <div class="modal-dialog-centered modal-dialog">
      <div class="modal-content radius-15 p-4">
        <h5>Khám sức khoẻ định kì 2024</h5>
        <div class="flex gap-3 mt-4">
          <div class="grid-item flex flex-col gap-2 items-center justify-center" style="flex: 3">
            <h5 class="font-semibold">Lịch khám</h5>
            <el-tag class="fs-18 tag-day text-black font-medium">31 tháng 3, 2024</el-tag>
            <span>14:00 - 14:30</span>
            <div class="btn-confirm w-full mt-2">Xác nhận có mặt</div>
          </div>
          <div class="grid-item" style="flex: 9">
            <div class="flex flex-col gap-2">
              <div class="flex items-center gap-2">
                <img src="/icons/icon-schedule.svg" alt class="flex 1" />
                <span style="flex: 3">Mã lịch hẹn</span>
                <span style="flex: 8">A1709538308199</span>
              </div>
              <div class="flex items-center gap-2">
                <img src="/icons/icon-clinic.svg" alt class="flex 1" />
                <span style="flex: 3">Phòng khám</span>
                <span style="flex: 8">Phòng Khám Đa Khoa Thu Cúc - TCI</span>
              </div>
              <div class="flex items-center gap-2">
                <img src="/icons/icon-type.svg" alt class="flex 1" />
                <span style="flex: 3">Hình thức</span>
                <span style="flex: 8">Khám trực tiếp</span>
              </div>
              <div class="flex items-center gap-2">
                <img src="/icons/icon-price.svg" alt class="flex 1" />
                <span style="flex: 3">Giá</span>
                <span style="flex: 8">200,000 VND</span>
              </div>
            </div>
            <hr />
            <div class="flex flex-col gap-2">
              <div class="flex items-center gap-2">
                <img src="/icons/icon-person.svg" alt class="flex 1" />
                <span style="flex: 3">Bệnh nhân</span>
                <span style="flex: 8">LÊ VĂN MINH TRIẾT - 38 TUỔI</span>
              </div>
              <div class="flex items-center gap-2">
                <img src="/icons/icon-doctor.svg" alt class="flex 1" />
                <span style="flex: 3">Bác sĩ</span>
                <span style="flex: 8">NGUYỄN CAO HÙNG</span>
              </div>
            </div>
          </div>
        </div>
        <div class="flex justify-end mt-4">
          <div class="btn-print" @click="closeModal">
            <span>Đóng</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalConfirmSchedule',
  data () {
    return {}
  },
  methods: {
    closeModal () {
      this.$emit('submit', 'closeModal')
      this.showModalConfirmSchedule(false)
    },
    // continue () {
    //   this.$emit('submit', 'continue')
    //   this.showModalConfirmSchedule(false)
    // },
    showModalConfirmSchedule (show) {
      window.$('#ModalConfirmSchedule').modal(show ? 'show' : 'hide')
    },
    goToBack () {
      this.showModalConfirmSchedule(false)
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-dialog {
  // width: 100%;
  max-width: 1000px;
  // margin: 0;
  .modal-content {
    // height: 100vh;
  }
}
.tag-day {
  padding: 8px 16px;
  height: fit-content;
  border-radius: 20px;
}
.grid-item {
  border: 1px solid #a6b3d7;
  border-radius: 10px;
  padding: 20px 16px;
}
.btn-confirm {
  background: #20419b;
  padding: 12px 16px;
  border-radius: 4px;
  line-height: 20px;
  color: #fff;
  display: flex;
  justify-content: center;
  cursor: pointer;
  &:hover {
    opacity: 0.9;
  }
}
.btn-print {
  width: fit-content;
  border: 1px solid #20419b;
  border-radius: 6px;
  padding: 8px 40px;
  font-weight: 500;
  color: #20419b;
  &:hover {
    cursor: pointer;
    background: #ecf5ff;
  }
}
</style>
