import i18n from '@/lang'

export const NODE_ENV = process.env.NODE_ENV
export const isDev = NODE_ENV === 'development'

export const PATIENT_VISIT = [
  {
    label: 'Chờ khám',
    value: 1
  },
  {
    label: 'Đang khám',
    value: 2
  },
  {
    label: 'Chờ kết quả',
    value: 3
  },
  {
    label: 'Chờ đọc kết quả',
    value: 4
  },
  {
    label: 'Xong',
    value: 5
  },
  {
    label: 'Hủy',
    value: 6
  }
]

export const ORDERS_STATUS = {
  RECEIVE_ORDERS: 1,
  PROCESSING: 2,
  // SENDING: 3,
  FINISHED: 3,
  CANCELED: 4,
  WAITING_CONFIRM: 6,
  ERROR_PAYMENT: 7
}

export const ORDER_FORM_CONFIG_STATUS = {
  PENDING: 1,
  ACCEPT: 2,
  REJECT: 3
}

export const PRODUCT_TYPE = {
  PRODUCT: 1,
  SERVICE: 2
}

export const VOUCHER_MESSAGES = {
  SUCCESS: 'Áp dụng mã giảm giá thành công',
  EXPIRED: 'Mã ưu đãi hết hiệu lực sử dụng. Vui lòng kiểm tra lại.',
  USED: 'Mã ưu đãi đã được sử dụng. Vui lòng kiểm tra lại.',
  NOT_EXIST: 'Mã ưu đãi không tồn tại/ không áp dụng cho đối tác hiện tại.'
}

export const EXAMINATION_STATUS = {
  CODE: {
    WAITING: 1,
    BEING: 2,
    WAITING_RESULT: 3,
    WAITING_READ_RESULT: 4,
    DONE: 5,
    CANCELED: 6,
    NEXT_CALL: 7,
    MISSING: 8
  },
  NAME: {
    WAITING: 'WAITING',
    BEING: 'BEING',
    WAITING_RESULT: 'WAITING_RESULT',
    WAITING_READ_RESULT: 'WAITING_READ_RESULT',
    DONE: 'DONE',
    CANCELED: 'CANCELED',
    NEXT_CALL: 'NEXT_CALL',
    MISSING: 'MISSING'
  },
  ID: {
    1: 'WAITING',
    2: 'BEING',
    3: 'WAITING_RESULT',
    4: 'WAITING_READ_RESULT',
    5: 'DONE',
    6: 'CANCELED',
    7: 'NEXT_CALL',
    8: 'MISSING'
  }
}

export const APPOINTMENT_STATUS = {
  CODE: {
    CLINIC_PENDING: 1,
    CLINIC_CONFIRMED: 2,
    CLINIC_REJECTED: 3,
    PATIENT_PENDING: 4,
    PATIENT_ACCEPTED: 5,
    PATIENT_CANCELLED: 6
  },
  NAME: {
    CLINIC_PENDING: 'CLINIC_PENDING',
    CLINIC_CONFIRMED: 'CLINIC_CONFIRMED',
    CLINIC_REJECTED: 'CLINIC_REJECTED',
    PATIENT_PENDING: 'PATIENT_PENDING',
    PATIENT_ACCEPTED: 'PATIENT_ACCEPTED',
    PATIENT_CANCELLED: 'PATIENT_CANCELLED'
  },
  ID: {
    1: 'CLINIC_PENDING',
    2: 'CLINIC_CONFIRMED',
    3: 'CLINIC_REJECTED',
    4: 'PATIENT_PENDING',
    5: 'PATIENT_ACCEPTED',
    6: 'PATIENT_CANCELLED'
  }
}

export const HODO_PACKAGE = {
  standard: {
    label: 'Standard',
    value: 1,
    price: 2500000
  },
  plus: {
    label: 'Plus',
    value: 2,
    price: 37500000
  },
  pro: {
    label: 'Pro',
    value: 3,
    price: '-'
  }
}

export const HODO_RETAIL_PRICE = [
  {
    name: 'Hội chẩn',
    description: [
      {
        label: 'Hội chẩn tương tác toàn diện'
      },
      {
        label: 'Bảo mật toàn diện bằng HIPAA'
      },
      {
        label: 'Có chế độ Bí danh (alias) cho từng ca bệnh'
      },
      {
        label: 'Tất cả dữ liệu của ca bệnh được tập trung tại một nơi'
      },
      {
        label: 'Dễ dàng tổ chức trên đa nền tảng'
      },
      {
        label:
          'Thành viên tham gia hội chẩn có thể tương tác độc lập với các Tài nguyên ca bệnh'
      },
      {
        label:
          'Hội chẩn quốc tế có tích hợp sẵn dịch thuật với những phiên bản ngôn ngữ khác nhau có thể được lựa chọn linh hoạt và thuận tiện'
      },
      {
        label: 'Không giới hạn số phiên'
      },
      {
        label: 'Không giới hạn thành viên tham gia'
      }
    ],
    packages: [
      {
        name: 'Standard',
        scale: '5 <span>phòng</span>',
        price: '3,500,000đ',
        benefit: 'Thời lượng 60 phút/ phiên'
      },
      {
        name: 'Plus',
        scale: '5-10 <span>phòng</span>',
        price: '7,000,000đ',
        benefit: 'Thời lượng 120 phút/ phiên'
      },
      {
        name: 'Pro',
        scale: '10-30 <span>phòng</span>',
        price: '20,000,000đ',
        benefit: 'Thời lượng 240 phút/ phiên'
      },
      {
        name: 'Enterprise',
        scale: '30+ <span>phòng</span>',
        price: 'LIÊN HỆ',
        benefit: 'Thời lượng 240 phút/ phiên'
      }
    ]
  },
  {
    name: 'Họp / Hội nghị',
    description: [
      {
        label: '<strong>Không giới hạn thời gian họp</strong>'
      },
      {
        label: '<strong>Không giới hạn người tham gia</strong>'
      },
      {
        label: 'Biên bản họp tích hợp được soạn trực tiếp trong cuộc họp'
      },
      {
        label: 'Có Phòng họp mặc định, thuận tiện truy cập'
      }
    ],
    packages: [
      {
        name: 'Standard',
        scale: '5 <span  >phòng</span>',
        price: '6,000,000đ',
        benefit: '3 GB Lưu trữ'
      },
      {
        name: 'Plus',
        scale: '5-20 <span>phòng</span>',
        price: '25,000,000đ',
        benefit: '10 GB Lưu trữ'
      },
      {
        name: 'Pro',
        scale: '20+ <span>phòng</span>',
        price: 'LIÊN HỆ',
        benefit: 'LIÊN HỆ'
      }
    ]
  },
  {
    name: 'Hội thảo',
    benefit: 'LIÊN HỆ',
    description: [
      {
        label: 'Quản lý thông tin hội thảo; Xây dựng lịch trình hội thảo'
      },
      {
        label: 'Tích hợp kênh thanh toán vé trực tuyến'
      },
      {
        label: 'Dễ dàng tổ chức trên nhiều nền tảng'
      },
      {
        label: 'Có tính năng ghi hình (recording)'
      },
      {
        label: 'Có tính năng phát trực tiếp (Livestream)'
      },
      {
        label:
          'Cho phép <strong>10.000 khách mời</strong> tham gia Hội thảo đồng thời'
      },
      {
        label:
          'Cho phép tới <strong>200 báo cáo viên</strong> tham gia Báo cáo đồng thời'
      },
      {
        label: 'Tích hợp buồng dịch đa ngôn ngữ trực tuyến'
      }
    ]
  }
]

export const PAYMENT_TYPE = [
  {
    label: i18n.t('Chưa thanh toán'),
    value: 1,
    class: 'text-warning',
    type: 'danger'
  },
  {
    label: i18n.t('Đã thanh toán'),
    value: 2,
    class: 'text-success',
    type: 'success'

  }
  // {
  //   label: i18n.t("lbl_canceled_bill"),
  //   value: 3,
  //   class: "text-danger",
  // },
]
