<template>
  <div class="full pt-3 pt-lg-5 pb-5 px-3">
    <div class="wraper">
      <p
        class="text-center fw-500 fs-16"
        v-if="doctor"
      >Bạn có 1 tin nhắn từ <span v-if="!doctor.type">bác sĩ </span>{{doctor.name}}</p>
      <div class="px-160">
        <div
          class="d-flex mb-3"
          v-if="doctor"
        >
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            v-if="doctor.user && !doctor.user.avatar"
          >
            <g clip-path="url(#clip0_14074_11748)">
              <circle
                cx="19.9856"
                cy="19.9856"
                r="19.9856"
                fill="#476DD6"
              />
              <path
                d="M19.9848 8.32715C15.1739 8.32715 11.2627 12.2589 11.2627 17.0952C11.2627 21.8391 14.9535 25.6786 19.7644 25.8447C19.9113 25.8262 20.0582 25.8262 20.1684 25.8447C20.2051 25.8447 20.2235 25.8447 20.2602 25.8447C20.2786 25.8447 20.2786 25.8447 20.2969 25.8447C24.9977 25.6786 28.6885 21.8391 28.7068 17.0952C28.7068 12.2589 24.7957 8.32715 19.9848 8.32715Z"
                fill="white"
              />
              <path
                d="M29.3146 30.7547C24.1916 27.3213 15.8367 27.3213 10.6769 30.7547C8.34493 32.3237 7.05957 34.4465 7.05957 36.717C7.05957 38.9874 8.34493 41.0917 10.6586 42.6423C13.2293 44.3774 16.6079 45.245 19.9866 45.245C23.3653 45.245 26.7439 44.3774 29.3146 42.6423C31.6283 41.0733 32.9136 38.969 32.9136 36.68C32.8953 34.4096 31.6283 32.3053 29.3146 30.7547Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_14074_11748">
                <rect
                  width="40"
                  height="40"
                  rx="19.9856"
                  fill="white"
                />
              </clipPath>
            </defs>
          </svg>
          <div v-if="doctor && doctor.user">
            <img
              :src="getImageUrl(doctor.user.avatar)"
              alt=""
              class="avatar"
              v-if="doctor.user.avatar"
            >
          </div>
          <div>
            <p
              class="fs-14 fw-500 mb-1"
              v-if="doctor"
            >{{doctor.name}}</p>
            <p
              class="fs-14 mb-1"
              v-if="doctor && !doctor.type"
            >(Bác sĩ)</p>
          </div>
        </div>
        <p
          class="fs-16 fw-500 mb-1"
          v-if="topic && topic.title"
        >
          {{topic.title}}
        </p>
        <button class="disease-name d-flex border-0 mb-3">
          <span
            class="fs-12 mb-0"
            v-if="disease"
          >{{disease.diseases_name}}</span>
        </button>
        <p
          class="fs-12 mb-3"
          v-if="topic && topic.content"
        >{{topic.content}}</p>
        <div
          class="d-flex align-items-center mb-3"
          v-if="topic"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="me-2"
          >
            <path
              d="M22 10V15C22 20 20 22 15 22H9C4 22 2 20 2 15V9C2 4 4 2 9 2H14"
              stroke="#5D5D5D"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M22 10H18C15 10 14 9 14 6V2L22 10Z"
              stroke="#5D5D5D"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M7 13H13"
              stroke="#5D5D5D"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M7 17H11"
              stroke="#5D5D5D"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <p class="fs-12 mb-0">Ảnh, tài liệu đã gửi</p>
        </div>

        <FileView
          v-if="topic.medias && topic.medias.length"
          :medias="topic.medias"
        />
        <div class="mt-5 pt-3">
          <p class="fs-16">Tải HODO để có thể xem chi tiết tin nhắn</p>
          <div class="d-flex align-items-center">
            <div class="border-end border-dark pe-3">
              <img
                src="../../../public/images/common/hodo_qr.svg"
                alt=""
              >
            </div>
            <div class="ps-3">
              <img
                src="../../../public/images/home/appstoredowload.png"
                class="dowload-img c-pointer mb-3"
                alt=""
                @click="goToPStore('i')"
              >
              <img
                src="../../../public/images/home/ggdowload.png"
                class="dowload-img c-pointer"
                alt=""
                @click="goToPStore('a')"
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FileView from '../../components/Files/FileView.vue'
import appUtils from '../../utils/appUtils'
export default {
  name: 'TopicDetail',
  components: { FileView },
  data () {
    return {
      topic: {},
      disease: {},
      doctor: {}
    }
  },
  mounted () {
    const self = this
    if (self.$route.params.id && self.$route.params.pdid) {
      self.getTopicDetail()
      self.getPersonDisease()
    }
  },
  methods: {
    async getTopicDetail () {
      const self = this
      await self.$rf.getRequest('AuthRequest').getTopicDetail(self.$route.params.id).then((r) => {
        self.topic = r.data
        if (r.data.doctor) {
          self.getDoctorDetail(r.data.doctor_id)
        }
      }).catch((e) => {
        window.$toast.open({
          message: 'Bạn không có quyền xem hồ sơ này hoặc đã có lỗi xảy ra',
          type: 'error'
        })
      })
    },
    async getPersonDisease () {
      const self = this
      await self.$rf.getRequest('AuthRequest').getDisease(self.$route.params.pdid).then((r) => {
        self.disease = r.data
      }).catch((e) => {
        window.$toast.open({
          message: 'Bạn không có quyền xem hồ sơ này hoặc đã có lỗi xảy ra',
          type: 'error'
        })
      })
    },
    async getDoctorDetail (id) {
      const self = this
      await self.$rf.getRequest('AuthRequest').getDoctorDetail(id).then((r) => {
        self.doctor = r.data
      }).catch((e) => {
        window.$toast.open({
          message: 'Bạn không có quyền xem thông tin này hoặc đã có lỗi xảy ra',
          type: 'error'
        })
      })
    },
    getImageUrl (avatar) {
      if (avatar) return appUtils.getDocumentURL(avatar)
    },
    goToPStore (os) {
      if (os === 'a') {
        window.open(process.env.VUE_APP_GOOGLE_PLAY)
      }
      if (os === 'i') {
        window.open(process.env.VUE_APP_APPLE_STORE)
      }
    }
  }
}
</script>

<style scoped>
.full {
  background-color: #f7f7f9;
  height: calc(100vh - 75px);
}
.wraper {
  width: 100%;
  max-width: 621px;
  padding: 32px 0;
  margin: 0 auto;
  background: #fcfcfd;
  box-shadow: 0px 10px 32px rgba(15, 15, 15, 0.05);
  border-radius: 32px;
}
.avatar {
  width: 36px;
  height: 36px;
  border-radius: 18px;
  margin-right: 16px;
  object-fit: cover;
}
.disease-name {
  background: #eeeeee;
  border-radius: 4px;
  padding: 2px 8px;
}

.dowload-img {
  max-width: 135px;
  margin-bottom: 5px;
  display: block;
  width: auto;
  height: auto;
}
</style>
