<template>
  <div class="content">
    <div class="header-container">
      <div class="mw-1440 px-160 m-auto py-4">
        <div class="row">
          <div class="col-md-7 offset-md-5 col-lg-6 offset-lg-6 bg-white radius-15 py-3">
            <p class="text-center fw-500 txt-black fs-28">Hợp tác cùng HODO</p>
            <div class="mb-3">
              <label
                for="name"
                class="fs-18 fw-500 txt-black mb-1"
              >Họ và Tên <span class="text-danger">*</span></label>
              <input
                type="text"
                class="form-control"
                required
                :class="form.name ? 'border-0' : 'border-bottom border-danger'"
                v-model="form.name"
              >
            </div>
            <div class="mb-3">
              <label
                for="name"
                class="fs-18 fw-500 txt-black mb-1"
              >Số điện thoại <span class="text-danger">*</span></label>
              <div
                class="input-group"
                :class="form.phone ? 'border-0' : 'border-bottom border-danger'"
              >
                <span class="input-group-text border-right"> +84 <span class="border-end border-dark"> &nbsp;&nbsp;</span></span>
                <input
                  type="text"
                  class="form-control border-0"
                  required
                  v-model="form.phone"
                >
              </div>
            </div>
            <div class="mb-3">
              <label
                for="name"
                class="fs-18 fw-500 txt-black mb-1"
              >Email của bạn</label>
              <input
                type="email"
                class="form-control border-0"
                required
                v-model="form.email"
                placeholder="Nhập email liên hệ "
              >
            </div>
            <div class="mb-3">
              <label
                for="name"
                class="fs-18 fw-500 txt-black mb-1"
              >Chuyên khoa</label>
              <input
                type="text"
                class="form-control border-0"
                required
                v-model="form.majors"
                placeholder="Nhập chuyên khoa"
              >
            </div>
            <div class="mb-3">
              <label
                for="name"
                class="fs-18 fw-500 txt-black mb-1"
              >Lời nhắn cho HODO</label>
              <textarea
                class="form-control border-0"
                placeholder="Lời nhắn của bạn"
                v-model="form.note"
                rows="3"
              >

              </textarea>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LandingPageDoctor',
  data () {
    return {
      form: {
        name: '',
        email: '',
        phone: '',
        majors: '',
        note: ''
      }
    }
  }
}
</script>

<style scoped>
.header-container {
  background-color: #f7f7f9;
  background-image: url('../../../public/images/doctor/header-bg.png');
  background-repeat: no-repeat;
  background-size: 758px 550px;
}
.input-group,
.input-group-text {
  background-color: #f7f7f7;
  border: 0;
  border-radius: 8px;
}
.form-control {
  background-color: #f7f7f7 !important;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  border-radius: 8px;
}
input.form-control {
  height: 50px !important;
}
.border-0 {
  border: 0 !important;
}
</style>
