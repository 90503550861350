<template>
  <div class="flex lg:h-full bg-white">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'BlankLayout'
}
</script>

<style scoped>
</style>
