import Vue from 'vue'
import i18n from '../lang'

const disease_status = [
  { stt: 0, text: 'Chưa duyệt', class: 'text-warning' },
  { stt: 1, text: 'Đang hội chẩn', class: 'txt-pri' },
  { stt: 2, text: 'Duyệt', class: 'txt-pri' },
  { stt: 3, text: 'Đã chuyển lên viện', class: 'text-green-800' }
]

const disease_status_en = [
  { stt: 0, text: 'Not Approved', class: 'text-warning' },
  { stt: 1, text: 'Meeting', class: 'txt-pri' },
  { stt: 2, text: 'Approved', class: 'txt-pri' },
  { stt: 3, text: 'Submitted', class: 'text-green-800' }
]

const pl_mdt_case_status = [
  { stt: 0, text: 'Chưa duyệt', class: 'text-warning' },
  { stt: 1, text: 'Duyệt', class: 'txt-pri' },
  { stt: 2, text: 'Thông qua', class: 'txt-pri' },
  { stt: 3, text: 'Không thông qua', class: 'text-danger' }
]

const mdtUtils = {
  getTimeStringDetail (room, lang = null) {
    if (room && room.repeat_type) {
      if (room.repeat_type === 1) {
        const time = window.moment(room.start_time, 'HH:mm:ss').format('HH:mm')
        return room.start_time
          ? time +
          ' - ' +
          i18n.t(
            'multidisciplinary_board.lbl_daily',
            lang || Vue.prototype.$lang
          )
          : i18n.t(
            'multidisciplinary_board.lbl_daily',
            lang || Vue.prototype.$lang
          )
      }
      if (room.repeat_type === 2) {
        const time = window.moment(room.start_time, 'HH:mm:ss').format('HH:mm')
        const r = room.start_time
          ? time +
          ' - ' +
          this.getNameOfDay(window.moment(room.date).day(), lang) +
          ' ' +
          i18n.t(
            'multidisciplinary_board.lbl_weekly',
            lang || Vue.prototype.$lang
          )
          : '' +
          this.getNameOfDay(window.moment(room.date).day()) +
          ' ' +
          i18n.t(
            'multidisciplinary_board.lbl_weekly',
            lang || Vue.prototype.$lang
          )
        return r
      }
    } else {
      return room.start_time
        ? this.createDateTime(room.date, room.start_time).format(
          'HH:mm - DD/MM/YYYY'
        )
        : window.moment(room.date).format('DD/MM/YYYY')
    }
  },
  getNameOfDay (day, lang = null) {
    return day === 0
      ? i18n.t('weeks.sunday', lang || Vue.prototype.$lang)
      : day === 1
        ? i18n.t('weeks.monday', lang || Vue.prototype.$lang)
        : day === 2
          ? i18n.t('weeks.tuesday', lang || Vue.prototype.$lang)
          : day === 3
            ? i18n.t('weeks.wednesday', lang || Vue.prototype.$lang)
            : day === 4
              ? i18n.t('weeks.thursday', lang || Vue.prototype.$lang)
              : day === 5
                ? i18n.t('weeks.friday', lang || Vue.prototype.$lang)
                : day === 6
                  ? i18n.t('weeks.saturday', lang || Vue.prototype.$lang)
                  : ''
  },

  createDateTime (dateStr, timeStr) {
    const date = window.moment(dateStr)
    const time = window.moment(timeStr, 'HH:mm:ss')

    date.set({
      hour: time.get('hour'),
      minute: time.get('minute'),
      second: time.get('second')
    })
    return date
  },
  linkRoom (r) {
    let dm = ''
    if (process.env.NODE_ENV === 'development') {
      dm = 'https://dev.meet.hodo.com.vn/#/'
    } else {
      dm = 'https://meet.hodo.com.vn/#/'
    }
    const routeData = dm + 'mdt/' + r?.room_name
    return routeData
  },
  linkPublicRoom (r) {
    let dm = ''
    if (process.env.NODE_ENV === 'development') {
      dm = 'https://dev.meet.hodo.com.vn/#/'
    } else {
      dm = 'https://meet.hodo.com.vn/#/'
    }
    const routeData = dm + 'pmdt/' + r?.room_name
    return routeData
  },
  getRoleName (r) {
    const l = Vue.prototype.$lang
    if (l === 'vi') {
      return r === 1
        ? 'Chủ tọa'
        : r === 2
          ? 'Thành viên'
          : r === 3
            ? 'Chuyên gia'
            : r === 4
              ? 'Thư ký'
              : ''
    } else if (l === 'en') {
      return r === 1
        ? 'Chairman'
        : r === 2
          ? 'Member'
          : r === 3
            ? 'Expert'
            : r === 4
              ? 'Secretary'
              : ''
    }
  },
  getRoleMember (r) {
    return r === null
      ? 'Bác sĩ'
      : r === 1
        ? 'Nhân viên y tế'
        : r === 2
          ? 'Bác sĩ chuyên khoa'
          : r === 3
            ? 'Điều dưỡng'
            : 'Khác'
  },
  getPersonInfoString (p) {
    return (
      p.name +
      ' (' +
      this.getGender(p.gender) +
      ' - ' +
      this.getAges(p.birthday) +
      (p.address ? ' - ' + p.address : '') +
      ')'
    )
  },
  getMdtPersonInfoString (p) {
    const pname = p.patient_name || 'Chưa cập nhật'
    const dob =
      p.alias && p.alias.status && p.alias.alias_age
        ? this.getAges(p.alias.alias_age)
        : this.getAges(new Date(p.patient_birthday))
    const pinfo =
      this.getGender(p.patient_gender) +
      ' - ' +
      dob +
      (p.patient_address ? ' - ' + p.patient_address : '')
    return pname + (pinfo ? ' (' + pinfo + ')' : '')
  },
  getGender (gender = 1, lang = null) {
    return gender === 1
      ? i18n.t('form.gender.male', lang || Vue.prototype.$lang)
      : gender === 2
        ? i18n.t('form.gender.female', lang || Vue.prototype.$lang)
        : i18n.t('form.gender.other', lang || Vue.prototype.$lang)
  },
  getAges (value) {
    if (!value) return ''

    if (value instanceof Date) {
      const bd = window.moment(value, 'YYYY-MM-DD')
      const age = window.moment().diff(bd, 'years')
      let month = 0
      if (!age) {
        month = window.moment().diff(bd, 'months')
      }
      return age
        ? `${age} ${i18n.t('health_book_page.lbl_age')}`
        : `${month} ${i18n.t('health_book_page.lbl_months_old')}`
    } else {
      return `${value} ${i18n.t('health_book_page.lbl_age')}`
    }
  },
  getYearOrders (date) {
    if (!date) return ''
    const bd = window.moment(date, 'YYYY-MM-DD')
    const age = window.moment().diff(bd, 'years')
    let month = 0
    if (!age) {
      month = window.moment().diff(bd, 'months')
    }
    return age
      ? `${age} ${i18n.t('health_book_page.lbl_yearolder')}`
      : `${month} ${i18n.t('health_book_page.lbl_months_old')}`
  },
  getNumberAges (value) {
    if (!value) return

    if (value instanceof Date) {
      const bd = window.moment(value, 'YYYY-MM-DD')
      const age = window.moment().diff(bd, 'years')
      return age
    } else {
      return value
    }
  },
  getStatusDisease (stt) {
    const l = Vue.prototype.$lang
    if (l === 'vi') {
      const result = disease_status[stt]
      return result || disease_status[0]
    } else if (l === 'en') {
      const result = disease_status_en[stt]
      return result || disease_status_en[0]
    }
  },
  getStatusPublicMdtCase (stt) {
    const result = pl_mdt_case_status[stt]
    return result || pl_mdt_case_status[0]
  },
  getDateNextWeekByDay (day) {
    return window.moment().isoWeekday(day).add(1, 'weeks')
  },
  getNameOfDayV2 (day, lang = null) {
    return day === 0
      ? i18n.t('weeks.sunday', lang || Vue.prototype.$lang)
      : day === 1
        ? i18n.t('weeks.monday', lang || Vue.prototype.$lang)
        : day === 2
          ? i18n.t('weeks.tuesday', lang || Vue.prototype.$lang)
          : day === 3
            ? i18n.t('weeks.wednesday', lang || Vue.prototype.$lang)
            : day === 4
              ? i18n.t('weeks.thursday', lang || Vue.prototype.$lang)
              : day === 5
                ? i18n.t('weeks.friday', lang || Vue.prototype.$lang)
                : day === 6
                  ? i18n.t('weeks.saturday', lang || Vue.prototype.$lang)
                  : ''
  }
}

export default mdtUtils
