<template>
  <div class="page-404">
    <img src="../../public/images/common/404.png" class="img-404" alt="">
  </div>
</template>

<script>
export default {
  name: '404'
}
</script>

<style scoped>
.page-404{
  height: calc(100vh - 75px);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
}
.img-404 {
  width: 100%;
  max-width: 500px;
}
</style>
