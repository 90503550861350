
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import enMessage from './en.json'
import viMessage from './vi.json'

Vue.use(VueI18n)

const LANGUAGE_CODES = {
  VI: 1,
  EN: 2
  // FR: 3
}

const LANGUAGE_CODES_NAME = {
  VI: 'VI',
  EN: 'EN'
  // FR: 'FR'
}

const LANGUAGE_CODES_NUMBER = {
  1: 'VI',
  2: 'EN'
  // 3: "FR"
}

const LANGUAGE_CODES_FOR_DISEASE = {
  VI: 1,
  EN: 2,
  FR: 3
}

const LANGUAGE_CODES_NAME_FOR_DISEASE = {
  VI: 'VI',
  EN: 'EN',
  FR: 'FR'
}

const LANGUAGE_CODES_NUMBER_FOR_DISEASE = {
  1: 'VI',
  2: 'EN',
  3: 'FR'
}

export const getCurrentLanguage = () => {
  let currentLanguage = window.localStorage.getItem('lang')
  if (
    !currentLanguage ||
    currentLanguage !== 'en' ||
    currentLanguage !== 'fr'
  ) {
    currentLanguage = 'vi'
  }

  return currentLanguage.toLowerCase()
}

// export const getTranslate = (key, components) => {

//   let currentLanguage = window.localStorage.getItem('lang')

//   if (
//     !currentLanguage ||
//     currentLanguage !== 'en' ||
//     currentLanguage !== 'fr'
//   ) {
//     currentLanguage = 'vi'
//   }

//   const langCode = LANGUAGE_CODES[currentLanguage.toUpperCase()]

//   return LANGUAGES[key][langCode]
// }

// const getLanguageDataByCode = (langCode) => {
//   if (!langCode) {
//     langCode = LANGUAGE_CODES?.VI
//   }

//   let data = {}

//   Object.entries(LANGUAGES).map(([key, value]) => {
//     data = {
//       ...data,
//       [key]: value[langCode]
//     }
//   })

//   return data
// }

const messages = {
  vi: viMessage,
  en: enMessage
}

const i18n = new VueI18n({
  locale: window.localStorage.lang
    ? window.localStorage.lang
    : LANGUAGE_CODES_NAME?.VI?.toLowerCase(),
  messages,
  fallbackLocale: LANGUAGE_CODES_NAME?.VI?.toLowerCase(),
  silentFallbackWarn: true
})

export {
  LANGUAGE_CODES,
  LANGUAGE_CODES_NAME,
  LANGUAGE_CODES_NUMBER,
  LANGUAGE_CODES_FOR_DISEASE,
  LANGUAGE_CODES_NAME_FOR_DISEASE,
  LANGUAGE_CODES_NUMBER_FOR_DISEASE
}
export default i18n
