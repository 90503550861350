<!-- eslint-disable no-tabs -->
<template>
<div class="backgroup-color-gray">
    <div class="row">
        <div class="col-md-10 offset-md-1">
            <div class="container">
                <div class="item item1 txt-black">
                    <div class="item1-child">
                        <div class="display-1399-block">
                            <div class="item1-child1">
                                <div class="robo-16-500">Hoá đơn số
                                    <div class="txt-blue-04 robo-25-700 mt-2">#{{ invoice_detail.code }}</div>
                                </div>
                                <div class="robo-16-500">Ngày
                                    <div class="txt-grey-900 robo-16-400 mt-2">{{ invoice_detail.created_at ?

formatDate(invoice_detail.created_at) : '' }}</div>
                                </div>
                                <div class="robo-16-500">Hạn thanh toán
                                    <div class="txt-grey-900 robo-16-400 mt-2">{{ invoice_detail.appt ?

formatDate(invoice_detail.appt.end_time) : '' }}</div>
                                </div>
                                <div class="robo-16-500">Tình trạng
                                    <div class="robo-16-500 mt-2" v-if="invoice_detail.status" disabled="true" :style="invoice_detail.status === 1 ? 'color: #DE3854;' : invoice_detail.status === 2 ? 'color: #FBCF5E;' : 'color: #6CC7A4;'">
                                        {{ invoice_detail.status === 1 ? 'Chưa thanh toán' : invoice_detail.status === 2

? 'Hệ thống chờ xử lý' : 'Chấp nhận' }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="display-500-none">
                            <div class="item1-child1-500">
                                <div class="robo-16-500">Tình trạng
                                    <div class="robo-16-500 mt-2" v-if="invoice_detail.status" disabled="true" :style="invoice_detail.status === 1 ? 'color: #DE3854;' : invoice_detail.status === 2 ? 'color: #FBCF5E;' : 'color: #6CC7A4;'">
                                        {{ invoice_detail.status === 1 ? 'Chưa thanh toán' : invoice_detail.status === 2

? 'Hệ thống chờ xử lý' : 'Chấp nhận' }}</div>
                                </div>
                                <div align="right" class="robo-16-500">Hạn thanh toán
                                    <div class="txt-grey-900 robo-16-400 mt-2">{{ invoice_detail.appt ?

formatDate(invoice_detail.appt.end_time) : '' }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="item1-child2"></div>
                        <div class="item1-child3">
                            <div class="item1-child3-child">
                                <div>
                                    <div class="robo-16-500 lineheight-31">Nhà cung cấp dịch vụ</div>
                                    <div class="robo-16-400 txt-medium-grey lineheight-31">{{ invoice_detail.clinic ?

invoice_detail.clinic.name : '' }}</div>
                                    <div class="robo-16-400 txt-medium-grey lineheight-31">{{ invoice_detail.clinic ?

invoice_detail.clinic.address : '' }}</div>
                                    <div class="robo-16-400 txt-medium-grey lineheight-31">Hotline:
                                        {{ invoice_detail.clinic ? invoice_detail.clinic.phone : '' }}</div>
                                    <div class="robo-16-400 txt-medium-grey lineheight-31">Email:
                                        {{ (invoice_detail.doctor_id === invoice_detail.doctor_id) ?

invoice_detail.doctor ? invoice_detail.doctor.user.email : '' : '' }}</div>
                                </div>
                                <div>
                                    <div class="robo-16-500 lineheight-31">Thông tin khách hàng</div>
                                    <div class="robo-16-400 txt-medium-grey lineheight-31">Họ và tên:
                                        {{ invoice_detail.appt ? invoice_detail.appt.person.name : '' }} </div>
                                    <div class="robo-16-400 txt-medium-grey lineheight-31">{{ invoice_detail.appt ?

invoice_detail.appt.person.gender === 1 ? 'Nam' :

invoice_detail.appt.person.gender === 2 ? 'Nữ' : 'Khác' : '' }}</div>
                                    <div class="robo-16-400 txt-medium-grey lineheight-31">{{ invoice_detail.appt ?

invoice_detail.appt.person.birthday ?

formatDate(invoice_detail.appt.person.birthday) : '' : '' }}</div>
                                    <div class="robo-16-400 txt-medium-grey lineheight-31">{{ invoice_detail.appt ?

invoice_detail.appt.person.address : '' }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="item1-child3">
                            <div class="item1-child3-child">
                                <div>
                                    <div class="robo-16-500 lineheight-31">Thông tin dịch vụ</div>
                                </div>
                                <div class="text-align-right-default">
                                </div>
                            </div>
                        </div>
                        <div class="item1-child4">
                            <div class="item1-child4-child">
                                <div>
                                    <div class="robo-16-500 lineheight-31">Dịch vụ</div>
                                </div>
                                <div class="text-align-right-default">
                                    <div class="robo-16-500 lineheight-31">Thành tiền</div>
                                </div>
                            </div>
                        </div>
                        <div class="item1-child5">
                            <div class="item1-child3-child">
                                <div>
                                    <div class="robo-16-500 lineheight-31">{{ invoice_detail.appt ? invoice_detail.appt.method === 1 ? 'Dịch vụ tại cơ sở y tế' : 'Dịch vụ tư vấn chăm sóc sức khỏe từ xa' : ''}}</div>
                                </div>
                                <div class="text-align-right-default">
                                    <div class="robo-16-400 lineheight-31 item1-child5-child">
                                        {{ formatCurency(invoice_detail.price) }}</div>
                                    <div class="item1-child3-child">
                                        <div class="robo-16-400 lineheight-31 text-align-left-default">Tổng dịch vụ
                                        </div>
                                        <div class="robo-16-400 lineheight-31">{{ formatCurency(invoice_detail.price) }}
                                        </div>
                                    </div>
                                    <div class="item1-child3-child item1-child5-child">
                                        <div class="robo-16-400 lineheight-31 text-align-left-default">Tài khoản trả
                                            trước</div>
                                        <div class="robo-16-400 lineheight-31">{{ formatCurency(0) }}</div>
                                    </div>
                                    <div class="item1-child3-child">
                                        <div class="robo-16-500 lineheight-31 text-align-left-default">Tổng tiền thanh
                                            toán</div>
                                        <div class="robo-16-500 lineheight-31">{{ formatCurency(invoice_detail.price) }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="invoice_detail.status === 1">
                            <div class="item1-child3">
                                <div class="item1-child3-child">
                                    <div>
                                        <div class="robo-16-500 lineheight-31">Chọn hình thức thanh toán</div>
                                    </div>
                                </div>
                            </div>
                            <div class="ml-3">
                                <input @input="checkPaymentOption('showPaymentTranfer')" class="radio-input" type="radio" id="html" name="fav_language" value="HTML">
                                <label class="radio-label robo-16-500 lineheight-31">Chuyển khoản Ngân
                                    hàng</label><br>
                                <input @input="checkPaymentOption('showPaymentCharge')" class="radio-input" type="radio" id="css" name="fav_language" value="CSS">
                                <label class="radio-label robo-16-500 lineheight-31">Thanh toán
                                    ATM/Visa/Master/QR Code</label>
                                <br>
                            </div>
                        </div>
                        <div v-if="showPaymentTranfer">
                            <div class="item1-child3">
                                <div class="robo-16-500 lineheight-20">Để hoàn thành thanh toán, bạn vui lòng chuyển
                                    khoản
                                    đến tài khoản sau </div>
                            </div>
                            <div class="item1-child3 mt-2">
                                <b-row>
                                    <b-col align-self="center" cols="4">
                                        <DisplayBankLogo :short_name="my_bank_account ? splitStrBankName(my_bank_account.bank_name) : ''">
                                        </DisplayBankLogo>
                                    </b-col>
                                    <b-col cols="8">
                                        <div class="robo-16-500 mb-2">Ngân hàng {{ my_bank_account ?

my_bank_account.bank_name : '' }}</div>
                                        <div class="robo-16-300">{{ my_bank_account ?

my_bank_account.bank_branch : '' }}</div>
                                    </b-col>
                                </b-row>
                            </div>

                            <div class="item1-child3">
                                <b-row>
                                    <b-col cols="8">
                                        <div class="robo-16-400">Số tài khoản:</div>
                                        <div class="robo-16-500" id="copy-bank-number">{{

my_bank_account

? my_bank_account.bank_number : '' }}</div>
                                    </b-col>
                                    <b-col cols="4" align-self="end">
                                        <b-button @click="copyText('copy-bank-number')" pill variant="primary" size="sm">
                                            Sao chép
                                        </b-button>
                                    </b-col>
                                </b-row>
                                <hr class="mt-2" style="width:100%">
                            </div>
                            <div class="item1-child3">
                                <b-row>
                                    <b-col cols="8">
                                        <div class="robo-16-400">Chủ tài khoản:</div>
                                        <div class="col-sm-7 col-12 robo-16-500" id="copy-bank-owner">{{ my_bank_account

?

my_bank_account.bank_account ?

my_bank_account.bank_account.toUpperCase() :

'' : '' }}</div>
                                    </b-col>
                                    <b-col cols="4" align-self="end">
                                        <b-button @click="copyText('copy-bank-number')" pill variant="primary" size="sm">
                                            Sao chép
                                        </b-button>
                                    </b-col>
                                </b-row>
                                <hr class="mt-2" style="width:100%">
                            </div>
                            <div class="item1-child3">
                                <b-row>
                                    <b-col cols="8">
                                        <div class="robo-16-400">Nội dung chuyển khoản:</div>
                                        <div class="col-sm-7 col-12 robo-16-500" id="copy-bank-content">
                                            {{ invoice_detail.code }}</div>
                                    </b-col>
                                    <b-col cols="4" align-self="end">
                                        <b-button @click="copyText('copy-bank-content')" pill variant="primary" size="sm">
                                            Sao chép
                                        </b-button>
                                    </b-col>
                                </b-row>
                                <hr class="mt-2" style="width:100%">
                            </div>
                            <div class="item1-child3">
                                <b-row>
                                    <b-col cols="8">
                                        <div class="robo-16-400">Số tiền cần thanh toán:</div>
                                        <div class="col-sm-7 col-12 robo-16-500" id="copy-payment-price">
                                            {{ formatCurency(invoice_detail.price) }}</div>
                                    </b-col>
                                    <b-col cols="4" align-self="end">
                                        <b-button @click="copyText('copy-payment-price')" pill variant="primary" size="sm">
                                            Sao chép
                                        </b-button>
                                    </b-col>
                                </b-row>
                                <hr class="mt-2" style="width:100%">
                            </div>
                            <div class="item1-child3">
                                <b-card style="background-color: #EFF2FF;" text-variant="primary">
									<div class="position-relative pl-5">
										<img class="position-absolute top-0 start-0" src="../../../public/icons/alert-icon.svg" alt="">
										<b-card-text class="pl-3">
                                        Vui lòng nhập đúng nội dung chuyển khoản, để giao dịch được hoàn tất trong thời
                                        gian
                                        sớm nhất.
                                    </b-card-text>
									</div>

                                </b-card>
                            </div>
                            <div class="item1-child3">
                                <b-row align-v="center" class="text-center">
                                    <b-col>
                                        <b-link href="https://www.hodo.com.vn/#/">
                                            Liên hệ hỗ trợ
                                        </b-link>
                                    </b-col>
                                    |
                                    <b-col>
                                        <b-link href="https://www.hodo.com.vn/#/help">
                                            Hướng dẫn thanh toán
                                        </b-link>
                                    </b-col>
                                </b-row>
                            </div>
                            <div class="item1-child3">
                                <b-button @click="changeInvoicesStatus()" variant="primary" style="width:100%" size="lg">
                                    Xác nhận thanh toán
                                </b-button>
                            </div>
                        </div>
                        <div v-if="showPaymentCharge">
                            <div class="item1-child3">
                                <input @input="acceptTerm()" class="radio-input" type="checkbox" id="vehicle1" name="vehicle1" value="Bike">
                                <label class="ml-2 robo-16-400" for="vehicle1"> Tôi đã đọc và đồng ý</label>
                                <b-link href="https://www.hodo.com.vn/#/" class="robo-16-400 ml-1 mt-1"> Điều khoản sử
                                    dụng </b-link>
                            </div>
                            <div class="item1-child3">
                                <b-button :disabled="!isAcceptTerm" @click="generateUrl()" variant="primary" style="width:100%" size="lg">
                                    Thanh toán
                                </b-button>
                            </div>
                        </div>
                        <br>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
  BCol,
  BRow,
  BButton,
  BCard,
  BCardText,
  BLink
} from 'bootstrap-vue'
import {
  addHashQueryParam
} from '@/utils/url'
import axios from 'axios'
import DisplayBankLogo from '@/components/PublicsPage/DisplayBankLogo.vue'
export default {
  name: 'InvoicePublicLink',
  components: {
    BLink,
    BCardText,
    BCard,
    BButton,
    BCol,
    BRow,
    DisplayBankLogo
  },
  data () {
    return {
      access_token: '',
      orderId: this.$route.params.id,
      id_teleme: 0,
      token_teleme: '',
      invoice_detail: Object,
      appt_id: 0,
      appointment_detail: Object,
      my_bank_account: Object,
      list_bank_total: 0,
      str_url: '',
      short_name: '',
      is_show_logo: true,
      is_post_file: false,
      formData: {},
      id_clinic: 0,
      paymentType: null,
      paymentTypeOptions: [{
        label: 'Chuyển khoản qua ngân hàng',
        value: 'bankTranfer'
      },
      {
        label: 'Thanh toán bằng thẻ Visa/Master/ATM',
        value: 'bankCharge'
      }
      ],
      showPaymentTranfer: false,
      showPaymentCharge: false,
      isAcceptTerm: false
    }
  },
  computed: {
    isResult () {
      return !!this.$route.query.hodo_result
    }
  },

  mounted () {
    this.id_teleme = this.$route?.params?.id
    this.token_teleme = this.$route.query.token
    this.getInvoices(this.id_teleme, this.token_teleme)
    // this.getBankAccounts()
  },
  methods: {
    acceptTerm () {
      this.isAcceptTerm = !this.isAcceptTerm
    },
    checkPaymentOption (value) {
      console.log(value)
      if (value === 'showPaymentTranfer') {
        this.showPaymentTranfer = true
        this.showPaymentCharge = false
      } else if (value === 'showPaymentCharge') {
        this.showPaymentTranfer = false
        this.showPaymentCharge = true
      }
    },
    changePayemntMethods () {
      if (this.paymentType.value === 'bankTranfer') {
        this.showPaymentTranfer = true
        this.showPaymentCharge = false
      } else if (this.paymentType.value === 'bankCharge') {
        this.showPaymentTranfer = false
        this.showPaymentCharge = true

        // this.generateUrl()
        console.log('bankCharge')
      }
    },
    async generateUrl () {
      const href = location.origin + `/#/invoice/public-result/${this.orderId}`
      //   console.log(addHashQueryParam(href, 'serviceMedical', this.invoice_detail.appt.method))
      //   const accessToken = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvYXBpLWRldi5ob2RvLmFwcFwvYXBpXC91c2Vyc1wvb3RwLXZlcmlmeSIsImlhdCI6MTY3ODI3MDEwOSwiZXhwIjoxNjgwODYyMTA5LCJuYmYiOjE2NzgyNzAxMDksImp0aSI6ImNXTExhUzZXRE55OTYzNVQiLCJzdWIiOjU2NywicHJ2IjoiODdlMGFmMWVmOWZkMTU4MTJmZGVjOTcxNTNhMTRlMGIwNDc1NDZhYSJ9.712zGdKGpmF17Bp7Fwx41GrPDklVa1yPeX4X8JMcaME'
      const res = await axios({
        // headers: {
        //   Authorization: 'Bearer ' + this.access_token
        // },
        headers: {
          Authorization: 'Bearer ' + this.access_token
        },
        url: 'https://staging.hodo.com.vn/api/node/onepay/generate-url',
        method: 'POST',
        data: {
          orderType: 2,
          orderId: parseInt(this.orderId),
          returnUrl: addHashQueryParam(href, 'token', this.$route.query.token)
        }
      })
      // returnUrl: addHashQueryParam(href, 'hodo_result', '1')
      location.replace(res.data.url)
      console.log('res', res.data)
    },
    async getTransaction (txnId) {
      const res = await axios({
        url: `https://staging.hodo.com.vn/api/node/onepay/${txnId}`,
        method: 'GET'
      })
      console.log('res', res)
      if (res.data?.vpc_TxnResponseCode === '0') {
        window.$toast.open({
          message: 'Giao dịch thành công',
          type: 'success'
        })
      } else {
        window.$toast.open({
          message: 'Giao dịch thất bại',
          type: 'error'
        })
      }
      this.onePay = res.data
    },
    async getInvoices (id, token) {
      try {
        const self = this
        const param = {
          token: self.token_teleme
        }
        const result = await self.$rf.getRequest('AuthRequest').getInvoices(self.id_teleme, param)
        self.invoice_detail = result.data
        self.access_token = result.data.access_token
        self.appt_id = result.data.appt_id
        var params = {
          token: this.token_teleme
        }
        const resultBank = await self.$rf.getRequest('AuthRequest').postBankAccounts(this.id_teleme, params)
        self.my_bank_account = resultBank.data.data[0]
        self.short_name = self.splitStrBankName(self.my_bank_account.bank_name)
        self.my_bank_account = resultBank.data.data.filter(item => parseInt(item.clinic_id) === parseInt(self.invoice_detail.clinic_id))[0]
        self.short_name = self.splitStrBankName(self.my_bank_account.bank_name)
      } catch (e) {
        console.log(e)
      }
    },
    formatDate (str) {
      return window.moment(str).format('DD/MM/YYYY')
    },
    formatCurency (str) {
      return str ? str.toLocaleString('it-IT', {
        style: 'currency',
        currency: 'VND'
      }) : '0'
    },
    splitStrBankName (name) {
      var shortname = ''
      if (name.length > 0) {
        if (name.includes('(')) {
          shortname = name.split('(')[1].split(')')[0].toLowerCase()
          this.is_show_logo = true
          return shortname
        }
        this.is_show_logo = false
      } else {
        this.is_show_logo = false
      }
    },
    async changeInvoicesStatus () {
      try {
        const self = this
        var r = confirm('Bạn chắc chắn không?')
        if (r) {
          if (self.invoice_detail.status === 1) {
            var param = {
              token: self.$route.query.token,
              status: 2
            }
            if (self.is_post_file) {
              await this.$rf.getRequest('AuthRequest').postInvoicesUpload(this.id_teleme, self.formData)
              self.$toast.clear()
            }
            await self.$rf.getRequest('AuthRequest').postInvoices(self.invoice_detail.id, param)
            self.$toast.clear()
            self.$toast.open({
              message: 'Hệ thống đang xử lý yêu cầu của bạn',
              type: 'info'
            })
            self.getInvoices(this.id_teleme, this.token_teleme)
          } else {
            self.$toast.open({
              message: 'Xác nhận thành công rồi',
              type: 'warning'
            })
          }
        }
      } catch (e) {
        console.log(e)
      }
    },
    copyText (idElement) {
      var code = document.getElementById(idElement)
      const range = document.createRange()
      var selection = window.getSelection()
      selection.removeAllRanges()
      range.selectNodeContents(code)
      selection.addRange(range)
      try {
        if (this.isSupport('copy')) {
          document.execCommand('copy')
          selection.removeAllRanges()
          this.$toast.open({
            message: 'Sao chép thành công',
            type: 'success'
          })
        } else {
          this.$toast.open({
            message: 'Sao chép không được hỗ trợ trên trình duyệt của bạn',
            type: 'error'
          })
        }
      } catch (e) {
        console.log(e)
      }
    },
    isSupport (cmd) {
      return document.queryCommandSupported(cmd)
    },
    async changeImageUpload (event) {
      const self = this
      self.formData = new FormData()
      self.formData.append('token', this.token_teleme)
      self.formData.append('photo', event.target.files[0])
      try {
        document.getElementById('name-file').innerText = event.target.files[0] ? this.splitNameImg(event.target.files[0].name) : 'Tải lên file'
        self.is_post_file = true
        self.$toast.open({
          message: 'Tải file lên thành công',
          type: 'success'
        })
      } catch (e) {
        console.log(e)
      }
    },
    splitNameImg (strName) {
      if (strName.length > 5) {
        var arr = strName.split('.')
        var arr2 = arr[0].split('')
        strName = ''
        for (var i = 0; i <= 4; i++) {
          strName = strName + arr2[i]
        }
        strName = strName + '...' + arr[1]
      }
      return strName
    }
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP&display=swap');

.radio-label {
    display: inline-block;
    vertical-align: center;
    margin-right: 3%;
}

input[type="radio"] {
    margin: 0 10px 0 10px;
}

.text-align-right {
    text-align: right;
}

.text-align-right-default {
    text-align: right;
}

.text-align-left-default {
    text-align: left;
}

.container {
    max-width: 1250px;
    margin: 0 auto;
    padding: 5px;
    display: flex;
}

.item {
    margin: 5px;
    color: white;
    width: 50px;
}

.item1 {
    flex-grow: 2;
    flex-basis: 981px;
    /*background-color: #FFFFFF;

border-radius: 5px;

padding: 5px;*/
}

.item1-child {
    background-color: #fff;
    height: 100%;
    border-radius: 10px;
}

.item1-child1 {
    display: grid;
    grid-template-columns: 20% 20% 30% 30%;
    padding: 15px 20px;
}

.item1-child2 {
    height: 5px;
    background-color: #F2F5FA;
}

.item1-child3 {
    background-color: #fff;
    padding: 5px 20px;
}

.item1-child4 {
    padding: 5px 20px;
    background-color: #F2F5FA;
}

.item1-child4-child {
    display: grid;
    grid-template-columns: 50% 50%;
}

.item1-child5 {
    padding: 5px 20px;
}

.item1-child5-child {
    border-bottom: 2px solid #19181A;
}

.item1-child1-500 {
    display: grid;
    padding: 15px 20px;
    grid-template-columns: 50% 50%;
    display: none;
}

.item1-child3-child {
    display: grid;
    grid-template-columns: 50% 50%;
}

.item2 {
    flex-grow: 1;
    flex-basis: 421px;
}

.item2-child {
    padding: 15px 20px;
    background-color: #20409B;
    border-radius: 10px;
}

.item2-child5-child-child-title {
    border: 1px solid #D2D2D2;
    border-radius: 5px;
    height: 30px;
    cursor: pointer;
    background-color: #fff;
    width: 100%;
}

.item2-child5-child-child-file {
    margin-top: 0px;
    color: #D2D2D2;
    border-right: 1px solid #D2D2D2;
    line-height: 30px;
    padding-right: 5px;
}

.item2-child5-child-child-str-file {
    margin-left: 10px;
    color: #D2D2D2;
}

.item3 {
    flex-shrink: 2;
}

.robo-25-700 {
    font-weight: 700;
    font-size: 25px;
}

.txt-black {
    color: #fff;
}

.txt-medium-grey {
    color: #6F6E70;
}

.txt-blue-04 {
    color: #20419B
}

.lineheight-31 {
    line-height: 31.35px;
}

.display-block {
    display: block;
}

.table-bank-tr-frist {
    min-width: 110px;
    display: flex;
    justify-content: top;
}

.min-height-100 {
    min-height: 100vh;
}

.font-weight-normal {
    font-weight: normal;
}

.txt-center {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
}

.txt-white {
    color: #fff;
}

.bound-img-bank {
    background-color: #fff;
    align-items: center;
    display: flex;
    justify-content: center;
    border-radius: 10px;
}

.icon-copy-white:before {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 14px;
    height: 14px;
}

.display-sm-500-none {
    display: block;
}

.display-sm-500-block {
    display: none;
}

.backgroup-color-gray {
    background-color: #E0E9FF;
    height: 100%;
    overflow-x: hidden;
}

.backgroup-color-gray-50 {
    background-color: #F8F7FA;
}

.min-height-98 {
    min-height: 98vh
}

.bound-big {
    background-color: #FFFFFF;
    border-radius: 8px;
    border-bottom: .5px solid ragb(0, 0, 0, .5);
    padding: 15px 20px;
}

.bound-small {
    background-color: #20409B;
    border-radius: 8px;
    min-height: 100%;
}

.btn-status {
    font-size: 12px;
    padding: 5px 5px;
    font-weight: bold;
    border: none;
    background-color: #fff;
}

.mtop-15 {
    margin-top: 15px;
}

.w-50 {
    width: 50%;
}

.w-100 {
    width: 100%;
}

.p-10-10 {
    padding: 10px 10px;
}

.pl-10 {
    padding-left: 10px;
}

.pr-10 {
    padding-right: 10px;
}

.bb-hr {
    border-bottom: 3px solid black;
}

.txt-black {
    color: #19181A;
}

.p-15-5 {
    padding: 15px 5px;
}

.p-15-8 {
    padding: 15px 8px;
}

.aglin-txt-center {
    display: flex;
    justify-content: center;
}

.mt-12 {
    margin-top: -12px;
}

.wh-20 {
    width: 20px;
    height: 20px;
}

.btn-change-bank {
    border-radius: 50px;
    color: #20409B;
    width: 95%;
    cursor: default !important;
    background-color: #fff;
    padding: 10px 10px;
    text-align: center;
    font-weight: bold;
}

.btn-change-bank-not-select {
    border-radius: 50px;
    color: #20409B;
    width: 95%;
    cursor: default !important;
    background-color: rgb(156, 154, 154);
    padding: 10px 10px;
    text-align: center;
    font-weight: bold;
}

.center {
    align-items: center;
    display: flex;
    justify-content: center;
}

.bound-bank {
    width: 100%;
    font-weight: normal;
    font-size: 13px;
}

.lh-30 {
    line-height: 30px;
}

.bound-a-copy {
    font-size: 10px;
    color: #fff;
}

.bl-hr {
    border-left: .5px solid #D2D2D2;
}

.ml-2px {
    margin-left: 2px;
}

.bound-str-copy {
    background-color: #fff;
    color: #20409B;
    border-radius: 2px;
}

.btn-alert-pay {
    color: #20409B;
    width: 98%;
    font-weight: bold;
    background-color: #fff;
    padding: 10px 10px;
    text-align: center;
    border: none;
    border-radius: 5px;
}

.mt-5px {
    margin-top: -5px;
}

.display-500-none {
    display: none;
}

.display-1399-block {
    display: block;
}

@media (max-width: 1200px) AND (min-width: 1000px) {
    .item1-child1 {
        display: grid;
        grid-template-columns: 20% 25% 35% 30%;
        padding: 15px 20px;
    }
}

@media (max-width: 1000px) AND (min-width: 500px) {
    .container {
        max-width: 1250px;
        margin: 0 auto;
        padding: 5px;
        display: inline-flex;
        flex-wrap: wrap;
    }
}

@media (max-width: 500px) {
    .display-500-none {
        display: block;
    }

    .display-1399-block {
        display: none;
    }

    .container {
        max-width: 1250px;
        margin: 0 auto;
        padding: 5px;
        display: inline-flex;
        flex-wrap: wrap;
    }

    .item1-child1 {
        display: grid;
        grid-template-columns: 1fr;
        padding: 15px 20px;
    }

    .item1-child3-child {
        display: grid;
        grid-template-columns: 1fr;
    }

    .text-align-right {
        text-align: left;
    }

    .item1-child1-500 {
        display: grid;
        padding: 15px 20px;
        grid-template-columns: 50% 50%;
    }
}

@media (max-width: 1920px) {
    .backgroup-color-gray {
        background-color: #E0E9FF;
        height: 100vh;
    }
}
</style>
