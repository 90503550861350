<template>
  <div class="login">
    <div class="row">
      <div class="col-md-6 offset-md-3 col-xl-4 offset-xl-4 pt-lg-5 px-4">
        <div v-show="step === 1" class="pt-lg-5">
          <div class="d-flex align-items-end mb-1">
            <p class="mb-0 fs-28 fw-500 mr-2">Đăng nhập HODO</p>
            <img src="../../../public/icons/lock.svg" alt width="42" />
          </div>
          <p class="fs-18 txt-gray-blur mb-4">Nhập số điện thoại của bạn</p>
          <div class="d-flex bg-f7 border-0 mb-4">
            <div class="d-flex align-items-center bg-f7 border-0 py-1 ps-2">
              <img src="../../../public/icons/vietnam.svg" width="30" alt />
              <span class="ps-2 fs-18 text-black">+</span>
              <input
                type="number"
                v-model="dial_code"
                class="form-control bg-f7 border-0 py-3 w-42px px-0 border-end"
              />
            </div>
            <input
              type="number"
              v-model="phone"
              @keyup.enter="nextStep"
              class="form-control bg-f7 border-0 py-3"
            />
          </div>
          <button
            @click="nextStep"
            class="btn btn-pri w-100 h-50px mb-1"
            :disabled="loading"
          >Đăng nhập</button>
          <p class="mb-0 fs-14">
            Bằng cách đăng nhập vào trang web này, bạn đã đọc và đồng ý với
            <span
              class="txt-pri text-decoration-underline c-pointer"
              @click="goToTerm(1)"
            >Điều khoản dịch vụ</span>
            cũng như
            <span
              class="txt-pri text-decoration-underline c-pointer"
              @click="goToTerm(2)"
            >Chính sách quyền riêng tư</span>
            của HODO
          </p>
        </div>
        <div v-if="step === 2" class="pt-lg-5">
          <p class="fs-28 fw-500">Nhập mã xác thực</p>
          <p class="fs-18 txt-gray-blur">Mã gồm 4 chữ số đã gửi đến : {{ phone }}</p>
          <div class="d-flex justify-content-center">
            <OtpInput
              ref="otpInput"
              input-classes="otp-input text-center"
              separator="&nbsp;"
              :num-inputs="4"
              :should-auto-focus="true"
              :is-input-num="true"
              @on-complete="handleOnComplete"
            ></OtpInput>
          </div>
          <button
            @click="getOTP(true)"
            class="btn w-100 fs-18 txt-gray-blur"
            :disabled="time_out > 0"
          >
            Gửi lại mã
            <span v-if="time_out">
              <span class="txt-pri">{{ time_out }}</span>giây
            </span>
          </button>
        </div>
        <div v-if="isBackStep" class="text-center txt-pri pt-2 pb-4">
          <div
            role="button"
            class="inline-block no-underline hover:text-blue-900 transition-all duration-200"
            @click.stop="handleBackStepJoinCircle"
          >
            <i class="el-icon-back" />
            <span class="ms-1">Quay lại</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OtpInput from '@bachdgvn/vue-otp-input'
import appUtils from '../../utils/appUtils'
import moment from 'moment'
export default {
  name: 'Login',
  components: { OtpInput },
  props: {
    isBackStep: Boolean
  },
  data () {
    return {
      phone: '',
      step: 1,
      user_id: null,
      time_out: 0,
      interval_time_out: null,
      dial_code: 84,
      loading: false
    }
  },
  mounted () {
    if (this.$user) {
      this.$router.push({ name: 'ListDiseases' })
    }
    window.amplitude.setUserId(null)
    window.amplitude.setUserProperties({
      'User ID': null,
      'User Name': null,
      Phone: null,
      'Person ID': null,
      'Person Name': null
    })
  },
  methods: {
    nextStep () {
      const self = this
      if (self.validatePhone()) {
        self.sendAmplitude('Enter phone', {
          Phone: this.phone,
          Timestamp: moment(new Date()).format('HH:mm DD-MM-YYYY')
        })
        self.getOTP()
      } else {
        window.$toast.open({
          message: 'Vui lòng kiểm tra lại số điện thoại đã nhập',
          type: 'error'
        })
      }
    },
    setIntervalTimeOut () {
      const self = this
      self.time_out = 30
      clearInterval(self.interval_time_out)
      self.interval_time_out = setInterval(() => {
        if (self.time_out > 0) {
          self.time_out--
        } else {
          clearInterval(self.interval_time_out)
        }
      }, 1000)
    },
    backStep () {
      const self = this
      self.step = 1
      self.user_id = null
      if (self.interval_time_out) {
        clearInterval(this.interval_time_out)
      }
    },
    async getOTP (resend) {
      const self = this
      this.loading = true
      const params = {
        dial_code: this.dial_code,
        username: this.phone
      }
      if (resend) {
        self.sendAmplitude('Resend OTP', {
          Phone: this.phone,
          Timestamp: moment(new Date()).format('HH:mm DD-MM-YYYY')
        })
      }
      await self.$rf
        .getRequest('GuestRequest')
        .getOTP(params)
        .then(resp => {
          if (resp?.data) {
            self.user_id = resp.data.id
          }
          self.step = 2
          self.setIntervalTimeOut()
        })
        .catch(e => {
          console.log(e)
          window.$toast.open({
            message: 'Vui lòng kiểm tra lại số điện thoại đã nhập',
            type: 'error'
          })
          self.user_id = null
        })
        .finally(() => {
          this.loading = false
        })
    },
    async otpVerify (value) {
      const self = this
      const params = {
        user_id: self.user_id,
        otp: value,
        web: 1
      }
      await self.$rf
        .getRequest('GuestRequest')
        .otpVerify(params)
        .then(resp => {
          appUtils.setLocalToken(resp?.data?.access_token)
          this.$router.push({ path: '/hssk' }).catch(() => {})
          self.getUserInfo(value)
        })
        .catch(e => {
          console.log(e)
          self.sendAmplitude('Enter OTP', {
            Phone: this.phone,
            Status: 'Fail',
            Otp: value,
            Timestamp: moment(new Date()).format('HH:mm DD-MM-YYYY')
          })
        })
    },
    async getUserInfo (value) {
      const self = this
      await self.$rf
        .getRequest('AuthRequest')
        .me()
        .then(resp => {
          appUtils.setLocalUser(resp.data)
          self.sendAmplitude('Enter OTP', {
            Phone: this.phone,
            Status: 'Success',
            Otp: value,
            Timestamp: moment(new Date()).format('HH:mm DD-MM-YYYY')
          })

          setTimeout(() => {
            let url = '/'
            if (self.$targetRoute) {
              url = self.$targetRoute
              self.$targetRoute = ''
            }
            if (!resp.data.person) {
              self.$router.push({ path: '/tai-khoan-moi' })
            } else window.location.href = url
          }, 100)
        })
        .catch(e => {
          console.log(e)
        })
    },
    validatePhone () {
      if (!this.phone) return
      const firstC = this.phone.charAt(0)
      if (parseInt(firstC) !== 0) this.phone = '0' + this.phone
      const phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
      return this.phone.match(phoneno)
    },
    handleOnComplete (value) {
      this.otpVerify(value)
    },
    handleClearInput () {
      this.$refs.otpInput.clearInput()
    },
    goToTerm (t) {
      const name = t === 1 ? 'Term' : 'Policy'
      const routeData = this.$router.resolve({ name: name })
      window.open(routeData.href, '_blank')
    },
    handleBackStepJoinCircle () {
      this.$emit('setStep', 1)
    }
  }
}
</script>

<style lang="css" scoped>
.login {
  height: 60vh;
}
.h-50px {
  height: 50px;
}
.w-42px {
  min-width: 42px;
  max-width: 60px;
}
</style>
