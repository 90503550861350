<template>
  <div class="pb-5 px-3">

    <!-- <SlideShow @showContent="animationContent(true)" @hideContent="animationContent(false)"/> -->
    <!-- <div class="lg:hidden bg-homepage bg-mobile pl-10 pb-28 flex flex-col justify-center montserrat-SemiBold font-bold">
      <div><span class="txt-prii text-6xl">{{$t('holaDoctor')}}</span></div>
      <div><span class="bg-prii-light txt-prii text-4xl">{{$t('helloDoctor')}}</span></div>
    </div>
    <div class="hidden lg:flex bg-homepage bg-image-des p-32  flex-col justify-end montserrat-SemiBold font-bold">
      <div><span class="bg-prii  text-white text-7xl">{{$t('holaDoctor')}}</span></div>
      <div><span class="bg-prii text-white text-7xl">{{$t('helloDoctor')}}</span></div>
    </div> -->

    <div id="carouselExampleDark" class="carousel carousel-dark slide" data-bs-ride="carousel">
  <div class="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="2" aria-label="Slide 3"></button>
    <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="3" aria-label="Slide 4"></button>
    <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="4" aria-label="Slide 5"></button>
  </div>
  <div class="carousel-inner">
    <div class="carousel-item active" data-bs-interval="3000">
      <img src="../../../public/carousel/1.png" class="d-block w-100" alt="...">
      <div class="carousel-caption d-none d-md-block">
      </div>
    </div>
    <div class="carousel-item" data-bs-interval="5000">
      <img src="../../../public/carousel/2.png" class="d-block w-100" alt="...">
      <div class="carousel-caption d-none d-md-block">
      </div>
    </div>
    <div class="carousel-item">
      <img src="../../../public/carousel/3.png" class="d-block w-100" alt="...">
      <div class="carousel-caption d-none d-md-block">
      </div>
    </div>
    <div class="carousel-item">
      <img src="../../../public/carousel/4.png" class="d-block w-100" alt="...">
      <div class="carousel-caption d-none d-md-block">
      </div>
    </div>
    <div class="carousel-item">
      <img src="../../../public/carousel/5.png" class="d-block w-100" alt="...">
      <div class="carousel-caption d-none d-md-block">
      </div>
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
  </button>
</div>

    <div class="">
     <div class="text-center mb-100 mt-32">
        <p class="fs-41 txt-prii nunitoBold mb-0">{{$t('home-page.title')}}</p>
        <p class="fs-41 txt-prii nunitoBold mb-50">{{$t('home-page.title2')}}</p>
        <div class="d-flex justify-content-center">
          <p class="divider"></p>
        </div>
     </div>

     <div class="bg-care">
      <div class="container">
        <p class="fs-33 txt-prii NunitoRegular mb-0">{{$t('home-page.content1')}}</p>
        <p class="fs-59 txt-prii nunitoBold mb-0">{{$t('home-page.content2')}}</p>
        <p class="txt-prii fs-59 nunitoBold mb-0">{{$t('home-page.content3')}}</p>
        <p class="fs-33 txt-prii NunitoRegular">{{$t('home-page.content4')}}</p>
      </div>
     </div>

     <div class="bg-text">
      <div class="text-center">
        <p class="fs-33 txt-prii NunitoRegular mb-1">{{$t('home-page.content6')}} <span class="text-decoration-underline">{{$t('home-page.sub1')}}</span> và <span class="text-decoration-underline">{{$t('home-page.sub2')}}</span></p>
        <p class="fs-59 txt-prii nunitoBold mb-1">{{$t('home-page.content7')}}</p>
        <p class="fs-ita txt-prii mb-0 NunitoRegular">{{$t('home-page.content8')}}</p>
        <p class="fs-ita txt-prii NunitoRegular">{{$t('home-page.content10')}}</p>
      </div>
     </div>
    </div>

    <div class="section">
      <div class="container">
        <div class="row mx-auto">
        <div class="col-md-6 txt-prii mt-30">
          <div class="m-mb">
            <p class="text-3xl lg:text-5xl txt-prii nunitoBold mb-2">{{$t('home-page.content12')}}</p>
            <p class="text-3xl lg:text-5xl txt-prii nunitoBold">{{$t('home-page.content13')}} </p>
          </div>
          <p class="fs-33 txt-prii NunitoRegular text-justify mb-0">{{$t('home-page.content15')}}</p>
          <p class="fs-33 txt-prii NunitoRegular text-justify">{{$t('home-page.content16')}}</p>
        </div>

        <div class="col-md-6 text-justify mt-3">
          <div class="mb-48">
            <p class="fs-43 mb-0 txt-prii nunitoBold">{{$t('home-page.hodo')}}</p>
            <p class="fs-33 txt-prii mb-2 NunitoRegular">{{$t('home-page.ulti1')}}</p>
            <p class="fs-33 txt-prii mb-2 NunitoRegular">{{$t('home-page.ulti-1')}}</p>
            <div @click="goToHodoApp" class="fs-24 txt-prii cursor-pointer NunitoRegular">{{$t('home-page.ulti2')}}</div>
          </div>
          <div class="">
            <p class="fs-43 txt-prii nunitoBold mb-0">{{$t('home-page.hodo-pro')}}</p>
            <p class="fs-33 txt-prii mb-2 NunitoRegular">{{$t('home-page.ulti3')}}</p>
            <p class="fs-33 txt-prii mb-2 NunitoRegular">{{$t('home-page.ulti-3')}}</p>
            <div @click="goToHodoPro" class="fs-24 txt-prii cursor-pointer NunitoRegular">{{$t('home-page.ulti4')}}</div>
          </div>
        </div>
      </div>
      </div>
    </div>

    <div class="footer text-center">
      <div class="">
        <p class="fs-footer mb-0 text-white NunitoRegular">{{$t('home-page.content17')}} <span class="nunitoBold">{{$t('home-page.text2')}}</span></p>
        <p class="fs-footer text-white mb-footer NunitoRegular">{{$t('home-page.content18')}} <span class="nunitoBold">{{$t('home-page.text3')}}</span></p>
      </div>
      <div class="px-2">
        <p class="lg:text-7xl text-5xl text-white mb-3 nunitoBold">{{$t('home-page.content19')}} <span class="nunitoBold text-decoration-underline">{{$t('home-page.text4')}}</span></p>
        <p class="lg:text-7xl text-5xl mb-footer text-white nunitoBold"><span class="text-decoration-underline nunitoBold">{{$t('home-page.text5')}}</span> {{$t('home-page.content20')}}</p>
      </div>
      <div class="row">
        <p class="text-center fs-footer-b fst-italic text-white NunitoRegular mb-70">{{$t('home-page.content21')}} </p>
        <div class="lg:flex justify-center items-center">
          <button class="style-btn-left nunitoBold text-decoration-none txt-prii mt-2 mx-2" onclick="location.href='https://hodo.app/#/go'">{{$t('home-page.content22')}}</button>
          <button class="style-btn-right nunitoBold text-decoration-none text-white mt-2 lg:ml-32" onclick="location.href='https://hodo.app/bsi'">{{$t('home-page.content23')}}</button>
        </div>

      </div>
    </div>
    <!-- <div class="header mb-4">
      <div class="h-left d-flex align-items-center">
        <div class="d-block pt-5 pt-lg-0">
          <p class="text-header-1 mb-2">
            {{$t('home-page.help-you')}}
          </p>
          <p class="text-header-2 mb-2 fw-500">
            {{$t('home-page.better')}}
          </p>
          <transition name="fade">
          <a
              href="#/login"
              class="fw-500 fs-16 cl text-decoration-none space-p btn btn-pri"
            >Tham gia HODO</a>
        </transition>
        </div>
      </div>
      <div class="h-right">
        <img
          src="../../../public/images/home/header-img.png"
          class="img-fluid c-pointersss"
          alt=""
        >
      </div>
    </div> -->
    <!-- <div
      class="row px-160"
      id="home-content"
    >
      <div
        class="col-lg-6 content-left"
        id="home-content-left"
      >
        <div class="row mb-3">
          <div class="col-lg-5">
            <p class="fs-36 fw-500 text-start title-text">{{$t('home-page.title-1')}}</p>
          </div>
            <div class="col-lg-7 text-start body-text">
              <p class="fs-18 mb-5">{{$t('home-page.content-1')}}</p>
              <a
              href="#/login"
              class="fs-18 fw-700 txt-black"
              ><img
                src="../../../public/icons/arrow-right-black.svg"
                alt=""
                width="24"
              > {{$t('home-page.action-1')}}</a>
            </div>
          </div>
          <div class="mb-3">
            <img
              src="../../../public/images/home/home-1.png"
              class="img-fluid c-pointersss"
              alt=""
            >
          </div>
      </div>
      <div
        class="col-lg-6"
        id="home-content-right"
      >
         <div class="mb-lg-5 row mb-3">
           <a @click="()=>{this.$router.push({path: '/thanh-vien'}).catch(()=>{})}">
           <div class="btn btn-primary btn-package d-flex align-items-center">
              <div class="w-75 text-start">
                <span class="fw-500 fs-22 cl">{{$t('home-page.PackageMember')}}</span>
              </div>
                <div class="left-arrow">
                  <i class="arrow"></i>
                </div>

          </div></a>
      </div>
        <div class="row mb-3">
          <div class="col-lg-5">
            <p class="fs-36 fw-500 text-start title-text">{{$t('home-page.title-2')}}</p>
          </div>
          <div class="col-lg-7 text-start body-text">
            <p class="fs-18 mb-5">{{$t('home-page.content-2')}}</p>
            <a
              href="javascript:;"
              class="fs-18 fw-700 txt-black"
            ><img
                src="../../../public/icons/arrow-right-black.svg"
                alt=""
                width="24"
              > {{$t('home-page.action-2')}}</a>
          </div>
        </div>
        <div class="mb-3">
          <img
            src="../../../public/images/home/home-2.png"
            class="img-fluid c-pointersss"
            alt=""
          >
        </div>
      </div>
    </div> -->

    <!-- //disable -->
    <!-- <div
      class="utils px-160"
      id="home-utils"
    >
      <p
        class="mb-3 fs-43 fw-500 u-title"
        id="home-utils-title"
      >{{$t('home-page.hodos-services')}}</p>
      <div class="row">
        <div class="col-lg-3 col-md-6 mb-3 home-utils-left">
          <div class="card text-center radius-15 c-pointer">
            <div class="ratio ratio-1x1">
              <img
                src="../../../public/images/home/util-1.svg"
                class="w-100 c-pointersss"
                alt=""
              >
            </div>
            <p
              class="fs-24 fw-500 txt-black max-line-2 mb-3"
              style="height: 64px"
            >{{$t('home-page.util-1')}}</p>
            <p class="fs-16 txt-black max-line-1">{{$t('details')}} <img
                src="../../../public/icons/triangle-black.svg"
                width="16"
                alt=""
              ></p>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 mb-3 home-utils-left">
          <div
            class="card text-center radius-15 c-pointer"
            @click="()=> {this.$router.push({path: '/dich-vu'}).catch(()=>{})}"
          >
            <div class="ratio ratio-1x1">
              <img
                src="../../../public/images/home/util-2.svg"
                class="w-100 c-pointersss"
                alt=""
              >
            </div>
            <p
              class="fs-24 fw-500 txt-black max-line-2 mb-3"
              style="height: 64px"
            >{{$t('home-page.util-2')}}</p>
            <p class="fs-16 txt-black max-line-1">{{$t('details')}} <img
                src="../../../public/icons/triangle-black.svg"
                width="16"
                alt=""
              ></p>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 mb-3 home-utils-right">
          <div class="card text-center radius-15 c-pointer">
            <div class="ratio ratio-1x1">
              <img
                src="../../../public/images/home/util-3.svg"
                class="w-100 c-pointersss"
                alt=""
              >
            </div>
            <p
              class="fs-24 fw-500 txt-black max-line-2 mb-3"
              style="height: 64px"
            >{{$t('home-page.util-3')}}</p>
            <p class="fs-16 txt-black max-line-1">{{$t('details')}} <img
                src="../../../public/icons/triangle-black.svg"
                width="16"
                alt=""
              ></p>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 mb-3 home-utils-right">
          <div class="card text-center radius-15 c-pointer">
            <div class="ratio ratio-1x1">
              <img
                src="../../../public/images/home/util-4.svg"
                class="w-100 c-pointersss"
                alt=""
              >
            </div>
            <p
              class="fs-24 fw-500 txt-black max-line-2 mb-3"
              style="height: 64px"
            >{{$t('home-page.util-4')}}</p>
            <p class="fs-16 txt-black max-line-1">{{$t('details')}} <img
                src="../../../public/icons/triangle-black.svg"
                width="16"
                alt=""
              ></p>
          </div>
        </div>
      </div>
    </div>
    -->
  </div>
</template>

<script>
// import SlideShow from '../../components/SlideShow/index'

export default {
  name: 'Home',
  data () {
    return {
      transitionContent: false
    }
  },
  // components: { SlideShow },
  mounted () {
    window.addEventListener('scroll', this.handleScroll)
    this.$nextTick(function () {
      var top = window.pageYOffset + window.innerHeight
      var isVisibleBody = top > document.querySelector('#home-content')?.offsetTop

      if (isVisibleBody) {
        document.getElementById('home-content-left').classList.add('slide-left')
        document.getElementById('home-content-right').classList.add('slide-right')
      }
      // entire view has been rendered
    })
    document.addEventListener('scroll', () => {
      var top = window.pageYOffset + window.innerHeight
      var isVisibleBody = top > document.querySelector('#home-content')?.offsetTop

      if (isVisibleBody) {
        document.getElementById('home-content-left').classList.add('slide-left')
        document.getElementById('home-content-right').classList.add('slide-right')
      }

      var isVisibleUltils = top > document.querySelector('#home-utils')?.offsetTop

      if (isVisibleUltils) {
        document.getElementById('home-utils-title').classList.add('slide-left')
        window.$('.home-utils-left').addClass('slide-left')
        window.$('.home-utils-right').addClass('slide-right')
      }

      var isVisibleDownload = top > document.querySelector('#home-download')?.offsetTop

      if (isVisibleDownload) {
        window.$('.dowload-title').addClass('slide-left')
        window.$('.download-ios').addClass('slide-left')
        window.$('.download-android').addClass('slide-right')
      }
    })
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  beforeRouteLeave (to, from, next) {
    document.removeEventListener('scroll', () => { })
    next()
  },
  beforeDestroy () {
    document.removeEventListener('scroll', () => { })
  },
  methods: {
    goToPStore (os) {
      if (os === 'a') {
        window.open(process.env.VUE_APP_GOOGLE_PLAY)
      }
      if (os === 'i') {
        window.open(process.env.VUE_APP_APPLE_STORE)
      }
    },
    animationContent (show) {
      this.transitionContent = show
    },
    goToHodoApp () {
      this.$router.push({ path: '/app-hodo' }).catch(() => { })
    },
    goToHodoPro () {
      this.$router.push({ path: '/hodo-pro' }).catch(() => { })
    },
    handleScroll () {
      if (window.scrollY > 400) {
        this.animationContent(true)
      } else {
        this.animationContent(false)
      }
    }
  }
}
</script>

<style lang="css" scoped>

/* body */
.style-btn-right:hover {
  color: #FFFFFF!important;
  background-color: #476dd6!important;
}
.style-btn-left:hover {
  color: #FFFFFF!important;
  background-color: #476dd6!important;
  border: 2px solid #FFFFFF;
}
.style-btn-right {
  background-color: #20409b;
  border-radius: 36px;
  font-size: 20px;
  padding: 15px 10px 15px 10px;
  width: 300px;
  border: 2px solid #FFFFFF;
}
.style-btn-left {
  width: 300px;
  border-radius: 36px;
  border: none;
  font-size: 20px;
  padding: 17px 10px 17px 10px;
  border: 2px solid #20409b;
}
.mt-30 {
  margin-top: 120px;
}
.mb-50 {
  margin-bottom: 50px;
}
.mb-70 {
  margin-bottom:70px;
}
.mb-100 {
  margin-bottom: 100px;
}
.mb-48 {
  margin-bottom: 48px;
}
.mb-footer {
  margin-bottom: 100px;
}
.myVideo {
  right: 0;
  bottom: 0;
  width: 100%;
  height: auto;
}
.home-1 {
  background-color: #f3f4f5;
  padding: 0px 70px 0px 70px;
}
.footer {
  background-color: #20409b;
  padding: 80px 0px 90px 0px;
}
.divider {
  border-bottom: 3px solid #20409B;
  width: 500px;
}
.section {
  background-color: #f3f4f5;
  padding: 80px 0px 90px 0px;
}
/* font */
.fs-92 {
  font-size: 92px;
}
.fs-81 {
  font-size: 81px;
}
.text-hl {
  font-size: 59px;
}
.fs-65 {
  font-size: 65px;
}
.fs-59 {
  font-size: 59px;
}
.fs-53 {
  font-size: 53px;
}
.fs-43 {
  font-size: 40px;
}
.fs-47 {
  font-size: 50px;
}
.fs-41 {
  font-size: 41px;
}
.fs-ita {
  font-size: 33px;
}
.fs-37 {
  font-size: 37px;
}
.fs-footer-b {
  font-size: 37px;
}
.fs-footer {
  font-size: 33px;
}
.fs-33 {
  font-size: 30px;
}
.fs-25 {
  font-size: 25px;
}
.txt-prii {
  color: #20409B;
}
@font-face {
  font-family: Montserrat-Light;
  src: url('../../../public/font/Montserrat-Light.ttf');
}
@font-face {
  font-family: Montserrat-Bold;
  src: url('../../../public/font/Montserrat-Bold.ttf');
}
@font-face {
  font-family: Montserrat-Medium;
  src: url('../../../public/font/Montserrat-Medium.ttf');
}
@font-face {
  font-family: Montserrat-SemiBold;
  src: url('../../../public/font/Montserrat-SemiBold.ttf');
}
@font-face {
  font-family: NunitoRegular;
  src: url('../../../public/font/Nunito-Regular.ttf');
}
@font-face {
  font-family: NunitoBold;
  src: url('../../../public/font/NunitoSans-ExtraBold.ttf');
}
.nunitoRegular {
  font-family: NunitoRegular;
}
.nunitoBold {
  font-family: NunitoBold;
}
.montserrat-light {
  font-family: Montserrat-Light;
}
.NunitoRegular {
  font-family: NunitoRegular;
}
.nunitoBold {
  font-family: NunitoBold;
}
.montserrat-SemiBold {
  font-family: Montserrat-SemiBold;
}
/* Highlight Background */
.background-text {
    margin: 0px auto 0px auto;
    background-color: #20409B;
    color: #FFFFFF;
    padding: 0px 20px 0px 20px;
}
.background-text-1 {
  padding:5px;
  background-color:#DFE4F3;
}
.mt-100 {
  margin-top: 100px;
}
.color-pri {
  color: #20409B;
}
.fs-124{
  font-size: 124px;
  font-weight: bold;
}
.fs-90{
  font-size: 90px;
}
.fs-25 {
  font-size:30px
}

.left-arrow {
  margin-left: 100px;
  margin-bottom: 25px;
}
.showText {
  animation: show .25s;
}
@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes show {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

.home {
  background-color: #f7f7f9;
}
.h-left,
.slide-left {
  animation: 2s ease-out 0s 1 slideInFromLeft;
}
.bg-care {
  width: 100%;
  display: flex;
  align-items: center;
  background: url('../../assets/bg1.png') no-repeat center center;
  background-size: cover;
  margin-bottom: 60px;
}
.bg-text {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url('../../assets/bg2.png') no-repeat center center;
  background-size: cover;
  margin-bottom: 60px;
}
@media (min-width: 876px) {
  .bg-care {
    height: 60vh;
  }
  .bg-text {
    height: 100vh;
  }
}
.h-right,
.slide-right {
  animation: 2s ease-out 0s 1 slideInFromRight;
}
.dowload-big-img {
  width: 230px;
  height: 68;
}
.title-text {
  line-height: 44px;
}
.body-text {
  line-height: 25px;
}
.dowload-title {
  text-align: center;
}
@media only screen and (min-width: 1440px) {
  .u-title {
    text-align: left;
  }
  .utils,
  .dowload {
    margin-top: 80px;
  }
  .content-left {
    padding-right: 64px;
  }
  .content-right {
    padding-top: 200px;
    padding-left: 64px;
  }
  .h-left {
    width: 55%;
    justify-content: start;
  }
  .h-right {
    width: 45%;
  }
  .text-header-1 {
    font-size: 60px;
    text-align: left;
  }
  .text-header-2 {
    font-size: 80px;
    text-align: left;
  }
  .dowload-title {
    font-size: 48px;
    font-weight: 500;
  }
}
@media only screen and (max-width: 1439px) and (min-width: 1024px) {
  .u-title {
    text-align: left;
  }
  .utils,
  .dowload {
    margin-top: 80px;
  }
  .content-left {
    margin-top: -60px;
    padding-right: 20px;
  }
  .content-right {
    padding-left: 20px;
  }
  .h-left {
    width: 55%;
    justify-content: start;
  }
  .h-right {
    width: 45%;
    float: left;
  }
  .text-header-1 {
    font-size: 48px;
    text-align: left;
  }
  .text-header-2 {
    font-size: 60px;
    text-align: left;
  }
  .dowload-title {
    font-size: 48px;
    font-weight: 500;
  }
}
@media only screen and (max-width: 1023px) and (min-width: 768px) {
  .u-title {
    text-align: left;
  }
  .utils,
  .dowload {
    margin-top: 80px;
  }
  .content-left {
    margin-top: 15px;
    padding-right: 20px;
  }
  .content-right {
    padding-left: 20px;
  }
  .h-left {
    width: 55%;
    justify-content: start;
  }
  .h-right {
    width: 45%;
    float: left;
  }
  .text-header-1 {
    font-size: 48px;
    text-align: left;
  }
  .text-header-2 {
    font-size: 60px;
    text-align: left;
  }
  .dowload-title {
    font-size: 48px;
    font-weight: 500;
  }
}
@media only screen and (max-width: 767px) {
  .utils {
    margin-top: 30px;
  }
  .h-left {
    width: 100%;
    justify-content: center;
  }
  .h-right {
    width: 100%;
  }
  .text-header-1 {
    font-size: 24px;
    text-align: center;
  }
  .text-header-2 {
    font-size: 32px;
    text-align: center;
  }
  .dowload-title {
    font-size: 24px;
    font-weight: 500;
    text-align: left;
  }
}
.space-p {
  height: 60px;
  width: 227px;
  line-height: 45px;
  margin: 50px 0px 0px 20px;
  border-radius: 12px;
}
.cl {
  color:#FFFFFF;
}
.fs-22 {
  font-size: 22px;
}
.space-pd {
  width: 543px;
  height: 83.6px;
  padding: 6px 13px 6px 13px;
}
.btn-package {
  background: linear-gradient(to right, #0575E6, #021B79);
  border-radius: 12.6px;
  width: 100%;
  min-height: 83.6px;
  border: 0px;
}
.arrow {
  display: block;
  width: 20px;
  height: 20px;
  border-top: 3px solid #fff;
  border-left: 3px solid #fff;
  transform: rotate(135deg);
  margin: 25px 15px 0px 15px;
  border-radius: 2px;
}
.col-1 {width: 8.33%;}
.col-2 {width: 16.66%;}
.col-3 {width: 25%;}
.col-4 {width: 33.33%;}
.col-5 {width: 41.66%;}
.col-6 {width: 50%;}
.col-7 {width: 58.33%;}
.col-8 {width: 66.66%;}
.col-9 {width: 75%;}
.col-10 {width: 83.33%;}
.col-11 {width: 91.66%;}
.col-12 {width: 100%;}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */

  /* body */
.mt-30 {
  margin-top: 20px;
}
.divider {
  border-bottom: 2px solid #20409B;
  width: 200px;
}
.footer {
  background-color: #20409b;
  padding: 50px 0px 50px 0px;
}
.mb-100 {
  margin-bottom: 50px;
}
.mb-footer {
  margin-bottom: 35px;
}
.text-hl {
  font-size: 22px;
}
.section {
  background-color: #f3f4f5;
  padding: 0px 0px 30px 0px;
}
.mb-70 {
  margin-bottom: 10px;
}
.m-mb {
  margin-bottom: 1px;
}
  /* font size */
.fs-ita {
  font-size: 16px;
}
.fs-footer {
  font-size: 18px;
}
.fs-41 {
  font-size: 16px;
  margin-bottom: 10px;
  padding: 0px;
}
.fs-92 {
  font-size: 82px;
}
.fs-81 {
  font-size: 26px;
}
.fs-65 {
  font-size: 55px;
}
.fs-59 {
  font-size: 27px;
}
.fs-53 {
  font-size: 43px;
}
.fs-43 {
  font-size: 33px;
}
.fs-47 {
  font-size: 37px;
}
.fs-37 {
  font-size: 27px;
}
.fs-footer-b {
  font-size: 20px;
}
.fs-33 {
  font-size: 18px;
}
.fs-25 {
  font-size: 15px;
}
.mb-48 {
  margin-bottom: 28px;
}
}

.hideContent {
  transform: translateY(500px);
  opacity: 0;
  transition: 2s all ease;
}
.showContent {
  transform: translateY(0);
  opacity: 1;
}
.cursor-pointer {
  cursor: pointer;
}
.bg-homepage {
  height: calc(100vh - 56px);
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  margin: 0 -16px;
}
.bg-mobile {
  background-image: url('../../assets/Home/BGHODOMOBILE.png');
}
.bg-image-des {
  background-image: url('../../assets/Home/BACKGROUNDHOME.png');
}
.bg-prii {
  background-color: #03278e;
}

.bg-prii-light {
  background-color: #dfe4f3;
}

</style>
