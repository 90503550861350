import '@/@fake-db/db'
import 'bootstrap'
import '../public/assets/css/_root.scss'
import 'vue-toast-notification/dist/theme-sugar.css'
import '../public/assets/css/style.css'
import './assets/css/tailwind.css'
import '@/utils/mixinSendAmplitude.js'
import './plugins'
// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

import App from './App.vue'
import AppLayout from './layouts/AppLayout'
import AuthLayout from './layouts/AuthLayout'
import BlankLayout from './layouts/BlankLayout'
import GuestLayout from './layouts/GuestLayout'
import PublicLayout from './layouts/PublicLayout'
import RequestFactory from './api/RequestFactory'
import VCalendar from 'v-calendar'
import Vue from 'vue'
import VueCompositionAPI from '@vue/composition-api'
import VueI18n from 'vue-i18n'
import VueProgressBar from 'vue-progressbar'
import VueQrcode from '@chenfengyuan/vue-qrcode'
import VueToast from 'vue-toast-notification'
import WhitePublicLayout from './layouts/WhitePublicLayout'
import amplitude from 'amplitude-js'
import enMessage from './lang/en.json'
import moment from 'moment'
import router from './router'
import store from './store'
import viMessage from './lang/vi.json'

Vue.use(VCalendar, {
  // componentPrefix: 'vc'
})

Vue.component(VueQrcode.name, VueQrcode)

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

amplitude.getInstance().init(process.env.VUE_APP_AMPLITUDE_API_KEY)
window.amplitude = amplitude.getInstance()
Vue.use(VueI18n)
Vue.use(VueToast, {
  position: 'top-right'
})
window.$ = window.jQuery = require('jquery')
Vue.use(VueProgressBar, {
  color: '#20419B',
  failedColor: 'red',
  height: '2px'
})
Vue.use(VueCompositionAPI)
Vue.component('public-layout', PublicLayout)
Vue.component('white-public-layout', WhitePublicLayout)
Vue.component('guest-layout', GuestLayout)
Vue.component('app-layout', AppLayout)
Vue.component('blank-layout', BlankLayout)
Vue.component('auth-layout', AuthLayout)

const echo = Vue.observable({ echo: null })

Object.defineProperty(Vue.prototype, '$echo', {
  get () {
    return echo.echo
  },

  set (value) {
    echo.echo = value
  }
})
const conectedEcho = Vue.observable({ conectedEcho: null })

Object.defineProperty(Vue.prototype, '$conectedEcho', {
  get () {
    return conectedEcho.conectedEcho
  },

  set (value) {
    conectedEcho.conectedEcho = value
  }
})

const refresh = Vue.observable({ refresh: 0 })

Object.defineProperty(Vue.prototype, '$refresh', {
  get () {
    return refresh.refresh
  },

  set (value) {
    refresh.refresh = value
  }
})

const uploading = Vue.observable({ uploading: false })
Object.defineProperty(Vue.prototype, '$uploading', {
  get () {
    return uploading.uploading
  },

  set (value) {
    uploading.uploading = value
  }
})

const multiUploading = Vue.observable({ multiUploading: [] })
Object.defineProperty(Vue.prototype, '$multiUploading', {
  get () {
    return multiUploading.multiUploading
  },

  set (value) {
    multiUploading.multiUploading = value
  }
})
Vue.prototype.$rf = new RequestFactory()
window.axios = require('axios')

Vue.prototype.moment = moment
Vue.config.productionTip = false
window.Number.prototype.format = function (n, x, s, c) {
  var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')'
  var num = this.toFixed(Math.max(0, ~~n))

  return (c ? num.replace('.', c) : num).replace(
    new RegExp(re, 'g'),
    '$&' + (s || ',')
  )
}
const user = Vue.observable({ user: null })

Object.defineProperty(Vue.prototype, '$user', {
  get () {
    return user.user
  },

  set (value) {
    user.user = value
  }
})

const singleLoading = Vue.observable({
  singleLoading: {
    show: false,
    value: 0,
    name: '',
    title: ''
  }
})

Object.defineProperty(Vue.prototype, '$singleLoading', {
  get () {
    return singleLoading.singleLoading
  },

  set (value) {
    singleLoading.singleLoading = value
  }
})

const shoppingCart = Vue.observable({ shoppingCart: [] })

Object.defineProperty(Vue.prototype, '$shoppingCart', {
  get () {
    return shoppingCart.shoppingCart
  },

  set (value) {
    shoppingCart.shoppingCart = value
  }
})

const targetRoute = Vue.observable({ targetRoute: '' })

Object.defineProperty(Vue.prototype, '$targetRoute', {
  get () {
    return targetRoute.targetRoute
  },

  set (value) {
    targetRoute.targetRoute = value
  }
})

const messages = {
  vi: viMessage,
  en: enMessage
}
const i18n = new VueI18n({
  locale: window.localStorage.lang ? window.localStorage.lang : 'vi',
  messages,
  fallbackLocale: window.localStorage.lang,
  silentFallbackWarn: true
})

window.moment = moment

new Vue({
  store,
  router,
  i18n,
  render: (h) => h(App)
}).$mount('#app')
