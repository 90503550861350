<template>
  <!-- Modal -->
  <div
    class="modal fade"
    id="modalAddCircle"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modalAddCircleTitle"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="d-flex justify-content-between p-3">
          <h5
            class="modal-title"
            id="modalUpdateDiseaseLabel"
          >{{ $t("circle_feature.lbl_join_circle") }}</h5>
          <button
            type="button"
            class="close bg-white border-0"
            data-dismiss="modal"
            aria-label="Close"
            @click="closeModal"
          >
            <span aria-hidden="true" class="robo-18-500">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div>
            <label class="txt-grey-600 fs-16 fw-600 required mb-2">
              {{
              $t("circle_feature.lbl_circle_code")
              }}
            </label>
            <input
              type="text"
              class="form-control bg-white border mb-3"
              v-model="code"
              placeholder="Nhập mã Circle"
              @input="debounceSearch"
            />
            <div v-if="(!circles || !circles.code) && code">
              <p>
                <img class="mr-2" src="../../../public/assets/images/Exclude.svg" alt />
                <span
                  class="text-danger fs-14 fw-400"
                >Không tìm thấy Circle nào, Vui lòng kiểm tra mã Circle của bạn</span>
              </p>
            </div>
            <div>
              <!-- <pre>{{ circles }}</pre> -->
              <div
                class="mb-2 p-3 border radius-10 bd-pri"
                v-if="circles"
                :set="(c = circles)"
                @click="selectCircle(c)"
              >
                <p v-if="c.name" class="fs-18 fw-700">{{ c.name }}</p>
                <p v-if="c.description" class="fs-14 fw-400 whitespace-pre-wrap">{{ c.description }}</p>
                <div class="d-flex">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="mr-2"
                  >
                    <path
                      d="M12.5947 18.2565V13.6353M12.5947 12.6891C12.8067 10.038 11.3857 5.02198 4.00492 6.16728C3.89887 8.34122 5.46835 12.6891 12.5947 12.6891ZM12.5947 12.6891C13.7081 11.5226 15.723 8.56014 20.5 10C20.0654 12.0378 18.3162 14.7488 12.5947 13.6353M12.5947 12.6891V13.6353"
                      stroke="#84818A"
                      stroke-width="2"
                    />
                    <rect x="3" y="18" width="17" height="2" fill="#84818A" />
                  </svg>
                  <div class="flex-1">
                    <p class="fs-14 fw-500 mb-1 mt-1">Seed</p>
                    <div class="d-flex align-items-center">
                      <div style="min-width: 40px">
                        <span
                          v-if="c.seed_info && c.seed_info.avatar"
                          class="avatar avatar-sm avatar-rounded mr-2"
                          :style="
                            'background-image: url(' +
                            getImageURL(c.seed_info && c.seed_info.avatar) +
                            ')'
                          "
                        ></span>
                        <span
                          v-if="c.seed_info && !c.seed_info.avatar"
                          class="avatar avatar-sm avatar-rounded bg-pri text-white mr-2"
                        >{{ (c.seed_info && c.seed_info.name.charAt(0)) || "-" }}</span>
                      </div>
                      <div class="flex-1">
                        <p class="fs-14 fw-600 txt-black mb-1">{{ c.seed_info && c.seed_info.name }}</p>
                        <p
                          class="fs-12 fw-500 txt-grey-600 mb-1"
                        >{{ c.seed_info && c.seed_info.doctor_infor && c.seed_info.doctor_infor.chuc_vu }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary w-100 fw-400"
            :disabled="!code || loading || !circles"
            @click="joinCircle"
          >{{ $t("circle_feature.lbl_request_join") }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import appUtils from '../../utils/appUtils'
export default {
  name: 'AddCircle',
  data () {
    return {
      type: 'join', // Types: create, join
      code: '',
      description: '',
      name: '',
      loading: false,
      circles: null,
      typing: false,
      debounce: null
    }
  },
  methods: {
    debounceSearch () {
      this.typing = true
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.typing = false
        if (!this.code?.length) return
        this.getCircles()
      }, 200)
    },
    async getCircles () {
      const self = this
      const params = {
        code: this.code
      }
      await this.$rf
        .getRequest('AuthRequest')
        .getCircles(params)
        .then(res => {
          if (res?.data?.code === 200009) {
            self.circles = null
          } else {
            self.circles = res?.data
          }
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          self.loading = false
        })
    },
    async joinCircle () {
      const self = this
      if (this.loading) return
      if (!this.circles) return
      this.loading = true
      await this.$rf
        .getRequest('AuthRequest')
        .joinCircle(this.circles.id)
        .then(result => {
          self.closeModal()
          self.refresh()
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          self.loading = false
        })
    },
    closeModal () {
      window.$('#modalAddCircle').modal('hide')
    },
    getImageURL (path) {
      return appUtils.getDocumentURL(path)
    },
    refresh () {
      this.$toast.open({
        message: 'Thành công',
        type: 'success'
      })
      this.$emit('refresh')
      this.code = ''
      this.description = ''
      this.name = ''
      this.circles = null
    }
  }
}
</script>

<style scoped>
.btn:disabled {
  background-color: #98a2b3;
}
.avatar {
  --tblr-avatar-size: 2.5rem;
  position: relative;
  width: var(--tblr-avatar-size);
  height: var(--tblr-avatar-size);
  font-size: calc(var(--tblr-avatar-size) / 2.8571429);
  font-weight: 400;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #656d77;
  text-align: center;
  text-transform: uppercase;
  vertical-align: bottom;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: #20419b no-repeat center/cover;
  border-radius: 3px;
}
.avatar-rounded {
  border-radius: 50%;
}
</style>
