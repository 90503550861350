<template>
  <div class="pb-5">
    <div class="container mx-auto">
      <div class="flex justify-end items-center mt-2">
        <svg
          @click="goToBack"
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="cursor-pointer"
        >
          <path
            d="M6 18.4998L17.9998 6.5"
            stroke="#292D32"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M17.9998 18.4998L6 6.5"
            stroke="#292D32"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <div class="text-pri-900 text-center pb-28 pt-12 lg:flex lg:flex-col lg:justify-center lg:items-center">
        <div class="font-bold text-3xl mb-5">Để xem và chia sẻ dữ liệu Chẩn đoán Hình ảnh, hãy trở thành Thành viên HODO. Lựa chọn gói:</div>
        <div class="lg:flex lg:gap-12">
          <div class="lg:m-0 mb-4 w-full">
            <div class="rounded-44px px-4 py-4 h-100 hover:bg-gray-200 cursor-pointer">
              <img
                class="h-16 mb-4"
                src="../../assets/HoDo/Silver.png"
              />
              <p class="font-bold text-center text-2xl f-24 fw-600 mb-4">HODO Silver</p>
              <p class="fs-30 fw-600 mt-5 mb-0 text-gray-700 text-decoration-line-through">50,000 VNĐ/tháng</p>
              <div class="mb-3 mt-16">
                <p class="mb-0 lg:text-4xl text-3xl fw-600">Hỗ trợ miễn phí</p>
              </div>
              <!-- <button
                onclick="location.href='https://www.hodo.com.vn/#/login"
                class="px-4 mb-4 rounded-11px bg-pri fs-24 fw-600 btn btn-pri lg:mt-14"
              >Bắt đầu</button> -->
              <p class="fs-18 fw-400 mb-1 text-justify">
                <svg
                  width="16"
                  height="12"
                  viewBox="0 0 16 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.79573 9.1308L1.19893 5.51294L0 6.71889L4.79573 11.5427L15.0723 1.20595L13.8734 0L4.79573 9.1308Z"
                    fill="#20409B"
                  />
                </svg>
                Nhắn tin không giới hạn với Bác sĩ
              </p>
              <p class="fs-18 fw-400 mb-1 text-justify">
                <svg
                  width="16"
                  height="12"
                  viewBox="0 0 16 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.79573 9.1308L1.19893 5.51294L0 6.71889L4.79573 11.5427L15.0723 1.20595L13.8734 0L4.79573 9.1308Z"
                    fill="#20409B"
                  />
                </svg>
                Gọi điện trực tiếp với Bác sĩ
              </p>
              <p class="fs-18 fw-400 mb-1 text-justify">
                <svg
                  width="16"
                  height="12"
                  viewBox="0 0 16 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.79573 9.1308L1.19893 5.51294L0 6.71889L4.79573 11.5427L15.0723 1.20595L13.8734 0L4.79573 9.1308Z"
                    fill="#20409B"
                  />
                </svg>
                Chuyển đổi số các thông tin sức khỏe từ hình ảnh
              </p>
              <p class="fs-18 fw-400 mb-1 text-justify">
                <svg
                  width="16"
                  height="12"
                  viewBox="0 0 16 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.79573 9.1308L1.19893 5.51294L0 6.71889L4.79573 11.5427L15.0723 1.20595L13.8734 0L4.79573 9.1308Z"
                    fill="#20409B"
                  />
                </svg>
                Lữu trữ phim Chẩn đoán hình ảnh (CT, MRI, PET/CT...)
              </p>
              <p class="fs-18 fw-400 mb-1 text-justify">
                <svg
                  class="mr-2"
                  width="16"
                  height="12"
                  viewBox="0 0 16 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.79573 9.1308L1.19893 5.51294L0 6.71889L4.79573 11.5427L15.0723 1.20595L13.8734 0L4.79573 9.1308Z"
                    fill="#20409B"
                  />
                </svg>
                <span class="text-red-500">5GB</span> lưu trữ dữ liệu trên HODO Cloud
              </p>
              <p class="fs-18 fw-400 mb-1 text-justify text-gray-500 text-decoration-line-through">
                <svg
                  class="mr-2"
                  width="13"
                  height="13"
                  viewBox="0 0 13 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.33279 6.71747L12.6328 2.42747C12.8211 2.23917 12.9269 1.98377 12.9269 1.71747C12.9269 1.45117 12.8211 1.19577 12.6328 1.00747C12.4445 0.819167 12.1891 0.713379 11.9228 0.713379C11.6565 0.713379 11.4011 0.819167 11.2128 1.00747L6.92279 5.30747L2.63279 1.00747C2.44449 0.819167 2.18909 0.713379 1.92279 0.713379C1.65649 0.713379 1.40109 0.819167 1.21279 1.00747C1.02449 1.19577 0.918699 1.45117 0.918699 1.71747C0.918699 1.98377 1.02449 2.23917 1.21279 2.42747L5.51279 6.71747L1.21279 11.0075C1.11906 11.1004 1.04467 11.211 0.993899 11.3329C0.943131 11.4548 0.916992 11.5855 0.916992 11.7175C0.916992 11.8495 0.943131 11.9802 0.993899 12.102C1.04467 12.2239 1.11906 12.3345 1.21279 12.4275C1.30575 12.5212 1.41635 12.5956 1.53821 12.6464C1.66007 12.6971 1.79078 12.7233 1.92279 12.7233C2.0548 12.7233 2.18551 12.6971 2.30737 12.6464C2.42923 12.5956 2.53983 12.5212 2.63279 12.4275L6.92279 8.12747L11.2128 12.4275C11.3058 12.5212 11.4164 12.5956 11.5382 12.6464C11.6601 12.6971 11.7908 12.7233 11.9228 12.7233C12.0548 12.7233 12.1855 12.6971 12.3074 12.6464C12.4292 12.5956 12.5398 12.5212 12.6328 12.4275C12.7265 12.3345 12.8009 12.2239 12.8517 12.102C12.9025 11.9802 12.9286 11.8495 12.9286 11.7175C12.9286 11.5855 12.9025 11.4548 12.8517 11.3329C12.8009 11.211 12.7265 11.1004 12.6328 11.0075L8.33279 6.71747Z"
                    fill="#CA0F0F"
                  />
                </svg>
                Xem và chia sẻ tài liệu CĐHA, HSSK cùng các thông tin, tài liệu sức khỏe
              </p>
              <p class="fs-18 fw-400 mb-1 text-justify text-gray-500 text-decoration-line-through">
                <svg
                  class="mr-2"
                  width="13"
                  height="13"
                  viewBox="0 0 13 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.33279 6.71747L12.6328 2.42747C12.8211 2.23917 12.9269 1.98377 12.9269 1.71747C12.9269 1.45117 12.8211 1.19577 12.6328 1.00747C12.4445 0.819167 12.1891 0.713379 11.9228 0.713379C11.6565 0.713379 11.4011 0.819167 11.2128 1.00747L6.92279 5.30747L2.63279 1.00747C2.44449 0.819167 2.18909 0.713379 1.92279 0.713379C1.65649 0.713379 1.40109 0.819167 1.21279 1.00747C1.02449 1.19577 0.918699 1.45117 0.918699 1.71747C0.918699 1.98377 1.02449 2.23917 1.21279 2.42747L5.51279 6.71747L1.21279 11.0075C1.11906 11.1004 1.04467 11.211 0.993899 11.3329C0.943131 11.4548 0.916992 11.5855 0.916992 11.7175C0.916992 11.8495 0.943131 11.9802 0.993899 12.102C1.04467 12.2239 1.11906 12.3345 1.21279 12.4275C1.30575 12.5212 1.41635 12.5956 1.53821 12.6464C1.66007 12.6971 1.79078 12.7233 1.92279 12.7233C2.0548 12.7233 2.18551 12.6971 2.30737 12.6464C2.42923 12.5956 2.53983 12.5212 2.63279 12.4275L6.92279 8.12747L11.2128 12.4275C11.3058 12.5212 11.4164 12.5956 11.5382 12.6464C11.6601 12.6971 11.7908 12.7233 11.9228 12.7233C12.0548 12.7233 12.1855 12.6971 12.3074 12.6464C12.4292 12.5956 12.5398 12.5212 12.6328 12.4275C12.7265 12.3345 12.8009 12.2239 12.8517 12.102C12.9025 11.9802 12.9286 11.8495 12.9286 11.7175C12.9286 11.5855 12.9025 11.4548 12.8517 11.3329C12.8009 11.211 12.7265 11.1004 12.6328 11.0075L8.33279 6.71747Z"
                    fill="#CA0F0F"
                  />
                </svg>
                Giảm giá 20% tất cả dịch vụ tư vấn trực tuyến và hàng hóa thiết yếu
              </p>
              <p class="fs-18 fw-400 mb-1 text-justify text-gray-500 text-decoration-line-through">
                <svg
                  class="mr-2"
                  width="13"
                  height="13"
                  viewBox="0 0 13 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.33279 6.71747L12.6328 2.42747C12.8211 2.23917 12.9269 1.98377 12.9269 1.71747C12.9269 1.45117 12.8211 1.19577 12.6328 1.00747C12.4445 0.819167 12.1891 0.713379 11.9228 0.713379C11.6565 0.713379 11.4011 0.819167 11.2128 1.00747L6.92279 5.30747L2.63279 1.00747C2.44449 0.819167 2.18909 0.713379 1.92279 0.713379C1.65649 0.713379 1.40109 0.819167 1.21279 1.00747C1.02449 1.19577 0.918699 1.45117 0.918699 1.71747C0.918699 1.98377 1.02449 2.23917 1.21279 2.42747L5.51279 6.71747L1.21279 11.0075C1.11906 11.1004 1.04467 11.211 0.993899 11.3329C0.943131 11.4548 0.916992 11.5855 0.916992 11.7175C0.916992 11.8495 0.943131 11.9802 0.993899 12.102C1.04467 12.2239 1.11906 12.3345 1.21279 12.4275C1.30575 12.5212 1.41635 12.5956 1.53821 12.6464C1.66007 12.6971 1.79078 12.7233 1.92279 12.7233C2.0548 12.7233 2.18551 12.6971 2.30737 12.6464C2.42923 12.5956 2.53983 12.5212 2.63279 12.4275L6.92279 8.12747L11.2128 12.4275C11.3058 12.5212 11.4164 12.5956 11.5382 12.6464C11.6601 12.6971 11.7908 12.7233 11.9228 12.7233C12.0548 12.7233 12.1855 12.6971 12.3074 12.6464C12.4292 12.5956 12.5398 12.5212 12.6328 12.4275C12.7265 12.3345 12.8009 12.2239 12.8517 12.102C12.9025 11.9802 12.9286 11.8495 12.9286 11.7175C12.9286 11.5855 12.9025 11.4548 12.8517 11.3329C12.8009 11.211 12.7265 11.1004 12.6328 11.0075L8.33279 6.71747Z"
                    fill="#CA0F0F"
                  />
                </svg>
                Được chuyên gia hướng dẫn chuyên biệt về dinh dưỡng
              </p>
            </div>
          </div>
          <div
            class="lg:m-0 mb-4 w-full"
            v-for="pac in list.slice().reverse()"
            :key="pac.id"
          >
            <div
              @click="goToSelect(pac.id)"
              :class="!isGold(pac.code) ? 'text-center rounded-44px-pri px-4 py-4 h-100 shadow-custom hover:bg-gray-200 cursor-pointer' : 'rounded-44px px-4 py-4 h-100 hover:bg-gray-200 cursor-pointer'"
            >
              <img
                class="h-16 mb-4"
                :src="getUrlImg(pac.package_icon)"
                alt=""
              />
              <p class="font-bold text-center text-2xl f-24 fw-600 mb-4">{{ pac.code }}</p>
              <p
                v-if="isGold(pac.code)"
                class="fs-30 fw-600 mt-5 mb-0 text-decoration-line-through text-gray-700"
              >350,000 VNĐ/tháng</p>
              <p
                v-else
                class="fs-30 fw-600 mt-5 mb-0 text-decoration-line-through text-gray-700"
              >120,000 VNĐ/tháng</p>
              <div class="mb-3 mt-16">
                <p
                  v-if="isGold(pac.code)"
                  class="mb-0 text-xl"
                >Từ <span class="lg:text-5xl text-4xl fw-600"> 188,000</span> VNĐ/tháng</p>
                <p
                  v-else
                  class="mb-0 text-xl"
                >Từ <span class="lg:text-5xl text-4xl fw-600"> 63,000</span> VNĐ/tháng</p>
              </div>
              <div v-if="!isGold(pac.code)">
                <p class="fs-18 fw-400 mb-1 text-justify">
                  <svg
                    width="16"
                    height="12"
                    viewBox="0 0 16 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.79573 9.1308L1.19893 5.51294L0 6.71889L4.79573 11.5427L15.0723 1.20595L13.8734 0L4.79573 9.1308Z"
                      fill="#20409B"
                    />
                  </svg>
                  Nhắn tin không giới hạn với Bác sĩ
                </p>
                <p class="fs-18 fw-400 mb-1 text-justify">
                  <svg
                    width="16"
                    height="12"
                    viewBox="0 0 16 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.79573 9.1308L1.19893 5.51294L0 6.71889L4.79573 11.5427L15.0723 1.20595L13.8734 0L4.79573 9.1308Z"
                      fill="#20409B"
                    />
                  </svg>
                  Gọi điện trực tiếp với Bác sĩ
                </p>
                <p class="fs-18 fw-400 mb-1 text-justify">
                  <svg
                    width="16"
                    height="12"
                    viewBox="0 0 16 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.79573 9.1308L1.19893 5.51294L0 6.71889L4.79573 11.5427L15.0723 1.20595L13.8734 0L4.79573 9.1308Z"
                      fill="#20409B"
                    />
                  </svg>
                  Chuyển đổi số các thông tin sức khỏe từ hình ảnh
                </p>
                <p class="fs-18 fw-400 mb-1 text-justify">
                  <svg
                    class="mr-2"
                    width="16"
                    height="12"
                    viewBox="0 0 16 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.79573 9.1308L1.19893 5.51294L0 6.71889L4.79573 11.5427L15.0723 1.20595L13.8734 0L4.79573 9.1308Z"
                      fill="#20409B"
                    />
                  </svg>
                  Lữu trữ phim Chẩn đoán hình ảnh (CT, MRI, PET/CT...)
                </p>
                <p class="fs-18 fw-400 mb-1 text-justify font-semibold">
                  <svg
                    class="mr-2"
                    width="16"
                    height="12"
                    viewBox="0 0 16 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.79573 9.1308L1.19893 5.51294L0 6.71889L4.79573 11.5427L15.0723 1.20595L13.8734 0L4.79573 9.1308Z"
                      fill="#20409B"
                    />
                  </svg>
                  <span class="text-red-500">15GB</span> lưu trữ dữ liệu trên HODO Cloud
                </p>
                <p class="fs-18 fw-400 mb-1 text-justify text-pri-900 font-semibold">
                  <svg
                    class="mr-2"
                    width="16"
                    height="12"
                    viewBox="0 0 16 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.79573 9.1308L1.19893 5.51294L0 6.71889L4.79573 11.5427L15.0723 1.20595L13.8734 0L4.79573 9.1308Z"
                      fill="#20409B"
                    />
                  </svg>
                  Xem và chia sẻ tài liệu CĐHA, HSSK cùng các thông tin, tài liệu sức khỏe
                </p>
                <p class="fs-18 fw-400 mb-1 text-justify text-gray-500 text-decoration-line-through">
                  <svg
                    class="mr-2"
                    width="13"
                    height="13"
                    viewBox="0 0 13 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.33279 6.71747L12.6328 2.42747C12.8211 2.23917 12.9269 1.98377 12.9269 1.71747C12.9269 1.45117 12.8211 1.19577 12.6328 1.00747C12.4445 0.819167 12.1891 0.713379 11.9228 0.713379C11.6565 0.713379 11.4011 0.819167 11.2128 1.00747L6.92279 5.30747L2.63279 1.00747C2.44449 0.819167 2.18909 0.713379 1.92279 0.713379C1.65649 0.713379 1.40109 0.819167 1.21279 1.00747C1.02449 1.19577 0.918699 1.45117 0.918699 1.71747C0.918699 1.98377 1.02449 2.23917 1.21279 2.42747L5.51279 6.71747L1.21279 11.0075C1.11906 11.1004 1.04467 11.211 0.993899 11.3329C0.943131 11.4548 0.916992 11.5855 0.916992 11.7175C0.916992 11.8495 0.943131 11.9802 0.993899 12.102C1.04467 12.2239 1.11906 12.3345 1.21279 12.4275C1.30575 12.5212 1.41635 12.5956 1.53821 12.6464C1.66007 12.6971 1.79078 12.7233 1.92279 12.7233C2.0548 12.7233 2.18551 12.6971 2.30737 12.6464C2.42923 12.5956 2.53983 12.5212 2.63279 12.4275L6.92279 8.12747L11.2128 12.4275C11.3058 12.5212 11.4164 12.5956 11.5382 12.6464C11.6601 12.6971 11.7908 12.7233 11.9228 12.7233C12.0548 12.7233 12.1855 12.6971 12.3074 12.6464C12.4292 12.5956 12.5398 12.5212 12.6328 12.4275C12.7265 12.3345 12.8009 12.2239 12.8517 12.102C12.9025 11.9802 12.9286 11.8495 12.9286 11.7175C12.9286 11.5855 12.9025 11.4548 12.8517 11.3329C12.8009 11.211 12.7265 11.1004 12.6328 11.0075L8.33279 6.71747Z"
                      fill="#CA0F0F"
                    />
                  </svg>
                  Giảm giá 20% tất cả dịch vụ tư vấn trực tuyến và hàng hóa thiết yếu
                </p>
                <p class="fs-18 fw-400 mb-1 text-justify text-gray-500 text-decoration-line-through">
                  <svg
                    class="mr-2"
                    width="13"
                    height="13"
                    viewBox="0 0 13 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.33279 6.71747L12.6328 2.42747C12.8211 2.23917 12.9269 1.98377 12.9269 1.71747C12.9269 1.45117 12.8211 1.19577 12.6328 1.00747C12.4445 0.819167 12.1891 0.713379 11.9228 0.713379C11.6565 0.713379 11.4011 0.819167 11.2128 1.00747L6.92279 5.30747L2.63279 1.00747C2.44449 0.819167 2.18909 0.713379 1.92279 0.713379C1.65649 0.713379 1.40109 0.819167 1.21279 1.00747C1.02449 1.19577 0.918699 1.45117 0.918699 1.71747C0.918699 1.98377 1.02449 2.23917 1.21279 2.42747L5.51279 6.71747L1.21279 11.0075C1.11906 11.1004 1.04467 11.211 0.993899 11.3329C0.943131 11.4548 0.916992 11.5855 0.916992 11.7175C0.916992 11.8495 0.943131 11.9802 0.993899 12.102C1.04467 12.2239 1.11906 12.3345 1.21279 12.4275C1.30575 12.5212 1.41635 12.5956 1.53821 12.6464C1.66007 12.6971 1.79078 12.7233 1.92279 12.7233C2.0548 12.7233 2.18551 12.6971 2.30737 12.6464C2.42923 12.5956 2.53983 12.5212 2.63279 12.4275L6.92279 8.12747L11.2128 12.4275C11.3058 12.5212 11.4164 12.5956 11.5382 12.6464C11.6601 12.6971 11.7908 12.7233 11.9228 12.7233C12.0548 12.7233 12.1855 12.6971 12.3074 12.6464C12.4292 12.5956 12.5398 12.5212 12.6328 12.4275C12.7265 12.3345 12.8009 12.2239 12.8517 12.102C12.9025 11.9802 12.9286 11.8495 12.9286 11.7175C12.9286 11.5855 12.9025 11.4548 12.8517 11.3329C12.8009 11.211 12.7265 11.1004 12.6328 11.0075L8.33279 6.71747Z"
                      fill="#CA0F0F"
                    />
                  </svg>
                  Được chuyên gia hướng dẫn chuyên biệt về dinh dưỡng
                </p>
              </div>
              <div v-else>
                <p class="fs-18 fw-400 mb-1 text-justify">
                  <svg
                    width="16"
                    height="12"
                    viewBox="0 0 16 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.79573 9.1308L1.19893 5.51294L0 6.71889L4.79573 11.5427L15.0723 1.20595L13.8734 0L4.79573 9.1308Z"
                      fill="#20409B"
                    />
                  </svg>
                  Nhắn tin không giới hạn với Bác sĩ
                </p>
                <p class="fs-18 fw-400 mb-1 text-justify">
                  <svg
                    width="16"
                    height="12"
                    viewBox="0 0 16 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.79573 9.1308L1.19893 5.51294L0 6.71889L4.79573 11.5427L15.0723 1.20595L13.8734 0L4.79573 9.1308Z"
                      fill="#20409B"
                    />
                  </svg>
                  Gọi điện trực tiếp với Bác sĩ
                </p>
                <p class="fs-18 fw-400 mb-1 text-justify">
                  <svg
                    width="16"
                    height="12"
                    viewBox="0 0 16 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.79573 9.1308L1.19893 5.51294L0 6.71889L4.79573 11.5427L15.0723 1.20595L13.8734 0L4.79573 9.1308Z"
                      fill="#20409B"
                    />
                  </svg>
                  Chuyển đổi số các thông tin sức khỏe từ hình ảnh
                </p>
                <p class="fs-18 fw-400 mb-1 text-justify">
                  <svg
                    class="mr-2"
                    width="16"
                    height="12"
                    viewBox="0 0 16 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.79573 9.1308L1.19893 5.51294L0 6.71889L4.79573 11.5427L15.0723 1.20595L13.8734 0L4.79573 9.1308Z"
                      fill="#20409B"
                    />
                  </svg>
                  Lữu trữ phim Chẩn đoán hình ảnh (CT, MRI, PET/CT...)
                </p>
                <p class="fs-18 fw-400 mb-1 text-justify font-semibold">
                  <svg
                    class="mr-2"
                    width="16"
                    height="12"
                    viewBox="0 0 16 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.79573 9.1308L1.19893 5.51294L0 6.71889L4.79573 11.5427L15.0723 1.20595L13.8734 0L4.79573 9.1308Z"
                      fill="#20409B"
                    />
                  </svg>
                  <span class="text-red-500">100GB</span> lưu trữ dữ liệu trên HODO Cloud
                </p>
                <p class="fs-18 fw-400 mb-1 text-justify">
                  <svg
                    width="16"
                    height="12"
                    viewBox="0 0 16 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.79573 9.1308L1.19893 5.51294L0 6.71889L4.79573 11.5427L15.0723 1.20595L13.8734 0L4.79573 9.1308Z"
                      fill="#20409B"
                    />
                  </svg>
                  Xem và chia sẻ tài liệu CĐHA, HSSK cùng các thông tin, tài liệu sức khỏe
                </p>
                <p class="fs-18 fw-400 mb-1 text-justify text-pri-900 font-semibold">
                  <svg
                    class="mr-2"
                    width="16"
                    height="12"
                    viewBox="0 0 16 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.79573 9.1308L1.19893 5.51294L0 6.71889L4.79573 11.5427L15.0723 1.20595L13.8734 0L4.79573 9.1308Z"
                      fill="#20409B"
                    />
                  </svg>
                  Giảm giá <span class="text-red-500">20% tất cả</span> dịch vụ tư vấn trực tuyến và hàng hóa thiết yếu
                </p>
                <p class="fs-18 fw-400 mb-1 text-justify text-pri-900 font-semibold">
                  <svg
                    class="mr-2"
                    width="16"
                    height="12"
                    viewBox="0 0 16 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.79573 9.1308L1.19893 5.51294L0 6.71889L4.79573 11.5427L15.0723 1.20595L13.8734 0L4.79573 9.1308Z"
                      fill="#20409B"
                    />
                  </svg>
                  Được chuyên gia hướng dẫn chuyên biệt về dinh dưỡng
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import appUtils from '../../utils/appUtils'
import moment from 'moment'
export default {
  name: 'PackageCombo',
  data () {
    return {
      list: []
    }
  },
  mounted () {
    this.getPackageMembers()

    const user = this.$user
    this.sendAmplitude('Select member package', {
      'Person ID': user.person?.id,
      'Person Name': user.person?.name,
      Timestamp: moment(new Date()).format('HH:mm DD-MM-YYYY')
    })
  },
  methods: {
    numberFormat (number) {
      return number.toLocaleString(undefined, { minimumFractionDigits: 0 })
    },
    isGold (code) {
      return code && (code.toUpperCase() === 'HODO GOLD' || code.toUpperCase() === 'HODO GOLD PLUS')
    },
    goToSelect (id) {
      this.$router.push({ path: `/chon-combo/${id}` })
    },
    async getPackageMembers () {
      const res = await this.$rf.getRequest('AuthRequest').getPackageMembers()
      this.list = res.data.data
    },
    getUrlImg (path) {
      return appUtils.getDocumentURL(path)
    },
    goToBack () {
      this.$router.go(-1)
    }
  }
}
</script>

  <style lang="css" scoped>
.fs-30 {
  font-size: 30px;
}
.border-bot {
  border-bottom: 2px solid #20409b;
}
.fs-64 {
  font-size: 64px;
}

.me-custom {
  margin-right: 40px;
}
@font-face {
  font-family: Montserrat-SemiBold;
  src: url("../../../public/font/Montserrat-SemiBold.ttf");
}
.montserrat-semibold div,
span {
  font-family: Montserrat-SemiBold !important;
}
.text-fill {
  caret-color: rgb(255, 255, 255);
  -webkit-text-stroke: 0.5px rgb(255, 255, 255);
  -webkit-text-fill-color: transparent;
}
.decoration-2 {
  text-decoration: 2px underline;
}
.rounded-44px {
  border-radius: 44px;
  border: 3px solid #6f768a;
}
.rounded-44px-pri {
  border-radius: 44px;
  border: 4px solid #20419b;
}
@font-face {
  font-family: NunitoRegular;
  src: url("../../../public/font/Nunito-Regular.ttf");
}
@font-face {
  font-family: NunitoBold;
  src: url("../../../public/font/NunitoSans-ExtraBold.ttf");
}
.NunitoRegular {
  font-family: NunitoRegular;
}
.nunitoBold {
  font-family: NunitoBold;
}
.fs-52 {
  font-size: 52px;
}
.shadow-custom {
  box-shadow: rgb(31, 69, 173) 0px 5px 15px;
}
.br-left {
  border-top-left-radius: 44px;
}
</style>
