<template>
  <div>
    <div class="select-doc">
      <div>
        <p class="fs-20 fw-500 txt-black my-3 ml-3"> Chẩn đoán hình ảnh</p>
      </div>
      <!-- item -->
      <div class="row">
        <div
          class="col-xl-3 col-lg-4 col-sm-6 mb-3"
          @click="modalUpload(true)"
          :class="$uploading || loading ? 'disabled' : ''"
        >
          <div class="p-3 radius-10 bg-gray-50 c-pointer item-wraper">
            <div class="upload item-doc radius-10 h-100 flex flex-col justify-center">
              <div class="bottom-item">
                <div class="w-100 flex justify-center mb-1">
                  <button class="btn btn-pri fs-10 text-sm flex items-center mt-4">
                    <svg
                      class="mr-1"
                      width="17"
                      height="17"
                      viewBox="0 0 17 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15.2878 7.92871H9.2878V1.92871C9.2878 1.66349 9.18244 1.40914 8.9949 1.2216C8.80737 1.03407 8.55301 0.928711 8.2878 0.928711C8.02258 0.928711 7.76823 1.03407 7.58069 1.2216C7.39315 1.40914 7.2878 1.66349 7.2878 1.92871V7.92871H1.2878C1.02258 7.92871 0.768226 8.03407 0.580689 8.2216C0.393153 8.40914 0.287796 8.66349 0.287796 8.92871C0.287796 9.19393 0.393153 9.44828 0.580689 9.63582C0.768226 9.82335 1.02258 9.92871 1.2878 9.92871H7.2878V15.9287C7.2878 16.1939 7.39315 16.4483 7.58069 16.6358C7.76823 16.8234 8.02258 16.9287 8.2878 16.9287C8.55301 16.9287 8.80737 16.8234 8.9949 16.6358C9.18244 16.4483 9.2878 16.1939 9.2878 15.9287V9.92871H15.2878C15.553 9.92871 15.8074 9.82335 15.9949 9.63582C16.1824 9.44828 16.2878 9.19393 16.2878 8.92871C16.2878 8.66349 16.1824 8.40914 15.9949 8.2216C15.8074 8.03407 15.553 7.92871 15.2878 7.92871Z"
                        fill="white"
                      />
                    </svg>
                    <span class="text-sm">Tải lên</span>
                  </button>
                </div>
              </div>
              <p class="w-100 text-center text-xs mb-0 mt-3">
                File hỗ trợ: <span class="text-blue-900 fw-500">RAR, ZIP</span>
              </p>
            </div>
          </div>
        </div>
        <div
          class="col-xl-3 col-lg-4 col-sm-6 mb-3"
          v-for="(doc, i) in uploadedPacs"
          :key="doc.id + i"
        >
          <div
            class="p-3 cursor-pointer radius-10 bg-gray-50 item-wraper"
            :class="doc.status === 2 ? 'c-pointer' : ''"
            :set="status = getStatus(doc.status)"
            @click="openPacs(doc)"
          >
            <div class="item-doc radius-10">
              <div class=" d-flex justify-content-center align-items-center py-3">
                <svg
                  width="97"
                  height="78"
                  viewBox="0 0 97 78"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M97 21.9286C97 17.5103 93.4183 13.9286 89 13.9286H44.5841C42.526 13.9286 40.5471 13.1354 39.0588 11.7139L29.1126 2.21466C27.6242 0.793185 25.6453 0 23.5872 0H8.00001C3.58173 0 0 3.58172 0 8V70C0 74.4183 3.58173 78 8 78H89C93.4183 78 97 74.4183 97 70V21.9286Z"
                    fill="#20419B"
                  />
                  <rect
                    x="13"
                    y="5"
                    width="77"
                    height="60"
                    rx="7"
                    fill="#F1BD19"
                  />
                  <path
                    d="M0 21.9286C0 17.5103 3.58172 13.9286 8 13.9286H52.4159C54.474 13.9286 56.4529 13.1354 57.9412 11.7139L67.8874 2.21466C69.3758 0.793185 71.3547 0 73.4128 0H89C93.4183 0 97 3.58172 97 8V70C97 74.4183 93.4183 78 89 78H8C3.58172 78 0 74.4183 0 70V21.9286Z"
                    fill="#476DD6"
                  />
                  <rect
                    x="74"
                    width="9"
                    height="9"
                    fill="white"
                  />
                  <rect
                    x="74"
                    y="18"
                    width="9"
                    height="9"
                    fill="white"
                  />
                  <rect
                    x="74"
                    y="36"
                    width="9"
                    height="9"
                    fill="white"
                  />
                  <rect
                    x="83"
                    y="9"
                    width="9"
                    height="9"
                    fill="white"
                  />
                  <rect
                    x="83"
                    y="27"
                    width="9"
                    height="9"
                    fill="white"
                  />
                  <rect
                    x="83"
                    y="45"
                    width="9"
                    height="9"
                    fill="white"
                  />
                  <rect
                    x="78"
                    y="54"
                    width="9"
                    height="15"
                    rx="1"
                    fill="#F1BD19"
                  />
                  <rect
                    x="80"
                    y="65"
                    width="5"
                    height="2"
                    rx="1"
                    fill="#476DD6"
                  />
                </svg>
              </div>
              <div class="bottom-item pt-2">
                <p class="mb-0 fs-14 max-line-1 txt-gray-blur">{{doc.name || ('Ảnh tài liệu ' + i)}}</p>
                <p class="mb-0 fs-14 max-line-1 txt-gray-blur">{{formatDMY(doc.created_at)}}</p>
                <div class="flex justify-between items-center">
                  <p
                    class="mb-0 fs-14 max-line-1"
                    :class="status ? status.class : ''"
                  >{{status.name || ''}}</p>
                  <div
                    class="cursor-pointer"
                    @click.stop="downloadPacs(doc)"
                  >
                    <svg
                      width="25"
                      height="24"
                      viewBox="0 0 25 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M19.0775 20H5.0775C4.81228 20 4.55793 20.1054 4.37039 20.2929C4.18286 20.4804 4.0775 20.7348 4.0775 21C4.0775 21.2652 4.18286 21.5196 4.37039 21.7071C4.55793 21.8946 4.81228 22 5.0775 22H19.0775C19.3427 22 19.5971 21.8946 19.7846 21.7071C19.9721 21.5196 20.0775 21.2652 20.0775 21C20.0775 20.7348 19.9721 20.4804 19.7846 20.2929C19.5971 20.1054 19.3427 20 19.0775 20ZM11.3675 17.71C11.4626 17.801 11.5747 17.8724 11.6975 17.92C11.8172 17.9729 11.9466 18.0002 12.0775 18.0002C12.2084 18.0002 12.3378 17.9729 12.4575 17.92C12.5803 17.8724 12.6924 17.801 12.7875 17.71L16.7875 13.71C16.9758 13.5217 17.0816 13.2663 17.0816 13C17.0816 12.7337 16.9758 12.4783 16.7875 12.29C16.5992 12.1017 16.3438 11.9959 16.0775 11.9959C15.8112 11.9959 15.5558 12.1017 15.3675 12.29L13.0775 14.59V3C13.0775 2.73478 12.9721 2.48043 12.7846 2.29289C12.5971 2.10536 12.3427 2 12.0775 2C11.8123 2 11.5579 2.10536 11.3704 2.29289C11.1829 2.48043 11.0775 2.73478 11.0775 3V14.59L8.7875 12.29C8.69426 12.1968 8.58357 12.1228 8.46175 12.0723C8.33993 12.0219 8.20936 11.9959 8.0775 11.9959C7.94564 11.9959 7.81507 12.0219 7.69325 12.0723C7.57143 12.1228 7.46074 12.1968 7.3675 12.29C7.27426 12.3832 7.2003 12.4939 7.14984 12.6158C7.09938 12.7376 7.07341 12.8681 7.07341 13C7.07341 13.1319 7.09938 13.2624 7.14984 13.3842C7.2003 13.5061 7.27426 13.6168 7.3675 13.71L11.3675 17.71Z"
                        fill="black"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="delete-btn p-0 radius-10 c-pointer"
              v-if="doc.status === 2"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21.25 9.14993C18.94 5.51993 15.56 3.42993 12 3.42993C10.22 3.42993 8.49 3.94993 6.91 4.91993C5.33 5.89993 3.91 7.32993 2.75 9.14993C1.75 10.7199 1.75 13.2699 2.75 14.8399C5.06 18.4799 8.44 20.5599 12 20.5599C13.78 20.5599 15.51 20.0399 17.09 19.0699C18.67 18.0899 20.09 16.6599 21.25 14.8399C22.25 13.2799 22.25 10.7199 21.25 9.14993ZM12 16.0399C9.76 16.0399 7.96 14.2299 7.96 11.9999C7.96 9.76993 9.76 7.95993 12 7.95993C14.24 7.95993 16.04 9.76993 16.04 11.9999C16.04 14.2299 14.24 16.0399 12 16.0399Z"
                  fill="#476DD6"
                />
                <path
                  d="M12.0004 9.13989C10.4304 9.13989 9.15039 10.4199 9.15039 11.9999C9.15039 13.5699 10.4304 14.8499 12.0004 14.8499C13.5704 14.8499 14.8604 13.5699 14.8604 11.9999C14.8604 10.4299 13.5704 9.13989 12.0004 9.13989Z"
                  fill="#476DD6"
                />
              </svg>
            </div>
          </div>
        </div>
        <div
          class="col-xl-3 col-lg-4 col-sm-6 mb-3"
          v-if="sltPac"
        >
          <div class="p-3 radius-10 bg-white item-wraper">
            <div class="item-doc radius-10">
              <div class=" d-flex justify-content-center align-items-center h-100 py-3">
                <svg
                  width="97"
                  height="78"
                  viewBox="0 0 97 78"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M97 21.9286C97 17.5103 93.4183 13.9286 89 13.9286H44.5841C42.526 13.9286 40.5471 13.1354 39.0588 11.7139L29.1126 2.21466C27.6242 0.793185 25.6453 0 23.5872 0H8.00001C3.58173 0 0 3.58172 0 8V70C0 74.4183 3.58173 78 8 78H89C93.4183 78 97 74.4183 97 70V21.9286Z"
                    fill="#20419B"
                  />
                  <rect
                    x="13"
                    y="5"
                    width="77"
                    height="60"
                    rx="7"
                    fill="#F1BD19"
                  />
                  <path
                    d="M0 21.9286C0 17.5103 3.58172 13.9286 8 13.9286H52.4159C54.474 13.9286 56.4529 13.1354 57.9412 11.7139L67.8874 2.21466C69.3758 0.793185 71.3547 0 73.4128 0H89C93.4183 0 97 3.58172 97 8V70C97 74.4183 93.4183 78 89 78H8C3.58172 78 0 74.4183 0 70V21.9286Z"
                    fill="#476DD6"
                  />
                  <rect
                    x="74"
                    width="9"
                    height="9"
                    fill="white"
                  />
                  <rect
                    x="74"
                    y="18"
                    width="9"
                    height="9"
                    fill="white"
                  />
                  <rect
                    x="74"
                    y="36"
                    width="9"
                    height="9"
                    fill="white"
                  />
                  <rect
                    x="83"
                    y="9"
                    width="9"
                    height="9"
                    fill="white"
                  />
                  <rect
                    x="83"
                    y="27"
                    width="9"
                    height="9"
                    fill="white"
                  />
                  <rect
                    x="83"
                    y="45"
                    width="9"
                    height="9"
                    fill="white"
                  />
                  <rect
                    x="78"
                    y="54"
                    width="9"
                    height="15"
                    rx="1"
                    fill="#F1BD19"
                  />
                  <rect
                    x="80"
                    y="65"
                    width="5"
                    height="2"
                    rx="1"
                    fill="#476DD6"
                  />
                </svg>
              </div>
              <div class="bottom-item pt-2">
                <p class="mb-0 fs-14 max-line-1">{{sltPac.name}}</p>
                <p class="mb-0 fs-14 text-danger">Đang chờ tải lên</p>
              </div>
            </div>
            <div
              class="delete-btn p-0 radius-10 c-pointer"
              @click="deletePac()"
            >
              <svg
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  width="30"
                  height="30"
                  rx="6"
                  fill="#DDE6F5"
                />
                <path
                  d="M24.0697 8.23C22.4597 8.07 20.8497 7.95 19.2297 7.86V7.85L19.0097 6.55C18.8597 5.63 18.6397 4.25 16.2997 4.25H13.6797C11.3497 4.25 11.1297 5.57 10.9697 6.54L10.7597 7.82C9.82967 7.88 8.89967 7.94 7.96967 8.03L5.92967 8.23C5.50967 8.27 5.20967 8.64 5.24967 9.05C5.28967 9.46 5.64967 9.76 6.06967 9.72L8.10967 9.52C13.3497 9 18.6297 9.2 23.9297 9.73C23.9597 9.73 23.9797 9.73 24.0097 9.73C24.3897 9.73 24.7197 9.44 24.7597 9.05C24.7897 8.64 24.4897 8.27 24.0697 8.23Z"
                  fill="#476DD6"
                />
                <path
                  d="M22.2297 11.14C21.9897 10.89 21.6597 10.75 21.3197 10.75H8.67975C8.33975 10.75 7.99975 10.89 7.76975 11.14C7.53975 11.39 7.40975 11.73 7.42975 12.08L8.04975 22.34C8.15975 23.86 8.29975 25.76 11.7897 25.76H18.2097C21.6997 25.76 21.8397 23.87 21.9497 22.34L22.5697 12.09C22.5897 11.73 22.4597 11.39 22.2297 11.14ZM16.6597 20.75H13.3297C12.9197 20.75 12.5797 20.41 12.5797 20C12.5797 19.59 12.9197 19.25 13.3297 19.25H16.6597C17.0697 19.25 17.4097 19.59 17.4097 20C17.4097 20.41 17.0697 20.75 16.6597 20.75ZM17.4997 16.75H12.4997C12.0897 16.75 11.7497 16.41 11.7497 16C11.7497 15.59 12.0897 15.25 12.4997 15.25H17.4997C17.9097 15.25 18.2497 15.59 18.2497 16C18.2497 16.41 17.9097 16.75 17.4997 16.75Z"
                  fill="#476DD6"
                />
              </svg>
            </div>
          </div>
        </div>
        <div class="col-12 text-center">
          <button
            class="btn radius-10 btn-pri btn-finish"
            @click="showSelectPacs()"
          >Quay lại</button>
        </div>
      </div>
      <!-- item -->
      <!-- Modal -->
      <div
        class="modal fade"
        id="modalUpload"
        tabindex="-1"
        aria-labelledby="modalUploadTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-sm modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header border-0">
              <h5
                class="modal-title fs-18 txt-black fw-500"
                id="modalUploadTitle"
              >Tải lên tệp</h5>
              <!-- <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button> -->
            </div>
            <div class="modal-body">
              <label class="fs-12 txt-black">Tên tài liệu <span class="text-danger">*</span></label>
              <input
                type="text"
                class="form-control txt-pri mb-0"
                v-model="name"
                placeholder="Nhập tên tài liệu ...."
                @input="err_name = ''"
              >
              <p class="mb-3 fs-12 text-danger">{{err_name}}</p>
              <!-- <p
              class="mb-2 fs-14 txt-pri"
              :set="count = selected_docs.length"
            ><span v-if="count">Đã chọn {{count}} files</span></p> -->
              <div
                class="upload-btn"
                @click="openPicker"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M14.7369 2.76175H8.08489C6.00489 2.75375 4.30089 4.41075 4.25089 6.49075V17.2277C4.20589 19.3297 5.87389 21.0697 7.97489 21.1147C8.01189 21.1147 8.04889 21.1157 8.08489 21.1147H16.0729C18.1629 21.0407 19.8149 19.3187 19.8029 17.2277V8.03775L14.7369 2.76175Z"
                    stroke="#476DD6"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M14.4756 2.75V5.659C14.4756 7.079 15.6246 8.23 17.0446 8.234H19.7986"
                    stroke="#476DD6"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M11.6416 9.90918V15.9502"
                    stroke="#476DD6"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M13.9869 12.2642L11.6419 9.90918L9.29688 12.2642"
                    stroke="#476DD6"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <span
                  class="fs-14 fw-500 txt-pri"
                  v-if="fileChoosed"
                >{{fileChoosed.name}}</span>
                <span
                  class="fs-14 fw-500 txt-pri"
                  v-if="!fileChoosed"
                >Định dạng hỗ trợ: .RAR .ZIP</span>
              </div>
              <p class="mb-3 fs-12 text-danger">{{err_file}}</p>
            </div>
            <div class="modal-footer border-0 justify-content-center">
              <button
                type="button"
                class="btn btn-pri radius-10 w-100"
                @click="onFinishUpload()"
              >Tải lên</button>
            </div>
          </div>
        </div>
      </div>
      <input
        type="file"
        ref="inputFile"
        accept=".zip, .rar"
        @change="onSelectCompressedFile($event)"
        multiple
        class="d-none"
      >
    </div>
    <ModalConfirmPac @submit="handler" />
  </div>
</template>

<script>
import ModalConfirmPac from './ModalConfirmPac.vue'
import moment from 'moment'
import downloadS3 from '../../utils/downloadS3'
const statuses = [
  { id: 1, name: 'Đang xử lý', class: 'text-warning' },
  { id: 2, name: 'Hoàn thành', class: 'text-success' },
  { id: 3, name: 'Không xử lý được tài liệu', class: 'text-danger' }
]
export default {
  name: 'UploadCompressedFile',
  components: { ModalConfirmPac },
  props: ['sltPac', 'uploadedPacs', 'loading', 'disease'],
  data () {
    return {
      bucket: null,
      fileChoosed: null,
      listFiles: [],
      statuses,
      err_file: '',
      err_name: '',
      name: '',
      user: null
    }
  },
  mounted () {
    this.user = this.$user
    if (this.$route.query.type === 'uploadPacs') {
      setTimeout(() => {
        const query = Object.assign({}, this.$route.query)
        delete query.type
        this.$router.replace({ query })
      }, 100)
    }
    // window.AWS.config.region = this.region // 1. Enter your region
    // window.AWS.config.credentials = new window.AWS.CognitoIdentityCredentials({
    //   IdentityPoolId: this.IdentityPoolId // 2. Enter your identity pool
    // })
    // window.AWS.config.credentials.get(function (err) {
    //   if (err) alert(err)
    //   // console.log(AWS.config.credentials);
    // })

    // this.bucket = new window.AWS.S3({
    //   params: {
    //     Bucket: this.bucketName
    //   }
    // })
    // this.listObjs()

    this.sendAmplitude('View List PACS', {
      'Person ID': this.user.person?.id,
      'Person Name': this.user.person?.name,
      Timestamp: moment(new Date()).format('HH:mm DD-MM-YYYY')
    })
  },
  computed: {
    isMemberPackage () {
      const pac = this.user?.redeem_package
      if (pac) {
        return pac
      }
      const packages = this.user?.user_packages || []
      const result = packages?.find(item => {
        return item.member_package?.code.toUpperCase() === 'HODO GOLD' || item.member_package?.code.toUpperCase() === 'HODO GOLD PLUS' || item.member_package?.code.toUpperCase() === 'HODO SILVER PLUS'
      })
      return result
    }
  },
  methods: {
    showSelectPacs (show) {
      this.$emit('showSelectPacs', show)
    },
    openPicker () {
      this.$refs.inputFile.click()
    },
    // onUpload () {
    //   const file = this.fileChoosed

    //   window.$uploadPacsFile(null, null, file)
    // },
    // listObjs () {
    //   const self = this
    //   this.bucket.listObjects({
    //     Prefix: this.prefix
    //   }, function (err, data) {
    //     if (err) {
    //       console.log('Lấy danh sách thất bại', err)
    //     } else {
    //       self.listFiles = data.Contents
    //     }
    //   })
    // },
    onSelectCompressedFile (e) {
      const self = this
      const file = e.target.files[0]
      self.err_file = ''
      if (file) {
        if (this.validateFile(file.name.split('.').pop())) {
          self.fileChoosed = file
        } else {
          alert('File không đúng định dạng nên đã bị loại bỏ')
        }
      }
      self.handleClearInput()
    },
    deletePac () {
      this.$emit('selectedPac', null)
    },
    onFinishUpload () {
      if (!this.name) {
        this.err_name = 'Vui lòng nhập tên tài liệu'
        return
      }
      if (!this.fileChoosed) {
        this.err_file = 'Vui lòng chọn tài liệu'
        return
      }
      this.finishUpload(this.fileChoosed, this.name)
      this.modalUpload(false)
      this.name = ''
      this.fileChoosed = null
      this.err_file = ''
      this.err_name = ''
    },
    finishUpload (files, name) {
      this.$emit('selectedPac', files, name)
    },
    getNamePacs (pac) {
      return pac.files.split('/').pop()
    },
    validateFile (ext) {
      return ['zip', 'rar'].findIndex(e => e === ext) > -1
    },
    handleClearInput () {
      this.$refs.inputFile.value = null
    },
    formatDMY (date) {
      return this.moment(date).format('DD/MM/YYYY')
    },
    getStatus (stt) {
      return this.statuses.find(s => s.id === stt) || this.statuses[0]
    },
    modalUpload (show) {
      window.$('#modalUpload').modal(show ? 'show' : 'hide')
    },
    openPacs (p) {
      if (!this.isMemberPackage) {
        this.modalConfirm(true)
      } else if (p.accession && p.status === 2) this.getUrl(p.accession)
    },
    downloadPacs (pac) {
      if (pac?.file) {
        this.callGetLinkDownloadAws(pac?.file?.file_name)
      } else {
        this.callGetLinkDownloadAws(pac.files)
      }
    },
    async callGetLinkDownloadAws (name) {
      downloadS3.downloadFilePac(name)
    },
    async getUrl (accession) {
      const self = this
      const params = {
        accession: accession
      }
      await self.$rf.getRequest('AuthRequest').getUrlPac(params).then((r) => {
        if (r.data) {
          window.open(r.data)
          self.sendAmplitude('View PACS file', {
            'Person ID': this.user.person?.id,
            'Person Name': this.user.person?.name,
            Timestamp: moment(new Date()).format('HH:mm DD-MM-YYYY')
          })
        }
      })
    },
    modalConfirm (show) {
      window.$('#modalConfirmPac').modal(show ? 'show' : 'hide')
    },
    handler (event) {
      if (event === 'continuePac') {
        this.$router.push({ name: 'PackageCombo' })
      } else {
        this.modalConfirm(false)
      }
      console.log('event', event)
    }
  }
}
</script>

<style scoped>
/* .pacs-file-picker {
  width: 100%;
  max-width: 910px;
  height: 100vh;
  max-height: 482px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff !important;
}
.pfp-empty {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  border: dashed 1px #20409B;
} */
.select-doc {
  width: 100%;
  max-width: 1328px;
  padding: 16px;
  min-height: calc(100vh - 75px);
  margin: 0 auto;
}
.item-wraper {
  position: relative;
}
.delete-btn {
  position: absolute;
  top: 5px;
  right: 5px;
}
.item-wraper {
  height: 216px;
}
.upload {
  border: 0.756458px dashed #20409b;
}
.upload-btn {
  width: 100%;
  border: 0.756458px dashed #20409b;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  border-radius: 10px;
  cursor: pointer;
}
.disabled {
  opacity: 0.8;
  cursor: not-allowed !important;
  pointer-events: none;
}
</style>
