<template>
    <div class="">
        <div class="container mx-auto">
            <!-- item -->
            <div class="text-center py-44 lg:pb-28">
                <div class="text-8xl lg:text-10xl nunitoBold mb-3 text-pri-900">{{$t('hodopro')}}</div>
                <div class="mt-6 text-xl lg:text-4xl px-4">
                    <p class="nunitoRegular lg:fs-48 text-pri-900">Mảnh ghép cuối cùng của</p>
                    <p class="nunitoRegular lg:fs-48 text-pri-900">Bệnh viện không giấy tờ</p>
                </div>
            </div>
            <!-- item -->
            <div class="lg:py-10 my-3">
                <div class="bg-pri-900 lg:flex lg:justify-between lg:items-center lg:px-12 px-12 py-5 lg:text-right text-center">
                    <div class="lg:pl-24 mb-4 lg:text-left">
                        <p class="text-5xl nunitoBold text-white mb-0">Future of</p>
                        <p class="text-5xl nunitoBold text-white mb-0">work</p>
                    </div>
                    <div class="lg:pr-24 lg:text-left text-justify">
                        <p class="fs-21 nunitoRegular text-white mb-0">HODO phát triển công nghệ để tăng cường và tăng tốc</p>
                        <p class="fs-21 nunitoRegular text-white mb-0">sự cộng tác giữa Bác sĩ, Nhân viên Y tế và bệnh nhân nhằm giúp con</p>
                        <p class="fs-21 nunitoRegular text-white mb-0">người đối diện với những khó khăn trong quá trình khám chữa bệnh.</p>
                        <p class="fs-24 nunitoRegular text-white mb-0"></p>
                    </div>
                </div>
            </div>

            <!-- item-6 -->
            <div class="my-3 lg:py-10 flex justify-center px-4">
              <img class="w-full lg:w-4/5" src="../../../public/images/home/6.png"/>
            </div>

            <RegisterFormHodoPro />

            <!-- item -->
            <div class="lg:py-10 my-3">
                <img class="w-full " src="../../../public/images/home/5.png"/>
            </div>
            <!-- item-1 -->
            <div class="flex flex-col-reverse justify-evenly items-center lg:py-10 my-3 lg:flex-row">
                <div class="text-center mb-3 order-1 lg:order-2 ml-2">
                    <img class="w-full lg:w-4/5" src="../../../public/images/home/1.png" />
                </div>
                <div class="text-center lg:text-left px-4">
                    <div class="lg:text-left lg:text-5xl text-2xl text-pri-900 nunitoBold lg:leading-tight lg:mb-12 max-w-xl pb-3">Nắm bắt và theo dõi toàn diện sức khỏe bệnh nhân</div>
                    <div class="text-pri-900 bg-white nunitoRegular text-justify flex mb-4">
                        <ul class="lg:px-0 px-3 max-w-lg">
                            <li class="lg:px-0 lg:text-2xl text-xl nunitoRegular text-pri-900 mb-2">Quản lý và chăm sóc dài hạn cho bệnh nhân với <span class="nunitoBold">Hồ sơ sức khỏe:</span> có khả năng tích hợp với bệnh án điện tử, thể hiện toàn diện và chính xác về lịch sử, diễn biến và tình trạng của bệnh nhân; có thể được chia sẻ với nhiều Bác sĩ để phối hợp chăm sóc bệnh nhân hiệu quả nhất.</li>
                            <li class="lg:px-0 lg:text-2xl text-xl nunitoRegular text-pri-900">Tất cả chỉ số và thông tin được gom lại và sắp xếp khoa học, trực quan trên một màn hình duy nhất.</li>
                        </ul>
                    </div>
                </div>
            </div>
            <!-- item-2 -->
            <div class="flex flex-col justify-evenly items-center lg:py-10 my-3 lg:flex-row">
                <div class="text-center mb-3">
                    <img class="w-full lg:w-11/12 lg:pr-12" src="../../../public/images/home/2.png" />
                </div>
                <div class="text-center lg:text-left px-4">
                    <div class="lg:text-left lg:text-5xl text-2xl text-pri-900 nunitoBold lg:leading-tight lg:mb-12 max-w-lg pb-3">Quản lý toàn diện trạng thái của công việc</div>
                    <div class="text-pri-900 bg-white nunitoRegular text-justify flex mb-4">
                        <ul class="lg:px-0 px-3 max-w-lg">
                            <li class="lg:text-2xl text-xl lg:px-0 text-pri-900 nunitoRegular mb-2">Tất cả các tính năng phục vụ công việc hằng ngày như theo dõi Hồ sơ sức khỏe của bệnh nhân, quản lý lịch làm việc, tham gia hội chẩn đều được phát triển trong một ứng dụng duy nhất.</li>
                            <li class="lg:text-2xl text-xl lg:px-0 text-pri-900 nunitoRegular">Lịch làm việc hiển thị một cách khoa học các đầu mục và trạng thái hoạt động của tất cả sự kiện sẽ diễn ra trên cùng một danh sách.</li>
                        </ul>
                    </div>
                </div>
            </div>
            <!-- item-3 -->
            <div class="lg:grid lg:grid-cols-3 lg:gap-3 lg:py-10 my-3">
                <div class="mb-3 lg:pr-5 px-4">
                    <p class="font-bold text-pri-900 mb-4 flex justify-start">
                        <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M33.25 5.25H8.75C6.825 5.25 5.25 6.825 5.25 8.75V33.25C5.25 35.175 6.825 36.75 8.75 36.75H33.25C35.175 36.75 36.75 35.175 36.75 33.25V8.75C36.75 6.825 35.175 5.25 33.25 5.25ZM33.25 33.25H8.75V8.75H33.25V33.25ZM31.4825 15.75L29.015 13.265L17.4825 24.7975L12.9675 20.3L10.4825 22.7675L17.4825 29.75L31.4825 15.75Z" fill="#20409B"/>
                        </svg>
                        <span class="text-xl lg:text-3xl ml-2">Dữ liệu dành cho báo cáo</span></p>
                    <ul>
                        <li class="lg:px-0 lg:text-2xl text-xl nunitoRegular text-pri-900 text-justify">Các số liệu được thống kê chi tiết theo thời gian thực như số hồ sơ sức khỏe, lịch khám bệnh, số ca bệnh đã hoàn thành hoặc điều chuyển,... dễ dàng được chuyển đồi thành dữ liệu dành cho báo cáo.
                        </li>
                    </ul>
                </div>
                <div class="mb-3 lg:pr-5 px-4">
                        <p class="font-bold text-pri-900 mb-4 flex justify-start">
                        <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M33.25 5.25H8.75C6.825 5.25 5.25 6.825 5.25 8.75V33.25C5.25 35.175 6.825 36.75 8.75 36.75H33.25C35.175 36.75 36.75 35.175 36.75 33.25V8.75C36.75 6.825 35.175 5.25 33.25 5.25ZM33.25 33.25H8.75V8.75H33.25V33.25ZM31.4825 15.75L29.015 13.265L17.4825 24.7975L12.9675 20.3L10.4825 22.7675L17.4825 29.75L31.4825 15.75Z" fill="#20409B"/>
                        </svg>
                        <span class="text-xl lg:text-3xl ml-2">Khả năng liên thông linh hoạt</span></p>
                        <ul>
                        <li class="lg:px-0 lg:text-2xl text-xl nunitoRegular text-pri-900 text-justify mb-4">Bệnh Án Điện Tử (EMR) có thể liên thông với tất cả các hệ thống Quản lý bệnh viện (HIS,LIS).
                        </li>
                        <li class="lg:px-0 lg:text-2xl text-xl nunitoRegular text-pri-900 text-justify">Có thể tùy chỉnh chủ động cho khớp với mẫu bệnh án của các chuyên khoa khác nhau mà không cần can thiệp của HODO.</li>
                    </ul>
                </div>
                <div class="lg:pr-5 px-4">
                    <p class="font-bold text-pri-900 mb-4 d-flex justify-start">
                        <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M33.25 5.25H8.75C6.825 5.25 5.25 6.825 5.25 8.75V33.25C5.25 35.175 6.825 36.75 8.75 36.75H33.25C35.175 36.75 36.75 35.175 36.75 33.25V8.75C36.75 6.825 35.175 5.25 33.25 5.25ZM33.25 33.25H8.75V8.75H33.25V33.25ZM31.4825 15.75L29.015 13.265L17.4825 24.7975L12.9675 20.3L10.4825 22.7675L17.4825 29.75L31.4825 15.75Z" fill="#20409B"/>
                        </svg>
                        <span class="text-xl lg:text-3xl ml-2">Có thể triển khai ngay lập tức</span></p>
                        <ul>
                        <li class="lg:px-0 text-xl lg:text-2xl nunitoRegular text-pri-900 text-justify mb-4">Có sẵn App trên Mobile, trình duyệt máy tính.
                        </li>
                        <li class="lg:px-0 text-xl lg:text-2xl nunitoRegular text-pri-900 text-justify">Sẵn sàng triển khai trên trên mọi thiết bị, truy cập được mọi lúc mọi nơi.</li>
                    </ul>
                </div>
            </div>
            <!-- item-4 -->
            <div class="mb-3">
                    <p class="text-center lg:text-5xl text-xl text-pri-900 nunitoBold lg:leading-tight mb-0 lg:mb-2">Nền tảng cộng tác toàn diện dành</p>
                    <p class="text-center lg:text-5xl text-2xl text-pri-900 nunitoBold lg:leading-tight lg:mb-12">cho Nhân viên Y tế</p>
                </div>
            <div class="flex flex-col-reverse justify-evenly items-center lg:py-10 my-3 lg:flex-row">
                <div class="text-center lg:text-left px-4">
                    <div class="lg:text-left lg:text-4xl text-2xl text-pri-900 nunitoBold lg:leading-tight lg:mb-5">Hội chẩn trực tuyến (MDR)</div>
                    <div class="text-pri-900 bg-white nunitoRegular text-justify flex mb-4">
                        <ul class="lg:px-0 px-3 max-w-xl">
                            <li class="lg:px-0 lg:text-2xl text-xl nunitoRegular text-pri-900 mb-2">Tăng cường sự cộng tác chuyên môn giữa các Bác sĩ, Nhân viên Y tế và chuyên gia giữa các tuyến, các cơ sở bằng việc tổ chức hội chẩn trực tuyến liên tuyến.</li>
                            <li class="lg:px-0 lg:text-2xl text-xl nunitoRegular text-pri-900 mb-2">Tất cả các thành viên tham gia đều có thể xem và thao tác trên file chẩn đoán hình ảnh một cách độc lập.</li>
                            <li class="lg:px-0 lg:text-2xl text-xl nunitoRegular text-pri-900">Tự động trích xuất Biên bản hội chẩn - thông qua mổ theo chuẩn, sẵn sàng để in.</li>
                        </ul>
                    </div>
                </div>
                <div class="text-center mb-3 ml-2">
                    <img class="w-full lg:w-4/5" src="../../../public/images/home/HODO-Pro.png" />
                </div>
            </div>
            <!-- item-5 -->
            <div class="flex flex-col justify-evenly items-center lg:py-10 my-3 lg:flex-row px-4">
                <div class="text-center mb-3">
                    <img class="w-full lg:w-11/12 lg:pr-12" src="../../../public/images/home/3.png" />
                </div>
                <div class="text-center lg:text-left">
                    <div class="lg:text-left lg:text-5xl text-2xl text-pri-900 nunitoBold lg:leading-tight lg:mb-12 max-w-lg pb-3">Mở rộng kết nối, Nâng tầm chuyên môn!</div>
                    <div class="text-pri-900 bg-white nunitoRegular text-justify flex mb-4">
                        <ul class="lg:px-0 px-3 max-w-lg">
                            <li class="lg:px-0 text-xl lg:text-2xl nunitoRegular text-pri-900 mb-2">Kết nối nhóm chuyên môn, chuyên gia giữa các tuyến.</li>
                            <li class="lg:px-0 text-xl lg:text-2xl nunitoRegular text-pri-900 mb-2">Chia sẻ tài nguyên trên nền tảng bảo mật chuyên nghiệp.</li>
                            <li class="lg:px-0 text-xl lg:text-2xl nunitoRegular text-pri-900">Tham gia Hội chẩn và họp trực tuyến .</li>
                        </ul>
                    </div>
                </div>
            </div>
            <!-- item-7 -->
            <div class="lg:flex lg:justify-center lg:items-center text-pri-900 lg:py-10 my-3 px-4">
                <div class="">
                    <img class="w-full lg:w-4/5" src="../../../public/images/home/4.png" alt="">
                </div>
                <div class="">
                    <div class="text-center">
                        <div class="lg:px-0 text-3xl lg:text-5xl mb-5 mt-14 lg:mt-20 nunitoBold lg:text-left">{{$t('app-hodo.Letgo')}}</div>
                    </div>
                        <div class="flex items-center mb-4">
                                <div class="w-16 lg:w-24 mr-5 lg:mr-5 lg:text-2xl nunitoBold">{{$t('app-hodo.step1')}}</div>
                                <div class="lg:flex lg:justify-center lg:items-center text-center w-4/6">
                                    <div onclick="location.href='https://hodo.app/#/sign-up'" class="bg-pri-900 rounded-full cursor-pointer text-white py-2 nunitoBold" :class="$t('app-hodo.img') === 'ENG' ? 'px-4 nunitoBold' : 'px-2 w-100'">
                                        Đăng ký tài khoản HODO Pro
                                    </div>
                                </div>
                        </div>
                        <div class="flex mb-4">
                            <div class="mr-5"> <div class="w-16 lg:w-24 nunitoBold lg:text-2xl">{{$t('app-hodo.step2')}}</div></div>
                                <div class="nunitoRegular text-justify w-72 lg:w-110 lg:text-2xl text-xl">
                                    Tiến hành xác thực danh tính với HODO Pro.
                                </div>
                        </div>
                        <div class="flex mb-4">
                            <div class="mr-5"> <div class="w-16 lg:w-24 nunitoBold lg:text-2xl">{{$t('app-hodo.step3')}}</div> </div>
                            <div class="nunitoRegular text-justify w-72 lg:w-110 lg:text-2xl text-xl">
                                Thêm Hồ sơ Sức khỏe của bệnh nhân để quản lý và chăm sóc dài hạn cho người bệnh.
                            </div>
                        </div>
                        <div class="flex mb-4">
                            <div class="mr-5"> <div class="w-16 lg:w-24 nunitoBold lg:text-2xl">{{$t('app-hodo.step4')}}</div> </div>
                            <div class="nunitoRegular text-justify w-72 lg:w-110 lg:text-2xl text-xl">
                                Khám phá HODO Pro.
                            </div>
                        </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import RegisterFormHodoPro from '@/pages/HodoPro/RegisterForm.vue'

export default {
  name: 'HodoProDetail',
  components: { RegisterFormHodoPro }
}
</script>

<style lang="css" scoped>
@font-face {
    font-family: Montserrat-SemiBold;
    src: url("../../../public/font/Montserrat-SemiBold.ttf");
}
@font-face {
  font-family: NunitoRegular;
  src: url('../../../public/font/Nunito-Regular.ttf');
}
@font-face {
  font-family: NunitoBold;
  src: url('../../../public/font/NunitoSans-ExtraBold.ttf');
}
.nunitoRegular {
  font-family: NunitoRegular;
}
.nunitoBold {
  font-family: NunitoBold;
}

.montserrat-semibold div,
span {
    font-family: Montserrat-SemiBold !important;
}
.fs-21 {
    font-size: 20px;
}
.section-3 {
    background-color: #20409b;
}

.fs-64 {
    font-size: 64px;
}
.fs-26 {
    font-size: 26px;
}

.me-custom {
    margin-right: 30px;
}

.height-150 {
    height: 150px;
}

.bg-bottom {
    height: 20px;
    background-color: #004aad;
}
.fs-52 {
    font-size: 52px;
}
</style>
