<template>
  <div class="pt-3 pt-lg-5 pb-5 mx-auto container-detail-diagnose">
    <BreadCrumbCpn :breadcrumb="breadcrumb"></BreadCrumbCpn>
    <div class="flex justify-end">
      <div class="flex items-center gap-2 justify-center btn-print">
        <img src="/icons/icon-print.svg" alt />
        <span>In kết quả</span>
      </div>
    </div>
  </div>
</template>

<script>
import appUtils from '../../utils/appUtils'
import BreadCrumbCpn from '@/components/Layout/BreadcrumbCpn.vue'
export default {
  name: 'Diagnose',
  components: { BreadCrumbCpn },
  data () {
    return {
      appUtils
    }
  },
  computed: {
    breadcrumb () {
      return [
        {
          text: this.$t('KQ xét nghiệm, chẩn đoán hình ảnh'),
          to: {
            name: 'Diagnose'
          }
        },
        {
          text: this.$t(
            'Siêu âm ổ bụng (Gan, Mật, Tụy, Lách, Thận, Bàng Quang)'
          )
        }
      ]
    }
  },
  mounted () {
    //
  },
  methods: {
    //
  }
}
</script>

<style scoped lang="scss">
.container-detail-diagnose {
  max-width: 80%;
}
.btn-print {
  border: 1px solid #20419b;
  border-radius: 6px;
  padding: 8px 20px;
  font-weight: 500;
  color: #20419b;
  &:hover {
    cursor: pointer;
    background: #ecf5ff;
  }
}
</style>
