<template>
  <div class="full">
    <div class="care-team">
      <div
        class="d-flex align-items-center c-pointer"
        @click="goToDisease()"
      >
        <img
          src="../../../public/icons/lessthan-blur-gray.svg"
          alt=""
        >
        <p class="mb-0 txt-gray-blur fs-14 fw-500">Quay lại</p>
      </div>
      <p class="fs-28 fw-500 txt-black mb-3">Mời bác sĩ</p>
      <CareTeamComponent
        :doctors="doctors"
        :main_doctor="main_doctor"
        :supporters="supporters"
        @onSelectDoctor="onSelectDoctor"
        @onDeleteDoctor="onDeleteDoctor"
        :care_team="care_team"
      />
      <div class="pt-4">
        <button
          class="btn btn-pri w-50 radius-15 m-auto"
          @click="onClose()"
        >Hoàn thành</button>
      </div>
    </div>
  </div>
</template>

<script>
import CareTeamComponent from '../../components/Diseases/CareTeamComponent.vue'
export default {
  name: 'CareTeam',
  components: { CareTeamComponent },
  data () {
    return {
      pdid: null,
      disease: null,
      care_team: null,
      doctors: [],
      main_doctor: null,
      supporters: []
    }
  },
  mounted () {
    const self = this
    self.pdid = self.$route.params.pdid
    if (!self.pdid) self.$router.go(-1)
    self.getPersonDisease()
  },
  methods: {
    onClose () {
      this.$router.push({ path: '/hssk' })
    },
    async getPersonDisease () {
      const self = this
      await self.$rf.getRequest('AuthRequest').getDisease(self.$route.params.pdid, false).then((r) => {
        self.disease = r.data
        self.care_team = r.data?.care_team
        if (r.data?.care_team?.care_team_doctor) {
          self.setDoctor(r.data?.care_team?.care_team_doctor)
        }
      }).catch((e) => {
        window.$toast.open({
          message: 'Bạn không có quyền xem hồ sơ này hoặc đã có lỗi xảy ra',
          type: 'error'
        })
      })
    },
    setDoctor (doctors = []) {
      const self = this
      self.doctors = []
      self.main_doctor = null
      self.supporters = []
      if (doctors) {
        doctors.forEach((d) => {
          if (d.role === 1) self.main_doctor = d
          if (d.role === 2) self.supporters.push(d)
          if (d.role === 4) self.doctors.push(d)
        })
      }
    },
    async onDeleteDoctor (ctd) {
      const r = confirm('Bạn có chắc chắn muốn xóa người này ra khỏi danh sách?')
      if (!r) return false
      const self = this
      const params = {
        person_diseases_id: ctd.person_diseases_id,
        doctor_id: ctd.doctor_id,
        clinic_id: ctd.clinic_id
      }
      await self.$rf.getRequest('AuthRequest').deleteDoctor(params, false).then((r) => {
        self.getPersonDisease()
        window.$toast.open({
          message: 'Đã xóa thành công',
          type: 'success'
        })
      }).catch((e) => {
        window.$toast.open({
          message: 'Bạn không có quyền thao tác hồ sơ này hoặc đã có lỗi xảy ra',
          type: 'error'
        })
      })
    },
    onSelectDoctor (type) {
      this.$router.push({ path: `/hssk/${this.$route.params.pdid}/moi-bac-si/${type}/chon-bac-si` })
    },
    goToDisease () {
      this.$router.push({ path: `/hssk/${this.$route.params.pdid}/view` })
    }
  }
}
</script>

<style scoped>
.full {
  background-color: #f7f7f9;
}
.care-team {
  width: 100%;
  max-width: 732px;
  padding: 16px;
  margin: 0 auto;
}
.btn {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0.10000000149011612px;
  text-align: center;
}
</style>
