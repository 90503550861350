<template>
  <div class="full">
    <div class="detail">
      <p class="fw-500 fs-20 txt-pri">Thông tin bác sĩ</p>
      <div class="d-flex mb-3">
        <div class="avatar">
          <img
            v-if="doctor.user && doctor.user.avatar"
            :src="getUrlImg(doctor.user.avatar)"
            alt=""
          >
          <p
            v-if="doctor.user && !doctor.user.avatar"
            class="fs-20 fw-500 mb-0"
          >{{doctor.name.charAt(0)}}</p>
        </div>
        <div class="info ps-2">
          <p class="fs-16 fw-500 txt-gray mb-0">{{doctor.name}}</p>
          <p class="fs-14 txt-gray-blur mb-0">{{doctor.chuc_vu}}</p>
          <p class="fs-14 txt-gray-blur mb-0">{{doctor.work}}</p>
        </div>
      </div>
      <div class="detail-info-wraper bg-white p-3 radius-10 mb-3 border">
        <div class="detail-info-header border-bottom d-flex align-items-center pb-2">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="me-2"
          >
            <path
              d="M9.28956 8.95248C9.28956 9.16414 9.46119 9.33576 9.67284 9.33576H11.5952V14.8104H9.67284C9.46119 14.8104 9.28956 14.982 9.28956 15.1936C9.28956 15.4053 9.46119 15.5769 9.67284 15.5769H12.0066V21.0277C9.28828 20.5388 5.72585 17.711 5.03551 17.1459C4.9563 17.0811 4.89796 16.9942 4.86985 16.8954C4.69439 16.2792 4.6535 15.5433 4.6782 14.8295C4.68289 14.6907 4.69013 14.5527 4.6995 14.4164V14.416L5.32468 11.2024C5.32468 11.2024 5.32511 11.2019 5.32468 11.2015C5.45287 10.7701 5.54145 10.3464 5.59894 9.93667C5.89109 7.86139 5.38558 6.1349 5.16498 5.511C5.12324 5.39303 5.21566 5.27123 5.34044 5.2789C11.2677 5.646 12.0066 4 12.0066 4V8.56919H9.67284C9.46119 8.56919 9.28956 8.74082 9.28956 8.95248Z"
              fill="#476DD6"
            />
            <path
              d="M5.12944 8.85926L3.67338 9.77441V11.6748H1.62695C1.53287 11.6748 1.47693 11.57 1.52894 11.4919L2.3433 10.2705C2.36966 10.2309 2.36966 10.1794 2.3433 10.1401L1.52894 8.91867C1.47693 8.84031 1.53287 8.7356 1.62695 8.7356H5.09361C5.16088 8.7356 5.18607 8.82344 5.12944 8.85926Z"
              fill="#476DD6"
            />
            <path
              d="M18.9209 8.85926L20.3769 9.77441V11.6748H22.4233C22.5174 11.6748 22.5734 11.57 22.5214 11.4919L21.707 10.2705C21.6806 10.2309 21.6806 10.1794 21.707 10.1401L22.5214 8.91867C22.5734 8.84031 22.5174 8.7356 22.4233 8.7356H18.9567C18.8894 8.7356 18.8642 8.82344 18.9209 8.85926Z"
              fill="#476DD6"
            />
            <g opacity="0.4">
              <path
                d="M14.7235 8.95248C14.7235 9.16414 14.5519 9.33576 14.3403 9.33576H12.4179V14.8104H14.3403C14.5519 14.8104 14.7235 14.982 14.7235 15.1936C14.7235 15.4053 14.5519 15.5769 14.3403 15.5769H12.0065V21.0277C14.7248 20.5388 18.2872 17.711 18.9776 17.1459C19.0568 17.0811 19.1151 16.9942 19.1433 16.8954C19.3187 16.2792 19.3596 15.5433 19.3349 14.8295C19.3302 14.6907 19.323 14.5527 19.3136 14.4164V14.416L18.6884 11.2024C18.6884 11.2024 18.688 11.2019 18.6884 11.2015C18.5602 10.7701 18.4716 10.3464 18.4142 9.93667C18.122 7.86139 18.6275 6.1349 18.8481 5.511C18.8899 5.39303 18.7974 5.27123 18.6727 5.2789C12.7454 5.646 12.0065 4 12.0065 4V8.56919H14.3403C14.5519 8.56919 14.7235 8.74082 14.7235 8.95248Z"
                fill="#476DD6"
              />
              <path
                d="M5.16059 8.78271V10.2642H3.67333L3.28198 9.87285L4.04457 9.11026L5.16059 8.78271Z"
                fill="#476DD6"
              />
              <path
                d="M18.8897 8.78271V10.2642H20.377L20.7683 9.87285L20.0057 9.11026L18.8897 8.78271Z"
                fill="#476DD6"
              />
              <path
                d="M5.75769 9.77429C5.72649 9.99666 5.67841 10.2267 5.60883 10.4608C5.60906 10.4611 5.60883 10.4613 5.60883 10.4613L5.26949 12.2056V12.2058C5.26441 12.2798 5.26048 12.3547 5.25793 12.4301H3.67358V9.77429H5.75769Z"
                fill="#476DD6"
              />
              <path
                d="M18.2926 9.77429C18.3238 9.99666 18.3719 10.2267 18.4415 10.4608C18.4412 10.4611 18.4415 10.4613 18.4415 10.4613L18.7808 12.2056V12.2058C18.7859 12.2798 18.7898 12.3547 18.7924 12.4301H20.3767V9.77429H18.2926Z"
                fill="#476DD6"
              />
            </g>
          </svg>
          <p class="fs-16 fw-500 txt-pri mb-0">Lĩnh vực nổi bật</p>
        </div>
        <div class="detail-info-body pt-2">
          <p class="fs-14 fw-500 txt-pri mb-0">{{doctor.majors}}</p>

        </div>
      </div>

      <div class="detail-info-wraper bg-white p-3 radius-10 mb-3 border">
        <div class="detail-info-header border-bottom d-flex align-items-center pb-2">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="me-2"
          >
            <path
              opacity="0.4"
              d="M18.8088 9.021C18.3573 9.021 17.7592 9.011 17.0146 9.011C15.1987 9.011 13.7055 7.508 13.7055 5.675V2.459C13.7055 2.206 13.5036 2 13.253 2H7.96363C5.49517 2 3.5 4.026 3.5 6.509V17.284C3.5 19.889 5.59022 22 8.16958 22H16.0463C18.5058 22 20.5 19.987 20.5 17.502V9.471C20.5 9.217 20.299 9.012 20.0475 9.013C19.6247 9.016 19.1177 9.021 18.8088 9.021Z"
              fill="#476DD6"
            />
            <path
              opacity="0.4"
              d="M16.0839 2.56725C15.7849 2.25625 15.2629 2.47025 15.2629 2.90125V5.53825C15.2629 6.64425 16.1739 7.55425 17.2799 7.55425C17.9769 7.56225 18.9449 7.56425 19.7669 7.56225C20.1879 7.56125 20.4019 7.05825 20.1099 6.75425C19.0549 5.65725 17.1659 3.69125 16.0839 2.56725Z"
              fill="#476DD6"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M8.97386 11.3876H12.3589C12.7699 11.3876 13.1039 11.0546 13.1039 10.6436C13.1039 10.2326 12.7699 9.89856 12.3589 9.89856H8.97386C8.56286 9.89856 8.22986 10.2326 8.22986 10.6436C8.22986 11.0546 8.56286 11.3876 8.97386 11.3876ZM8.97396 16.3818H14.418C14.829 16.3818 15.163 16.0488 15.163 15.6378C15.163 15.2268 14.829 14.8928 14.418 14.8928H8.97396C8.56296 14.8928 8.22996 15.2268 8.22996 15.6378C8.22996 16.0488 8.56296 16.3818 8.97396 16.3818Z"
              fill="#476DD6"
            />
          </svg>

          <p class="fs-16 fw-500 txt-pri mb-0">Giới thiệu</p>
        </div>
        <div class="detail-info-body pt-2">
          <p class="fs-14 mb-0">{{doctor.gioi_thieu || 'Đang cập nhật'}}</p>
        </div>
      </div>

      <div class="detail-info-wraper bg-white p-3 radius-10 mb-3 border">
        <div class="detail-info-header border-bottom d-flex align-items-center pb-2">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="me-2"
          >
            <g clip-path="url(#clip0_2572_12598)">
              <path
                d="M17.5585 18.2036L17.5568 18.2054"
                stroke="#525152"
                stroke-width="0.182967"
                stroke-miterlimit="10"
              />
              <path
                opacity="0.4"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11.9781 20.2968C16.9296 20.2968 20.9435 16.2828 20.9435 11.3314C20.9435 6.37991 16.9296 2.36597 11.9781 2.36597C7.02664 2.36597 3.0127 6.37991 3.0127 11.3314C3.0127 16.2828 7.02664 20.2968 11.9781 20.2968ZM13.2716 8.0808L12.1234 4.49983C12.0789 4.3571 11.8792 4.3571 11.8328 4.49983L10.6846 8.0808C10.665 8.14404 10.6062 8.1874 10.5402 8.1874H6.82455C6.67657 8.1874 6.61595 8.37891 6.7354 8.46744L9.74143 10.6789C9.79492 10.7186 9.81631 10.7891 9.7967 10.8523L8.64849 14.4333C8.60392 14.576 8.76438 14.6953 8.88384 14.6068L11.8881 12.3935C11.9416 12.3538 12.0147 12.3538 12.0682 12.3935L15.0742 14.6086C15.1936 14.6971 15.3559 14.5779 15.3095 14.4351L14.1613 10.8542C14.1399 10.7891 14.1631 10.7205 14.2166 10.6807L17.2208 8.46744C17.3403 8.37891 17.2797 8.1874 17.1317 8.1874H13.4161C13.3501 8.1874 13.2912 8.14404 13.2716 8.0808Z"
                fill="#476DD6"
              />
              <path
                d="M6.44149 18.2055V19.6235H1.20862L3.82505 16.7729L1.20862 13.9241H3.44082C4.00985 15.6293 5.07472 17.1187 6.44149 18.2055Z"
                fill="#476DD6"
              />
              <path
                d="M20.175 16.7729L22.7915 19.6235H17.5586V18.2037C18.9254 17.1187 19.9902 15.6293 20.5593 13.9241H22.7915L20.175 16.7729Z"
                fill="#476DD6"
              />
              <path
                d="M17.6025 16.147H6.39571C6.00768 16.147 5.69312 16.4616 5.69312 16.8496V18.8193C5.69312 20.2951 6.88952 21.4915 8.36535 21.4915H15.6346C17.1105 21.4915 18.3069 20.2951 18.3069 18.8193V16.8515C18.3069 16.4624 17.9915 16.147 17.6025 16.147Z"
                fill="#476DD6"
              />
              <line
                x1="7.5"
                y1="17.5"
                x2="16.5"
                y2="17.5"
                stroke="white"
                stroke-linecap="round"
              />
              <line
                x1="9.5"
                y1="19.5"
                x2="14.5"
                y2="19.5"
                stroke="white"
                stroke-linecap="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_2572_12598">
                <rect
                  width="22"
                  height="19.583"
                  fill="white"
                  transform="translate(1 2)"
                />
              </clipPath>
            </defs>
          </svg>
          <p class="fs-16 fw-500 txt-pri mb-0">Công việc</p>
        </div>
        <div class="detail-info-body pt-2">
          <p class="fs-14 fw-500 mb-1">Nơi công tác</p>
          <p class="fs-14 mb-1">{{doctor.work || 'Đang cập nhật'}}</p>
          <p class="fs-14 fw-500 mb-1">Chức vụ</p>
          <p class="fs-14 mb-0">{{doctor.chuc_vu || 'Đang cập nhật'}}</p>
        </div>
      </div>

      <div class="detail-info-wraper bg-white p-3 radius-10 mb-3 border">
        <div class="detail-info-header border-bottom d-flex align-items-center pb-2">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="me-2"
          >
            <path
              opacity="0.4"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M2.16122 8.45844H2.57591V13.185C2.66798 13.202 2.73693 13.282 2.73693 13.3793V15.2064C2.73693 15.3161 2.64911 15.404 2.53934 15.404H2.19783C2.08806 15.404 2.00024 15.3161 2.00024 15.2064V13.3793C2.00024 13.282 2.06917 13.202 2.16122 13.185V8.45844Z"
              fill="#476DD6"
            />
            <path
              d="M11.9876 5.00783L21.8451 8.19344C22.0516 8.26127 22.0516 8.65785 21.8451 8.72568L11.99 11.9922C11.9577 12.0026 11.9254 12.0026 11.893 11.9922L2.15488 8.76742C1.94837 8.69959 1.94837 8.30302 2.15488 8.23518L11.893 5.00783C11.9254 4.99739 11.9577 4.99739 11.9876 5.00783Z"
              fill="#476DD6"
            />
            <path
              opacity="0.4"
              d="M12 12.7335L5 10V14.2156C5 14.2156 9.9416 16.039 12 19C14.0584 16.039 19 14.2156 19 14.2156V10L12 12.7335Z"
              fill="#476DD6"
            />
          </svg>
          <p class="fs-16 fw-500 txt-pri mb-0">Học vấn</p>
        </div>
        <div class="detail-info-body pt-2">

          <div v-if="!doctor.edus || !doctor.edus.length">
            <p class="fs-14 mb-0">Đang cập nhật</p>
          </div>
          <div v-if="doctor.edus && doctor.edus.length">
            <div
              class="mb-3"
              v-for="edu in doctor.edus"
              :key="edu.id+'edu'"
            >
              <div class="d-flex w-100">
                <svg
                  width="10"
                  height="11"
                  viewBox="0 0 10 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="me-2 mt-1"
                >
                  <circle
                    cx="5"
                    cy="5.5"
                    r="4"
                    fill="white"
                    stroke="#476DD6"
                    stroke-width="2"
                  />
                </svg>
                <div class="w-100">
                  <p class="mb-0 fs-14 fw-500">{{edu.noi_tu_nghiep}}</p>
                  <div class="d-flex justify-content-between">
                    <span class="fs-14 txt-gray-blur">{{edu.chuyen_nganh}}</span>
                    <span class="fs-14 txt-gray-blur">{{edu.start_year}} / {{edu.end_year|| 'Hiện tại'}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="detail-info-wraper bg-white p-3 radius-10 mb-3 border">
        <div class="detail-info-header border-bottom d-flex align-items-center pb-2">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="me-2"
          >
            <path
              opacity="0.4"
              d="M14.4183 5.48994C13.9422 5.402 13.505 5.70579 13.4144 6.17048C13.3238 6.63516 13.6285 7.08884 14.0916 7.17978C15.4859 7.4516 16.5624 8.53085 16.8353 9.92989V9.93089C16.913 10.3336 17.2675 10.6264 17.6759 10.6264C17.7306 10.6264 17.7854 10.6214 17.8412 10.6114C18.3043 10.5185 18.609 10.0658 18.5184 9.60012C18.1111 7.51055 16.5027 5.89666 14.4183 5.48994Z"
              fill="#476DD6"
            />
            <path
              opacity="0.4"
              d="M14.356 2.00793C14.1329 1.97595 13.9088 2.04191 13.7305 2.18381C13.5473 2.32771 13.4328 2.53557 13.4079 2.76841C13.3551 3.23908 13.6947 3.66479 14.1648 3.71776C17.4064 4.07951 19.926 6.60477 20.2905 9.85654C20.3393 10.2922 20.7048 10.621 21.141 10.621C21.1739 10.621 21.2058 10.619 21.2386 10.615C21.4667 10.59 21.6699 10.4771 21.8133 10.2972C21.9557 10.1174 22.0204 9.89351 21.9945 9.66467C21.5404 5.60746 18.4003 2.45862 14.356 2.00793Z"
              fill="#476DD6"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M11.0317 12.9724C15.0208 16.9604 15.9258 12.3467 18.4656 14.8848C20.9143 17.3328 22.3216 17.8232 19.2192 20.9247C18.8306 21.237 16.3616 24.9943 7.6846 16.3197C-0.993478 7.644 2.76158 5.17244 3.07397 4.78395C6.18387 1.67385 6.66586 3.08938 9.11449 5.53733C11.6544 8.0765 7.04266 8.98441 11.0317 12.9724Z"
              fill="#476DD6"
            />
          </svg>

          <p class="fs-16 fw-500 txt-pri mb-0">Quá trình công tác</p>
        </div>
        <div class="detail-info-body pt-2">
          <div v-if="!doctor.exps || !doctor.exps.length">
            <p class="fs-14 mb-0">Đang cập nhật</p>
          </div>
          <div v-if="doctor.exps && doctor.exps.length">
            <div
              class="mb-3"
              v-for="exp in doctor.exps"
              :key="exp.id+'exp'"
            >
              <div class="d-flex w-100">
                <svg
                  width="10"
                  height="11"
                  viewBox="0 0 10 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="me-2 mt-1"
                >
                  <circle
                    cx="5"
                    cy="5.5"
                    r="4"
                    fill="white"
                    stroke="#476DD6"
                    stroke-width="2"
                  />
                </svg>
                <div class="w-100">
                  <p class="mb-0 fs-14 fw-500">{{exp.noi_cong_tac}}</p>
                  <div class="d-flex justify-content-between">
                    <span class="fs-14 txt-gray-blur">{{exp.chuc_vu}}</span>
                    <span class="fs-14 txt-gray-blur">{{exp.start_year}} / {{exp.end_year|| 'Hiện tại'}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-3">

        <button class="w-100 btn-lg btn bg-pri fw-500 fs-20 text-white" @click="$router.push({path: '/login'})">
          Kết nối với bác sĩ
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4 11.25C3.58579 11.25 3.25 11.5858 3.25 12C3.25 12.4142 3.58579 12.75 4 12.75V11.25ZM19.5303 12.5303C19.8232 12.2374 19.8232 11.7626 19.5303 11.4697L14.7574 6.6967C14.4645 6.40381 13.9896 6.40381 13.6967 6.6967C13.4038 6.98959 13.4038 7.46447 13.6967 7.75736L17.9393 12L13.6967 16.2426C13.4038 16.5355 13.4038 17.0104 13.6967 17.3033C13.9896 17.5962 14.4645 17.5962 14.7574 17.3033L19.5303 12.5303ZM4 12.75H19V11.25H4V12.75Z"
              fill="#ffffff"
            />
          </svg>

        </button>
      </div>
    </div>
  </div>
</template>

<script>
import appUtils from '../../utils/appUtils'
export default {
  name: 'DoctorDetail',
  data () {
    return {
      doctor: null
    }
  },
  mounted () {
    const self = this
    if (self.$route.params.code) {
      self.getDoctor()
    } else {
      self.$router.push({ name: 'Home' })
    }
  },
  methods: {
    async getDoctor () {
      const self = this
      self.$rf.getRequest('GuestRequest').showDoctor(self.$route.params.code).then((r) => {
        self.doctor = r.data
      })
    },
    getUrlImg (path) {
      return appUtils.getDocumentURL(path)
    }
  }
}
</script>

<style scoped>
.full {
  display: flex;
  justify-content: center;
  background-color: #f7f7f9;
  padding-bottom: 100px;
}
.detail {
  width: 100%;
  max-width: 500px;
  padding: 16px;
}
.avatar {
  width: 62px;
  height: 62px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}
.avatar img {
  width: 62px;
  height: 62px;
  border-radius: 12px;
  object-fit: cover;
}
</style>
